<template>
    <report-card-base
        ref="report"
        :title="'Deine Freizeit'"
        :icon="'mdi-tent'"
        :show-shrink-icon="false"
        v-if="data"
    >
        <template v-slot:header-tools>
            <v-btn 
                icon small
                @click="logout"
            >
                <v-icon>mdi-logout</v-icon>
            </v-btn>

        </template>

        <template v-slot:content>
            <v-container v-if="data"
                class="pa-0"
            >
                <v-row>
                    <v-col>
                        <h3>Hallo {{data.data_employee.vorname}},</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" :sm="!isPositionCamphelfer(data.data_employee)?6:12" class="d-flex">
                        <v-card class="flex grow-1">
                            <v-card-title class="pb-1 pt-1">
                                <v-icon>mdi-account</v-icon>Deine Position<span v-if="getPositionsCount(data.data_employee) > 1">   en</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                
                                <ol>
                                    <li v-for="(pos, camp) in getPositions(data.data_employee)" :key="camp">
                                        <span v-if="pos!=='Camphelfer'">
                                            <b>{{pos}}</b> in der {{camp}}.
                                        </span>
                                        <span v-else>
                                            <b>{{ pos }}</b>
                                        </span>
                                    </li>
                                </ol>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col 
                        v-if="!isPositionCamphelfer(data.data_employee)"
                        cols="12" sm="6" class="d-flex"
                    >
                        <v-card class="flex grow-1">
                            <v-card-title class="pb-1 pt-1">
                                <v-icon>mdi-calendar</v-icon> Freizeiten
                            </v-card-title>
                            <v-divider></v-divider>

                            <v-card-text>
                                
                                <div v-for="(days, camp) in getCampDates(getCamps(data.data_employee))" :key="camp">
                                    <div v-if="days > 0">
                                        Es {{ days===1?'ist':'sind' }} noch <b>{{days}}</b> {{ days===1?'Tag':'Tage' }} bis zur {{ camp }}
                                    </div>
                                    <div v-else-if="days === 0">
                                        Heute beginnt die {{camp}}
                                    </div>
                                    <div v-else>
                                        Die {{camp}} is bereits gestartet/geendet.
                                    </div>
                                </div>
                                <!-- Es sind noch <b>{{getDaysTillCamp(data.data_camp.freizeit[0].datum_von)}}</b> Tage bis zu deiner Freizeit. -->
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-card >
                            <div v-for="table, tableIndex in data.data_camp.freizeit_daten_tabellen" :key="tableIndex">
                                <v-card-title>
                                    <v-icon>
                                        {{ table.icon }}
                                    </v-icon>
                                    {{ table.title }}
                                </v-card-title>
                                <v-card-text>
                                    <v-simple-table
                                        dense
                                        style="border:1px solid silver;"
                                    >
                                        <thead>
                                            <tr>
                                                <th v-for="header, headerIndex in table.headers" :key="headerIndex">
                                                    {{ header }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="row, rowIndex in table.rows" :key="rowIndex">
                                                <td v-for="col, colIndex in row" :key="colIndex">
                                                    <strong v-if="colIndex === 0">
                                                        {{ col }}
                                                    </strong>
                                                    <span v-else>
                                                        {{ col }}
                                                    </span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </v-simple-table>
                                </v-card-text>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
                

                <v-row v-if="myFahrGemeinschaften && myFahrGemeinschaften.length > 0">
                    <v-col>
                        <v-card>
                            <v-card-title class="pb-1 pt-1">
                                <v-icon>mdi-car</v-icon>
                                Deine Fahrgemeinschaft/en    
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-simple-table 
                                    style="border:1px solid silver;"
                                    dense
                                >
                                    <thead>
                                        <tr>
                                            <th>Ziel der Fahrt</th>
                                            <th>Mitfahrer</th>
                                            <th>Abfahrt-Ort</th>
                                            <th>Abfahrt-Zeit</th>
                                            <th>Kommentar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item, index) in myFahrGemeinschaften" :key="index"
                                        >
                                            <td>
                                                {{ item.ereignis }}
                                            </td>
                                            <td
                                                v-if="getBelegtePlaetzeAnzahl(item.belegte_plaetze) > 0"
                                            >
                                                <div 
                                                     v-for="(mitfahrer, index) in item.belegte_plaetze" :key="index"
                                                >
                                                    <v-icon>mdi-circle-small</v-icon>{{mitfahrer.name}}
                                                </div>
                                            </td>
                                            <td v-else>
                                                -
                                            </td>
                                            <td>
                                                {{ item.abfahrt_ort }}
                                            </td>
                                            <td>
                                                {{ formatDate(item.abfahrt_datum) + " - " +item.abfahrt_zeit }}
                                            </td>
                                            <td>
                                                {{ item.kommentar }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row v-if="myMitfahrgelegenheiten && myMitfahrgelegenheiten.length > 0">
                    <v-col>
                        <v-card>
                            <v-card-title class="pb-1 pt-1">
                                <v-icon>mdi-car</v-icon>
                                Deine Mitfahrgelegenheiten/en   
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-simple-table 
                                    style="border:1px solid silver;"
                                    dense
                                >
                                    <thead>
                                        <tr>
                                            <th>Ziel der Fahrt</th>
                                            <th>Fahrer</th>
                                            <th>Abfahrt-Ort</th>
                                            <th>Abfahrt-Zeit</th>
                                            <th>Kommentar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item, index) in myMitfahrgelegenheiten" :key="index"
                                        >
                                            <td>
                                                {{ item.ereignis }}
                                            </td>
                                            <td>
                                                {{ item.fahrer }}
                                            </td>
                                            <td>
                                                {{ item.abfahrt_ort }}
                                            </td>
                                            <td>
                                                {{ formatDate(item.abfahrt_datum) + " - " +item.abfahrt_zeit }}
                                            </td>
                                            <td>
                                                {{ item.kommentar }}
                                            </td>

                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-card
                    flat
                >

                    <v-tabs
                        class="mt-4"
                        centered
                        v-model="tab"
                        show-arrows
                        small
                        :style="{
                            'position':'sticky',
                            'top':userData.backend?'120px':'60px', 
                            'z-index':'1'
                        }"
                    >
                        <v-tab
                            :href="'#tab-'+key"
                            v-for="(list, key) in data.data_lists" :key="key"
                        >   
                            <span>{{key}}</span>
                            
                        </v-tab>
    
                        
                        <v-tab
                            href="#tab-fahrgemeinschaften"
                        >
                            <v-icon>mdi-car</v-icon>
                        </v-tab>
                        <v-tab
                            href="#tab-dokumente"
                        >
                            <v-icon>mdi-file-document</v-icon>
                        </v-tab>
                    </v-tabs>
                    <v-divider></v-divider>
                    
                    <v-tabs-items
                        v-model="tab"
                        touchless
                    >
                        <v-tab-item
                            v-for="(campList, key) in data.data_lists" :key="key"
                            :value="'tab-' + key"
                            class="pa-0 ma-0"
                            :transition="false"
                        >   
                            <div
                                v-for="(list, index) in campList" :key="index"
                            >
                                <v-divider v-if="(list.people && list.people.length > 0) || list.type === 'MESSAGE_BOX'"></v-divider>
                                <v-alert 
                                    class="mt-2"
                                    v-if="list.type === 'MESSAGE_BOX'" 
                                    text border="left" 
                                    :color="list.color"
                                    :icon="list.icon"
                                >
                                    <strong>{{list.title}}</strong>
                                </v-alert>
                                <v-row
                                    v-if="list.people && list.people.length > 0"
                                    no-gutters
                                    class="mb-2"
                                    
                                >
                                    <v-col>
                                        <v-card-title
                                            class="pa-1"
                                        >
                                            <v-icon class="mr-1" v-if="!list.color">{{ list.icon }}</v-icon>
                                            <v-icon
                                                size="16"
                                                class="mr-1"
                                                v-if="list.color"
                                                :style="{'color':getProvinzColorByName(list.color, list.freizeit).color}"
                                            >
                                                mdi-circle
                                            </v-icon>
                                            <span
                                                class="text-subtitle-1"
                                            >
                                                {{list.title}}
                                            </span>
                                            
                                            <span
                                                v-if="list.color"
                                                class="text-subtitle-2"
                                            >
                                                ({{list.color}})
                                            </span>
                                        </v-card-title>
    
                                        <v-simple-table
                                            style="border:1px solid silver;"
                                            dense
                                        >
                                            <thead class="text-left">
                                                <tr>
                                                    <th></th>
                                                    <th
                                                        v-for="(field, index) in list.fields" :key="index"
                                                    >
                                                        {{field.label}}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                class="text-left"
                                            >   
                                                <tr
                                                    v-for="(person, index) in list.people" :key="index"
                                                >
                                                    <td>
                                                        <v-icon v-if="hasBirthday(person)" color="pink">mdi-party-popper</v-icon>
                                                        <v-icon 
                                                            size="20"
                                                            :color="person.geschlecht==='weiblich'||person.geschlecht==='Mädchen'?'pink lighten-4':'blue lighten-4'"
                                                        >
                                                            {{person.geschlecht==='weiblich'||person.geschlecht==='Mädchen'?'mdi-face-woman' : 'mdi-face-man'}}
                                                        </v-icon>
                                                    </td>
                                                    <td
                                                        v-for="(field, index) in list.fields" :key="index"
                                                    >   
                                                        <!-- <v-chip-group
                                                            v-if="Array.isArray(person[field.key])"
                                                        >
                                                            <v-chip
                                                                v-for="(item, index) in person[field.key]" :key="index"
                                                                label
                                                                small
                                                                class="ma-0 mr-2"
                                                            >
                                                                {{item}}
                                                            </v-chip>
                                                        </v-chip-group> -->

                                                        <span
                                                            v-if="Array.isArray(person[field.key])"
                                                        >
                                                            <div
                                                                v-for="(item, index) in person[field.key]" :key="index"
                                                            >
                                                                {{item}}<span v-if="index < person[field.key].length-1">,</span>
                                                            </div>
                                                        </span>
                                                        <span
                                                            v-else-if="field.key === 'geburtstag'"
                                                        >
                                                            {{formatDate(person[field.key])}}
                                                        </span>
                                                        <span
                                                            v-else
                                                        >
                                                            {{person[field.key]}}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-tab-item>


                        <v-tab-item
                            :value="'tab-fahrgemeinschaften'"
                            :transition="false"
                        >

                            <v-container flex class="pa-0 mt-2">
                                <v-card-title class="pa-1">
                                    <span class="text-subtitle-1">
                                        <v-icon>mdi-car</v-icon>
                                        Fahrgemeinschaften
                                    </span>
                                </v-card-title>
                                <v-row>
                                    <v-col>
                                        <v-simple-table
                                            style="border:1px solid silver;"
                                            dense
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Ziel der Fahrt</th>
                                                    <th>Fahrer</th>
                                                    <th>Plätze zum Mitfahren</th>
                                                    <th>Abfahrt-Ort</th>
                                                    <th>Abfahrt-Zeit</th>
                                                    <th>Kommentar</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in sortedMitfahrgelegenheiten" :key="index">
                                                    <td>
                                                        {{ item.ereignis }}
                                                    </td>
                                                    <td>
                                                        {{ item.fahrer }}
                                                    </td>
                                                    <td nowrap>
                                                        <span>
                                                            <v-tooltip bottom v-for="(platz, platzIndex) in item.belegte_plaetze" :key="platzIndex">
                                                                <span dark>
                                                                    {{ 'Belegt von '+platz.name }}
                                                                </span>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn icon v-bind="attrs" v-on="on">
                                                                        <v-icon @click="unbookSeat(index, platzIndex)" :color="name===platz.name&&email===platz.email?'pink darken-4':'pink'">
                                                                            {{platz.name===name?'mdi-account-badge':'mdi-account'}}
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-tooltip>
                                                        </span>

                                                        <span>
                                                            <v-tooltip  bottom v-for="platzIndex in (item.plaetze - getBelegtePlaetzeAnzahl(item.belegte_plaetze))" :key="platzIndex">
                                                                
                                                                <span dark>
                                                                    {{ 'Der Platz ist frei. Klick um den Platz zu buchen.' }}
                                                                </span>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn icon v-bind="attrs" v-on="on"
                                                                        @click="bookSeat(index, platzIndex)"
                                                                    >
                                                                        <v-icon color="success">
                                                                            mdi-account-plus
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-tooltip>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {{ item.abfahrt_ort }}
                                                    </td>
                                                    <td>
                                                        {{ formatDate(item.abfahrt_datum) + " - " +item.abfahrt_zeit }}
                                                    </td>
                                                    <td>
                                                        {{ item.kommentar }}
                                                    </td>
                                                    <td>
                                                        <v-btn
                                                            x-small icon class="text-error"
                                                            v-if="item.fahrer === name && item.email_fahrer === email || isAdministration"
                                                            @click="showMitfahrGelegenheitDialog(item)"
                                                        >
                                                            <v-icon>mdi-pencil</v-icon>
                                                        </v-btn>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-2" no-gutters>
                                    <v-spacer></v-spacer>
                                    <v-col
                                        class="text-right"
                                    >
                                        <v-btn small class="primary"
                                            @click="showMitfahrGelegenheitDialog()"
                                        >
                                            <v-icon
                                                size="16"
                                                class="mr-1"
                                            >
                                                mdi-plus
                                            </v-icon>
                                            Fahrgemeinschaft
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>

                        <v-tab-item
                            :value="'tab-dokumente'"
                            :transition="false"
                        >
                            <v-container v-if="data.shared_folders" class="pa-0 mt-2">
                                <span class="text-subtitle-1">
                                    <v-icon>mdi-file-document</v-icon>
                                    Dokumente & Dateien
                                </span>

                                <file-browser
                                    :root-folder-id="data.shared_folders[0]"
                                    :email="email"
                                    :password="password"
                                    :year="parseInt(userData.campYear)"
                                    :selectable="true"
                                    :show-tool-buttons="false"
                                    :windowed="false"
                                    :isDialog="false"
                                    :file-tools='["view","download"]'
                                    :file-extensions='[".png",".jpg",".jpeg",".webp",".gif",".pdf",".mp3"]'
                                />
                            </v-container>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>

                <popup-dialog-base
                    :title="'Erstelle eine Mitfahrgelegenheit'"
                    ref="MitfahrgelegenheitDialog"
                    :tool-buttons="dialogButtons"
                >
                    <template v-slot:content>
                        <v-card-text>

                            <!-- <v-container></v-container> -->
                            <v-row>
                                <v-col>
                                    <div v-if="isAdministration">
                                        Fahrer
                                        <v-text-field v-model="neueMitfahrgelegenheit.fahrer" class="mb-2" solo dense hide-details></v-text-field>
                                        Email Fahrer
                                        <v-text-field v-model="neueMitfahrgelegenheit.email_fahrer" class="mb-2" solo dense hide-details></v-text-field>
                                    </div>
                                    Ziel der Fahrt
                                    <v-select v-model="neueMitfahrgelegenheit.ereignis" class="mb-2" solo dense hide-details :items="getEreignisItems"></v-select>
                                    Plätze zum Mitfahren
                                    <v-text-field v-model="neueMitfahrgelegenheit.plaetze" min="1" type="number" class="mb-2" solo dense hide-details></v-text-field>
                                    <div v-if="isAdministration && neueMitfahrgelegenheit.id">
                                        Belegte Plätze
                                        <v-card>
                                            <v-card-text>
                                                <v-row
                                                    no-gutters
                                                    v-for="( mitfahrer, index ) in neueMitfahrgelegenheit.belegte_plaetze" :key="index"
                                                >
                                                    <v-col>
                                                        <v-text-field dense hide-details v-model="mitfahrer.name"/>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field dense hide-details v-model="mitfahrer.email"/>
                                                    </v-col>
                                                    <v-btn icon @click="()=>{neueMitfahrgelegenheit.belegte_plaetze.splice(index, 1)}"><v-icon>mdi-close</v-icon></v-btn>
                                                </v-row>
                                                <v-row>
                                                    <v-spacer></v-spacer>
                                                    <v-btn @click="()=>{neueMitfahrgelegenheit.belegte_plaetze.push({name:'',email:''})}" class="primary" small>
                                                        <v-icon>mdi-plus</v-icon>
                                                        Mitfahrer erstellen
                                                    </v-btn>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </div>
                                    
                                    Abfahrt-Ort
                                    <v-text-field v-model="neueMitfahrgelegenheit.abfahrt_ort" class="mb-2" solo dense hide-details></v-text-field>
                                    Abfahrt-Datum
                                    <v-text-field v-model="neueMitfahrgelegenheit.abfahrt_datum" class="mb-2" solo dense hide-details type="date"></v-text-field>
                                    Abfahrt-Zeit
                                    <v-text-field v-model="neueMitfahrgelegenheit.abfahrt_zeit" class="mb-2" solo dense hide-details type="time"></v-text-field>
                                    Kommentar
                                    <v-textarea solo height="100" v-model="neueMitfahrgelegenheit.kommentar"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </template>

                </popup-dialog-base>
                
                <confirm-dialog
                    ref="DeleteDialog"
                    title="Löschen"
                    :max-width="400"
                    icon="mdi-alert"
                    icon-color="error--text"
                    text=""
                    @onConfirm="deleteFahrgemeinschaft"
                >
                    <div>
                        <p>
                            Bist du sicher, dass du die Fahrgemeinschaft löschen willst? 
                            Bitte informiere die Mitfahrer, dass diese Fahrgemeinschaft nicht stattfindet.
                        </p>
                        <h4>
                            Mitfahrer
                        </h4>
    
                        <p>
                            <ul>
                                <li v-for="(person, index) in neueMitfahrgelegenheit.belegte_plaetze" :key="index">
                                    {{ person.name }}
                                </li>
                            </ul>
                        </p>
                    </div>

                </confirm-dialog>
            </v-container>
        </template>
    </report-card-base>
</template>

<script>
import PopupDialogBase from '../../../components/PopupDialogBase.vue'
import ReportCardBase from './ReportCardBase.vue'
import ConfirmDialog from '../../../components/ConfirmDialog.vue'
import FileBrowser from '../../../components/FileBrowser/FileBrowser.vue'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
dayjs.extend(isToday)

export default {
    components:{
        ReportCardBase,
        PopupDialogBase,
        ConfirmDialog,
        FileBrowser
    },
    props:{
        data:{type:Object},
        email:{type:String},
        password:{type:String}
    },
    data(){
        return {
            documentsMeta:undefined,
            tab:null,
            refreshTimer:undefined,
            dialogButtons:[
                {
                    label:"Erstellen", 
                    color:"primary", 
                    icon:"mdi-plus", 
                    method:()=>{this.createNewMitfahrgelegenheit()}, 
                    viewCondition:()=>{return this.neueMitfahrgelegenheit.id === undefined},
                    disabled:()=>{return false}
                },
                {
                    label:"Löschen", 
                    color:"error", 
                    icon:"mdi-delete", 
                    method:()=>{this.showDeleteFahrgemeinschaftDialog(this.neueMitfahrgelegenheit.id)}, 
                    viewCondition:()=>{return this.neueMitfahrgelegenheit.id !== undefined},
                    disabled:()=>{return false}
                },
                {
                    label:"Ändern", 
                    color:"primary", 
                    icon:"mdi-check", 
                    method:()=>{this.createNewMitfahrgelegenheit()}, 
                    viewCondition:()=>{return this.neueMitfahrgelegenheit.id !== undefined},
                    disabled:()=>{return false}
                }
            ],
            neueMitfahrgelegenheit:{
                jahr:0,
                fahrer:undefined,
                email_fahrer:undefined,
                ereignis:undefined,
                belegte_plaetze:[],
                plaetze:1,
                abfahrt_ort:undefined,
                abfahrt_datum:undefined,
                abfahrt_zeit:undefined,
                kommentar:"",
            },
            table:{
                header:[
                    ""
                ]
            },
            fahrgemeinschaftMetaData:undefined,
        }
    },
    inject:[
        "userData"
    ],

    async created(){
        // let url = "/fields/camp/dokumente"
        // let result = await this.$network.requestGet(url);
        // if(result.status === 200){
        //     this.documentsMeta = result.data.data.meta;
        // }
        this.fahrgemeinschaftMetaData = await this.$network.getFieldsData("fahrgemeinschaft");
    },
    computed:{
        isAdministration(){
            return this.data.data_employee.is_administration;
        },
        name(){
            return this.data.data_employee.vorname + " " + this.data.data_employee.nachname;
        },
        // email(){
        //     return this.data.data_employee.email;
        // },
        getEreignisItems(){
            let arr = [];
            if(this.fahrgemeinschaftMetaData){
                for(let field of this.fahrgemeinschaftMetaData){
                    if(field.field === "ereignis"){
                        if(field.meta.options && field.meta.options.choices){
                            for(let choice of field.meta.options.choices){
                                arr.push(choice)
                            }
                        }
                    }
                }
            }
            // return arr.concat(this.freizeitenKeys);
            return arr;
        },
        myFahrGemeinschaften(){
            let items = []
            if(this.data.data_fahrgemeinschaft){
                for(let item of this.data.data_fahrgemeinschaft){
                    if(item.fahrer === this.name && item.email_fahrer === this.email){
                        items.push(item)
                    }
                }
            }
            return items;
        },
        myMitfahrgelegenheiten(){
            let items = []
            if(this.data.data_fahrgemeinschaft){
                for(let item of this.data.data_fahrgemeinschaft){
                    if(item.belegte_plaetze){
                        for(let platz of item.belegte_plaetze){
                            if(platz.name === this.name && platz.email === this.email){
                                items.push({
                                    fahrer:item.fahrer,
                                    ereignis:item.ereignis,
                                    abfahrt_ort:item.abfahrt_ort,
                                    abfahrt_datum:item.abfahrt_datum,
                                    abfahrt_zeit:item.abfahrt_zeit,
                                    kommentar:item.kommentar
                                })
                            }
                        }
                    }
                }
            }

            return items;
        },
        sortedMitfahrgelegenheiten(){
            let data = JSON.parse(JSON.stringify(this.data.data_fahrgemeinschaft))
            data.sort((a, b)=>{
                if(a.ereignis < b.ereignis){
                    return -1;
                }else if(a.ereignis > b.ereignis){
                    return 1;
                }
                return 0
            })
            return data;
        },
        freizeitenKeys(){
            let keys = []
            for(let camp of this.data.data_camp.freizeit){
                keys.push("Freizeit "+camp.name)
            }
            return keys;
        }
    },
    methods:{
        showDeleteFahrgemeinschaftDialog(id){
            this.$refs.DeleteDialog.show(id)
        },
        async deleteFahrgemeinschaft(id){
            let url = "/items/fahrgemeinschaft/"+id.toString();
            let result = await this.$network.requestDelete(url);
            if(result.status === 204){
                let message = "Deine Fahrgemeinschaft wurde gelöscht."
                this.userData.app.showNotification(message, undefined, "success")
            }
            let data = JSON.parse(JSON.stringify(this.data))
            for(let index in data.data_fahrgemeinschaft){
                let fahrgemeinschaft = data.data_fahrgemeinschaft[index];
                if(fahrgemeinschaft.id == id){
                    data.data_fahrgemeinschaft.splice(index, 1);
                }
            }

            this.$emit("updateData",data);
            this.$refs.MitfahrgelegenheitDialog.hide()
        },
        getBelegtePlaetzeAnzahl(belegte_plaetze){
            if(belegte_plaetze){
                return belegte_plaetze.length;
            }
            return 0;
        },
        showMitfahrGelegenheitDialog(data){
            if(data){
                this.neueMitfahrgelegenheit = data
            }else{
                this.neueMitfahrgelegenheit = {
                    jahr:0,
                    fahrer:this.name,
                    email_fahrer:this.email,
                    ereignis:undefined,
                    plaetze:1,
                    belegte_plaetze:[],
                    abfahrt_ort:undefined,
                    abfahrt_datum:undefined,
                    abfahrt_zeit:undefined,
                    kommentar:undefined,
                }
            }
            this.$refs.MitfahrgelegenheitDialog.show()
        },
        async modifyMitfahrgelegenheit(){

        },
        async createNewMitfahrgelegenheit(){
            // check before creating
            if(!this.neueMitfahrgelegenheit.ereignis){
                this.userData.app.showNotification("Du musst das Ziel für die Fahrgemeinschaft noch wählen.", undefined, "error")
                return
            }else if(this.neueMitfahrgelegenheit.plaetze < 1){
                this.userData.app.showNotification("Du musst mindestens einen Platz anbieten.", undefined, "error")
                return
            }else if(!this.neueMitfahrgelegenheit.abfahrt_ort){
                this.userData.app.showNotification("Du musst den Abfahrt-Ort noch definieren.", undefined, "error")
                return
            }else if(!this.neueMitfahrgelegenheit.abfahrt_datum){
                this.userData.app.showNotification("Du musst noch ein Abfahrt-Datum definieren.", undefined, "error")
                return
            }else if(!this.neueMitfahrgelegenheit.abfahrt_zeit){
                this.userData.app.showNotification("Du musst noch eine Abfahrt-Zeit definieren.", undefined, "error")
                return
            }

            // create new fahrgemeinschaft after check passed
            let data = JSON.parse(JSON.stringify(this.neueMitfahrgelegenheit));

            let payload = data;
            payload.jahr = this.userData.campYear;
            let result
            if(!payload.id){
                result = await this.$network.requestPost("/items/fahrgemeinschaft", payload, true);
            }else{
                while (payload.belegte_plaetze.length > Math.max(0,payload.plaetze)) { payload.belegte_plaetze.pop();}
                result = await this.$network.requestPatch("/items/fahrgemeinschaft/"+payload.id.toString(), payload, true);
            }
            
            if(result.status === 204){
                this.neueMitfahrgelegenheit = {
                    jahr:0,
                    fahrer:this.name,
                    email_fahrer:this.email,
                    ereignis:undefined,
                    plaetze:1,
                    belegte_plaetze:[],
                    abfahrt_ort:undefined,
                    abfahrt_datum:undefined,
                    abfahrt_zeit:undefined,
                    kommentar:undefined,
                }
                this.$refs.MitfahrgelegenheitDialog.hide();
            }
            this.userData.loadSessionCookie(true);
        },
        async unbookSeat(index, platzIndex){
            let clickedPlatz = this.data.data_fahrgemeinschaft[index].belegte_plaetze[platzIndex];
            if(clickedPlatz.name === this.name){
                await this.bookSeat(index, platzIndex, true)
            }
        },
        async bookSeat(index, platzIndex, clear){
            let ereignis = this.data.data_fahrgemeinschaft[index].ereignis
            let alreadyBooked = false;
            let alreadyBookedAt = ""
            
            for(let item of this.data.data_fahrgemeinschaft){
                if(item.ereignis === ereignis && item.belegte_plaetze){
                    for(let platz of item.belegte_plaetze){
                        if(platz.name === this.name && platz.email === this.email){
                            alreadyBooked = true;
                            alreadyBookedAt = item.fahrer;    
                            break
                        }
                    }
                }
                if(alreadyBooked){
                    break;
                }
            }

            let data = JSON.parse(JSON.stringify(this.data));
            let data_fahrgemeinschaft = JSON.parse(JSON.stringify(this.sortedMitfahrgelegenheiten));
            if(clear){
                data_fahrgemeinschaft[index].belegte_plaetze.splice(platzIndex, 1)
                let url = "/items/fahrgemeinschaft/"+data_fahrgemeinschaft[index].id.toString();
                await this.$network.requestPatch(url,data_fahrgemeinschaft[index]);
                let message = "Du hast deine Mitfahrgelegenheit bei "+data_fahrgemeinschaft[index].fahrer+" abgesagt.";
                this.userData.loadSessionCookie(true);
                this.userData.app.showNotification(message, undefined, "success")
            }else if(!alreadyBooked){
                if(!data_fahrgemeinschaft[index].belegte_plaetze && this.getBelegtePlaetzeAnzahl(data_fahrgemeinschaft[index].belegte_plaetze) === 0){
                    data_fahrgemeinschaft[index].belegte_plaetze = [];
                }
                data_fahrgemeinschaft[index].belegte_plaetze.push({name:this.name, email:this.email})
                let url = "/items/fahrgemeinschaft/"+data_fahrgemeinschaft[index].id.toString();
                let payload = {
                    belegte_plaetze: JSON.parse(JSON.stringify(data_fahrgemeinschaft[index].belegte_plaetze))
                }
                await this.$network.requestPatch(url,payload);
                let message = "Du hast eine Mitfahrgelegenheit bei "+data.data_fahrgemeinschaft[index].fahrer+" gebucht.";
                this.userData.loadSessionCookie(true);
                this.userData.app.showNotification(message, undefined, "success")
            }else{
                let message = "Du hast bereits eine Mitfahrgelegenheit bei "+alreadyBookedAt+" gebucht."
                this.userData.app.showNotification(message, undefined, "warning")
            }

            data.data_fahrgemeinschaft = data_fahrgemeinschaft
            this.$emit("updateData",data)
        },
        hasBirthday(person){
            if(!person.geburtstag){
                return false;
            }
            let birthday = dayjs(person.geburtstag).year(dayjs().year());
            return birthday.isToday();
        },
        logout(){
            this.$cookie.delete('camp_data');
            this.userData.data = undefined;
        },
        isPositionCamphelfer(person){
            if(person.mitarbeiter_position){
                return person.mitarbeiter_position.Freizeit !== undefined;
            }
            return false;
        },
        isPositionGL(person, freizeitKey){
            if(person.mitarbeiter_position){
                return person.mitarbeiter_position && person.mitarbeiter_position[freizeitKey].position === "Gruppenleiter/in"
            }
            return false;
        },
        formatDate(date){
            return dayjs(date).format('DD.MM.YYYY');
        },
        getCamps(data){
            let camps = [];
            for(let campKey in data.mitarbeiter_position){
                let position = data.mitarbeiter_position[campKey].position
                if(position){
                    camps.push(campKey);
                }
            }
            return camps;
        },
        getProvinzColorByName(color, freizeit){
            for(let c of this.getFreizeitByName(freizeit).provinzen){
                if (color && c.key === color){
                    return c;
                }
            }
            return undefined;
        },
        getFreizeitByName(name){
            for(let freizeit of this.data.data_camp.freizeit){
                if(freizeit.name === name){
                    return freizeit;
                }
            }
            return undefined;
        },
        getCampDates(camps){
            let campDates = {}
            for(let camp of this.data.data_camp.freizeit){
                if(camps.includes(camp.name)){
                    campDates[camp.name] = this.getDaysTillCamp(camp.datum_von);
                }
            }
            return campDates;
        },
        getPositionsCount(data){
            let positions = this.getPositions(data);
            let count = 0
            for(let key in positions){
                key
                count += 1
            }
            return count;
        },
        getPositions(data){
            let positions = {};
            for(let campKey in data.mitarbeiter_position){
                let position = data.mitarbeiter_position[campKey].position
                if(position){
                    positions[campKey] = position;
                }
            }
            return positions;
        },
        getDaysTillCamp(date){
            let date1 = dayjs(date)
            let dateToday = dayjs();
            return date1.diff(dateToday, "day") + 1;
        },
        async requestData(initialLoad=false){
            if(initialLoad){
                this.$refs.report.setLoading(true);
            }
            this.$refs.report.setLoading(false);
        },
    }
}
</script>

<style>
    .striped {
        background-color: rgba(0, 0, 0, .03);
    }
    .capitalize {
        text-transform: capitalize;
    }
    .custom-table{
        border-right:1px solid silver;
    }
    .custom-row{
        border-radius: 0;
        border-bottom:1px solid silver;
    }
    .custom-col{
        border-bottom:1px solid silver;
    }
</style>