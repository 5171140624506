<template>
    <div style="z-index:1000;">
        <v-btn
            v-if="showButton"
            fab
            x-small
            :class="buttonColor"
            @click="show()"
            :disabled="buttonDisabled"
        >
            <v-icon>
                {{ buttonIcon }}
            </v-icon>
        </v-btn>
        <v-dialog 
            :persistent="persistent"
            :width="width"
            :max-width="maxWidth" v-model="dialog"
        >
            <v-card>
                <v-card-title >
                    <v-icon class="mr-1 mb-1" :class="iconColor">{{icon}}</v-icon>{{title}}
                </v-card-title>
                <v-card-text>
                    <slot name="content">
                        <v-row>
                            {{text}}
                        </v-row>
                        <v-row>

                            <v-text-field
                                ref="textInput"
                                v-if="textInput" 
                                solo hide-details dense
                                v-model="textValue"
                                @change="onTextEnter"
                            />
                            <slot></slot>
                        </v-row>
                    </slot>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <slot name="actions">
                        <v-spacer/>
                        <v-btn ref="delBtn" :disabled="buttonsDisabled" small @click="clickOnConfirm" class="primary">{{ confirmLabel }}</v-btn>
                        <v-btn :disabled="buttonsDisabled" small @click="onCancel(); hide();">{{ cancelLabel }}</v-btn>
                        <v-spacer/>
                    </slot>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name:"ConfirmDialog",
    props:{
        buttonIcon:{type:String, default:"mdi-cursor-default"},
        buttonColor:{type:String, default:"primary"},
        showButton:{type:Boolean, default:false},
        icon:{type:String, default:"mdi-information"},
        iconColor:{type:String, default:"primary--text"},
        title:{type:String, default:"Title"},
        text:{type:String, default:"Text"},
        textInput:{type:Boolean, default:false},
        onConfirm:{type:Function, default:()=>{}},
        onCancel:{type:Function, default:()=>{}},
        width:{type:Number, default:250},
        maxWidth:{type:Number, default:250},
        hideOnConfirm:{type:Boolean, default:true},
        buttonDisabled:{type:Boolean, default:false},
        confirmLabel:{type:String, default:"Ja"},
        cancelLabel:{type:String, default:"Nein"},
    },
    data(){
        return{
            textValue:"",
            dialog:false,
            buttonsDisabled:false,
            persistent:false,
            confirmData:undefined,
        }
    },
    methods:{
        onTextEnter(){
            if(this.textValue !== ""){
                this.clickOnConfirm()
            }
        },
        clickOnConfirm(){
            this.onConfirm();
            if(this.hideOnConfirm){
                this.hide();
            }
            if(this.textInput){
                this.$emit("onConfirm",this.textValue);
                this.textValue = "";
            }else{
                this.$emit("onConfirm",this.confirmData);
            }
        },
        makePersistent(value){
            this.persistent = value;
        },
        disableButtons(){
            this.buttonsDisabled = true;
        },
        enableButtons(){
            this.buttonsDisabled = false;
        },
        show(data){
            this.confirmData = data;
            this.dialog=true;
            if(this.textInput){
                this.$nextTick(()=>{
                    this.$nextTick(()=>{
                        this.$refs.textInput.focus();
                    })
                })
            }
            this.$emit("onShow")
        },
        hide(){
            this.dialog=false;
            this.$emit("onHide")
        }
    }
}
</script>