<template>
    <popup-dialog-base
        title="Teilnehmer-Bestätigung"
        ref="editorDialog"
        :toolButtons="buttons"
    >
        <template v-slot:content>
            <v-container full-width class="pa-0" v-if="!edit">
                <v-row class="ma-0 pa-0">
                    <v-col
                        cols="12"
                        class="mb-0 pb-0"
                    >
                        <v-form>
                            <div v-for="(prop, key) in emailVariables" :key="key"
                            >
                                <v-select
                                    v-if="prop.options !== undefined && !prop.hide"
                                    :items="prop.options"
                                    @change="updateEmailBody()"
                                    class="mb-2"
                                    dense
                                    solo
                                    hide-details
                                    :label="emailVariables[key].key" 
                                    v-model="emailVariables[key].value"
                                />
                                <v-text-field
                                    v-else-if="typeof prop.value === 'string' && prop.key && !prop.hide"
                                    @change="updateEmailBody()"
                                    class="mb-2"
                                    dense
                                    outlined
                                    hide-details
                                    :label="emailVariables[key].key" 
                                    v-model="emailVariables[key].value"
                                />
                                <v-checkbox
                                    @change="updateEmailBody()"
                                    class="mb-2"
                                    dense
                                    hide-details
                                    :label="emailVariables[key].key"
                                    v-else-if="typeof prop.value === 'boolean' && prop.key && !prop.hide"
                                    v-model="emailVariables[key].value"
                                />
                            </div>
                        </v-form>
                    </v-col>
                    <v-col
                        cols="12"
                        ref="editorCol"
                    >
                        <!-- <h3>Email</h3>
                        <v-divider class="mb-2"></v-divider> -->
                        <v-text-field label="Betreff" class="mb-2" hide-details dense solo v-model="betreff"></v-text-field>

                        <interface-text-editor
                            v-if="$refs.editorDialog !== undefined && requestedPersonData !== undefined"
                            v-model="email"
                        />
                    </v-col>

                </v-row>

                
                <!-- Attachments -->
                <v-row class="ma-0 pa-0">
                    <v-col class="pb-0 pt-1">
                        <interface-attachment
                            v-if="attachments && emailVariables.status.value === 'Bestätigt'"
                            v-model="attachments"
                            :allowSonderUrlaub="false"
                            :emailPreview="true"
                            :collection="'teilnehmer_bestaetigung'"
                            :postDeleteMethod="editTemplates"
                        />
                    </v-col>
                </v-row>
            </v-container>

            
            <v-container full-width v-else-if="edit && emailBlocks">
                <v-row v-for="(emailBlock, key) in emailBlocks" :key="key">
                    <v-col v-if="emailBlock.type !=='integer'">
                        <h3>{{emailBlock.label}}</h3>
                        <!-- {{emailBlock.type}} -->
                        <interface-text-editor
                            v-if="emailBlock.interface === 'input-rich-text-html'"
                            v-model="emailBlocks[key].value"
                        />
                        <v-text-field
                            v-else-if="emailBlock.interface === 'input'"
                            solo
                            hide-details
                            v-model="emailBlocks[key].value"
                        />
                        <v-combobox
                            v-else-if="emailBlock.interface === 'select-dropdown'"
                            @keydown="$event.target.blur()"
                            @keypress="$event.target.blur()"
                            @keyup="$event.target.blur()"
                            solo
                            hide-details
                            :items="app.emailAdresses"
                            v-model="emailBlocks[key].value"
                        />

                        <!-- Attachments -->
                        <div
                            v-else-if="emailBlock.interface === 'files'"
                        >
                            <interface-attachment
                                v-if="attachments"
                                v-model="attachments"
                                :allowSonderUrlaub="false"
                                :collection="'teilnehmer_bestaetigung'"
                                :postDeleteMethod="editTemplates"
                            />
                            <interface-file-upload 
                                :uploadFolder="'Teilnehmer Bestätigung'"
                                :collection="'teilnehmer_bestaetigung'"
                                :itemId="emailBlocks.id.value"
                                :preUploadMethod="()=>{$refs.editorDialog.setLoading(true)}"
                                :postUploadMethod="editTemplates"
                            />
                        </div>
                    </v-col>
                </v-row>
                
            </v-container>

            <error-dialog 
                ref="ErrorDialog"
            />
        </template>

    </popup-dialog-base>
</template>

<script>
import dayjs from 'dayjs';
import PopupDialogBase from '../PopupDialogBase.vue';
import InterfaceFileUpload from '../interfaces/InterfaceFileUpload.vue';
import InterfaceAttachment from '../interfaces/InterfaceAttachment.vue';
import InterfaceTextEditor from '../interfaces/InterfaceTextEditor.vue';
import ErrorDialog from '../ErrorDialog.vue';


export default {
    components:{
        PopupDialogBase,
        InterfaceFileUpload,
        InterfaceAttachment,
        InterfaceTextEditor,
        ErrorDialog
    },
    props:{
        peopleManager:Object,
    },
    inject:[
        "app"
    ],
    data(){
        return {
            buttons:[
                {label:"Abbrechen", color:"error", icon:"", method:this.hide, viewCondition:()=>{return !this.edit}, disabled:()=>{return this.sending}},
                {label:"Vorlagen bearbeiten", color:"primary", icon:"mdi-pencil", method:this.editTemplates, viewCondition:()=>{return !this.edit}, disabled:()=>{return this.sending}},
                {label:"Email senden", color:"primary", icon:"mdi-mail-send", method:this.sendEmail, viewCondition:()=>{return !this.edit}, disabled:()=>{return this.sending || this.emailVariables.status.value === ''}},
                {label:"Zurück", color:"error", icon:"", method:()=>{this.edit=false}, viewCondition:()=>{return this.edit}},
                {label:"Vorlagen speichern", color:"primary", icon:"mdi-check", method:this.saveTemplates, viewCondition:()=>{return this.edit}},
            ],
            sending:false,
            id:undefined,
            userId:-1,
            requestedPersonData:undefined,
            freizeitData:undefined,
            senderEmail:undefined,
            emailVariables:{
                status:{key:"Status",value:"", options:["Bestätigt","Warteliste","Absage","Abgemeldet"]},
                vorname:{key:"Vorname",value:"", hide:true},
                nachname:{key:"Nachname",value:"", hide:true},
                email:{key:"Email",value:"", hide:true},
                freizeit:{key:"Freizeit",value:"", hide:true},
                freizeit_datum:{key:"Frezeit Datum",value:"",hide:true},
                freizeit_kosten:{key:"Freizeit Kosten",value:"",hide:true},
                kosten_gesamt:{key:"Kosten Gesamt",value:"",hide:true},
                bearbeiter:{key:"Bearbeiter",value:"", hide:true},
                bearbeiter_telefon:{key:"Bearbeiter Telefon",value:"", hide:true}
            },
            betreff:"",
            email:"",
            maxEditorHeight:0,
            edit:false,
            emailBlocks:undefined,
            attachments:undefined,
        }
    },
    methods:{
        async loadAttachments(attachments){
            // load email attachments
            this.attachments = []
            for(let attachment of attachments){
                if(attachment.directus_files_id){
                    let result = await this.$network.requestGet("/files/"+attachment.directus_files_id);
                    if (result.status === 200){
                        this.attachments.push({
                            id:attachment.id, 
                            data:result.data.data
                        });
                    }
                }else{
                    this.attachments.push({
                        id:attachment.id,
                        data:{id:undefined, filename_download:undefined}
                    })
                }
            }
        },
        async getEmbeddedAttachments(email){
            var el = document.createElement('div');
            el.innerHTML = email;
            var images = el.getElementsByTagName('img');
            var attachments = []
            for(let i=0; i<images.length; i++){
                let img = images[i];
                var path = img.src;
                var id = path.split("/")[path.split("/").length-1]
                let url = "/files/"+id;
                let result = await this.$network.requestGet(url);
                let filename = "";
                if(result.status === 200){
                    console.log(result.data.data)
                    filename = result.data.data.filename_download;
                }
                attachments.push({
                    filename:filename,
                    path:path,
                    cid:filename
                })
                img.src = "cid:"+filename
            }
            return {html:el.innerHTML, attachments:attachments};
        },
        getAttachments(){
            let attachments = [];
            for(let attachment of this.attachments){
                let url = "./uploads/"+attachment.data.filename_disk
                attachments.push({
                    filename:attachment.data.filename_download,
                    path:url
                });
            }
            return attachments;
        },
        async getFilteredEmailBlocks(emailBlocks){
            let blocks = undefined         
            let result = await this.$network.requestGet("/fields/teilnehmer_bestaetigung");
            if(result.status === 200){
                blocks = {};
                let fields = result.data.data.sort((a,b)=>{
                    return a.meta.sort - b.meta.sort;
                });
                for(let field of fields){
                    blocks[field.field] = {
                        value:emailBlocks[field.field],
                        interface:field.meta.interface,
                        label:"",
                        type:field.type
                    };
                    
                    if(field.meta.translations){
                        for(let trans of field.meta.translations){
                            if(trans.language === "de-DE"){
                                blocks[field.field].label = trans.translation;
                            }
                        }
                    }
                }
            }
            return blocks
        },
        async editTemplates(){
            this.$refs.editorDialog.setLoading(true);
            this.edit = true;
            let year = this.peopleManager.dashboard.activeCampYear;
            let url = "/items/camp?filter[jahr][_eq]="+year.toString()
            url += "&fields=teilnehmer_bestaetigung.*,teilnehmer_bestaetigung.anhang.*"
            let campResult = await this.$network.requestGet(url)
            if(campResult.status === 200){
                this.emailBlocks = await this.getFilteredEmailBlocks(campResult.data.data[0].teilnehmer_bestaetigung);
            }
            await this.loadAttachments(this.emailBlocks.anhang.value);
            this.$refs.editorDialog.setLoading(false);
        },
        async saveTemplates(){
            this.edit = false;
            let url = "/items/teilnehmer_bestaetigung/"+this.emailBlocks.id.value.toString();
            let payload = {}
            for(let key in this.emailBlocks){
                payload[key] = this.emailBlocks[key].value;
            }
            let result = await this.$network.requestPatch(url,payload);
            if(result.status === 200){
                this.$refs.editorDialog.showNotification("Vorlagen gespeichert.")
            }
            this.emailBlocks = undefined
            this.loadEmailBlocks()
        },
        updateEmailBody(){
            if(this.requestedPersonData){
                this.loadEmailBlocks();
            }
        },
        changeStatusDate(oldStatus, newStatus){
            if(oldStatus != newStatus){
                return dayjs();
            }
            return undefined;
        },
        async sendEmail(){
            let attachmentsEmbedded = await this.getEmbeddedAttachments(this.email);
            let email = {
                from:this.senderEmail,
                to:this.emailVariables.email.value,
                subject:this.betreff,
                html:attachmentsEmbedded.html,
                attachments:attachmentsEmbedded.attachments
            }
            if(this.emailVariables.status.value === 'Bestätigt'){
                let attachments = this.getAttachments();
                email["attachments"] = email["attachments"].concat(attachments);
            }
            let payload = {
                email:email,
                data:{}
            }
            this.sending = true;
            this.$refs.editorDialog.setLoading(true);
            let result = await this.$network.requestPost("/sendmail", payload);
            if (result.status === 200){
                this.sending = false;
                this.$refs.editorDialog.setLoading(false);
                this.$refs.editorDialog.showNotification("Email wurde erfolgreich gesendet.")

                let payload = {
                    "status_person":this.emailVariables.status.value,
                    "status_aenderung_datum":this.changeStatusDate(this.requestedPersonData.status_person, this.emailVariables.status.value)
                };
                await this.$network.requestPatch("/items/"+this.peopleManager.collectionWithYear+"/"+this.id, payload);
                for(let item of this.peopleManager.peopleData){
                    if (item.id === this.id){
                        item.status_person = this.emailVariables.status.value;
                        item.status_aenderung_datum = payload.status_aenderung_datum;
                    }
                }
                this.hide();
            }else{
                this.$refs.editorDialog.setLoading(false);
                this.$refs.editorDialog.showNotification("Es ist ein Fehler beim versenden aufgetreten.","error","mdi-alert");
                this.sending = false;
                this.$refs.ErrorDialog.show(JSON.stringify(result.message))
            }
        },
        hide(){
            this.$refs.editorDialog.hide();
        },
        async show(){
            await this.loadCampVariables();
            this.edit = false;
            this.email = "";
            this.betreff = "";
            this.$refs.editorDialog.show();
            this.requestedPersonData = undefined;
            this.emailVariables.status.value = '';
        },
        getMaxEditorHeight(){
            return (Math.floor(window.innerHeight*0.48)).toString();
        },
        async loadEmailVariables(id){
            this.id = id;
            let bearbeiterResult = await this.$network.requestGet("/users/me");
            if(bearbeiterResult.status === 200){
                this.emailVariables.bearbeiter.value = bearbeiterResult.data.data.first_name + " " + bearbeiterResult.data.data.last_name
                this.emailVariables.bearbeiter_telefon.value = bearbeiterResult.data.data.mobil_telefon;
                let result = await this.$network.requestGet("/items/"+this.peopleManager.collectionWithYear+"/"+id)
                if (result.status === 200){
                    this.requestedPersonData = result.data.data;
                    
                    this.emailVariables.vorname.value = this.requestedPersonData.vorname;
                    this.emailVariables.nachname.value = this.requestedPersonData.nachname;
                    this.emailVariables.email.value = this.requestedPersonData.email;
                    this.emailVariables.freizeit.value = this.requestedPersonData.freizeit;
                    this.updateEmailBody();
                    this.freizeitData = await this.loadFrezeitData();
                    this.emailVariables.freizeit_datum.value = this.getFreizeitDate(this.requestedPersonData.freizeit);
                    this.emailVariables.freizeit_kosten.value = this.$utils.buildCheckoutTable(this.requestedPersonData, this.camp, this.getFreizeit(this.requestedPersonData.freizeit));
                    this.emailVariables.kosten_gesamt.value = this.$utils.getFreizeitKostenGesamt(this.requestedPersonData, this.camp, this.getFreizeit(this.requestedPersonData.freizeit));
                }
            }
        },
        getFreizeit(name){
            for(let freizeit of this.freizeitData){
                if(freizeit.name === name){
                    return freizeit;
                }
            }
            return undefined;
        },
        getFreizeitDate(name){
            for(let freizeit of this.freizeitData){
                if(freizeit.name === name){
                    return dayjs(freizeit.datum_von).format("DD.MM") + " - " + dayjs(freizeit.datum_bis).format("DD.MM.YYYY")
                }
            }
            return ""
        },
        async loadFrezeitData(){
            let year = this.peopleManager.dashboard.activeCampYear;
            let result = await this.$network.requestGet("/items/camp?filter[jahr][_eq]="+year+"&fields=freizeit.*");
            if(result.status === 200){
                return result.data.data[0].freizeit;
            }
            return undefined;
        },
        async loadCampVariables(){
            let year = this.peopleManager.dashboard.activeCampYear;
            let url = "/items/camp?filter[jahr][_eq]="+year.toString();
            url += "&fields=jahr,tasse_preis,tshirt_preis";
            let campResult = await this.$network.requestGet(url)
            if(campResult.status === 200){
                this.camp = campResult.data.data[0];
            }
        },
        async loadEmailBlocks(){
            let year = this.peopleManager.dashboard.activeCampYear;
            let url = "/items/camp?filter[jahr][_eq]="+year.toString()
            url += "&fields=teilnehmer_bestaetigung.*,teilnehmer_bestaetigung.anhang.*"
            let campResult = await this.$network.requestGet(url)
            if(campResult.status === 200){
                let emailBlocks = campResult.data.data[0].teilnehmer_bestaetigung;
                this.senderEmail = emailBlocks.absender;
                
                this.loadAttachments(emailBlocks.anhang)

                // this.betreff = this.replaceVariables(emailBlocks.betreff);
                if(this.emailVariables.status.value === 'Bestätigt'){
                    this.email = this.replaceVariables(emailBlocks.email_bestaetigt);
                    this.betreff = this.replaceVariables(emailBlocks.betreff_bestaetigt);
                }else if(this.emailVariables.status.value === 'Absage'){
                    this.email = this.replaceVariables(emailBlocks.email_absage);
                    this.betreff = this.replaceVariables(emailBlocks.betreff_absage);
                }else if(this.emailVariables.status.value === 'Warteliste'){
                    this.email = this.replaceVariables(emailBlocks.email_warteliste);
                    this.betreff = this.replaceVariables(emailBlocks.betreff_warteliste);
                }else if(this.emailVariables.status.value === 'Abgemeldet'){
                    this.email = this.replaceVariables(emailBlocks.email_abgemeldet);
                    this.betreff = this.replaceVariables(emailBlocks.betreff_abgemeldet);
                }
                
            }
        },
        replaceVariables(text){
            for(let key in this.requestedPersonData){
                let value = this.requestedPersonData[key];
                let find = "{{"+key+"}}";
                let re = new RegExp(find, 'g');
                text = text.replace(re, value);
            }
            for(let key in this.emailVariables){
                let value = this.emailVariables[key].value;
                let find = "{{"+key+"}}";
                let re = new RegExp(find, 'g');
                text = text.replace(re, value);
            }
            for(let key in this.camp){
                let value = this.camp[key];
                let find = "{{"+key+"}}";
                let re = new RegExp(find, 'g');
                text = text.replace(re, value);
            }
            return text;
        }
    }
}
</script>

<style>
.top-row{
    position:absolute; right:0px; top:0px;
}
</style>