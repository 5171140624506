<template>
    <div>
        <!-- <Bar
            :chart-options="chartOptions"
            :chart-data="chartData"
            :height="height"
            :width="width"
        /> -->

        
        <LineChartGenerator
            :chart-options="chartOptions"
            :chart-data="chartData"
            :width="width"
            :height="height"
        />
    </div>
</template>

<script>
// import { Bar } from 'vue-chartjs/legacy'
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'BarChart',
  components: { LineChartGenerator },
  props: {
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    chartData:{
        type:Object,
        default:()=>{
            return {
                labels: ["Januar","Februar","März","April","",""],
                datasets: [ 
                    { 
                        data: [40, 20, 12,39,20,17],
                        label:"Hello World"
                    } 
                ]
            }
        }
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio:false,
        layout:{
            padding:0,
        },
        plugins:{
            legend:{
                position:'bottom',
                boxWidth:30,
                boxHeight:20
            }
        },
        elements:{
            point:{
                radius:0,
                hitRadius:40,
                pointStyle:"point"
            }
        },
        scales:{
            x:{
                display:false
            }
        },
      }
    }
  }
}
</script>