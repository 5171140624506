<template>
    <v-container>   
        <v-row justify="center">
            <div v-if="!isComponent" style="width:100%; height:60px;"/>
            <v-card
                class="ma-4 mt-8"
                width="400"
            >
            <v-card-title >
                <v-icon>mdi-account</v-icon>
                Anmeldung
            </v-card-title>    
            <v-card-text class="text-center">
                <v-text-field @keydown.enter="login" v-model="email" label="Email" type="Login" solo>

                </v-text-field>

                <v-text-field 
                    @keydown.enter="login" 
                    v-model="password" 
                    label="Passwort" 
                    :type="showPassword ? 'text' : 'password'"
                    solo
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                >

                </v-text-field>

                <v-btn
                    class="primary"
                    @click="login()"
                >   
                    Login
                    <v-icon class="" small>mdi-login</v-icon>
                </v-btn>

            </v-card-text>
        </v-card>    
        </v-row>
        <v-row class="ma-2" align="center" justify="center">
                <v-snackbar v-model="warning" :timeout="warningTimeout" color="error">
                    <v-icon>mdi-alert-circle-outline</v-icon> Login ist fehlgeschlagen
                    <!-- <template slot:action>
                        <v-btn fab icon><v-icon>mdi-close</v-icon></v-btn>
                    </template> -->
                </v-snackbar>
        </v-row>
    </v-container>
    
</template>

<script>

export default {
    name:"PageLogin",
    inject:[
        "app"
    ],
    props:{
        isComponent:{type:Boolean, default:false},
        redirect:{type:Boolean, default:true}
    },
    created(){
        if(this.$network.app.loggedIn){
            this.$router.replace({'name':'Dashboard'});
        }
    },
    data(){
        return{
            email:"",
            password:"",
            showPassword:false,
            warning: false,
            warningTimeout: 2000,
        }
    },
    methods:{
        register(){
            if(this.$router.currentRoute.name !== 'Register'){
                this.$router.replace({'name':'Register'});
            }
        },
        async login(){
            this.email = this.email.trim();
            delete this.$axios.defaults.headers.common['Authorization'];

            let result = await this.$network.loginUser(this.email, this.password, false);
            if(result.status === 200){
                await this.$network.checkIfLoggedIn(this.redirect);
                this.$emit("loggedIn",this.app.userData);
            }else{
                this.warning = true;
            }
        }
    }
}
</script>

<style scoped>

</style>