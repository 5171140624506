<template>
    <report-card-base
        ref="report"
        :title="'Tassen-Bestellungen'"
        :icon="'mdi-coffee'"
    >
    <template v-slot:content>
        <v-simple-table
            dense
        >
            <thead>
                <tr>
                    <th>
                        Mitarbeiter
                    </th>
                    <th>
                        Teilnehmer
                    </th>
                    <th>
                        Gesamt
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        {{cupsEmployee}}
                    </td>
                    <td>
                        {{cupsParticipants}}
                    </td>
                    <td>
                        {{(cupsParticipants + cupsEmployee)}} <v-icon>mdi-coffee</v-icon>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

    </template>


    </report-card-base>


</template>

<script>
import ReportCardBase from './ReportCardBase.vue';
export default {
    components:{
        ReportCardBase
    },

    props:{
        year:String
    },
    data(){
        return{
            cupsEmployee:0,
            cupsParticipants:0,
        }
    },
    mounted(){
        this.getCupOrders();
    },
    methods:{
        async getCupOrders(){
            let url = "/items/mitarbeiter_"+this.year+"?limit=-1";
            // url += "&filter[tassen][_gt]=0";
            url += '&filter={"_and":[{"status_person":{"_eq":"Bestätigt"}},{"tassen":{"_gt":0}}]}'
            url+="&fields=tassen,vorname,nachname";
            let result = await this.$network.requestGet(url);
            if(result.status === 200){
                for(let person of result.data.data){
                    this.cupsEmployee += person.tassen;
                }
            }
            url = "/items/teilnehmer_"+this.year+"?limit=-1";
            url += '&filter={"_and":[{"status_person":{"_eq":"Bestätigt"}},{"tassen":{"_gt":0}}]}'
            // url += "&filter[tassen][_gt]=0"
            url += "&fields=tassen,vorname,nachname";
            result = await this.$network.requestGet(url);
            if(result.status === 200){
                for(let person of result.data.data){
                    this.cupsParticipants += person.tassen;
                }
            }
        }
    }
}
</script>