<template>
    <v-row
        class="ma-0"
    >
        <v-checkbox
            class="mt-1 mr-5"
            v-for="(item, index) in items" :key="index"
            :value="item.text"
            :label="item.text"
            v-model="localValue"
            @change="changeValue"
            hide-details="auto"
        >

        </v-checkbox>
    </v-row>
</template>

<script>
export default {
    props:{
        value:Array,
        items:Array
        // value:{
        //     type:Array,
        //     default:[]
        // },
        // items:{
        //     type:Array,
        //     default:[]
        // }
    },
    data(){
        return{
            localValue: this.value,
        }
    },
    watch:{
        value(value){
            this.localValue = value || [];
        }
    },
    methods:{
        changeValue(){
            let val = this.localValue;
            if(typeof val === "string"){
                val = [val];
            }
            this.$emit("input", val);
        }
    }
    
}
</script>