<template>
    <v-textarea
        solo
        hide-details
        v-model="localValue"
        @input="onValueChanged"
    />
</template>

<script>
export default {
    props:{
        value:String
    },
    data(){
        return{
            localValue:this.value,
        }
    },
    watch:{
        value(val){
            this.localValue = val;
        }
    },
    methods:{
        onValueChanged(val){
            this.$emit("input", val);
        }
    }
}
</script>