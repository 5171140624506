<template>
    <popup-dialog-base
        ref="dialog"
        :toolButtons="[]"
        :title="activeImageTitle"
        :persistent="false"
        :max-width="2000"
    >
        <template v-slot:button
            v-if="showThumbNails"
        >
            <v-row class="pa-2"
                v-if="dataUriThumb"
            >
                <v-card class="mr-2 mb-1" v-for="(image, index) in images" :key="index">
                    <v-img
                        v-if="getImage(image.id, true) != ''"
                        class="mouse-grab"
                        @click="showImage(image.id, image.label)"
                        :src="getImage(image.id, true)"
                        max-height="100"
                        max-width="100"
                    />
                </v-card>
            </v-row>
        </template>

        <template v-slot:content>
            <!-- <v-carousel
            >
                <v-carousel-item
                    v-for="(img,i) in images" :key="i"
                    :src="($network.app.restUrl+'/assets/'+img.id)"
                ></v-carousel-item>
            </v-carousel> -->
            <v-container
                fluid
                class="fill-height"
                ref="imageContainer"
            >

                <v-row 
                    class="ma-0" 
                    no-gutters
                    v-touch="{
                        left: () => nextImage(),
                        right: () => prevImage(),
                    }"
                >   
                    <div
                        class="mr-8" 
                        style="z-index:2; position:absolute; right:0px;"
                    >
                        <v-btn icon @click="zoom=!zoom">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </div>
                    <v-col>
                        <v-img
                            v-if="!zoom"
                            class="center"
                            contain
                            :width="getWidth()"
                            :height="getHeight()"
                            :src="getImage(activeImage, false)"
                        />
                        <div
                            v-else
                            :style="{
                                'overflow-y':'scroll',
                                'max-height':getHeight()+'px'
                            }"
                        >
                            <v-img
                                class="center"
                                :src="getImage(activeImage, false)"
                            />
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn x-large icon elevation="0" @click="prevImage()"><v-icon>mdi-chevron-left</v-icon></v-btn>
                    </v-col>
                    <v-col>
                        <v-btn x-large icon elevation="0" @click="nextImage()"><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </popup-dialog-base>
</template>
<script>
import PopupDialogBase from '../PopupDialogBase.vue';
export default {
    components:{
        PopupDialogBase
    },
    props:{
        images:Array,
        showThumbNails:{type:Boolean, default:true},
        email:{type:String},
        password:{type:String},
        year:{type:Number},
    },
    data(){
        return{
            zoom:false,
            activeImage:"",
            activeImageTitle:"",
            eventListener:undefined,
            scaleListener:undefined,
            availableWidth:0,
            availableHeight:0,
            dataUriThumb:undefined,
            dataUri:undefined,
        }
    },
    async created(){
        this.eventListener = window.addEventListener('keyup', this.keyboardListener)
        this.scaleListener = window.addEventListener("resize",()=>{
            this.calcAvailableDimensions()
        })
        this.dataUriThumb = await this.requestImages(true)
        this.dataUri = await this.requestImages(false)
    },
    beforeDestroy(){
        if(this.eventListener){
            window.removeEventListener(this.eventListener);
        }
        if(this.scaleListener){
            window.removeEventListener(this.scaleListener);
        }
    },
    methods:{
        getImage(id, thumb){
            if(thumb){
                if(this.dataUriThumb && this.dataUriThumb[id]){
                    return this.dataUriThumb[id]
                }
            }else{
                if(this.dataUri && this.dataUri[id]){
                    return this.dataUri[id]
                }
            }
            return "";
        },
        async requestImages(thumbs){
            
            var images = {}
            if(this.email && this.password){
                for(let image of this.images){
                    let url = "/userdata/assets"
                    if(thumbs){
                        url += ""
                    }
                    const payload = {
                        email: this.email,
                        password: this.password,
                        year: this.year,
                        id: image.id,
                        responseType: "arraybuffer",
                        arguments: thumbs?"?width=100&height=100" : undefined
                    }
                    let result = await this.$network.requestPost(url, payload, true);
                    if(result.status === 200){
                        images[image.id] = "data:image/png;base64,"+result.data;
                    }
                }
            }else{
                for(let image of this.images){
                    let url = "/assets/"+image.id
                    if(thumbs){
                        url += "?width=100&height=100"
                    }
                    let result = await this.$network.requestAssetAsBase64(url);
                    if(result.status === 200){
                        images[image.id] = "data:image/png;base64,"+result.data;
                    }
                }
            }
            return images;
        },
        calcAvailableDimensions(){
            this.availableWidth = window.innerWidth;
            this.availableHeight = window.innerHeight;
        },
        getWidth(){
            if(this.$vuetify.breakpoint.mobile){
                return this.availableWidth * 0.9;
            }
            return this.availableWidth * 0.6
        },
        getHeight(){
            return this.availableHeight * 0.6
        },
        keyboardListener(e){
            if(this.$refs.dialog && this.$refs.dialog.dialog.visible){
                if(e.code === "ArrowRight"){
                    this.nextImage();
                }else if(e.code === "ArrowLeft"){
                    this.prevImage();
                }
            }
        },
        showImage(imgID, imgTitle){
            this.$nextTick(()=>{
                this.calcAvailableDimensions();
            })
            this.$refs.dialog.show();
            this.activeImage = imgID;
            this.activeImageTitle = imgTitle;
        },
        nextImage(){
            for(let i=0; i<this.images.length; i++){
                let img = this.images[i];
                if (img.id === this.activeImage){
                    let index = (i+1)%(this.images.length)
                    this.activeImage = this.images[index].id;
                    this.activeImageTitle = this.images[index].filename_download;
                    break;
                }
            }
        },
        prevImage(){
            for(let i=0; i<this.images.length; i++){
                let img = this.images[i];
                if (img.id === this.activeImage){
                    let index = (i-1 + this.images.length)%(this.images.length)
                    this.activeImage = this.images[index].id;
                    this.activeImageTitle = this.images[index].label;
                    break;
                }
            }
        }
    }

}
</script>

<style>
.mouse-grab{
    cursor:pointer;
}
.v-btn {
  background-color: transparent !important;
}
.top-row{
    position:absolute; right:0px; top:0px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  max-width:100%;
}
</style>