<template>    
    <v-alert border="left" text color="secondary darken-2">
        <h3>Personen-Daten</h3>
        <v-simple-table class="text--secondary">
            <tbody>
                <tr :style="(index+1)%2==0 ? 'background-color:#f4f6f6;' : ''"
                    v-for="(fieldData, index) in getRegistrationSummary()" :key="index"
                >
                    <td width="250">
                        <b>
                            {{fieldData.label}}
                        </b>
                    </td>
                    <td>
                        {{formatValue(fieldData.label, fieldData.value)}}
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-alert>

</template>

<script>
export default {
    props:{
        peopleFormEditor:Object,
        peopleManager:Object,
    },
    methods:{
        formatValue(key, value){
            let valueFormated = ""
            if(typeof(value) === "object" && key === "Mitarbeiter Wunsch"){
                for(let key in value){
                    if(value[key].wish1){
                        valueFormated += key + " - Wunsch 1: " + value[key].wish1;
                    }
                    if(value[key].wish2){
                        valueFormated += " Wunsch 2: " + value[key].wish2;
                    }
                }
            }else if(typeof(value) === "object" && Array.isArray(value)){
                for(let item of value){
                    valueFormated += item + ", "
                }
            }else{
                valueFormated = value;
            }
            return valueFormated;
        },
        getFormFieldsForCheckPreview(){
            var fields = [];
            for(let fieldGroup of this.peopleFormEditor.getEditFields()){
                for(let key in fieldGroup.fields){
                    let field = fieldGroup.fields[key]
                    if(!["display-images", "group-raw"].includes(field.meta.interface)){
                        fields.push(field);
                    }
                }
            }
            return fields;
        },
        formatValueForCheckPreview(value, meta){
            if(value === undefined || value === null || value.length === 0){
                return " - ";
            }else if(value === true){
                let extraInfo = "";
                if(meta.options && meta.options.label){
                    extraInfo += " " + meta.options.label
                }
                return "Ja" + extraInfo;
            }else if(value === false){
                return "Nein";
            }else if (value === ""){
                return " - ";
            }else{
                return value;
            }
        },
        getRegistrationSummary(){
            let registrationSummary = [];
            for(let field of this.getFormFieldsForCheckPreview()){
                registrationSummary.push({
                    label: this.peopleFormEditor.getTranslation(field.meta.field),
                    value: this.formatValueForCheckPreview(this.peopleManager.editData[field.meta.field], field.meta)
                });
            }
            return registrationSummary;
        }
    }
}
</script>