<template>
    <div>
        <!-- {{ id }} -->
        <!-- {{ meta }} -->
        <div v-if="fileMeta && localValue" >
            <!-- {{ meta }} -->
            <v-alert
                v-for="(item, index) in localValue" :key="index"
                border="left" 
                class="pa-1 ma-0 mb-1"
                text 
                hide-details
                dense 
                color="primary"
            >
                <v-btn icon class="primary--text"
                    @click="downloadFile(item)"
                >
                    <v-icon>
                        mdi-download
                    </v-icon>
                </v-btn>
                <v-btn icon class="primary--text"
                    @click="showPdf(item)"
                >
                    <v-icon>
                        mdi-eye
                    </v-icon>
                </v-btn>
                <span v-if="fileMeta && fileMeta[item.id]">
                    {{ fileMeta[item.id].filename_download }}  
                    <span style="font-size:small; font-weight: bold;">
                        ({{(fileMeta[item.id].filesize*0.000001).toFixed(2)}} MB)
                    </span>
                </span>
                <span v-else>
                    Datei existiert nicht mehr!
                </span>

                
                <template v-slot:close>
                    <v-btn small icon
                        v-if="editable"
                        @click="deleteFile(item)"
                    >
                        <v-icon color="primary">
                            mdi-close
                        </v-icon>
                    </v-btn>
                </template>
            </v-alert>
        </div>
        <v-alert
            v-if="editable"
            color="secondary"
            text
            class="file-drop pa-0 ma-0"
        >
            <v-file-input
                v-model="files"
                flat
                block
                prepend-icon="mdi-file-upload"
                hide-details
                multiple
                style="border:0px;"
                :label="`Dokumente hochladen  (max ${maxSize} MB pro Datei)`"
                chips
                accept=".pdf,.jpeg,.jpg,.png"
                size="5"
            >
            </v-file-input>
        </v-alert>
        <v-btn 
            v-if="files && files.length > 0"
            @click="uploadFiles"
            class="primary mt-1"
            block
            :loading="uploading"
        >
            <v-icon>
                mdi-upload
            </v-icon>
            Dateien hochladen
        </v-btn>



        <display-images
            ref="ImageViewer"
            v-if="images.length > 0"
            :show-thumb-nails="false"
            :images="images"
            :email="email"
            :password="password"
            :year="year"
        />

        <popup-dialog-base
            ref="PdfViewer"
            :tool-buttons="pdfViewerButtons"
            :title="activeFileName"
            :fullscreen="false"
        >

            <template v-slot:content>
                <iframe style="margin:0; padding:0; border:0px; width:100%; height:99%;" v-if="pdfUrl" :src="pdfUrl"></iframe>
            </template>
        </popup-dialog-base>
    </div>
</template>

<script>
import PopupDialogBase from '../PopupDialogBase.vue'
import DisplayImages from '../displays/DisplayImages.vue';

export default {
    components:{
        PopupDialogBase,
        DisplayImages
    },
    props:{
        id:{type:Number},
        collection:{type:String},
        fieldName:{type:String},
        value:{type:Array},
        meta:{type:Object},
        maxSize:{type:Number, default:2},
        editable:{type:Boolean, default:true},
        email:{type:String, default:undefined},
        password:{type:String, default:undefined},
        year:{type:Number},
    },
    data(){
        return{

            pageCount:0,
            currentPage:1,
            activeFileName:"",
            activeFileType:"",
            pageString:"",
            localValue:this.value,
            files:[],
            uploading:false,
            fileMeta:undefined,
            parentFolderName:undefined,
            uploadFolderName:undefined,
            activePdfId:"",
            pdfUrl:undefined,
            pdfViewerButtons:[
                // {label:"", color:"primary", icon:"mdi-arrow-left", method:()=>{this.prevPage()}, viewCondition:()=>{return true}, disabled:()=>{return false}},
                // {label:"", color:"primary", icon:"mdi-arrow-right", method:()=>{this.nextPage()}, viewCondition:()=>{return true}, disabled:()=>{return false}},
                // {label:"Download", color:"primary", icon:"mdi-download", method:()=>{console.log("test")}, viewCondition:()=>{return true}, disabled:()=>{return false}},
            ]
        }
    },
    async created(){

        this.parentFolderName = this.meta.options.parent_folder;
        this.uploadFolderName = this.meta.options.upload_folder;
        this.localValue = this.value;
        this.fileMeta = await this.getFileMetaData(this.localValue)
    },
    computed:{
        images(){
            let images = []

            if(this.fileMeta){
                for(let fileId in this.fileMeta){
                    let file = this.fileMeta[fileId]
                    file["label"] = file.filename_download;
                    if(file.type.includes("image")){
                        images.push(file);
                    }
                }
            }
            return images
        }
    },

    methods:{
        async showPdf(file){            
            this.activePdfId = file.id;
            this.activeFileName = this.fileMeta[this.activePdfId].filename_download;
            this.activeFileType = this.fileMeta[this.activePdfId].type;

            if(this.activeFileType === "application/pdf"){
                this.$refs.PdfViewer.show();
                this.$nextTick(async ()=>{
                    let url = '/assets/'+this.activePdfId;
                    
                    let result;
                    this.currentPage = 1;
                    if(this.email && this.password){
                        const payload = {
                            email:this.email,
                            password:this.password,
                            year:this.year,
                            id:file.id,
                            responseType:"arraybuffer"
                        }
                        result = await this.$network.requestPost("/userdata/assets", payload, true);
                    }else{
                        result = await this.$network.requestAssetAsBase64(url);
                    }
                    if(result.status === 200){
                        this.pdfUrl = "data:"+this.activeFileType+";base64,"+result.data;
                    }
                })
            }else{
                this.$refs.ImageViewer.showImage(file.id, this.activeFileName);
            }
        },
        downloadFile(file){
            if(this.email && this.password){

                const payload = {
                    email:this.email,
                    password:this.password,
                    year:this.year,
                    id:file.id,
                    responseType:"arraybuffer"
                }
                this.$network.requestPost("/userdata/assets", payload, true)
                .then(result => {
                    if(result.status === 200){
                        const base64Data = "data:"+this.activeFileType+";base64,"+result.data;
                        const downloadLink = document.createElement('a');
                        document.body.appendChild(downloadLink);

                        downloadLink.href = base64Data;
                        downloadLink.target = '_self';
                        downloadLink.download = this.fileMeta[file.id].filename_download;
                        downloadLink.click(); 
                    }

                }).catch(error =>{
                    console.log(error);
                })
            }else{
                let url = this.$network.app.restUrl+"/assets/"+file.id
                this.$axios.get(url,{responseType:"blob"})
                .then(result => {
                    if(result.status === 200){
                        const blob = new Blob([result.data], {type: 'application/pdf'})
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = this.fileMeta[file.id].filename_download
                        link.click()
                        URL.revokeObjectURL(link.href)
                    }
                }).catch(error =>{
                    console.log(error);
                })
            }
        },
        async deleteFile(file){
            await this.$network.requestDelete("/files/"+file.id);
            if(this.localValue){
                for(let i=0; i<this.localValue.length; i++){
                    let f = this.localValue[i];
                    if (f.id === file.id){
                        this.localValue.splice(i, 1);
                        i -= 1;
                    }
                }
            }
            let url = "/items/"+this.collection+"/"+this.id.toString();
            let payload = {}
            payload[this.fieldName] = this.localValue;
            await this.$network.requestPatch(url, payload);
            this.$emit("input",this.localValue);
        },
        async getFileMetaData(files){
            var fileMeta = {}
            if(files){
                for(let file of files){
                    let result;
                    if(this.email && this.password){
                        const payload = {
                            email:this.email,
                            password:this.password,
                            year:this.year,
                            id:file.id
                        }
                        result = await this.$network.requestPost("/userdata/files", payload, true);
                    }else{
                        result = await this.$network.requestGet("/files/"+file.id);
                    }
                    
                    if(result.status === 200){
                        fileMeta[file.id] = result.data.data
                    }
                }
                return fileMeta;
            }
            return undefined;
        },
        async getUploadFolderId(){
            let parentFolder
            if(this.parentFolderName){
                let filter = {"name":{"_eq":this.parentFolderName}};
                let url = "/folders"
                url += "?filter="+JSON.stringify(filter).replace(" ","%");
                
                let result = await this.$network.requestGet(url);
                if(result.status === 200 && result.data.data.length > 0){
                    parentFolder = result.data.data[0];
                }else{
                    let payload = {name:this.parentFolderName};
                    let result = await this.$network.requestPost(url, payload);

                    if(result.status === 200){
                        parentFolder = result.data.data;
                    }
                }
            }
            let uploadFolder
            if(this.uploadFolderName){
                let filter = {"_and":[
                    {"name":{"_eq":this.uploadFolderName}},
                    {"parent":{"_eq":parentFolder.id}}
                ]};
                let url = "/folders"
                url += "?filter="+JSON.stringify(filter).replace(" ","%");
                
                let result = await this.$network.requestGet(url);
                if(result.status === 200 && result.data.data.length > 0){
                    uploadFolder = result.data.data[0];
                }else{
                    let payload = {name:this.uploadFolderName, parent:parentFolder.id};
                    let result = await this.$network.requestPost(url, payload);
                    if(result.status === 200){
                        uploadFolder = result.data.data;
                    }
                }
            }
            if(uploadFolder){
                return uploadFolder.id;
            }
            return undefined;
        },
        fileSizeCheckPassed(){
            let limit = this.maxSize * 1000000;
            for(let file of this.files){
                if (file.size > limit){
                    this.$network.app.showNotification("Die Datei überschreibtet das Größenlimit.",undefined,"warning")
                    return false;
                }
            }
            return true;
        },

        async uploadFiles(){
            if(!this.fileSizeCheckPassed()){
                return
            }

            if(this.files && this.files.length){
                this.uploading = true;
                const formData = new FormData();
                for(let file of this.files){
                    formData.append('file', file);
                }
                let folderId = await this.getUploadFolderId();
                let result = await this.$network.requestPost("/files",formData);
                if(result.status === 200 && folderId){
                    let ids = []
                    if (Array.isArray(result.data.data)){
                        for(let file of result.data.data){
                            ids.push(file.id)
                        }
                    }else{
                        ids = [result.data.data.id]
                    }
                    let payload = {
                        keys:ids,
                        data:{
                            folder:folderId
                        }
                    }
                    await this.$network.requestPatch("/files",payload);

                    // patch collection with new entries
                    let documents = []

                    if(this.localValue){
                        for(let item of this.localValue){
                            documents.push(item);
                        }
                    }
                    for(let id of ids){
                        documents.push({"id":id});
                    }
                    payload = {};
                    payload[this.fieldName] = documents;
                    let url = "/items/"+this.collection+"/"+this.id.toString();
                    this.fileMeta = undefined;
                    await this.$network.requestPatch(url, payload);
                    this.fileMeta = await this.getFileMetaData(documents);
                    this.localValue = documents;
                    this.$emit("input", this.localValue);
                }
            }
            this.files = [];
            this.uploading = false;
        }
    }
}
</script>