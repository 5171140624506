<template>
    <v-container v-if="!reload" fluid>
        <v-row>
            <v-col cols="12" md="6" lg="6" class="">
                <report-participant-overview
                    :collection="'teilnehmer_'+year.toString()"
                    :title="'Teilnehmer - Anmeldungen Übersicht'"
                    :chartTitle="'Anmeldungen Teilnehmer'"
                    :cardSettings="{type:'teilnehmer',sort:['freizeit','vorname','nachname'], fields:['freizeit','gemeinde','geburtstag'],cols:5}"
                    :year="year.toString()"
                />
            </v-col>
            <v-col cols="12" md="6" lg="6" class="">
                <report-participant-overview
                    :collection="'mitarbeiter_'+year.toString()"
                    :chartTitle="'Anmeldungen Mitarbeiter'"
                    :title="'Mitarbeiter - Anmeldungen Übersicht'"
                    :cardSettings="{type:'mitarbeiter',sort:['vorname','nachname'], fields:['freizeit','gemeinde','meine_gemeinde','geburtstag','mitarbeiter_position'], cols:2}"
                    :year="year.toString()"
                />
            </v-col>
            

            <v-col cols="12" md="12" lg="12" class="d-flex">
                <report-participant-registrations
                    :collection="'teilnehmer_'+year.toString()"
                    :year="year.toString()"
                />
            </v-col>

            <v-col cols="12">
                <report-cup-order
                    :year="year.toString()"
                />
            </v-col>
            
            <v-col cols="12">
                <report-tshirt-order
                    :year="year.toString()"
                />
            </v-col>
            <v-col cols="12">
                <report-cake
                    :title="'Kuchen Freizeit'"
                    :cake-field="'kuchen_backen'"
                    :cake-count-field="'anzahl_kuchen'"
                    :cake-date-field="'kuchen_abgabe'"
                    :year="year.toString()"
                />
            </v-col>
            <v-col cols="12">
                <report-cake
                    :title="'Kuchen Mitarbeiter-Wochenende'"
                    :cake-field="'kuchen_backen_mw'"
                    :cake-count-field="'anzahl_kuchen_mw'"
                    :cake-date-field="'kuchen_abgabe_mw'"
                    :year="year.toString()"
                />
            </v-col>
            <v-col cols="12">
                <report-income
                    :year="year.toString()"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ReportParticipantRegistrations from './ReportParticipantRegistrations.vue'
import ReportParticipantOverview from './ReportParticipantOverview.vue'
import ReportCake from './ReportCake.vue'
import ReportCupOrder from './ReportCupOrder.vue'
import ReportTshirtOrder from './ReportTshirtOrder.vue'
import ReportIncome from './ReportIncome.vue'
export default {
    components:{
        ReportParticipantRegistrations,
        ReportParticipantOverview,
        ReportCupOrder,
        ReportTshirtOrder,
        ReportIncome,
        ReportCake,

    },
    props:{
        year:{type:String, default:""}
    },
    data(){
        return{
            reload:false,
        }
    },
    methods:{
        reloadData(){
            this.reload = true;
            this.$nextTick(()=>{
                this.reload = false;
            })
        }
    }
}
</script>