<template>
    <div>
        <slot name="button" :dialog="dialog">
        </slot>

        <!-- <v-dialog -->
        <component
            :is="componentType"
            v-model="dialog.visible"
            :persistent="persistent"
            :scrollable="true"
            :max-width="this.maxWidth"
            :max-height="windowed?'90%':''"
            :height="windowed?'90%':''"
            :fullscreen="$vuetify.breakpoint.xsOnly || fullscreen"
        >
            <v-card
                ref="editorDialog"
                class="card pa-0"
                :height="windowed?'90vh':''"
                :loading="dialog.loading"
            >
                <slot name="title"
                    v-if="windowed"
                >
                    <v-card-title class="mt-1 background" style="position:sticky; top:0px; z-index:1;">
                        <span class="text-h5" >
                            <v-icon
                                :class="iconColor+'--text'"
                            >
                                {{icon}}
                            </v-icon>
                            {{title}}
                            <slot name="title-append"></slot>
                        </span>
                        
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="isDialog"
                            style="bottom:15px; left:20px;" @click="hide()" x-small icon fab
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                </slot>
                <slot name="subtitle">
                    <v-card-subtitle v-if="subtitle" class="pb-1" style="position:sticky; top:0px; z-index:1001;">
                        <span>{{subtitle}}</span>
                    </v-card-subtitle>
                </slot>
                <v-divider></v-divider>

                <v-card-text
                    class="pr-0 pl-0"
                >
                    <!-- Popup Content -->
                    <slot name="content" :dialog="dialog"/>
                </v-card-text>
                
                <v-divider
                    v-if="toolButtons.length > 0"
                />
                <v-card-actions
                    v-if="toolButtons.length > 0"
                    style="position:sticky; bottom:0px; width:100%;"
                    ref="toolbar"
                    class="background"
                >
                    <v-divider></v-divider>
                    <v-container fluid>
                        <v-row
                            :justify="$vuetify.breakpoint.xsOnly ? 'center' : 'end'"
                        >
                            <slot name="toolbar"/>

                            
                            <v-btn
                                v-for="(btn, index) in toolButtons" :key="index"
                                :class="[
                                    btn.color,
                                    $vuetify.breakpoint.xsOnly?'mb-2':'ml-2'
                                ]"
                                :fab="useIconToolButtons"
                                :small="!useIconToolButtons"
                                :x-small="useIconToolButtons"
                                :loading="btn.loading ? btn.loading() : false"
                                v-show="btn.viewCondition()"
                                :block="$vuetify.breakpoint.xsOnly"
                                :disabled="btnDisabled(btn)"
                                @click="btn.method()"
                            >
                                <v-icon>{{btn.icon}}</v-icon>
                                <span v-if="!useIconToolButtons">
                                    {{btn.label}}
                                </span>
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card>
            
            <v-snackbar
                style="z-index:1000;"
                v-model="notification.visible"
                :color="notification.color"
            >
                <div
                    v-if="notification.icon !== ''"
                >
                    <v-icon class="mr-2">{{notification.icon}}</v-icon>{{notification.message}}
                </div>
            </v-snackbar>
        </component>
    </div>
</template>
<script>
import { VDialog } from 'vuetify/lib/components/VDialog'

export default {
    name:"PopupDialogBase",
    props:{
        windowed:{type:Boolean, default:true},
        isDialog:{type:Boolean, default:true},
        fullscreen:{type:Boolean, default:false},
        icon:{type:String, default:""},
        persistent:{type:Boolean, default:true},
        iconColor:{type:String, default:"primary"},
        title:{type:String, default:"Popup Title"},
        subtitle:{type:String, default:undefined},
        showBottomBar:{type:Boolean, default:true},
        maxWidth:{type:Number, default:1000},
        useIconToolButtons:{type:Boolean, default:false},
        toolButtons:{type:Array, default:()=>{
            return [{
                label:"Button Label", 
                color:"primary", 
                icon:"mdi-information", 
                loading:()=>{return false},
                method:()=>{console.log("test")}, 
                viewCondition:()=>{return true},
                disabled:()=>{return true}
            }]
        }},
    },
    data(){
        return{
            dialog:{
                visible:false,
                loading:false,
            },
            notification:{
                visible:false,
                message:"",
                color:"success",
                icon:"mdi-alert"
            },
        }
    },
    computed:{
        componentType(){
            return this.isDialog && this.windowed ? VDialog : "div";
        }
    },
    methods:{
        
        showNotification(message="",color="success",icon="mdi-information"){
            this.notification.visible = true;
            this.notification.message = message;
            this.notification.color = color;
            this.notification.icon = icon;
        },
        btnDisabled(btn){
            if(btn.disabled){
                return btn.disabled();
            }
            return false;
        },
        setLoading(value){
            this.dialog.loading = value;
        },
        show(){
            this.dialog.visible = true;
        },
        hide(){
            this.dialog.visible = false;
        }
    }
    
}
</script>

<style>
.top-row{
    position:sticky !important; right:0px !important; top:0px !important;
    z-index:1000;
}
</style>