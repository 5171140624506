<template>
    <v-container fluid fill-height class="ma-0 pa-0">
        <v-container
            fluid
            fill-height
            align-start
            class="pb-0 pt-0"
            v-if="app.loggedIn && hasDashboardAccess"
        >        
            
            <nav-bar
                v-if="app.userData && app.loggedIn && activeCampYear"
                ref="NavBar"
            />
            <!-- <v-btn v-if="!app.loggedIn">Neu Laden</v-btn> -->
            <v-row class="mb-6" v-if="(app.loggedIn && activeCampYear)">
                <v-col>
                    <div
                        v-if="app.activeSubMenu"
                    >
                        <dashboard-master
                            :icon="app.activeSubMenu.icon"
                            :title="app.activeSubMenu.title"
                            :nav-bar="getNavBar()"
                        >
                        <template v-slot:content>
                            <component 
                                :is="getCurrentComponent()"
                                v-bind="componentProperties"
                                @onCampDataSaved="onCampDataSaved"
                            />
                        </template>
        
                        </dashboard-master>
                    </div>
                </v-col>    
            </v-row>
            
            <v-bottom-navigation 
                v-if="showFooter"
                class="grey lighten-2 elevation-4" height="20" fixed
            >
                <strong class="">
                    <caption class="grey--text text--lighten-1 smaller">v{{version}}</caption>
                </strong>
    
            </v-bottom-navigation>
        </v-container>

        <!-- Show reload button if session is expired -->
        <v-container
            v-else
        >
            <v-row v-if="hasDashboardAccess">
                <!-- <v-spacer></v-spacer> -->
                <v-col
                    class="text-center"
                >
                    <v-alert border="left" text color="primary" type="info">
                        Die Session ist abgelaufen. Klick auf den Button um da weiterzumachen wo du aufgehört hast.
                    </v-alert>
                    <v-btn 
                        @click="reloadPage()"
                        class="primary"
                    >   
                        <v-icon>mdi-reload</v-icon>
                        Seite neu laden
                    </v-btn>
                </v-col>
                <!-- <v-spacer></v-spacer> -->
            </v-row>
            <v-row
                v-else-if="!hasDashboardAccess || !deviceOnline"
            >
                <v-col class="text-center">
                    <v-alert  border="left" text color="primary" type="info">
                        Du hast kein Zugriff auf das Dashboard.
                    </v-alert>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import FileBrowser from '../components/FileBrowser/FileBrowser.vue'
import DashboardMaster from './DashboardPages/DashboardMaster.vue'
import NavBar from '../components/NavBar.vue'
import PeopleManager from '@/components/PeopleManager.vue'
import Inventory from './DashboardPages/Inventory.vue'
import Reports from './DashboardPages/Reports/Reports.vue'
import ReportProvinzenDeprecated from './DashboardPages/Reports/ReportProvinzenDeprecated.vue'
import ReportProvinzen from './DashboardPages/Reports/ReportProvinzen.vue'
import PrintableForms from '../components/PrintableForms.vue'
import CampSettings from '../components/CampWizard/CampSettings.vue'
import ReportEmployeeOverview from './DashboardPages/Reports/ReportEmployeeOverview.vue'
import UserData from './UserData.vue';
import EmailClient from '@/components/email/EmailClient.vue'

export default {
    name:"PageDashboard",
    components: {
        DashboardMaster,
        NavBar,
        PeopleManager,
        Inventory,
        Reports,
        ReportProvinzenDeprecated,
        ReportProvinzen,
        ReportEmployeeOverview,
        PrintableForms,
        CampSettings,
        UserData,
        FileBrowser,
        EmailClient
    },
    inject:[
        "app"
    ],
    provide(){
        return{
            dashboard:this
        }
    },
    async mounted(){
        this.dashboardPage = this.$refs.DashboardPage;
        this.loadCampList();
        if(this.app.loggedIn){
            if(this.$router.currentRoute.name === "Dashboard" || this.$router.currentRoute.name === "Admin"){
                this.$router.push({path:this.app.menu[0].sub_menu_items[0].url});
            }
        }else{
            this.$router.push({name:"Login"})
        }
        this.app.dashboard = this;
        
    },
    data(){
        return{
            showFooter:false,
            version:process.env.VUE_APP_VERSION,
            dashboard:this,
            dashboardPage:undefined,
            navbar:undefined,
            drawer:true,
            activeCampYear:undefined,
            activeCampData:undefined,
            campYears:[],
            sideBarWidth:0,
            sideBarHeight:0,
            selectedDashPage:'Camp Einstellungen',
            menuItems: []
        }
    },
    watch:{
        $route (to, from){
            if(to.path !== from.path){
                this.reloadDashPage();
            }
        }
    },
    computed:{
        isOnline(){
            return this.app.network.deviceOnline
        },
        hasDashboardAccess(){
            const userData = this.app.userData;
            if(userData){
                const role = userData.role;
                if(role){
                    let editor = role.access_group.includes("editor");
                    let admin = role.access_group.includes("admin");
                    return editor || admin;
                }
            }
            return false
        },
        campDataEditable(){
            if(!this.activeCampData){
                return false
            }
            return this.activeCampData.freizeit_editierbar;
        },
        componentProperties(){
            let props = {
                year:this.activeCampYear,
                dashboard:this.dashboard,
            }
            if(this.app.activeSubMenu && this.app.activeSubMenu.properties){
                for(let prop of this.app.activeSubMenu.properties){
                    if(prop.value && prop.value.includes(",")){
                        let finalProps = []
                        for(let value of prop.value.split(",")){
                            if(value === "false" || value === "true"){
                                finalProps.push(eval(value))
                            }else{
                                finalProps.push(value)
                            }
                        }
                        props[prop.key] = finalProps
                    }else{
                        if(prop.value === "false" || prop.value === "true"){
                            props[prop.key] = eval(prop.value);
                        }else{
                            props[prop.key] = prop.value;
                        }
                    }
                }
                return props;
            }
            return props;
        }
    },
    methods:{
        onCampDataSaved(campData){
            for(let key of Object.keys(campData)){
                this.activeCampData[key] = campData[key];
            }
        },
        reloadPage(){
            window.location.reload();
        },
        getCurrentComponent(){
            if(this.app.activeSubMenu){
                return this.app.activeSubMenu.component;
            }
            return null;
        },
        getCurrentDashboard(){
            if(this.$refs){
                return this.$refs.DashboardPage
            }
            return undefined;
        },
        getNavBar(){
            if(this.$refs){
                return this.$refs.NavBar;
            }
            return undefined;
        },
        async selectCamp(year){
            if(year != null && year !== undefined && year !== "null"){
                this.$cookie.set("activeCampYear",year);
                let result = await this.$network.getCampData(year);
                this.activeCampData = result;
                this.reloadDashPage();
            }
        },
        async reloadDashPage(){
            let activeSubMenu = this.app.activeSubMenu;
            this.app.activeSubMenu = undefined;
            this.$nextTick(()=>{
                this.app.activeSubMenu = activeSubMenu;
            })
        },
        async loadCampList(){
            this.campYears = await this.$network.getCampListByYear();
            this.campYears.sort().reverse();
            let storedCampYear = this.$cookie.get("activeCampYear");
            if(this.$router.currentRoute.query.jahr){
                this.activeCampYear = this.$router.currentRoute.query.jahr;
                this.selectCamp(this.activeCampYear);
            }else{

                if(this.campYears.length > 0){
                    this.activeCampYear = this.campYears[0];
                    this.selectCamp(this.campYears[0]);
                }
                if(storedCampYear){
                    this.activeCampYear = storedCampYear;
                    this.selectCamp(storedCampYear);
                }
            }
        }
    }
    
}
</script>
<style scoped>
.full-width{
    min-width:100% !important;
}

.smaller{
    font-size:12px;
}
</style>