<template>
    <v-container>
        <v-row>
            
            <v-select
                class="pr-0 hiddenSelector" 
                ref="selectInterface"
                hide-details="auto" 
                solo dense 
                :items="items"
                :value="localValue" 
                @input="changeValue"
                :rules="rules"
            >   
                <template slot="item" slot-scope="data">
                    {{data.item.text}}
                </template>
            </v-select>
        </v-row>

        <v-row class="justify-space-between"
            v-if="campData && campData.freizeit"
        >
            <v-col 
                v-for="(camp, key) in campData.freizeit" :key="key"
                class="pa-0"
                cols="12"
                :sm="cardWidth(campData.freizeit)"
            >
                <!-- {{camp}} -->
                <v-card
                    :disabled="!passesAgeValidation(camp) || !formData.geburtstag"
                    block
                    class="pa-2 ma-1"
                    :dark="camp.name === localValue"
                    :class="{
                        'primary':camp.name === localValue,
                        'silver lighten-5':!passesAgeValidation(camp),
                        'grey lighten-5':camp.name !== localValue && passesAgeValidation(camp),
                    }"
                    @click="changeValue(camp.name); localValue=camp.name"
                > 
                    <h2 class="text-center"
                        :class="{
                            'primary--text':camp.name !== localValue && passesAgeValidation(camp),
                            'grey--text lighten-5--text text-decoration-line-through':!passesAgeValidation(camp),
                            
                        }"
                    >   
                        
                        {{camp.name}}
                        <v-icon style="position:absolute; right:16px; top:10px;" v-if="camp.name === localValue">mdi-check-bold</v-icon>
                        <!-- <v-icon style="position:absolute; right:16px; top:10px;" class="primary--text" v-else>mdi-checkbox-blank-circle</v-icon> -->
                    </h2>
                    <v-divider/>
                    <v-card-text
                        class="pr-1 pl-1"
                        v-if="camp"
                        :class="{
                            'grey--text lighten-5--text':!passesAgeValidation(camp),
                        }"
                    >
                        <p class="ma-0">
                            <v-icon small>mdi-calendar-month</v-icon> 
                            {{getDate(camp.datum_von, false)}} - {{getDate(camp.datum_bis)}}
                        </p>
                        <p class="ma-0">
                            <v-icon small>mdi-account</v-icon>
                            Jahrgang: {{getDate(camp.alter_von, true, true)}} - {{getDate(camp.alter_bis, true, true)}}
                        </p>
                        <h3 class="ma-0">
                            <b>Preis: {{camp.preis}} €</b>
                        </h3>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="error--text caption"
            v-if="selectInterface && !selectInterface.valid && validate"
        >
            <v-col class="pt-0">
                {{selectInterface.errorBucket[0]}}
            </v-col>
        </v-row>

        
        <v-row v-if="campData !== undefined">
            <v-col class="pa-1">
                <v-alert dense class="mb-0" icon="mdi-alert" v-if="ageToOld(campData.freizeit[campData.freizeit.length-1])" border="left"  color="error" text>
                    <p>
                        Leider bist du bist schon zu alt für eine Teilnahme in der <b>E3</b>.
                    </p>
                    <p><b>info@espelcamp.de</b></p> 

                </v-alert>
                <v-alert dense class="mb-0" icon="mdi-alert" v-else-if="ageToYoung(campData.freizeit[0])" border="left"  color="error" text>
                    <p>
                        Leider bist du bist du noch zu jung für eine Teilnahme in der <b>E1</b>.
                    </p>
                    <p><b>info@espelcamp.de</b></p>
                </v-alert>
            </v-col>
        </v-row>

        <v-row v-if="campData && !ageToOld(campData.freizeit[campData.freizeit.length-1]) && !ageToYoung(campData.freizeit[0]) && !value && formData.geburtstag">
            <v-col class="pa-1">
                <v-alert border="left" dense icon="mdi-cursor-default-click" class="text-center mb-0" color="primary" text>
                    <strong>Wähle eine Freizeit</strong>
                </v-alert>
            </v-col>
        </v-row>

        <v-row v-if="!formData.geburtstag">
            <v-col class="pa-1">
                <v-alert border="left" dense icon="mdi-cursor-default-click" class="text-center mb-0" color="warning" text>
                    <strong>Trage zuerst deinen Geburtstag ein.</strong>
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import dayjs from 'dayjs'

export default {
    async mounted(){
        await this.getCampData();
        this.selectInterface = this.$refs.selectInterface;
        this.items = []
        for(let camp of this.campData.freizeit){
            this.items.push({text:camp.name, value:camp.name})
        }
    },
    props:{
        // items:Array,
        value:String,
        year:Number,
        rules: {
            type: Array,
            required: false
        },
        formData:Object
    },
    data(){
        return{
            items:[],
            selectInterface:undefined,
            localValue: this.value || "",
            campData:undefined,
            validate:false
        }
    },
    watch:{
        value(value){
            this.localValue = value || "";
        }
    },
    methods:{
        cardWidth(camps){
            return Math.floor(12/camps.length);
        },
        validateInput(){
            this.validate = true;
        },
        changeValue(event){
            this.validate = true
            this.$emit("input",event);
        },
        ageToOld(camp){
            if(!this.formData.geburtstag || this.ignoreValidation()){
                return false
            }
            
            let birthday = dayjs(dayjs(this.formData.geburtstag).format()).unix();
            let age = dayjs(dayjs(camp.alter_von).format()).unix();
            if(birthday < age){
                return true;
            }
            return false
        },
        ageToYoung(camp){
            if(!this.formData.geburtstag || this.ignoreValidation()){
                return false
            }
            let birthday = dayjs(dayjs(this.formData.geburtstag).format()).unix();
            let age = dayjs(dayjs(camp.alter_bis).format()).unix();
            if(birthday > age){
                return true
            }
            return false
        },
        ignoreValidation(){
            return this.$router.currentRoute.path.includes('/teilnehmer-anmeldung-extra');
        },
        passesAgeValidation(camp){
            if(this.ignoreValidation()){
                return true;
            }
            if (!this.formData.geburtstag){
                return true;
            }
            let birthday = dayjs(this.formData.geburtstag).unix();
            let from = dayjs(camp.alter_von).unix();
            let to = dayjs(camp.alter_bis).unix();

            if (birthday >= from && birthday <= to){
                return true
            }
            if(camp.name === this.localValue){
                this.$emit("input",undefined);
            }
            return false;

        },
        async getCampData(){
            let result = await this.$network.requestGet("/items/camp?filter[jahr][_eq]="+this.year+"&fields=jahr,freizeit.*");
            this.campData = result.data.data[0];
        },
        getCamp(name){
            if(this.campData !== undefined && this.campData.freizeit){
                for(let camp of this.campData.freizeit){
                    if(camp.name === name){
                        this.getDate(camp.datum_von);
                        return camp;
                    }
                }
            }
            return undefined;
        },
        getDate(dateString, withYear=true, monthOnly=false){
            var d;
            if(withYear){
                d = dayjs(dateString).locale('de').format('DD.MM.YYYY');
            }else{
                d = dayjs(dateString).locale('de').format('DD.MM');
            }

            if(monthOnly){
                d = dayjs(dateString).locale('de').format('MM.YYYY');
            }
            return d;
        },
    }
    
}
</script>

<style>
    .hiddenSelector{
        display: none !important;
    }
</style>