<template>
    <v-alert
        :color="color"
        text
        border="left"
    >   	
        <h3>Termine</h3>

            <v-simple-table
                dense
                class="primary--text"
            >
                <thead class="ma-0 pa-0">
                    <tr>
                        <th class="pa-1 primary--text">Freizeit</th>
                        <th class="pa-1 primary--text">Datum</th>
                        <th class="pa-1 primary--text">Anreise</th>
                        <th class="pa-1 primary--text">Teilnehmer</th>
                    </tr>
                </thead>
                <tbody class="ma-0 pa-0">
                    <tr v-for="(camp, index) in camps" :key="index">
                        <td class="pa-1">
                            <b>{{camp.name}}</b>
                        </td>
                        <td class="pa-1">
                            {{formatDate(camp.datum_von, false)}} - {{formatDate(camp.datum_bis, true)}}
                        </td>
                        <td class="pa-1">
                            Anreise der Mitarbeiter ist am {{formatDate(camp.anreise_mitarbeiter, true)}} {{formatTime(camp.anreise_mitarbeiter)}} (Mindestalter <b>{{camp.mitarbeiter_alter_von}}</b> Jahre)
                        </td>
                        <td class="pa-1">
                            Teilnehmer im Alter von {{getAge(camp.datum_von, camp.alter_bis)}} - {{getAge(camp.datum_von, camp.alter_von)-1}} Jahren
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
    </v-alert>
</template>

<script>
import dayjs from 'dayjs';

export default {
    props:{
        color:String,
        year:Number
    },
    data(){
        return{
            camps:[]
        }
    },
    async mounted(){
        let result = await this.$network.requestGet("/items/camp?fields=freizeit.*&filter[jahr][_eq]="+this.year.toString())
        if(result.status === 200){
            this.camps = result.data.data[0].freizeit;
        }
    },
    methods:{
        formatTime(dateString){
            return dayjs(dateString).format('HH:mm')
        },
        formatDate(dateString, full){
            if(full){
                return dayjs(dateString).format('DD.MM.YY');
            }
            return dayjs(dateString).format('DD.MM');
        },
        getAge(dateCampString, dateString){
            return dayjs(dateCampString).diff(dateString, 'years')
        },
        getArrivalDate(campDateString){
            return dayjs(campDateString).subtract(1, "days").format('DD.MM.YY')
        }

    }
}
</script>

<style>

    th{
      max-width: 100px;
      white-space:nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

</style>