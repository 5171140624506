var render = function render(){var _vm=this,_c=_vm._self._c;return _c('popup-dialog-base',{ref:"dialog",attrs:{"toolButtons":[],"title":_vm.activeImageTitle,"persistent":false,"max-width":2000},scopedSlots:_vm._u([(_vm.showThumbNails)?{key:"button",fn:function(){return [(_vm.dataUriThumb)?_c('v-row',{staticClass:"pa-2"},_vm._l((_vm.images),function(image,index){return _c('v-card',{key:index,staticClass:"mr-2 mb-1"},[(_vm.getImage(image.id, true) != '')?_c('v-img',{staticClass:"mouse-grab",attrs:{"src":_vm.getImage(image.id, true),"max-height":"100","max-width":"100"},on:{"click":function($event){return _vm.showImage(image.id, image.label)}}}):_vm._e()],1)}),1):_vm._e()]},proxy:true}:null,{key:"content",fn:function(){return [_c('v-container',{ref:"imageContainer",staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{directives:[{name:"touch",rawName:"v-touch",value:({
                    left: () => _vm.nextImage(),
                    right: () => _vm.prevImage(),
                }),expression:"{\n                    left: () => nextImage(),\n                    right: () => prevImage(),\n                }"}],staticClass:"ma-0",attrs:{"no-gutters":""}},[_c('div',{staticClass:"mr-8",staticStyle:{"z-index":"2","position":"absolute","right":"0px"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.zoom=!_vm.zoom}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_c('v-col',[(!_vm.zoom)?_c('v-img',{staticClass:"center",attrs:{"contain":"","width":_vm.getWidth(),"height":_vm.getHeight(),"src":_vm.getImage(_vm.activeImage, false)}}):_c('div',{style:({
                            'overflow-y':'scroll',
                            'max-height':_vm.getHeight()+'px'
                        })},[_c('v-img',{staticClass:"center",attrs:{"src":_vm.getImage(_vm.activeImage, false)}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"x-large":"","icon":"","elevation":"0"},on:{"click":function($event){return _vm.prevImage()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),_c('v-col',[_c('v-btn',{attrs:{"x-large":"","icon":"","elevation":"0"},on:{"click":function($event){return _vm.nextImage()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }