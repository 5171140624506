<template>
    <v-combobox
        v-model="localValue"
        :items="items"
        :label="label"
        chips
        multiple
        dense
        solo
        small-chips
        small
        hide-details="auto"
        @input="changeValue"
    >
        <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
            v-bind="attrs"
            small
            label
            :input-value="selected"
            close
            @click="select"
            @click:close="remove(item)"
        >   
            <strong
                v-if="item.text"
            >
                {{ item.text }}
            </strong>
            <strong
                v-else
            >
                {{ item }}
            </strong>
        </v-chip>
        </template>
    </v-combobox>
</template>

<script>
export default {
    props:{
        value:Array,
        items:Array,
        label:String
    },
    data(){
        return{
            localValue: this.value,
        }
    },
    watch:{
        value(value){
            this.localValue = value || [];
        }
    },
    methods:{
        changeValue(event){
            this.$emit("input", event);
        },
        remove (item) {
            this.localValue.splice(this.localValue.indexOf(item), 1);
            this.localValue = [...this.localValue];
            this.$emit("input", this.localValue);
        },
    }
    
}
</script>