<template>
    <div>
        <!-- Header Buttons -->
        <portal to="header-tools">
            <v-row 
                class="pr-3"
                v-if="freizeiten && dataChanged()"
            >
                <v-btn 
                    class="error mr-2" fab x-small elevation="2" @click="restoreFreizeiten()"
                >
                    <v-icon>mdi-close-thick</v-icon>
                </v-btn>
                <v-btn 
                    class="primary mr-2" fab x-small elevation="2" @click="saveCampData()"
                >
                    <v-icon>mdi-check-bold</v-icon>
                </v-btn>
            </v-row>
        </portal>
        <report-card-base
            ref="report"
            :title="title"
            :icon="'mdi-home-group'"
        >
            <template v-slot:header-tools>
                <v-btn
                    icon
                    x-small
                    @click="edit=!edit;"
                    class="mr-2"
                    v-if="dashboard.campDataEditable"
                >
                    <v-icon
                        :class="[edit?'success--text':'']"
                    >
                        mdi-pencil
                    </v-icon>
                </v-btn>
                
                <v-btn
                    icon
                    x-small
                    @click="printTable()"
                >
                    <v-icon>
                        mdi-printer
                    </v-icon>
                </v-btn>
            </template>
    
            <template v-slot:content>
                <v-select 
                    solo 
                    dense
                    hide-details
                    v-model="activeFreizeit" 
                    :items="freizeiten"
                    return-object
                    item-text="name"
                    @change="selectFreizeit"
                >
                </v-select>
                <v-row class="mb-0 mt-0 pt-0"
                    v-if="activeFreizeit"
                >
                    <v-col class="mb-0 mt-0 pt-0">
                        <v-switch
                            :disabled="!dashboard.campDataEditable"
                            v-model="activeFreizeit.teilnehmer_planung_freigeben" 
                            hide-details 
                            label="Planung freigeben"
                        >
                        </v-switch>
                    </v-col>
                </v-row>
                <v-row class="mt-0">
                    <v-col class="mt-0">
                        <h3><v-icon>mdi-face-man</v-icon>Jungen</h3>
                    </v-col>
                </v-row>
    
                <!-- {{activeFreizeit}} {{data}} {{collectionParticipants}} -->
                <!-- {{data}} -->
                <v-row no-gutters
                    v-if="activeFreizeit && data && dataEmployee"
                >
                    <v-col class="ma-0 pa-0" v-for="(provinz, key) in activeFreizeit.provinzen" :key="key">
                        <interface-group-sort 
                            :data="data.boys[activeFreizeit.name][provinz.key]"
                            :dataEmployee="dataEmployee.m[activeFreizeit.name][provinz.key]"
                            :title="provinz.key"
                            :color="provinz.color"
                            :group="'jungen'"
                            :freizeit-name="activeFreizeit.name"
                            :freizeiten="freizeiten"
                            :totalSlots="provinz.count.boys"
                            :collection="(collectionParticipants + year)"
                            :collectionEmployees="(collectionEmployees + year)"
                            :availableParticipants="availableParticipants[activeFreizeit.name].boys"
                            :availableEmployees="availableEmployees[activeFreizeit.name].m"
                            :updateMethod="requestData"
                            :edit="edit"
                        />
                    </v-col>
                </v-row>
    
    
                <v-row>
                    <v-col>
                        <h3><v-icon>mdi-face-woman</v-icon>Mädchen</h3>
                    </v-col>
                </v-row>
                <v-row no-gutters
                    v-if="activeFreizeit && data && dataEmployee"
                >
                    <v-col class="ma-0 pa-0" v-for="(provinz, key) in activeFreizeit.provinzen" :key="key">
                        <interface-group-sort 
                            :data="data.girls[activeFreizeit.name][provinz.key]"
                            :dataEmployee="dataEmployee.w[activeFreizeit.name][provinz.key]"
                            :title="provinz.key"
                            :color="provinz.color"
                            :group="'mädchen'"
                            :freizeit-name="activeFreizeit.name"
                            :freizeiten="freizeiten"
                            :totalSlots="provinz.count.girls"
                            :collection="(collectionParticipants + year)"
                            :collectionEmployees="(collectionEmployees + year)"
                            :availableParticipants="availableParticipants[activeFreizeit.name].girls"
                            :availableEmployees="availableEmployees[activeFreizeit.name].w"
                            :updateMethod="requestData"
                            :edit="edit"
                        />
                    </v-col>
                </v-row>
    
            </template>
        </report-card-base>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import ReportCardBase from './ReportCardBase.vue'
import InterfaceGroupSort from '../../../components/interfaces/InterfaceGroupSort.vue'
export default {
    components:{
        ReportCardBase,
        InterfaceGroupSort
    },
    async mounted(){
        this.reloadData();
    },
    props:{
        collectionParticipants:String,
        collectionEmployees:String,
        year:String,
        title:String,
    },
    inject:['app','dashboard'],
    data(){
        return {
            data:undefined,
            dataEmployee:undefined,
            freizeiten:undefined,
            activeFreizeit:undefined,
            activeFreizeitCopy:undefined,
            lists:{},
            availableParticipants:{},
            availableEmployees:{},
            edit:false,
        }
    },
    methods:{
        hexToInt(hex) {
            return parseInt(hex.replace('#', ''), 16);
        },
        async saveCampData(){
            let url = "/items/freizeit/"+this.activeFreizeit.id.toString();
            let result = await this.$network.requestPatch(url,this.activeFreizeit);
            if(result.status === 200){
                this.activeFreizeitCopy = JSON.parse(JSON.stringify(this.activeFreizeit));
                this.app.showNotification("Freizeit-Daten wurden geändert.",2000, "success")
            }
        },
        dataChanged(){
            let frezeitChanged = JSON.stringify(this.activeFreizeit) !== JSON.stringify(this.activeFreizeitCopy);
            return frezeitChanged;
        },
        restoreFreizeiten(){
            this.activeFreizeit = JSON.parse(JSON.stringify(this.activeFreizeitCopy));
        },
        async reloadData(){
            await this.requestFreizeiten();
            await this.requestData();
            
            let selection = this.$cookie.get("provinzPlanerSelection");
            if(selection){
                for(let freizeit of this.freizeiten){
                    if(freizeit.name === selection){
                        this.activeFreizeit = freizeit;
                        this.activeFreizeitCopy = JSON.parse(JSON.stringify(this.activeFreizeit))
                    }
                }
            }
        },
        selectFreizeit(freizeit){
            this.activeFreizeit = freizeit;
            this.activeFreizeitCopy = JSON.parse(JSON.stringify(this.activeFreizeit))
            this.$cookie.set("provinzPlanerSelection", freizeit.name);
        },
        async requestFreizeiten(){
            let url = "/items/camp"
            url += "?fields=freizeit.*"
            url += "&filter[jahr][_eq]="+this.year;
            let result = await this.$network.requestGet(url);
            if(result.status === 200){
                this.freizeiten = result.data.data[0].freizeit
                this.activeFreizeit = this.freizeiten[0]
                this.activeFreizeitCopy = JSON.parse(JSON.stringify(this.activeFreizeit))
            }

        },
        async requestData(){
            if(this.$refs.report){
                this.$refs.report.setLoading(true);
            }
            
            // get participant data
            let url = "/items/"+this.collectionParticipants+this.year+"?limit=-1";
            url += "&sort=freunde_farbe";
            // fields can only be accessed if role has field access to *
            // url += "&fields=id,vorname,nachname,provinz_farbe,geschlecht,freizeit,geburtstag,freunde,freunde_kommentar,status_person,telefon,mobile_telefon,email,strasse,plz,ort,gesundheit,gemeinde"
            url += '&filter=["status_person"]["_eq"]="Bestätigt"'
            let result = await this.$network.requestGet(url);
            if(result.status === 200){
                this.availableParticipants = this.extractAvailableParticipants(result.data.data);
                this.data = this.extractDisplayData(result.data.data);
            }

            // get employees data
            url = "/items/"+this.collectionEmployees+this.year+"?limit=-1"
            // fields can only be accessed if role has field access to *
            // url += "&fields=id,vorname,nachname,geschlecht,status_person,mitarbeiter_position,provinz"
            url += '&filter=["status_person"]["_eq"]="Bestätigt"'
            result = await this.$network.requestGet(url);
            if(result.status === 200){
                this.availableEmployees = this.extractAvailableEmployees(result.data.data);
                this.dataEmployee = this.extractEmployeeDisplayData(result.data.data);
            }
            if(this.$refs.report){
                this.$refs.report.setLoading(false);
            }
        },
        extractEmployeeDisplayData(data){
            let provinzLeiter = {};
            let provinzLeiterinnen = {};
            for(let freizeit of this.freizeiten){
                provinzLeiter[freizeit.name] = {};
                provinzLeiterinnen[freizeit.name] = {};
            }
            for(let person of data){
                if(person.status_person === "Bestätigt"){
                    for(let freizeitKey in person.provinz){
                        let provinzFarbe = person.provinz ? person.provinz[freizeitKey].color : "";
                        let position = person.mitarbeiter_position[freizeitKey].position;
                        if(provinzFarbe && position === "Gruppenleiter/in"){
                            if(person.geschlecht === "männlich"){
                                provinzLeiter[freizeitKey][provinzFarbe] = person;
                            }else if(person.geschlecht === "weiblich"){
                                provinzLeiterinnen[freizeitKey][provinzFarbe] = person;
                            }
                        }
                    }
                }
            }
            return {m:provinzLeiter, w:provinzLeiterinnen};
        },
        extractAvailableEmployees(data){
            let availableEmployees = {}
            for(let freizeit of this.freizeiten){
                availableEmployees[freizeit.name] = {m:[], w:[]};
            }

            for(let person of data){
                if(person.status_person === "Bestätigt"){
                    for(let freizeitKey in person.mitarbeiter_position){
                        let position = person.mitarbeiter_position[freizeitKey].position;
                        let provinzColor = person.provinz ? person.provinz[freizeitKey].color : "";
                        if(position === "Gruppenleiter/in" && !provinzColor){
                            if(person.geschlecht === "männlich"){
                                availableEmployees[freizeitKey].m.push(person);
                            }else if(person.geschlecht === "weiblich"){
                                availableEmployees[freizeitKey].w.push(person);
                            }
                        }
                    }
                }
            }
            return availableEmployees;
        },
        extractAvailableParticipants(data){
            let availableParticipants = {};
            for(let freizeit of this.freizeiten){
                if(!availableParticipants[freizeit.name]){
                    availableParticipants[freizeit.name] = {boys:[], girls:[]}
                }
            }
            for(let person of data){
                if(!person.provinz_farbe && person.status_person === "Bestätigt"){
                    if(person.geschlecht === "Junge"){
                        availableParticipants[person.freizeit].boys.push(person)
                    }else if(person.geschlecht === "Mädchen"){
                        availableParticipants[person.freizeit].girls.push(person)
                    }
                }
            }
            return availableParticipants;
        }, 
        extractDisplayData(data){
            let provinzenGirls = {};
            let provinzenBoys = {};
            for(let freizeit of this.freizeiten){
                provinzenGirls[freizeit.name] = {};
                provinzenBoys[freizeit.name] = {};
            }
            for(let person of data){
                if(person.provinz_farbe){

                    if(person.geschlecht === 'Mädchen' && person.status_person === "Bestätigt"){
                        if(!provinzenGirls[person.freizeit][person.provinz_farbe]){
                            provinzenGirls[person.freizeit][person.provinz_farbe] = []
                        }
                        provinzenGirls[person.freizeit][person.provinz_farbe].push(person);
                    }
                    if(person.geschlecht === 'Junge' && person.status_person === "Bestätigt"){
                        if(!provinzenBoys[person.freizeit][person.provinz_farbe]){
                            provinzenBoys[person.freizeit][person.provinz_farbe] = []
                        }
                        provinzenBoys[person.freizeit][person.provinz_farbe].push(person);
                    }
                }
            }
            return {boys:provinzenBoys, girls:provinzenGirls};
        },
        printTable(){
            let html = "<html>"
            html += `
                <head>
                    <meta charset="utf-8" />
                    <style>
                        @media print, screen{
                            th{
                                background-color:#f0f0f0;"
                            }

                            .darken{
                                background-color:#fafafa;"
                            }
                            body {
                                font-family:Arial;
                                -webkit-print-color-adjust: exact;
                                -moz-print-color-adjust: exact;
                                -ms-print-color-adjust: exact;
                                print-color-adjust: exact;
                            }
                        }
                    </style>
                </head>
            `
            html += "<body>"
            // html += `<h4 style='margin-bottom:5px; font-family:Arial;'>Espelcamp - Provinz Übersicht ${this.year} - ${this.activeFreizeit.name}</h4>`

            for(let provinz of this.activeFreizeit.provinzen){
                
                let genders = ["boys","girls"];


                for(let gender of genders){
                    let genderGL = gender==='boys'?'m':'w';
                    let gruppenLeiter = this.dataEmployee[genderGL][this.activeFreizeit.name][provinz.key];
                    html += "<p></p>"
                    html += "<p>"
                    html += `
                        <div style="margin:5px;float:left; width:10px; height:10px; background-color:${provinz.color} !important;"></div>
                        <span style="text-transform: capitalize;">${provinz.key} - ${gender==='boys'?'Jungen':'Mädchen'}</span>
                    `
                    if(gruppenLeiter){
                        html += `
                        <p>
                            <strong>${gender==='boys'?'Gruppenleiter':'Gruppenleiterin'}:</strong> ${gruppenLeiter.nachname}, ${gruppenLeiter.vorname}
                            - <strong>${this.activeFreizeit.name}</strong>
                        </p>`
                    }
                    html += "</p>"
                    html += "<table style='page-break-inside: auto; width:100%; font-family:Arial; border:1px solid silver; border-collapse: collapse;'>"
                    html += "<thead>"
                    html += `
                    <tr style="font-size:12px; border:1px solid silver;">
                        <th style="text-align:left;"></th>
                        <th style="width:100px; text-align:left;">Name</th>
                        <th style="text-align:left;">Geb.</th>
                        <th style="text-align:left;">Telefon/Handy</th>
                        <th style="text-align:left;">Email</th>
                        <th style="text-align:left;">Adresse</th>
                        <th style="text-align:left;">Gemeinde</th>
                        <th style="text-align:left;">Gesundheitliche Einschränkungen</th>
                    </tr>
                    `

                    html += "</thead>"
                    html += "<tbody>"
                    
                    for(let i=0; i<provinz.count[gender]; i++){
                        let persons = this.data[gender][this.activeFreizeit.name][provinz.key];
                        let person;
                        if(persons){
                            person = persons[i];
                        }
                        if(person){
                            html += `
                                <tr class="${i%2===0?'darken':''}" style="height:25px; border:1px solid silver; font-size:10px;">
                                    <td>${(i+1).toString().padStart(2)}</td>
                                    <td>${person.nachname}, ${person.vorname}</td>
                                    <td>${dayjs(person.geburtstag).format("DD.MM.YYYY")}</td>
                                    <td>${person.telefon?person.telefon:''}/${person.mobil_telefon}</td>
                                    <td>${person.email}</td>
                                    <td>${person.strasse}, ${person.plz} ${person.ort}</td>
                                    <td>${person.gemeinde?person.gemeinde:''}</td>
                                    <td>${person.gesundheit?String(person.gesundheit).replace(",",", "):''}</td>
                                </tr>
                            `
                        }else{
                            html += `
                                <tr class="${i%2===0?'darken':''}" style="height:25px; border:1px solid silver; font-size:10px;">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            `
                        }
                    }
                    
                    html += "</tbody>"
                    html += "</table>"
                }
                html += "<div style='page-break-after: always;'> </div>"
            }
            html += "</body></html>"
            let newWin= window.open("");
            newWin.document.write(html);
            newWin.print();
            newWin.close();
        }
    }
}
</script>

<style>
    .striped {
        background-color: rgba(0, 0, 0, .03);
    }
    .capitalize {
        text-transform: capitalize;
    }
</style>