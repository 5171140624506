<template>
    <report-card-base
        ref="report"
        :title="'Teilnehmer - Freizeit-Anmeldungen'"
        :icon="'mdi-tent'"
    >
        <template v-slot:content>
            <v-simple-table
                dense
                fixed-header
                v-if="data"
            >
                <thead>
                    <th class="pl-2 pr-2 text-center" v-for="(item, key) in data[0].data" :key="key">
                        {{item.key}}
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in data" :key="index"
                        :class="index%2===0 ? 'striped' : ''"
                    >
                        <td v-for="(item, key) of item.data" :key="key"
                            class="text-left no-line-break"
                        >   
                            <strong
                                v-if="item.key === 'Gesamt' || item.key==='Freizeit'"
                            >
                                
                                <v-row no-gutters>
                                    <v-col class="text-center" cols="12" :sm="key==='freizeit'?'12':'4'">
                                        <span :class="item.key === 'Gesamt'?'blue--text text--accent-3':''">{{item.value}} </span>
                                    </v-col>
                                    <v-col class="text-center" cols="12" sm="4" >
                                        <span class="success--text ">{{item.confirmed}}</span>
                                    </v-col>
                                    <v-col class="text-center" cols="12" sm="4" >
                                        <span class="warning--text ">{{item.queued}}</span>
                                    </v-col>
                                </v-row>
                            </strong>
                            <span v-else>
                                <v-row no-gutters>
                                    <v-col class="text-center" cols="12" sm="4" >
                                        <span class="blue--text text--accent-3">{{item.value}}</span>
                                    </v-col>
                                    <v-col class="text-center" cols="12" sm="4" >
                                        <span class="success--text ">{{item.confirmed}}</span>
                                    </v-col>
                                    <v-col class="text-center" cols="12" sm="4" >
                                        <span class="warning--text ">{{item.queued}}</span>
                                    </v-col>
                                </v-row>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-divider></v-divider>
            <v-row>
                <v-col class="d-flex justify-center">
                    <span class="pr-2">
                        <v-icon size="16" color="blue accent-3">
                            mdi-square
                        </v-icon> Angemeldet
                    </span>
                    <span class="pr-2">
                        <v-icon size="16" color="success">
                            mdi-square
                        </v-icon> Bestätigt
                    </span>
                    
                    <span class="pr-2">
                        <v-icon size="16" color="warning">
                            mdi-square
                        </v-icon> Warteliste
                    </span>
                </v-col>
            </v-row>
        </template>
    </report-card-base>
</template>

<script>
import ReportCardBase from './ReportCardBase.vue'
export default {
    components:{
        ReportCardBase
    },
    async mounted(){
        await this.getFreizeiten()
        this.requestData()
    },
    props:{
        collection:String,
        year:String,
    },
    data(){
        return {
            data:undefined,
            freizeiten:undefined,
        }
    },
    methods:{
        async getFreizeiten(){
            let result = await this.$network.requestGet("/items/camp?filter[jahr][_eq]="+this.year+"&fields=freizeit.name")
            if(result.status === 200){
                this.freizeiten = result.data.data[0].freizeit;
            }
        },
        async requestData(){
            if(this.$refs.report){
                this.$refs.report.setLoading(true);
            }
            let url = "/items/"+this.collection+"?limit=-1"
            // url += "&fields=freizeit,geschlecht,status_person,status_email"
            let result = await this.$network.requestGet(url);
            if(result.status === 200){
                this.data = this.extractRegistrationData(result.data.data);
            }
            if(this.$refs.report){
                this.$refs.report.setLoading(false);
            }
        },
        extractRegistrationData(registrations){
            var data = {};
            for(let freizeit of this.freizeiten){
                data[freizeit.name] = {
                    camp:freizeit.name, 
                    data:{
                        freizeit:{key:"Freizeit", value:freizeit.name},
                        boys:{key:"Jungen", value:0, confirmed:0, queued:0},
                        girls:{key:"Mädchen", value:0, confirmed:0, queued:0},
                        gesamt:{key:"Gesamt", value:0, confirmed:0, queued:0},
                    }
                }
            }
            registrations
            for(let registration of registrations){
                if(registration.geschlecht === "Junge"){
                    if(["Abgemeldet","Absage"].includes(registration.status_person) === false){
                        data[registration.freizeit].data.boys.value += 1;
                    }
                    if(registration.status_person === "Bestätigt"){
                        data[registration.freizeit].data.boys.confirmed += 1;
                        data[registration.freizeit].data.gesamt.confirmed += 1;
                    }else if(registration.status_person === "Warteliste"){
                        data[registration.freizeit].data.boys.queued += 1;
                        data[registration.freizeit].data.gesamt.queued += 1;
                    }
                }else if(registration.geschlecht === "Mädchen"){
                    if(["Abgemeldet","Absage"].includes(registration.status_person) === false){
                        data[registration.freizeit].data.girls.value += 1;
                    }
                    if(registration.status_person === "Bestätigt"){
                        data[registration.freizeit].data.girls.confirmed += 1;
                        data[registration.freizeit].data.gesamt.confirmed += 1;
                    }else if(registration.status_person === "Warteliste"){
                        data[registration.freizeit].data.girls.queued += 1;
                        data[registration.freizeit].data.gesamt.queued += 1;
                    }
                }
                if(["Abgemeldet","Absage"].includes(registration.status_person) === false){
                    data[registration.freizeit].data.gesamt.value += 1;
                }
            }

            if(data){
                let dataArray = [];
                for(let freizeitKey in data){
                    dataArray.push(data[freizeitKey]);
                }
                let total = {
                    camp:"Gesamt",
                    data:{
                        freizeit:{key:"Freizeit", value:"Gesamt"},
                        boys:{key:"Jungen", value:0, confirmed:0, queued:0},
                        girls:{key:"Mädchen", value:0, confirmed:0, queued:0},
                        gesamt:{key:"Gesamt", value:0, confirmed:0, queued:0},
                    }
                }
                for(let camp of dataArray){
                    total.data.boys.value += camp.data.boys.value;
                    total.data.boys.confirmed += camp.data.boys.confirmed;
                    total.data.boys.queued += camp.data.boys.queued;

                    total.data.girls.value += camp.data.girls.value;
                    total.data.girls.confirmed += camp.data.girls.confirmed;
                    total.data.girls.queued += camp.data.girls.queued;

                    total.data.gesamt.value += camp.data.gesamt.value;
                    total.data.gesamt.confirmed += camp.data.gesamt.confirmed;
                    total.data.gesamt.queued += camp.data.gesamt.queued;
                }
                dataArray.push(total);
                return dataArray;
            }
            return undefined;
        }
    }
}
</script>

<style>
    .striped {
        background-color: rgba(0, 0, 0, .03);
    }
    .no-line-break{
        white-space: nowrap;
    }
</style>