<template>
    <v-alert
        color="secondary"
        text
        class="file-drop pa-0 ma-0"
    >
        <v-file-input
            v-model="file"
            flat
            block
            prepend-icon="mdi-image-plus"
            hide-details
            style="border:0px;"
            label="Bild hochladen"
            @change="uploadImage"
        >
        </v-file-input>
    </v-alert>
</template>

<script>
export default {
    props:{
        isSystemCollection:{type:Boolean, default:false},
        collectionName:{type:String},
        fieldName:{type:String},
        itemId:{type:String},
        uploadFolder:{type:String, default:undefined},
        updateMethod:Function,
    },
    data(){
        return{
            file:undefined
        }
    },
    methods:{
        async getUploadFolderId(){
            if(this.uploadFolder){
                
                let url = "/folders?filter[name][_eq]="+this.uploadFolder;
                let result = await this.$network.requestGet(url)
                if(result.status === 200){
                    return result.data.data[0].id;
                }
            }
            return undefined;
        },
        async uploadImage(fileInput){
            if(fileInput){
                const formData = new FormData();
                formData.append('file', fileInput)
                let folderId = await this.getUploadFolderId();

                // upload file into root folder
                let result = await this.$network.requestPost("/files",formData);

                // move file into destination folder
                if(result.status === 200 && folderId){
                    console.log(result.data)
                    let imageId = result.data.data.id;
                    console.log(imageId,"    #############")
                    let payload = {
                        keys:[imageId],
                        data:{
                            folder:folderId
                        }
                    }
                    await this.$network.requestPatch("/files",payload);
                    payload = {}
                    payload[this.fieldName] = imageId
                    // set inventory item image id to uploaded image id
                    if(this.isSystemCollection){
                        await this.$network.requestPatch("/"+this.collectionName+"/"+this.itemId,payload);
                    }else{
                        await this.$network.requestPatch("/items/"+this.collectionName+"/"+this.itemId,payload);
                    }
                    if(this.updateMethod){
                        this.updateMethod();
                    }
                }
            }
        }
    }
}
</script>