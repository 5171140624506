<template>
    <v-container>
        <v-card
            :flat="uiHidden()"
        >
            <div v-if="!uiHidden()">
                <v-card-title>
                    <v-icon>mdi-timer</v-icon>
                    {{title}}
                </v-card-title>
                <v-divider></v-divider>
            </div>
            <v-card-text
                v-if="dueDate !== undefined"
            >

                <v-row>
                    <v-col class="text-center">

                        <v-progress-circular :size="smallWatch?200:300" value="0">
                                    
                            <v-container class="counter-content">
        
                                <v-row no-gutters class="mb-0">
                                    <v-col cols="auto"></v-col>
                                    <v-col class="text-center">
                                        <!-- <span class="grey--text text--lighten-1">Tage</span> -->
                                        <h1 class="success--text"
                                            :class="smallWatch?'extra-large':'xx-large'"
                                        >
                                            {{days}}
                                        </h1>
                                        <div class="pt-5 grey--text text--lighten-2">
                                            <strong>Tage</strong>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto"></v-col>
                                </v-row>
                                <v-divider class="mt-0 mb-2"></v-divider>
                                <v-row no-gutters>
                                    <v-col offset="3"></v-col>
                                    <v-col class="text-center">
                                        <!-- <span class="grey--text text--lighten-1">Stunden</span> -->
                                        <h1 class="grey--text text--darken-2"
                                            :class="smallWatch?'':'extra-large'"
                                        >
                                            {{hours.toString().padStart(2,"0")}}<span class="grey--text text--lighten-1">:</span>{{minutes.toString().padStart(2,"0")}}<span class="grey--text text--lighten-1">:</span>{{seconds.toString().padStart(2,"0")}}
                                        </h1>
                                    </v-col>
                                    <v-col offset="3"></v-col>
                                </v-row>
                            </v-container>
                        </v-progress-circular>
                    </v-col>


                </v-row>
                <div v-if="showRegistrationButton" class="ma-2">
                    <v-row no-gutters class="ma-1">
                        <v-col class="text-center">
                            <v-btn :disabled="!countdownPassed()" @click="openRegistration('teilnehmer')" class="success" width="300" :progress="true"><v-icon>mdi-account-group</v-icon>  Teilnehmer {{ this.year }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="ma-1">
                        <v-col class="text-center">
                            <v-btn @click="openRegistration('mitarbeiter')" class="success" width="300" :progress="true"><v-icon>mdi-account-hard-hat</v-icon>  Mitarbeiter {{ this.year }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="ma-1">
                        <v-col class="text-center">
                            <v-btn @click="openRegistration('camphelfer')" class="success" width="300" :progress="true"><v-icon>mdi-account-clock</v-icon>  Camphelfer {{ this.year }}</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import dayjs from 'dayjs';

export default {
    async created(){
        await this.setDueDate();
        this.setRemainingTime();
        this.intervalCallback = setInterval(this.setRemainingTime, 1000);
        this.localValue = this.countdownPassed();
    },
    destroyed(){
        if(this.intervalCallback){
            clearInterval(this.intervalCallback);
            this.intervalCallback = undefined;
        }
    },
    props:{
        title:{type:String, default:"Anmeldung Countdown"},
        dueDateString:{type:String, default:"2023-04-22 13:50"}
    },
    data(){
        return{
            localValue:false,
            intervalCallback:undefined,
            dueDate:undefined,
            dateNow:undefined,
            showRegistrationButton:false,
            year:undefined,
            days:0,
            hours:0,
            minutes:0,
            seconds:0,
            smallWatch:false,
        }
    },
    inject:[
        "app",
    ],
    methods:{
        openRegistration(type){
            window.open(`/${type}-anmeldung/${this.year}`, "_blank")
        },
        uiHidden(){
            return this.$route.query.hide_ui === "true";
        },
        async setDueDate(){
            this.showRegistrationButton = this.$route.query.show_registration_button;
            this.smallWatch = this.$route.query.small_watch;
            if(this.$route.query.due_date){
                this.dueDate = dayjs(this.$route.query.due_date).locale("de");
            }else if(this.$route.query.request_date === "true"){
                const year = parseInt(this.app.$router.currentRoute.path.replace(/[^0-9]/g,''));
                this.year = year;
                const url = `/items/camp?filter[jahr][_eq]=${year}`
                let result = await this.$network.requestGet(url);
                if(result.status === 200){
                    if(result.data.data.length > 0){
                        const camp = result.data.data[0];
                        this.dueDate = dayjs(camp.teilnehmer_anmeldung_countdown).locale("de");
                    }
                }
            }
            else{
                this.dueDate = dayjs(this.dueDateString).locale("de");
            }
        },
        countdownPassed(){
            return !(this.dueDate.diff(this.dateNow, 'seconds') > 0);
        },
        setRemainingTime(){
            if(this.countdownPassed()){
                this.days = 0;
                this.hours = 0;
                this.minutes = 0;
                this.seconds = 0;
                if(this.intervalCallback){
                    clearInterval(this.intervalCallback);
                }
                this.intervalCallback = undefined;
                this.$emit("input",true);
            }else{
                this.dateNow = dayjs().locale("de");
                this.seconds = this.dueDate.diff(this.dateNow, 'seconds');
                this.minutes = Math.floor(this.seconds / 60);
                this.hours = Math.floor(this.minutes / 60);
                this.days = Math.floor(this.hours / 24);
                this.seconds -= (this.minutes * 60);
                this.minutes -= (this.hours * 60);
                this.hours -= (this.days * 24);
                this.$emit("input",false);
            }
        }
    }
}
</script>

<style scoped>
    .extra-large{
        margin-top:10px;
        font-size:50px;
    }
    .xx-large{
        margin-top:30px;
        font-size:90px;
    }

    .counter-content{
        position:relative;
        top:-10px;
    }
</style>