<template>
    <report-card-base
        ref="report"
        :title="title"
        :icon="'mdi-cupcake'"
    >
    <template v-slot:content>
        <v-simple-table
            dense
        >
            <thead>
                <tr>
                    <th>
                        Ereignis/Datum
                    </th>
                    <th>
                        Kuchen(Bleche)
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, key) in date" :key="key">
                    <td>
                        {{ key }}
                    </td>
                    <td>
                        {{date[key]}}
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Gesamt</strong>
                    </td>
                    <td>
                        <strong>{{cakesTotal}}</strong>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

    </template>


    </report-card-base>


</template>

<script>
import ReportCardBase from './ReportCardBase.vue';
export default {
    components:{
        ReportCardBase
    },

    props:{
        year:String,
        title:{type:String, default:"Kuchen Freizeit"},
        cakeField:{type:String, default:"kuchen_backen"},
        cakeCountField:{type:String, default:"anzahl_kuchen"},
        cakeDateField:{type:String, default:"kuchen_abgabe"},
    },
    data(){
        return{
            cakesTotal:0,
            date:{}
        }
    },
    mounted(){
        this.getCakeData();
    },
    methods:{
        async getCakeData(){
            let url = "/items/camphelfer_"+this.year+"?limit=-1";
            // url += "&filter[tassen][_gt]=0";
            url += `&filter={"_and":[{"${this.cakeField}":{"_eq":"true"}},{"${this.cakeCountField}":{"_gt":0}}]}`
            url+=`&fields=${this.cakeField},${this.cakeCountField},${this.cakeDateField}`;
            let result = await this.$network.requestGet(url);
            if(result.status === 200){
                for(let item of result.data.data){
                    this.cakesTotal += item[this.cakeCountField];
                    if(!this.date[item[this.cakeDateField]]){
                        this.date[item[this.cakeDateField]] = 0;
                    }
                    this.date[item[this.cakeDateField]] += item[this.cakeCountField];
                }
            }
        }
    }
}
</script>