<template>
    <div>
        <v-alert
            color="secondary"
            text
            class="file-drop pa-0 ma-0"
        >
            <v-file-input
                v-model="files"
                flat
                block
                prepend-icon="mdi-file-upload"
                hide-details
                multiple
                style="border:0px;"
                label="Anhang hochladen"
                chips
            >
            </v-file-input>
        </v-alert>
        <v-btn 
            v-if="files && files.length > 0"
            @click="uploadFiles"
            class="primary mt-1"
            block
            :loading="uploading"
        >
            <v-icon>
                mdi-upload
            </v-icon>
            Dateien hochladen
        </v-btn>
    </div>
</template>

<script>
export default {
    props:{
        collection:String,
        itemId:Number,
        uploadFolder:{type:String, default:undefined},
        preUploadMethod:Function,
        postUploadMethod:Function
    },
    data(){
        return{
            files:[],
            uploading:false,
        }
    },
    methods:{
        async getUploadFolderId(){
            if(this.uploadFolder){
                // let find = ' ';
                // let re = new RegExp(find, 'g');
                // this.uploadFolder = this.uploadFolder.replace(re, '%');
                
                let url = "/folders?filter[name][_eq]="+this.uploadFolder;
                let result = await this.$network.requestGet(url)
                if(result.status === 200){
                    return result.data.data[0].id;
                }
            }
            return undefined;
        },
        async uploadFiles(){
            if(this.files && this.files.length){
                this.uploading = true;
                if(this.preUploadMethod){
                    this.preUploadMethod();
                }
                const formData = new FormData();
                for(let file of this.files){
                    formData.append('file', file);
                }
                let folderId = await this.getUploadFolderId();
                let result = await this.$network.requestPost("/files",formData);
                if(result.status === 200 && folderId){
                    let ids = []
                    if (Array.isArray(result.data.data)){
                        for(let file of result.data.data){
                            ids.push(file.id)
                        }
                    }else{
                        ids = [result.data.data.id]
                    }
                    let payload = {
                        keys:ids,
                        data:{
                            folder:folderId
                        }
                    }
                    await this.$network.requestPatch("/files",payload);

                    for(let id of ids){
                        let collection_id = this.collection + "_id";
                        payload = {}
                        payload[collection_id] = this.itemId;
                        payload["directus_files_id"] = id;
                        await this.$network.requestPost("/items/"+this.collection+"_files",payload)
                    }
                }
            }
            this.files = [];
            this.uploading = false;
            if(this.postUploadMethod){
                this.postUploadMethod()
            }
        }
    }
}
</script>