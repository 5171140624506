<template>
    <v-card
        v-show="passesFilter(localValue)"
        :flat="localValue.field === undefined"
        :class="[
            (localValue.children.length > 0 && localValue.field)? 'parent':'',
        ]"
    >
        <v-card-text :class="[
                localValue.field?'mb-1':'mb-0',
                localValue.field?'pa-2':'pa-0',
            ]" 
        >
            <!-- BUTTONS FOR SAVING, EDITING, AND LEAVING -->
            <div style="position:absolute; top:0px; left:0px; text-align: center; min-width:100%; min-height:100%;"
                v-if="localValue.field !== undefined"
            >
                <v-btn
                    v-if="!edit"
                    text block
                    elevation="0"
                    style="height:40px; background-color:transparent;"
                    @click="toggleEditItem()"
                    @mouseover="hover=true"
                    @mouseout="hover=false"
                >
                    <v-icon v-show="hover">mdi-pencil</v-icon>
                </v-btn>
                <div v-else>
                    <div
                        class="mt-1"
                        v-if="valueChanged && !loading"
                    >
                        <v-btn
                            icon
                            small
                            class="error--text mr-2"
                            @click="resetChanges()"
                        >
                            <v-icon>mdi-close-thick</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            text
                            small
                            class="primary--text"
                            @click="saveChanges()"
                        >
                            <v-icon>mdi-check-bold</v-icon>
                        </v-btn>
                    </div>
                    <div v-else-if="loading">
                        
                        <v-btn
                            icon
                            text
                            small
                            class="primary--text"
                            loading
                        >
                            <v-icon>mdi-check-bold</v-icon>
                        </v-btn>
                    </div>
                    <div v-else>
                        <v-btn
                            block text elevation="0"
                            style="height:40px;"
                            @click="edit=!edit"
                        >
                            <v-icon>mdi-keyboard-backspace</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>

            <!-- ICON DISPLAY ON THE RIGHT SIDE -->
            <v-row v-if="localValue.field && editor">
                <v-col>
                    <v-layout class="d-flex flex-row">
                        <v-icon style="cursor: grab" class="drag-handle">mdi-drag-vertical</v-icon>
                        <h5>{{ localValue.field.field }}</h5>
                        <v-spacer></v-spacer>
                        <v-icon size="18" class="mr-1" :color="localValue.field.meta.hidden?'grey lighten-3':undefined">mdi-eye</v-icon>
                        <v-icon size="18" class="mr-1" :color="!localValue.field.meta.required?'grey lighten-3':undefined">mdi-asterisk</v-icon>
                        <v-icon size="18" class="mr-1" :color="localValue.field.meta.readonly?'grey lighten-3':undefined">mdi-pencil</v-icon>
                        <v-icon size="18" class="mr-1" :color="editor.formPermission.fields.indexOf(localValue.field.field) === -1?'grey lighten-3':undefined">mdi-form-select</v-icon>

                    </v-layout>
                </v-col>
            </v-row>

            <!-- EDIT VIEW WITH ALL FIELD DATA -->
            <v-container fluid v-if="edit" class="pa-0">

                <v-tabs 
                    v-model="currentTab" 
                    centered 
                    show-arrows
                    class="mt-2"
                >
                    <v-tab 
                        v-for="(tab, key) in fieldData" :key="key"
                        :href="'#tab-'+key"
                    >
                        {{ key }}
                    </v-tab>
                </v-tabs>
                <v-divider></v-divider>

                <v-tabs-items class="pa-1" v-model="currentTab" touchless>
                    <v-tab-item v-for="(tab, key) in fieldData" :key="key" :value="'tab-'+key">
                        
                        <!-- CUSTOM BOOLEAN BUTTON -->
                        <div v-if="key === 'Feld'">
                            <h5>In Anmeldung anzeigen</h5>
                            <interface-boolean
                                :value = "inRegistration()"
                                @input="toggleInRegistration()"
                            />
                        </div>

                        <!-- FIELDS -->

                        <fields
                            v-if="fieldData[key] !== undefined"
                            v-model="localValue"
                            :fields="fieldData[key].fields"
                            :ob="fieldData[key].ob"
                        >
                        </fields>
                    </v-tab-item>
                </v-tabs-items>

            </v-container>

            <!-- CHILD DISPLAY OF NESTED ITEMS -->
            <v-container
                class="pl-0 pr-0"
                v-if="localValue.children && localValue.children.length > 0"
                fluid
            >
                <draggable 
                    handle=".drag-handle" 
                    v-model="localValue.children"
                    :move="onMove" @end="onEnd"
                    v-bind="dragOptions"
                >
                <CFieldContainer 
                    v-for="(childItem, idx) in localValue.children" :key="idx" 
                    :editor="editor"   
                    v-model="localValue.children[idx]"
                />
                </draggable>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import draggable from 'vuedraggable';
import InterfaceBoolean from '../../interfaces/InterfaceBoolean.vue';

import Fields from '../Fields.vue';

export default {
    name:"CFieldContainer",
    components:{
        draggable,
        InterfaceBoolean,
        Fields
    },
    props:{
        value:Object,
        editor:Object,
    },
    data(){
        return{
            self:this,
            hover:false,
            loading:false,
            initialValue:JSON.stringify(this.value),
            localValue:this.value,
            edit:false,
            fieldData:{
                Schema:{
                    ob:"schema",
                    fields:[
                        {key:"name", label:"Name", interface:"String", disabled:true},
                        {key:"data_type", label:"Typ", interface:"String", disabled:true},
                        {key:"default_value", label:"Standartwert", disabled:false, interface:"Dynamic", options:{"options":[{key:"True", value:true}, {key:"False", value:false},{key:"Null", value:null}]}},
                        {key:"is_nullable", label:"Nullable", disabled:false, interface:"Boolean"},
                        {key:"is_unique", label:"Unique", disabled:false, interface:"Boolean"},
                    ]
                },
                Feld:{
                    ob:"meta",
                    fields:[
                        {key:"hidden", label:"Versteckt in Detailansicht", interface:"Boolean", disabled:false},
                        {key:"readonly", label:"Kann nicht bearbeitet werden", interface:"Boolean", disabled:false},
                        {key:"required", label:"Muss gesetzt werden", interface:"Boolean", disabled:false},
                        {key:"translations", label:"Übersetzung", interface:"Translation", disabled:false},
                        {key:"note", label:"Info", interface:"CodeEditor", disabled:false, options:{"auto-grow":true, "rows":4}},
                    ]
                },
                Eingabe:{
                    ob:"meta",
                    fields:[
                        {key:"interface", label:"Eingabemaske", interface:"Selection",options:{"options":[
                            {key:"Eingabe", value:"input"},
                            {key:"Schalter", value:"boolean"},
                            {key:"Datum", value:"datetime"},
                            {key:"Auswahl", value:"select-dropdown"}
                        ]}}
                    ]
                },
                Bedingungen:{
                    ob:"meta",
                    fields:[
                        {key:"conditions", label:"Bedingungen", interface:"CodeEditor",disabled:false, options:{"editJson":true}}
                    ]
                }
            },
            interfaceOptions:{
                "select-dropdown":[
                    // {key:"choices", label:"Choices", interface:[
                    //     {key:"text", label:"Text", interface:"String"},
                    //     {key:"value", label:"Wert", interface:"String"},
                    // ]},
                    {key:"allowOther", label:"Allow Other", interface:"Boolean"}
                ]
            },
            currentTab:undefined,
        }
    },
    watch:{
        value(val){
            this.localValue = val;
        }
    },
    computed:{
        dragOptions() {
            return {
                group: {
                name: 'g1'
                },
                scrollSensitivity: 100,
                forceFallback: true
            };
        },
        localValueChanged(){
            return JSON.stringify(this.localValue) !== this.initialValue;
        },
        valuePermissionsChanged(){
            return JSON.stringify(this.editor.formPermission) !== this.editor.formPermissionInitial;
        },
        valueChanged(){
            return this.localValueChanged || this.valuePermissionsChanged;
        }
    },
    methods:{
        toggleInRegistration(){
            const idx = this.editor.formPermission.fields.indexOf(this.localValue.field.field);
            if(idx !== -1){
                this.editor.formPermission.fields.splice(idx, 1);
            }else{
                this.editor.formPermission.fields.push(this.localValue.field.field);
            }
        },
        inRegistration(){
            return this.editor.formPermission.fields.indexOf(this.localValue.field.field) !== -1;
        },
        async saveChanges(){
            if(this.localValueChanged){
                let payload = this.localValue.field;
                if(payload !== undefined){
                    let url = `/fields/${this.editor.collection+this.editor.year}/${this.localValue.field.field}`
                    this.loading = true;
                    let result = await this.$network.requestPatch(url, payload);
                    this.loading = false;
                    if(result.status === 200){
                        this.initialValue = JSON.stringify(this.localValue);
                        this.editor.fieldDataInitial = JSON.stringify(this.editor.fieldData);
                        this.editor.$refs.popup.showNotification(`${this.localValue.field.field} wurde erfolgreich gespeichert.`);
                        this.edit = false;
                    }else{
                        this.editor.$refs.popup.showNotification(result.message, "error", "mdi-alert");
                    }
                }
            }
            if(this.valuePermissionsChanged){
                let url = `/permissions/${this.editor.formPermission.id}`;
                console.log(url)
                let result = await this.$network.requestPatch(url, {fields:this.editor.formPermission.fields});
                if(result.status === 200){
                    this.editor.formPermissionInitial = JSON.stringify(this.editor.formPermission);
                    this.editor.$refs.popup.showNotification(`${this.localValue.field.field} wurde erfolgreich gespeichert.`);
                    this.edit = false;
                }else{
                    this.editor.$refs.popup.showNotification(result.message, "error", "mdi-alert");
                }
            }
        },
        resetChanges(){
            this.$emit("input",JSON.parse(this.initialValue));
            this.editor.formPermission = JSON.parse(this.editor.formPermissionInitial);
        },
        getFieldValue(ob, key){
            if(this.localValue.field[ob] && this.localValue.field[ob][key] !== undefined){
                return this.localValue.field[ob][key];
            }
            return undefined
        },
        passesFilter(item){
            let passesFilter = false;
            if(item){
                if(this.editor.filter === null) this.editor.filter = ""
                if(this.editor.filter === "" || item.field?.field.includes(this.editor.filter.toLowerCase()) || !item.field){
                    passesFilter = true;
                }
                
                if(!passesFilter){
                    for(let child of item.children){
                        passesFilter = this.passesFilter(child);
                        if(passesFilter){
                            return passesFilter
                        }
                    }
                }
            }
            return passesFilter;
        },
        getInterfaceByType(schemaType, type){
            if(type === "Dynamic"){
                if(schemaType === "boolean"){
                    return "Selection"
                }else{
                    return "String"
                }
            }
            return type;
        },
        onMove(element){
            element.dragged.style.backgroundColor = "rgba(0,255,155, 0.15)";
        },
        onEnd(event){
            event.item.style.backgroundColor = "";
            this.$emit("input", this.localValue);
            this.$emit("orderChanged", this.localValue);
        },
        toggleEditItem(){
            this.edit = !this.edit;
        }
    }
}
</script>

<style scoped>

    .parent{
        background-color:rgba(0,255, 155, 0.05);
    }


</style>