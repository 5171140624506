<template>
    <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="buttonColor"
          :icon="iconOnly"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ buttonIcon }}</v-icon>
        </v-btn>
      </template>
      <slot name="content"></slot>
    </v-menu>
  </div>
</template>

<script>
export default {
    props:{
        buttonIcon:{type:String, default:"mdi-mouse"},
        buttonColor:{type:String, default:"primary"},
        iconOnly:{type:Boolean, default:true},
    }
}
</script>