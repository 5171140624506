<template>
    <popup-dialog-base 
        ref="EmailClient"
        :title="'Email' + templateTitle"
        :toolButtons="getButtons()"
        :windowed="windowed"
    >

        <template v-slot:title-append v-if="activeTemplate">
            <v-chip
                class="success mb-2"
                label
            >
                <strong v-if="userData.length > 1"><v-icon color="white">mdi-account</v-icon>{{userData.length}} Adressaten ausgewählt</strong>
                <strong v-else><v-icon color="white">mdi-account</v-icon>{{userData.length}} Empfänger</strong>
            </v-chip>
            
        </template>
        <template v-slot:content>
            <v-container ref="cont" full-width class="ma-0 content" v-if="activeTemplate === undefined">
                <!-- <h3>
                    <div class="search">
                        <v-icon>
                            mdi-form-select
                        </v-icon>
                        <span class="mt-3 mr-2">
                            Email-Vorlagen
                        </span>
                    </div>
                </h3> -->
                
                <div class="d-flex">

                    <v-text-field 
                        label="Suche"
                        prepend-inner-icon="mdi-magnify"
                        solo dense hide-details 
                        v-model="searchFilter"
                        clearable
                    />
                    <drop-down-menu
                        button-icon="mdi-sort"
                        button-color="grey"
                    >
                        <template v-slot:content>
                            <v-btn-toggle v-model="templateSort">
                                <v-btn v-for="(sort, index) in sort" :key="index">
                                    <v-icon>{{ sort.icon }}</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </template>
                    </drop-down-menu>
                </div>
                <v-divider class="mb-2 mt-2"/>
                <v-virtual-scroll
                    v-if="filteredEmailTemplates"
                    :items="filteredEmailTemplates"
                    :height="getScrollContainerHeight()"
                    item-height="40"
                    :class="{
                        'fixedHeight':windowed
                    }"
                >
                    <template v-slot:default="{index, item}">
                        <v-hover v-slot="{ hover }">

                            <v-container
                                full-width
                                v-if="item.id"
                                :class="{
                                    'blue lighten-4' : item === selectedTemplate,
                                    'grey lighten-4' : index%2 === 0 && item !== selectedTemplate && !hover,
                                    'grey lighten-3' : item !== selectedTemplate && hover, 
                                }"
                                class="ma-0 pa-0"
                                fill-height
                                align-center
                                @click="selectTemplate(item)"
                            >
                                <v-col class="pa-1 pl-2">
                                    <strong >{{item.titel}}</strong>
                                </v-col>
                                <v-col class="pa-1" cols="auto">
                                    <v-btn
                                        fab
                                        x-small
                                        class="error mr-2"
                                        v-if="item.access !== 'admin'"
                                        @click="confirmDeleteTemplate(item.id)"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        x-small
                                        class="primary"
                                         @click="loadTemplate(item)"
                                    >
                                        <v-icon>mdi-email</v-icon>
                                    </v-btn>
                                </v-col>
    
                            </v-container>
                            <v-container 
                                full-width
                                class="ma-0 pa-0"
                                :class="{'grey lighten-4' : index%2==0 }"                        
                                v-else
                            >
                                <v-row class="ma-0 pa-0">
                                    <v-col class="pl-2 pa-1">
                                        <v-text-field 
                                            hide-details 
                                            dense 
                                            v-model="item.titel"
                                            ref="newTemplate"
                                        />
                                    </v-col>
                                    <v-col class="pa-1" cols="auto">
                                        <v-btn fab x-small class="error mr-2"
                                            @click="cancelCreateNewTemplate()"
                                        >
                                            <v-icon>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                        <v-btn fab x-small class="success"
                                            @click="confirmNewTemplate()"
                                        >
                                            <v-icon>
                                                mdi-check
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-hover>

                    </template>

                </v-virtual-scroll>
            </v-container>
            <!-- Template loaded -->
            <v-container full-width class="ma-0" v-else>
                <v-row>
                    <v-col class="mb-0 pb-0">
                        <v-combobox
                            :items="app.emailAdresses"
                            @keydown="$event.target.blur()"
                            @keypress="$event.target.blur()"
                            @keyup="$event.target.blur()"
                            v-model="activeTemplate.absender"
                            solo
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field label="Betreff" hide-details class="mb-2" dense solo v-model="activeTemplate.email_betreff" />
                    </v-col>
                </v-row>
                <!-- <v-text-field hide-details class="mb-2" dense solo v-model="activeTemplate.access" /> -->

                <v-row>
                    <v-col class="pb-0 pt-0">
                        <interface-text-editor
                            v-model="activeTemplate.email_body"
                        />
                    </v-col>
                </v-row>

                
                <v-row class="mt-2" no-gutters>
                    <v-col>
                        <!-- <v-btn small class="primary" @click="generateFuehrungszeugnisAntragPdf(userData[0])">Erstelle Antrag</v-btn> -->
                        <v-checkbox dense hide-details label="Führungszeugnis-Antrag mitsenden?" v-model="activeTemplate.antrag_fuehrungszeugnis"/>
                    </v-col>
                </v-row>

                <!-- Attachments -->
                <v-row no-gutters>
                    <v-col class="pb-0 pt-1">
                        <interface-attachment
                            v-if="activeTemplateAttachments"
                            v-model="activeTemplateAttachments"
                            :allowSonderUrlaub="false"
                            :collection="'email_vorlagen'"
                            :postDeleteMethod="reloadAttachments"
                        />
                        <interface-file-upload 
                            :uploadFolder="'Email Anhänge'"
                            :collection="'email_vorlagen'"
                            :itemId="activeTemplate.id"
                            :postUploadMethod="reloadAttachments"
                        />
                    </v-col>
                </v-row>
            </v-container>


            <!-- delete dialog -->
            <confirm-dialog 
                ref="delDialog"
                icon="mdi-alert"
                icon-color="error--text"
                title="Vorlage wirklich löschen?"
                text="Vorlage wird unwiderruflich gelöscht."
                :max-width="400"
                :onConfirm="deleteTemplate"
            />

            <confirm-dialog
                ref="renameDialog"
                icon="mdi-text-cursor"
                title="Vorlage Umbenennen"
                :onConfirm="renameTemplate"
                :width="400"
                :max-width="400"
                :confirm-label="'Ändern'"
                :cancel-label="'Zurück'"
            >
                <template v-slot:content
                    v-if="selectedTemplate !== undefined"
                >
                    <v-text-field
                        ref="renameField"
                        solo dense hide-details
                        v-model="newTemplateName"
                        @focus="$event.target.select()"
                    >

                    </v-text-field>
                </template>
        
            </confirm-dialog>
        </template>
        <template v-slot:toolbar>
        </template>
    </popup-dialog-base>
</template>

<script>
import PopupDialogBase from '../PopupDialogBase.vue';
import ConfirmDialog from '../ConfirmDialog.vue';
import InterfaceFileUpload from '../interfaces/InterfaceFileUpload.vue';
import InterfaceAttachment from '../interfaces/InterfaceAttachment.vue';
import InterfaceTextEditor from '../interfaces/InterfaceTextEditor.vue';
import DropDownMenu from '../DropDownMenu.vue';
import jsPDF from 'jspdf';
import dayjs from 'dayjs';

export default {
    components:{
        PopupDialogBase,
        ConfirmDialog,
        InterfaceFileUpload,
        InterfaceAttachment,
        InterfaceTextEditor,
        DropDownMenu
    },
    props:{
        year:{type:String, default:undefined},
        windowed:{type:Boolean, default:true}
    },
    inject:[
        "app"
    ],
    data(){
        return {
            templateSort:0,
            sort:[
                {   
                    sortBy:"titel",
                    icon:"mdi-sort-alphabetical-ascending",
                    direction:"ascending"
                },
                {   
                    sortBy:"titel",
                    icon:"mdi-sort-alphabetical-descending",
                    direction:"descending"
                },
                {   
                    sortBy:"id",
                    icon:"mdi-sort-calendar-ascending",
                    direction:"ascending"
                },
                {   
                    sortBy:"id",
                    icon:"mdi-sort-calendar-descending",
                    direction:"descending"
                },
            ],
            newTemplateName:"",
            selectedTemplate:undefined,
            searchFilter:"",
            emailClient:this,
            sending:false,
            userData:[],
            camp:undefined,
            templateTitle:"",
            activeTemplate:undefined,
            activeTemplateAttachments:undefined,
            emailTemplates:[],
            templateId:undefined,
            buttons:{
                activeTemplate:[
                    {label:"Zurück", color:"error", icon:"", method:this.closeEmailClient, viewCondition:()=>{return true}},
                    {label:"Vorlage Speichern", color:"primary", icon:"mdi-save", method:this.saveTemplate, viewCondition:()=>{return this.activeTemplate && this.activeTemplate.id}},
                    {label:"Email senden", color:"primary", icon:"mdi-email-send", method:this.sendEmail, viewCondition:()=>{return this.userData.length > 0}, disabled:()=>{return this.sending}}
                ],
                templateSelection:[
                    {label:"Umbenennen", color:"primary", icon:"mdi-cursor-text", method:this.openRenameDialog, viewCondition:()=>{return this.selectedTemplate !== undefined}},
                    {label:"Neue Vorlage", color:"primary", icon:"mdi-email-plus", method:this.createNewTemplate, viewCondition:()=>{return true}},
                    {label:"Ohne Vorlage", color:"primary", icon:"mdi-email", method:this.loadTemplate, viewCondition:()=>{return true}}
                ]
            },
        }
    },
    mounted(){
        if(!this.windowed){
            this.$nextTick(()=>{
                this.show([], "mitarbeiter_"+this.year, this.year)
            })
        }
    },
    computed:{
        filteredEmailTemplates(){
            let templates = [];
            for(let template of this.emailTemplates){
                if(this.searchFilter === "" || template.titel.toLowerCase().includes(this.searchFilter.toLowerCase())){
                    templates.push(template)
                }
            }
            templates.sort((a, b)=>{
                return a[this.sortBy.sortBy] - b[this.sortBy.sortBy];
            })
            if(this.sortBy.direction === "descending"){
                templates.reverse();
            }
            return templates;
        },
        sortBy(){
            return this.sort[this.templateSort];
        }
    },
    methods:{
        openRenameDialog(){
            this.newTemplateName = this.selectedTemplate.titel;
            this.$refs.renameDialog.show();
            this.$nextTick(()=>{
                setTimeout(()=>{
                    this.$refs.renameField.focus();
                },200)
            })
        },
        cancelRenameDialog(){
            this.newTemplateName = "";
        },
        async renameTemplate(){
            const payload = {titel:this.newTemplateName};
            this.selectedTemplate.titel = this.newTemplateName;
            let url = "/items/email_vorlagen/"+this.selectedTemplate.id.toString();
            await this.$network.requestPatch(url, payload);
            this.$refs.EmailClient.showNotification("Vorlage wurde umbenannt.", "success", "mdi-check");
        },
        selectTemplate(item){
            this.selectedTemplate = item;
        },
        async requestFuehrungszeugnisPdf(){
            let url = "/files?filter[filename_download][_eq]=VorlageFuehrungszeugnis.png";
            let result = await this.$network.requestGet(url);
            if(result.status == 200){
                var pdfId = result.data.data[0].id;
                url = "/assets/"+pdfId;
                result = await this.$network.requestAssetAsBase64(url);
                // result = await this.$network.request(url);
                if (result.status === 200){
                    var uri = "data:image/png;base64,"+result.data;
                    return uri
                }
            }
            return undefined;
        },
        async generateFuehrungszeugnisAntragPdf(user, pdfUri){

            
            var doc = new jsPDF('p','mm',[297, 210]);
            doc.addImage(pdfUri, 'PNG', 0, 0, 210, 297);
            doc.setFontSize(12);
            doc.text(50, 148, user.vorname + " " +user.nachname);
            doc.text(50, 162, dayjs(user.geburtstag).format("DD.MM.YYYY"));
            doc.text(115, 162, user.geburtsort);
            doc.text(20, 254, "Espelkamp, "+dayjs().format("DD.MM.YYYY"));
            return doc.output('datauristring');
        },

        getScrollContainerHeight(){
            if(this.$refs.cont){
                return this.$refs.cont.clientHeight;
            }
            return 300;
        },
        getButtons(){
            let allButtons = []
            if(this.activeTemplate === undefined){
                allButtons = this.buttons.templateSelection;
            }else{
                allButtons = this.buttons.activeTemplate;
            }
            let buttons = []
            for(let button of allButtons){
                if(button.viewCondition()){
                    buttons.push(button);
                }
            }
            return buttons;
        },
        showNotification(message, type){
            this.snackbar = true;
            this.notificationMessage = message;
            this.notificationType = type;
        },
        cancelCreateNewTemplate(){
            this.emailTemplates.pop();
        },
        createNewTemplate(){
            this.emailTemplates.push(
                {
                    titel:undefined,
                    access:"editor"
                }
            )
            this.$nextTick(()=>{    
                this.$refs.newTemplate.focus()
            })
        },
        async saveTemplate(){
            let url = "/items/email_vorlagen/"+this.activeTemplate.id.toString();
            await this.$network.requestPatch(url, this.activeTemplate);
            this.$refs.EmailClient.showNotification("Vorlage gespeichert.", "success", "mdi-check");
        },
        async confirmNewTemplate(){
            let template = this.emailTemplates[this.emailTemplates.length -1];
            if (template.titel !== ""){
                let result = await this.$network.requestPost("/items/email_vorlagen",template);
                if(result.status === 200){
                    this.loadEmailTemplates();
                }
            }
        },
        async getuserData(peopleSelectedRows, collection){
            let result = await this.$network.requestGet("/items/camp?filter[jahr][_eq]="+this.year.toString()+"&fields=*,freizeit.*")
            if(result.status === 200){
                this.camp = result.data.data[0];
            }

            let ids = "";
            // for(let item of peopleSelectedRows){
            for(let i=0; i<peopleSelectedRows.length; i++){
                let item = peopleSelectedRows[i];
                ids += item.id.toString();
                if(i<peopleSelectedRows.length-1){
                    ids+=","
                }
            }
            result = await this.$network.requestGet("/items/"+collection+"?limit=-1&filter[id][_in]="+ids)
            if (result.status === 200){
                this.userData = result.data.data
            }
        },
        show(peopleSelectedRows, collection, year){
            this.year = year;
            this.getuserData(peopleSelectedRows, collection)
            this.$refs.EmailClient.show();
            this.loadEmailTemplates();
        },
        hide(){
            this.$refs.EmailClient.hide();
        },
        closeEmailClient(){
            this.activeTemplate = undefined;
            this.templateTitle = "";
            this.hide();
            // this.show([], "mitarbeiter_"+this.year, this.year);
            // this.loadEmailTemplates();
        },
        async loadTemplate(template){
            if(template){
                this.activeTemplate = template;
                this.templateTitle = " - " + template.titel
                this.loadAttachments(template);
            }else{
                this.templateTitle = ""
                this.activeTemplate = {
                    email_betreff:"",
                    email_body:"",
                }
            }
        },
        async loadEmailTemplates(){
            let url = "/items/email_vorlagen?fields=*,anhang.*";
            url += "&sort=titel";
            let result = await this.$network.requestGet(url);
            if(result.status === 200){
                this.emailTemplates = result.data.data;
            }
        },
        getMaxEditorHeight(){
            return (Math.floor(window.innerHeight*0.48)).toString();
        },
        confirmDeleteTemplate(id){
            this.$refs.delDialog.show()
            this.templateId = id;
        },
        async deleteTemplate(){
            let payload = {
                data: [this.templateId]
            }
            await this.$network.requestDelete("/items/email_vorlagen",payload)
            this.loadEmailTemplates();
            this.templateId = undefined;
            this.$refs.EmailClient.showNotification("Vorlage wurde gelöscht.", "", "mdi-trash");
        },
        emailContainsVariables(subject, html){
            if(subject.includes("{{") && subject.includes("}}")){
                return true;
            }
            if(html.includes("{{") && html.includes("}}")){
                return true;
            }
            return false;
        },
        async reloadAttachments(){
            let url = "/items/email_vorlagen/"+this.activeTemplate.id+"?fields=anhang.*"
            let result = await this.$network.requestGet(url);
            if (result.status === 200){
                this.activeTemplate.anhang = result.data.data.anhang;
                this.loadAttachments(this.activeTemplate);
            }
        },
        async loadAttachments(template){
            // load email attachments
            this.activeTemplateAttachments = []
            for(let attachment of template.anhang){
                if(attachment.directus_files_id){
                    let result = await this.$network.requestGet("/files/"+attachment.directus_files_id);
                    if (result.status === 200){
                        this.activeTemplateAttachments.push({
                            id:attachment.id, 
                            data:result.data.data
                        });
                    }
                }else{
                    this.activeTemplateAttachments.push({
                        id:attachment.id,
                        data:{id:undefined, filename_download:undefined}
                    })
                }
            }
        },
        async getEmbeddedAttachments(){
            var el = document.createElement('div');
            el.innerHTML = this.activeTemplate.email_body;
            var images = el.getElementsByTagName('img');
            var attachments = []
            for(let i=0; i<images.length; i++){
                let img = images[i];
                var path = img.src;
                var id = path.split("/")[path.split("/").length-1]
                let url = "/files/"+id;
                let result = await this.$network.requestGet(url);
                let filename = "";
                if(result.status === 200){
                    filename = result.data.data.filename_download;
                }
                attachments.push({
                    filename:filename,
                    path:path,
                    cid:filename
                })
                img.src = "cid:"+filename
            }
            return {html:el.innerHTML, attachments:attachments};
        },
        getAttachments(){
            let attachments = [];
            if (this.activeTemplate.anhang){
                for(let attachment of this.activeTemplateAttachments){
                    // let url = this.$network.app.restUrl + "/assets/" + attachment.data.id;
                    let url = "./uploads/"+attachment.data.filename_disk
                    attachments.push({
                        filename:attachment.data.filename_download,
                        path:url
                    });
                }
            }
            return attachments;
        },
        async sendEmail(){
            this.sending = true;
            if(this.emailContainsVariables(this.activeTemplate.email_betreff, this.activeTemplate.email_body)
            || this.activeTemplate.antrag_fuehrungszeugnis){
                let embeddedAttachments = await this.getEmbeddedAttachments();
                let attachments = this.getAttachments().concat(embeddedAttachments.attachments);

                var dataUri = await this.requestFuehrungszeugnisPdf();
                for(let user of this.userData){

                    if (user.email && user.email !== ""){
                        var userspecifiedAttachments = attachments
                        if (dataUri && this.activeTemplate.antrag_fuehrungszeugnis){
                            let finalUri = await this.generateFuehrungszeugnisAntragPdf(user, dataUri)
                            userspecifiedAttachments = attachments.concat([{filename:"Antrag Führungszeugnis.pdf",path:finalUri}])
                        }
                        let email = {
                            from:this.activeTemplate.absender,
                            to:user.email,
                            // bcc:user.email,
                            subject:this.activeTemplate.email_betreff,
                            // html:this.activeTemplate.email_body,
                            html:embeddedAttachments.html,
                            // attachments:attachments
                            attachments:userspecifiedAttachments
                        }
                        let payload = {
                            email:email,
                            data:user,
                            camp:this.camp
                        }

                        this.$network.requestPost("/sendmail", payload);
                    }
                }
            }else{
                let emails = [];
                let embeddedAttachments = await this.getEmbeddedAttachments();
                let attachments = this.getAttachments().concat(embeddedAttachments.attachments);
                for(let user of this.userData){
                    emails.push(user.email)
                }
                if(emails.length > 0){
                    let email = {
                        from:this.activeTemplate.absender,
                        to:emails.length === 1 ? emails[0] : undefined,
                        bcc:emails.length > 1 ? emails : undefined,
                        subject:this.activeTemplate.email_betreff,
                        html:embeddedAttachments.html,
                        attachments:attachments
                    }
                    let payload = {
                        email:email,
                    }
                    await this.$network.requestPost("/sendmail", payload);
                }
            }
            this.sending = false;
            this.closeEmailClient()
            this.$refs.EmailClient.showNotification("Emails wurden versendet.", "success");
        }
    }
}
</script>

<style>
    .file-drop{
        border:2px dashed !important;
    }

    .search{
        display:flex;
    }

    .content{
        height:100% !important;
    }

    .fixedHeight{
        height:95% !important;
    }
</style>