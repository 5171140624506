<template>
    <!-- <div>
        {{getItems()}}   
    </div> -->

    <v-select
        v-model="localValue"
        :items="getItems()"
        :label="label"
        dense
        solo
        small
        hide-details="auto"
        @input="changeValue"
        :disabled="disabled"
    >
    </v-select>
</template>

<script>
export default {
    props:{
        value:String,
        items:Array,
        label:String,
        disabled:Boolean
    },
    data(){
        return{
            localValue: this.value,
        }
    },
    watch:{
        value(value){
            this.localValue = value || [];
        }
    },
    methods:{
        getItems(){
            var items = [];
            for(let item of this.items){
                items.push(item.name);
            }
            return items;
        },
        changeValue(event){
            this.$emit("input", event);
        },
        remove (item) {
            this.localValue.splice(this.localValue.indexOf(item), 1);
            this.localValue = [...this.localValue];
            this.$emit("input", this.localValue);
        },
    }
    
}
</script>