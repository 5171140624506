<template>
    <div>
        <div
            v-for="(prop, key) of value" :key="key"
        >
            <div
                v-if="prop.type"
            >
                <h5>{{prop.key}}</h5>
                <v-text-field
                    v-if="!['positions','provinzen'].includes(prop.type)"
                    class="mb-0"
                    solo
                    dense
                    hide-details
                    :type="prop.type"
                    :label="prop.key"
                    v-model="prop.value"
                />
                <v-card
                    v-else-if="prop.type === 'positions'"
                >   
                    <v-card-title class="justify-end">
                        <v-btn icon x-small @click="toggleTextEditor('textEditorPositions', 'textEditorPositionsValue', prop)"><v-icon>mdi-code-json</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text
                        v-if="!textEditorPositions"
                    >
                        <v-row class="pl-6 pr-3">
                            <v-col
                                cols="4" class="no-wrap"
                            >
                                <h5>Position</h5>
                            </v-col>
                            <v-col
                                class="no-wrap"
                            >
                                <h5>Alias</h5>
                            </v-col>
                            <v-col
                                class="no-wrap">
                                <h5>Anzahl</h5>
                            </v-col>
                            <v-col
                                class="no-wrap">
                                <h5>Anzahl Frauen</h5>
                            </v-col>
                            <v-col
                                class="no-wrap">
                                <h5>Anzahl Männer</h5>
                            </v-col>
                            <div class="ml-0 mr-2" style="width:80px; height:20px;"></div>
                        </v-row>

                        <draggable v-model="prop.value"  handle=".drag-handle" @start="drag=true" @end="drag=false">
                            <v-card
                                v-for="(position, index) in prop.value" :key="index"
                            >
                                <v-card-text class="ma-0 pa-1 mb-1">
                                    <v-row>   
                                        <v-col
                                            cols="4"
                                            class="pr-1 pl-1"
                                        >

                                            <v-layout
                                                class="d-flex d-row"
                                            >
                                                <v-icon class="drag-handle">mdi-drag-vertical</v-icon>
                                                <v-text-field
                                                    solo
                                                    dense
                                                    hide-details
                                                    v-model="position.position"
                                                    label="Position"
                                                />
                                            </v-layout>
                                        </v-col>
                                        <v-col class="pr-1 pl-1">
                                            <v-text-field
                                                solo
                                                dense
                                                hide-details
                                                v-model="position.alias"
                                                label="Alias"
                                            />
                                        </v-col>
                                        <v-col class="pr-1 pl-1">
                                            <v-text-field
                                                dense
                                                solo
                                                hide-spin-buttons
                                                hide-details
                                                v-model="position.count"    
                                                type="number"
                                                min="0"
                                                clearable
                                            />
                                        </v-col>
                                        <v-col class="pr-1 pl-1">
                                            <v-text-field
                                                dense
                                                solo
                                                hide-spin-buttons
                                                hide-details
                                                v-model="position.count_female"
                                                type="number"
                                                min="0"
                                                clearable
                                            />
                                        </v-col>
                                        <v-col class="pr-1 pl-1">
                                            <v-text-field
                                                dense
                                                solo
                                                hide-spin-buttons
                                                hide-details
                                                v-model="position.count_male"
                                                type="number"
                                                min="0"
                                                clearable
                                            />
                                        </v-col>

                                        <div>
                                            <v-btn
                                                icon
                                                fab
                                                x-small
                                                @click="toggleFormVisibility(position)"
                                                class="mr-4 mt-4"
                                            >
                                                <v-icon :color="position.form?'success':''">{{position.form?'mdi-text-box-check':'mdi-text-box-outline'}}</v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                fab
                                                x-small
                                                @click="deletePosition(prop.value, index)"
                                                class="mr-4 mt-4"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </draggable>
                        <v-btn
                            block
                            small
                            class="primary"
                            @click="createNewPosition(prop)"
                        >
                            <v-icon>mdi-plus</v-icon>Neue Position
                        </v-btn>
                    </v-card-text>
                    <v-card-text
                        v-else
                    >
                        <interface-code-editor
                            v-model="textEditorPositionsValue"
                        />
                    </v-card-text>
                </v-card>
                <v-card
                    v-else-if="prop.type === 'provinzen'"
                >   
                    <v-card-title class="justify-end">
                        <v-btn icon x-small @click="toggleTextEditor('textEditorProvinzen', 'textEditorProvinzenValue', prop)"><v-icon>mdi-code-json</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text
                        v-if="!textEditorProvinzen"
                    >
                        <v-row>
                            <v-col>
                                <h5>Farbe</h5>
                            </v-col>
                            <v-col>
                                <h5>Farbwert</h5>
                            </v-col>
                            <v-col>
                                <h5>Anzahl Mädchen</h5>
                            </v-col>
                            <v-col>
                                <h5>Anzahl Jungen</h5>
                            </v-col>
                        </v-row>
                        <draggable v-model="prop.value"  handle=".drag-handle" @start="drag=true" @end="drag=false">
                            <v-card
                                v-for="(provinz, index) in prop.value" :key="index"
                            >   
                                <v-card-text class="ma-0 pa-1 mb-1">
                                    <v-row >
                                        <v-col class="pr-1 pl-1">
                                            <v-layout
                                                class="d-flex flex-row"
                                            >
                                                <v-icon class="drag-handle">mdi-drag-vertical</v-icon>
                                                <v-text-field
                                                    solo
                                                    dense
                                                    hide-details
                                                    v-model="provinz.key"
                                                />
                                            </v-layout>
                                        </v-col>
                                        <v-col class="pr-1 pl-1">
                                            
                                            <v-menu
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn 
                                                        elevation="1" block
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-icon :color="provinz.color">mdi-square</v-icon>{{provinz.color}}
                                                    </v-btn>
                                                </template>
                                                <v-card>
                                                    <v-card-text>
                                                        <v-color-picker v-model="provinz.color"></v-color-picker>
                                                    </v-card-text>
                                                </v-card>
                                            </v-menu>
                                        </v-col>
                                        <v-col class="pr-1 pl-1">
                                            <v-text-field 
                                                solo
                                                dense
                                                hide-details
                                                v-model="provinz.count.girls"
                                                type="number"
                                                min="0"
                                            />
                                        </v-col>
                                        <v-col class="pr-1 pl-1">
                                            <v-text-field
                                                solo
                                                dense
                                                hide-details
                                                v-model="provinz.count.boys"
                                                type="number"
                                                min="0"
                                            />
                                        </v-col>

                                        <v-btn
                                            icon
                                            fab
                                            x-small
                                            @click="deleteProvinz(prop.value, index)"
                                            class="mr-4 mt-4"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </draggable>
                        <v-btn
                            block
                            small
                            class="primary"
                            @click="createNewProvinz(prop)"
                        >
                            <v-icon>mdi-plus</v-icon>Neue Provinz
                        </v-btn>
                    </v-card-text>
                    
                    <v-card-text
                        v-else
                    >
                        <interface-code-editor
                            v-model="textEditorProvinzenValue"
                        />
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import InterfaceCodeEditor from '../interfaces/InterfaceCodeEditor.vue';
export default {
    components:{
        draggable,
        InterfaceCodeEditor
    },
    props:{
        campSettings:Object,
        value:{
            type:Object, 
            default:()=>{return{
                name:{
                    key:"Freizeit",
                    value:"E1",
                    type:undefined
                },
                datum_von:{
                    key:"Freizeit-Datum Von",
                    value:"",
                    type:"date"
                },
                datum_bis:{
                    key:"Freizeit-Datum Bis",
                    value:"",
                    type:"date"
                },
                alter_von:{
                    key:"Teilnehmer-Alter Von",
                    value:"",
                    type:"date"
                },
                alter_bis:{
                    key:"Teilnehmer-Alter Bis",
                    value:"",
                    type:"date"
                },
                anreise_mitarbeiter:{
                    key:"Mitarbeiter Anreisedatum",
                    value:"",
                    type:"date"
                },
                preis:{
                    key:"Preis (€)",
                    value:135,
                    type:"number"
                },
                preis_tasse:{
                    key:"Preis Tasse (€)",
                    value:5,
                    type:"number"
                },
                preis_tshirt:{
                    key:"Preis T-Shirt (€)",
                    value:10,
                    type:"number"
                },
                mitarbeiter_alter_von:{
                    key:"Mitarbeiter-Mindestalter",
                    value:18,
                    type:"number"
                }
            }
        }}
    },
    data(){
        return{
            textEditorPositions:false,
            textEditorPositionsValue:undefined,
            textEditorProvinzen:false,
            textEditorProvinzenValue:undefined,
        }
    },
    methods:{
        toggleTextEditor(editor, editorValue, prop){
            this[editor] = !this[editor]; 
            if(!this[editor] && this[editorValue]){
                prop.value = JSON.parse(this[editorValue]);
            }else{
                this[editorValue] = JSON.stringify(prop.value, undefined, 4);
            }

        },
        toggleFormVisibility(position){
            if(!position.form){
                position.form = true
            }else{
                position.form = !position.form
            }
            this.$forceUpdate();
            if(this.campSettings){
                this.campSettings.$forceUpdate();
            }
        },
        createNewPosition(prop){
            if(!prop.value){
                prop.value = []
            }
            prop.value.push(
                {position:"", count:null, count_female:null, count_male:null}
            )
        },
        deletePosition(value, index){
            value.splice(index, 1);
        },
        createNewProvinz(prop){
            if(!prop.value){
                prop.value = []
            }
            prop.value.push(
                {key:"", color:"#ffffff", count:{boys:8, girls:8}}
            )
        },
        deleteProvinz(value, index){
            value.splice(index, 1);
        }
    }
}
</script>

<style scoped>
    .no-wrap{
        /* overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; */
    }
</style>