<template>
    <v-row
        v-if="attachments"
    >  
        <v-col>
            <div
                v-for="(attachment, index) in attachments" :key="index"
            >
                <v-alert 
                    v-if="showSonderUrlaub"
                    icon="mdi-paperclip" 
                    border="left" 
                    class="pa-1 ma-0 mb-1"
                    text 
                    hide-details
                    dense 
                    color="primary" 
                    
                >
                    <v-list-item-subtitle>
                        <v-tooltip bottom>
                            <span>Sonderurlaub</span>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-if="allowSonderUrlaub"
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    fab
                                    x-small
                                    :disabled="emailPreview"
                                    @click="toggleSonderurlaub(attachment)"
                                >
                                    <v-icon v-if="attachment.sonderurlaub" color="secondary">
                                        mdi-file
                                    </v-icon>
                                    <v-icon v-else color="secondary">
                                        mdi-file-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-tooltip>
                        {{attachment.data.filename_download}} ({{(attachment.data.filesize*0.000001).toFixed(2)}} MB)
                        
                    </v-list-item-subtitle>
                    <template v-slot:close>
                        <v-btn small icon
                            v-if="!emailPreview"
                            @click="deleteAttachment(attachment)"
                        >
                            <v-icon color="primary">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </template>
                </v-alert>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props:{
        value:Array,
        collection:String,
        emailPreview:{type:Boolean, default:false},
        showSonderUrlaub:{type:Boolean, default:true},
        allowSonderUrlaub:{type:Boolean, default:false},
        postDeleteMethod:Function,
    },
    data(){
        return{
            attachments:this.value,
        }
    },
    watch:{
        value(value){
            this.attachments = value || [];
        }
    },
    methods:{
        changeValue(event){
            this.$emit("input", event);
        },
        async deleteAttachment(attachment){
            await this.$network.requestDelete("/items/"+this.collection+"_files/"+attachment.id)
            if(attachment.data.id){
                await this.$network.requestDelete("/files/"+attachment.data.id);
            }
            await this.postDeleteMethod()
        },
        async toggleSonderurlaub(attachment){
            let payload={sonderurlaub:!attachment.sonderurlaub}
            await this.$network.requestPatch("/items/"+this.collection+"_files/"+attachment.id, payload);
            attachment.sonderurlaub = !attachment.sonderurlaub;
            this.$emit("onUpdate")
        },
    }
}
</script>