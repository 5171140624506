import Vue from 'vue'
import VueRouter from 'vue-router'
import PageLogin from '@/pages/Login.vue'
import PageDashboard from '@/pages/Dashboard.vue'
import EmailConfirmation from '@/pages/EmailConfirmation.vue'
import Page404 from '@/components/404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: PageDashboard,
    props: true,
    meta:{requieresAuth:true},
  },
  {
    path: '/login',
    name: 'Login',
    component: PageLogin,
    props: true,
  },
  {
    path: '/email-bestaetigung',
    name: 'Email Bestätigung',
    component: EmailConfirmation,
    props: true,
  },
  {
    path: '/404',
    name:'404',
    component: Page404,
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
