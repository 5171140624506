import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import VueCookie from 'vue-cookie';
import {DirectusNetworking} from './network.js';
import {Utils} from './utils.js'
import PortalVue from 'portal-vue';
import VueQrcodeReader from "vue-qrcode-reader";
import VueQrcode from '@chenfengyuan/vue-qrcode';

import 'vuetify/dist/vuetify.min.css'
import './registerServiceWorker'

Vue.component(VueQrcode.name, VueQrcode);
Vue.use(VueQrcodeReader);
Vue.use(PortalVue);
Vue.use(VueCookie);
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.prototype.$network = new DirectusNetworking()
Vue.prototype.$utils = new Utils()

function createApp(){
  const app = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  })
  app.$mount('#app');
}

createApp()