<template>

    <div class="code-editor-container"
        :style="{'min-height':lines*20}"
    >
        <div class="code-editor-lines">
            <div 
                v-for="line in (lines+1)" :key="line"
                class="code-editor-line pl-1 pr-1"
            >
                {{ line }}
            </div>
        </div>
        <textarea
            ref="editor"
            class="code-editor-content"
            hide-details
            :auto-grow="autoGrow"
            :rows="rows"
            v-model="localValue"
            spellcheck="false"
            @input="onInput"
        />
        <v-alert
            v-if="editJson && jsonIsInvalid"
            style="position:absolute; right:0;"
            ref="error"
            class="pa-1 ma-2"
            color="warning"
            dark
            top
            :timeout="-1"
            absolute
        >
            <v-row no-gutters>
                <v-col cols="auto" class="mt-1 mr-2">
                    <v-icon>mdi-alert-decagram</v-icon>
                </v-col>
                <v-col class="mt-2 d-flex flex-row">
                    Invalides Datenobjekt.
                    <v-spacer/>
                    <v-btn icon x-small @click="showError = !showError"><v-icon>{{showError?"mdi-chevron-down":"mdi-chevron-left"}}</v-icon></v-btn>
                </v-col>
            </v-row>
            <v-row
                no-gutters
            >
                <v-col
                        v-if="showError"
                    >
                        <v-divider/>
                        {{ validateJson().error }}
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>
export default {
    name:"CodeEditor",
    props:{
        indentation:{default:"\t", type:String},
        editJson:{default:false, type:Boolean},
        value:{default:""},
        rows:{default:3, type:Number},
        autoGrow:{default:true, type:Boolean}
    },
    created(){
        if(this.editJson){
            this.localValue = JSON.stringify(this.value, null, this.indentation);
        }else{
            this.localValue = this.value;
        }
    },
    mounted(){
        const textarea = this.$refs.editor;
        textarea.addEventListener('keydown',(e)=>{
            if(e.key === "Tab"){
                e.preventDefault();
                let start = textarea.selectionStart;
                let end = textarea.selectionEnd;
                textarea.value = textarea.value.substring(0, start) + this.indentation + textarea.value.substring(end);
                textarea.selectionEnd = start + 1;
            }else if(e.key === "Enter"){
                e;
            }
        })
        this.calcLines();
    },
    data(){
        return{
            localValue:"",
            lines:0,
            showError:false,
        }
    },
    watch:{
        value(val){
            if(this.editJson){
                this.localValue = JSON.stringify(val, null, this.indentation);
            }else{
                this.localValue = val;
            }
            this.$nextTick(()=>{
                this.calcLines();
            })
        }
    },

    computed:{
        jsonIsInvalid(){
            try{
                JSON.parse(this.localValue);
                return false;
            }catch(e){
                return true;
            }
        }
    },

    methods:{
        validateJson(){
            try{
                JSON.parse(this.localValue);
                return {valid:true};
            }catch(e){
                return {valid:false, error:e};
            }
        },
        onInput(){

            if(this.editJson){
                try{
                    const json = JSON.parse(this.localValue);
                    if(JSON.stringify(json) !== JSON.stringify(this.value)){
                        const cursorPos = this.$refs.editor.selectionStart;
                        const originalText = this.localValue;
                        const newText = JSON.stringify(json, null, this.indentation);
                        const textDiff = newText.length - originalText.length;

                        this.$emit("input", JSON.parse(this.localValue));

                        this.$nextTick(()=>{
                            this.$refs.editor.setSelectionRange(cursorPos+textDiff, cursorPos+textDiff);
                        })
                    }
                }catch(e){
                    e;
                }

            }else{
                this.$emit("input", this.localValue);
            }
            this.calcLines();
        },
        calcLines(){
            const textarea = this.$refs.editor
            if(textarea && textarea.value){
                this.lines =  textarea.value.split("\n").length;
            }else{
                this.lines = 0;
            }
        },
    }
}
</script>

<style scoped>

    .code-editor-container{
        display:flex;
        border:1px solid silver;
    }
    .code-editor-lines{
        border-right:1px solid silver;
        background-color: #f9f9f9;
    }

    .code-editor-content:focus{
        border:none;
        outline:none;
    }
    .code-editor-content{
        border:none;
        width:100%;
        line-height:20px;
        font-size: 0.9em; 
        font-family:'Courier New', Courier, monospace;
        white-space: pre;
        overflow-y:hidden;
        padding-left:4px;
        resize:none;
    }
    .code-editor-line{
        font-family:'Courier New', Courier, monospace;
        height:20px;
        font-size:12px;
        font-weight: 800;
        text-align: right;
    }
</style>