<template>
    <div v-if="localValue.field[ob]">
        <div
            v-for="(prop, index) in fields" :key="index"
        >
            <h5>{{ prop.label }}</h5>
            <component
                :is="interfaces[prop.interface]" 
                v-model="localValue.field[ob][prop.key]"
                :disabled="prop.disabled"
                v-bind="prop.options"
            />
        </div>
    </div>
</template>

<script>
import InterfaceBoolean from '../interfaces/InterfaceBoolean.vue';
import InterfaceSelection from '../interfaces/InterfaceSelection.vue';
import InterfaceString from '../interfaces/InterfaceString.vue';
import InterfaceTranslation from '../interfaces/InterfaceTranslation.vue';
import InterfaceTextarea from '../interfaces/InterfaceTextarea.vue';
import InterfaceTextEditor from '../interfaces/InterfaceTextEditor.vue';
import InterfaceCodeEditor from '../interfaces/InterfaceCodeEditor.vue';
import InterfaceFilterCombobox from '../interfaces/InterfaceFilterCombobox.vue';

export default {
    name:"Fields",
    props:{
        value:Object,
        fields:Array,
        ob:String
    },
    data(){
        return{
            localValue:this.value,
            interfaces:{
                "String":InterfaceString,
                "TextArea":InterfaceTextarea,
                "TextEditor":InterfaceTextEditor,
                "CodeEditor":InterfaceCodeEditor,
                "Boolean":InterfaceBoolean,
                "Selection":InterfaceSelection,
                "Translation":InterfaceTranslation,
                "Filter":InterfaceFilterCombobox,
            },
        }
    },
    watch:{
        value(val){
            this.localValue = val;
        }
    },
    methods:{
        getInterfaceByType(schemaType, type){
            if(type === "Dynamic"){
                if(schemaType === "boolean"){
                    return "Selection"
                }else{
                    return "String"
                }
            }
            return type;
        },
    }
}
</script>