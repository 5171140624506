<template>
    <v-switch
        hide-details
        v-model="localValue"
        @change="changeValue"
        class="mt-0 mb-2"
    />
</template>

<script>
export default {
    props:{
        value:Boolean
    },
    data(){
        return{
            localValue: this.value,
        }
    },
    watch:{
        value(value){
            this.localValue = value;
        }
    },
    methods:{
        changeValue(value){
            this.$emit("input", value);
        }
    }
    
}
</script>