<template>
    <v-snackbar 
        :color="type" v-model="snackbar" :timeout="timeout"
        :top="position==='top'?true:false"
        :bottom="position==='bottom'?true:false"
    >
        <v-icon>{{ icon }}</v-icon>
        {{message}}
    </v-snackbar>
</template>

<script>
export default {
    data(){
        return{
            snackbar:false,
            timeout:2000,
            message:"",
            type:"success",
            icon:"",
            position:""
        }
    },
    methods:{
        showNotification(message, timeout, type, icon, position){
            this.snackbar = true;
            this.message = message;
            this.timeout = timeout;
            this.type = type;
            this.icon = icon;
            this.position = position;
        }
    }

}
</script>