<template>
    <v-container
        :class="$vuetify.breakpoint.xsOnly?'pa-0':''"
    >
        <v-row v-if="data === undefined" justify="center">
            <div style="width:100%; height:60px;"/>
            <v-card
                class="ma-4 mt-8"
                width="400"
            >
                <v-card-title >
                    
                    <v-icon>mdi-tent</v-icon>
                    Meine Freizeit-Daten
                </v-card-title>    
                <v-card-text class="text-center">
                    <v-text-field 
                        autocomplete="off"
                        @keydown.enter="login" 
                        v-model="email" 
                        label="Email" 
                        type="text" 
                        solo
                        :rules="emailRule()"
                    />
                    <v-text-field 
                        style="display:none;"
                        autocomplete="off"
                        v-model="email" 
                        label="Email" 
                        type="text" 
                        solo
                    />
                    <v-text-field 
                        style="display:none;"
                        autocomplete="off"
                        v-model="password" 
                        label="Passwort" 
                        type="password" 
                        solo
                    />
                    <v-text-field
                        autocomplete="off"
                        @keydown.enter="login" 
                        v-model="password" 
                        label="Passwort" 
                        
                        :rules="passwordRule()"
                        :type="showPassword ? 'text' : 'password'"
                        solo
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                    />
                    

                    <v-btn
                        class="primary"
                        @click="login()"
                        :loading="loading"
                        :disabled="loading"
                    >   
                        Freizeit-Daten laden
                    </v-btn>

                </v-card-text>
            </v-card>
        </v-row>

        <v-row v-else>
            <v-col cols="12" class="d-flex">
                <report-camp-data
                    ref="ReportCampData"
                    :email="email"
                    :password="password"
                    :data="data"
                    v-on:updateData="updateParentData"
                />
            </v-col>
            
            <v-col cols="12">
                <report-employee-overview-deprecated
                    v-if="year === 2022"
                    :data="data"
                    :editable="false"
                />
                <report-employee-overview
                    v-else
                    :data="data"
                    :editable="false"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ReportCampData from "./DashboardPages/Reports/ReportCampData.vue"
import ReportEmployeeOverviewDeprecated from "./DashboardPages/Reports/ReportEmployeeOverviewDeprecated.vue"
import ReportEmployeeOverview from "./DashboardPages/Reports/ReportEmployeeOverview.vue"

export default {
    components:{
        ReportCampData,
        ReportEmployeeOverview,
        ReportEmployeeOverviewDeprecated
    },
    async created(){
        this.loadSessionCookie();
    },
    provide(){
        return{
            userData:this,
            dashboard:this
        }
    },
    inject:[
        "app"
    ],
    props:{
        year:{type:String},
        backend:{type:Boolean, default:false}
    },
    data(){
        return{
            loading:false,
            sessionObject:{},
            showPassword:false,
            email:"",
            password:"",
            authPassed:false,
            data:undefined,
        }
    },
    computed:{
        campYear(){
            if(this.year){
                return this.year;
            }
            return this.$router.currentRoute.path.replace(/[^0-9]/g,'');
        }
    },
    methods:{
        emailRule(){
            var rules = []
            rules.push(v => /.+@.+\..+/.test(v) || "Bitte eine korrekte Email-Adresse eintragen")
            return rules
        },
        passwordRule(){
            var rules = []
            rules.push(v => v.length > 0 || "Bitte eine korrekte Email-Adresse eintragen")
            return rules
        },
        updateParentData(data){
            this.data = data
            this.$forceUpdate();
        },
        setupSessionCookie(){
            if(this.$cookie.get("camp_data")){
                this.sessionObject = JSON.parse(this.$cookie.get("camp_data"));
            }
            this.sessionObject[this.campYear] = {
                token:this.password,
                email:this.email
            }
            this.$cookie.set("camp_data",JSON.stringify(this.sessionObject),{expires:'7D'});
        },
        async loadSessionCookie(silently){
            if(this.$cookie.get("camp_data")){
                this.sessionObject = JSON.parse(this.$cookie.get("camp_data"));
            }
            
            let session = this.sessionObject[this.campYear];
            this.loaded=true
            if(session){
                this.email = session.email;
                this.password = session.token;
                await this.login(silently);
            }
        },

        async login(silently){
            if(this.email.length === 0){
                this.app.showNotification("Du musst deine Email-Adresse eingeben.",undefined,"error")
                return
            }
            if(this.password.length === 0){
                this.app.showNotification("Du musst ein Passwort eingeben.",undefined,"error")
                return
            }
            this.loading = true
            let payload={
                email:this.email,
                password:this.password,
                year:parseInt(this.campYear),
            }
            let result = await this.$network.requestPost("/userdata",payload);
            if(result.status === 200){
                this.authPassed = true;
                this.data = result.data;
                if(!silently){
                    this.app.showNotification("Anmeldung erfolgreich.")
                }
                this.setupSessionCookie();
                this.$forceUpdate();
            }else if(result.status === 400){
                this.app.showNotification(result.message.message,4000,"error")
            }
            this.loading = false
        }
    }
}
</script>