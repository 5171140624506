<template>
    <v-card class="pa-0">
        <v-card-text class="pa-1">
            <draggable
                handle=".drag-handle" 
                v-model="localValue" @end="onDragEnd"
            >
                <v-row v-for="(item, index) in localValue" :key="index" no-gutters class="mb-1">
                    <v-col cols="2">
                        <v-layout class="d-flex flex-row">
                            <v-icon 
                                style="cursor: grab"
                                class="drag-handle"
                            >
                                mdi-drag-vertical
                            </v-icon>
                            <interface-selection v-model="item['language']" :options="countries"></interface-selection>
                        </v-layout>
                    </v-col>
                    <v-col>
                        <interface-string v-model="item['translation']"></interface-string>
                    </v-col>
                    <v-btn icon class="mt-1" @click="deleteTranslation(index)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-row>
            </draggable>
            <v-row no-gutters class="">
                <v-col @click="addNewTranslation" class="text-right">
                    <v-btn block small class="primary">
                        <v-icon>mdi-plus</v-icon> Neue Übersetzung
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import InterfaceSelection from './InterfaceSelection.vue';
import InterfaceString from './InterfaceString.vue';
import draggable from 'vuedraggable';
export default {
    components:{
        InterfaceSelection,
        InterfaceString,
        draggable
    },
    props:{
        value:Array
    },
    data(){
        return{
            localValue:this.value,
            countries:[
                "de-DE",
                "en-US"
            ]
        }
    },
    watch:{
        value(val){
            this.localValue = val;
        }
    },
    methods:{
        onDragEnd(){
            this.$emit("input", this.localValue)
        },
        addNewTranslation(){
            if(this.localValue === null || this.localValue === undefined){
                this.localValue = []
            }
            this.localValue.push({language:"de-DE", translation:""});
            this.$emit("input", this.localValue);
        },
        deleteTranslation(index){
            this.localValue.splice(index, 1);
            this.$emit("input", this.localValue);
        },
    }
}
</script>