<template>
    <div
        v-if="(app.menu && app.userData)"
        style="z-index:3;"
    >
        <portal to="topbar-menu-button">
            <v-btn
                dark
                fab
                small
                elevation="0"
                @click="toggleMenu()"
            >
                <v-icon>
                    mdi-menu
                </v-icon>
            </v-btn>
        </portal>
      
        <v-navigation-drawer
            v-model="drawer"
            app
            style="position:fixed; top:60px; z-index:2;"
            :absolute="$vuetify.breakpoint.xsOnly"
            :permanent="!$vuetify.breakpoint.xsOnly"
            clipped
            touchless
        >

            <!-- Main Menu -->
            <v-navigation-drawer
                elevation="0"
                hide-overlay
                :permanent="!$vuetify.breakpoint.xsOnly"
                v-model="drawer"
                absolute
                color="primary-dark"
                mini-variant
                ref="mini-drawer"
                touchless
                mobile-breakpoint
            >
                <v-list class="pt-0">
                    <!-- v-for="(item, index) in app.menu" :key="index" -->
                    <v-list-item
                        v-for="(item, index) in getFilteredMainMenu(app.menu)" :key="index"
                        link
                        :class="app.activeMenu && item.title === app.activeMenu.title ? 'grey lighten-5' : ''"
                        @click="pressMenuItem(item)"
                    >
                        <v-hover v-slot="{hover}">
                            <div>
                                <v-icon 
                                    v-if="app.activeMenu"
                                    :light="item.title == app.activeMenu.title ? true : false"
                                    :dark="item.title == app.activeMenu.title ? false : true"
                                    :class="[
                                        (hover && item.title != app.activeMenu.title) ? 'secondary--text text--lighten-2' : '',
                                        item.title == app.activeMenu.title ? 'success--text' : 'secondary--text'
                                    ]"
                                >
                                    {{item.icon}}
                                </v-icon>
                            </div>
                        </v-hover>
                    </v-list-item>

                </v-list>
            </v-navigation-drawer>

            <v-row>
                <v-col class="ml-14">

                    <v-card-title>
                        <v-row class="">
                            <v-col v-if="app.activeMenu" class="pb-0">
                                <v-icon class="mr-2">{{app.activeMenu.icon}}</v-icon>
                                {{app.activeMenu.title}}
                                
                                <portal-target style="float:right;" name="sub-menu-title">
                                </portal-target>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-divider class="mb-3"></v-divider>

                    <v-col 
                        v-if="app.activeMenu && app.activeMenu.title === 'Freizeit'" 
                        cols="12"
                        class="pt-0"
                    >
                        <v-row no-gutters>
                            <v-col>
                                <v-select 
                                    single-line 
                                    dense 
                                    hide-details
                                    v-model="dashboard.activeCampYear" 
                                    return-object :items="dashboard.campYears" 
                                    item-text="Year" 
                                    solo 
                                    @change="dashboard.selectCamp(dashboard.activeCampYear)"
                                />
                            </v-col>
                            <v-col cols="2"
                                v-if="app.userData.role && app.userData.role.admin_access"
                            >
                                <new-camp-dialog
                                    :dashboard="dashboard"
                                ></new-camp-dialog>
                            </v-col>
                        </v-row>
                    </v-col>
                    <div
                        v-if="app.activeMenu"
                    >

                        <!-- SubMenu -->
                        <portal-target name="sub-menu-above">
                        </portal-target>
                        <draggable v-model="app.activeMenu.sub_menu_items"  handle=".drag-handle" @end="sortMenuItems(app.activeMenu)">
                        
                            <!-- v-for="item in app.activeMenu.sub_menu_items" :key="item.title" -->
                            <v-list
                                v-for=" (item, index) in getFilteredSubMenu() " :key="index"
                                dense
                                class="pa-0"
                            >
                                <div
                                    v-if="item.status==='published' && item.type === 'SEPARATOR'"
                                >
                                    <v-divider class="ma-0 pa-0" />
                                </div>
                                <v-list-item
                                    v-else-if="item.status==='published' && (!item.type || ['COMPONENT', 'FUNCTION_CALL'].includes(item.type))"
                                    link
                                    @click="pressMenuItem(item)"
                                    :class="app.activeSubMenu && item.title === app.activeSubMenu.title? 'grey lighten-4' : ''"
                                >
                                    <v-list-item-icon class="mr-2">
                                    
                                        <v-icon class="drag-handle" v-if="(item.type === 'COMPONENT' && editMenu)" style="position:absolute;left:-4px;">mdi-drag-vertical</v-icon>
                                        <v-icon
                                            :class="app.activeSubMenu && item.title === app.activeSubMenu.title ? 'success--text' : ''"
                                        >
                                            {{ item.icon }}
                                        </v-icon>
                                    </v-list-item-icon>
    
                                    <v-list-item-content>
                                        <v-list-item-title
                                            :class="app.activeSubMenu && item.title === app.activeSubMenu.title ? 'success--text' : ''"
                                        >
                                            {{ item.title }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action
                                        class="ma-0 pa-0"
                                        v-if="editMenu"
                                    >
                                        <v-btn fab icon x-small
                                            @click="itemToBeDeleted=item; $refs.deleteDialog.show()"
                                        >
                                            <v-icon>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </draggable>
                        

                        <v-list-item
                            v-if="editMenu"
                        >
                            <v-spacer></v-spacer>   
                            <v-btn block class="primary" small
                                @click="$refs.newMenuItem.show();"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-list-item>
                    </div>
                    <v-col v-else>

                        <v-progress-linear  indeterminate></v-progress-linear>
                    </v-col>
                </v-col>
            </v-row>
        </v-navigation-drawer>

        <popup-dialog-base
            title="Neue Kategorie erstellen"
            ref="newMenuItem"
            :tool-buttons='newMenuItemButtons'
        >
            <template v-slot:content>
                <v-card-text>
                    <v-row>
                        <v-col>
                            Kategorie-Name
                            <interface-string v-model="newMenuItem.title"></interface-string>
                            Icon
                            <v-combobox
                                solo
                                v-model="newMenuItem.icon"
                                hide-details
                                dense
                                :items="icons"
                            >
                                <template slot="selection" slot-scope="data">
                                    <v-icon>{{data.item}}</v-icon>  
                                </template>
                                <template slot="item" slot-scope="data">
                                    <v-icon>{{data.item}}</v-icon>
                                </template>
                            </v-combobox>
                            <!-- <v-text-field solo>Test</v-text-field> -->
                        </v-col>
                    </v-row>
                </v-card-text>

            </template>
        </popup-dialog-base>

        <confirm-dialog
            ref="deleteDialog"
            icon="mdi-alert"
            icon-color="error--text"
            title="Kategorie Löschen?"
            text="Kategorie wird entfert. Die Artikel bleiben weiterhin erhalten."
            :onConfirm="deleteMenuItem"
        />
    </div>
</template>

<script>
import NewCampDialog from './CampWizard/NewCampDialog.vue';
import PopupDialogBase from './PopupDialogBase.vue';
import draggable from 'vuedraggable';
import InterfaceString from './interfaces/InterfaceString.vue';
import Dashboard from '../pages/Dashboard.vue';
import ConfirmDialog from './ConfirmDialog.vue';

export default {
    name:"NavBar",
    components:{
        NewCampDialog,
        draggable,
        PopupDialogBase,
        InterfaceString,
        ConfirmDialog
    },
    props:{
      dashboard:Object,
    },
    inject:[
      "app",
      "dashboard"
    ],
    mounted(){
        this.makeMenuItemActive();
    },
    data(){
      return{
        itemToBeDeleted:undefined,
        icons:[
            'mdi-soccer',
            'mdi-pencil',
            'mdi-wrench',
            'mdi-tag',
            'mdi-printer',
            'mdi-content-cut',
            'mdi-silverware-fork-knife',
            'mdi-microphone-variant',
            'mdi-home-outline',
            'mdi-hospital-box',
            'mdi-baguette',
        ],
        newMenuItemButtons:[{
            label:"Erstellen", 
            color:"primary", 
            icon:"mdi-plus", 
            method:()=>{this.createNewMenuItem()}, 
            viewCondition:()=>{return true}, 
            disabled:()=>{return this.newMenuItem.title === ""}
        }],
        newMenuItem:{
            status:"published",
            title:"",
            icon:"",
            type:"COMPONENT",
            component:"Inventory",
            url:"",
            sort:0,
            properties:[{key:"filteredTags", value:""}]
        },
        navbar:this,
        editMenu:false,
        drawer:false,
        subMenuLoading:false,
      }
    },
    provide(){
        return{
            navbar:this.navbar
        }
    },
    watch:{
        $route (to, from){
            this.$nextTick(()=>{
                if(to.path !== from.path){
                    this.makeMenuItemActive();
                }
            })
        }
    },
    methods:{
        getFilteredMainMenu(){
            if(this.app.userData.role.admin_access){
                return this.app.menu;
            }

            let menu = [];
            if(this.app && this.app.menu && this.app.userData.role){
                for(let mainMenuItem of this.app.menu){
                    for(let id of this.app.userData.role.main_menu_access){
                        if(id.main_menu_id === mainMenuItem.id){
                            menu.push(mainMenuItem);
                        }
                    }
                }
            }
            return menu;
        },
        getFilteredSubMenu(){
            if(this.app.userData.role.admin_access){
                return this.app.activeMenu.sub_menu_items;
            }

            let menu = [];
            if(this.app && this.app.activeMenu.sub_menu_items){
                for(let menuItem of this.app.activeMenu.sub_menu_items){
                    let menuName = this.app.activeMenu.sub_menu
                    for(let id of this.app.userData.role[menuName+"_access"]){
                        if(id[menuName+"_id"] === menuItem.id){
                            menu.push(menuItem);
                        }
                    }
                }
            }
            return menu;
        },
        deleteMenuItem(){
            let item = this.itemToBeDeleted;
            let url = "/items/"+this.app.activeMenu.sub_menu;
            this.$network.requestDelete(url, {data:[item.id]})
            let index = this.app.activeMenu.sub_menu_items.indexOf(item);
            if(index !== -1){
                this.app.activeMenu.sub_menu_items.splice(index, 1)
            }
        },
        async createNewMenuItem(){
            let url = "/items/"+this.app.activeMenu.sub_menu;

            this.newMenuItem.properties[0].value = this.newMenuItem.title + ",";
            this.newMenuItem.url = "/"+this.newMenuItem.title.toLowerCase().replaceAll(" ","-");
            this.newMenuItem.sort = this.app.activeMenu.sub_menu_items.length;
            let result = await this.$network.requestPost(url, this.newMenuItem)
            if(result.status === 200){
                this.$refs.newMenuItem.hide();
                let route = this.app.activeMenu.url+result.data.data.url;
                result.data.data.url = route;
                this.app.activeMenu.sub_menu_items.push(result.data.data);
                this.$router.addRoute({name:this.newMenuItem.title, path:route, component:Dashboard})
                this.newMenuItem.title = "";
                this.newMenuItem.icon = "";
            }
        },
        async sortMenuItems(menu){
            for(let i=0; i<menu.sub_menu_items.length; i++){
                let menuItem = menu.sub_menu_items[i];
                let payload = {
                    sort:i
                }
                await this.$network.requestPatch("/items/"+menu.sub_menu+"/"+menuItem.id, payload)
            }
        },
        toggleMenu(){
            this.drawer = !this.drawer;
        },
        getWidth(){
            if(this.drawer){
                return 250;
            }
            return 56;
        },
        pressMenuItem(item){
            if(!item.type || item.type === "COMPONENT"){
                if(this.$router.currentRoute.path !== item.url){
                    if(item.sub_menu_items && item.sub_menu_items[0]){
                        for(let subMenuItem of item.sub_menu_items){
                            if(!subMenuItem.type || subMenuItem.type === "COMPONENT"){
                                this.$router.push({path:subMenuItem.url});
                                break;    
                            }
                        }
                    }else{
                        this.$router.push({path:item.url});
                    }
                    
                    let route = {path:this.$router.currentRoute.path, query:this.$router.currentRoute.query}
                    localStorage.setItem("routerPath", JSON.stringify(route));
                    
                }
            }else if(item.type === "FUNCTION_CALL"){
                item
            }
        },
        makeMenuItemActive(){
            let url = this.$router.currentRoute.path;
            for(let mainMenuItem of this.app.menu){
                if(url.indexOf(mainMenuItem.url) !== -1){
                    this.app.activeMenu = mainMenuItem;
                }
            }
            if(this.app.activeMenu){
                let subMenuFound = false;
                for(let subMenuItem of this.app.activeMenu.sub_menu_items){
                    if(url.indexOf(subMenuItem.url) !== -1){
                        this.app.activeSubMenu = subMenuItem;
                        subMenuFound = true;
                    }
                }
                if(!subMenuFound){
                    this.app.activeSubMenu = this.app.activeMenu.sub_menu_items[0];
                }
            }
        }
    }
}
</script>

<style>
    .menu{
        background-color: rgb(43, 49, 65);
    }
</style>