<template>
    <v-alert border="left" text color="secondary darken-2">
        <h3>Freizeit-Kosten</h3>
        <v-simple-table class="text--secondary" >
            <thead>
                <tr>
                    <th><strong>Posten</strong></th>
                    <th class="text-right"><strong>Stk.</strong></th>
                    <th class="text-right"><strong>Preis</strong></th   >
                </tr>
            </thead>
            <tbody>
                
                <tr 
                    v-for="(item, index) in freizeitKosten" :key="index"
                    :style="(item.index+0)%2==0 ? 'background-color:#f4f6f6;' : ''"
                >   
                    <td>
                        <b>{{item.posten}}</b>
                    </td>
                    <td class="text-right">
                        <span v-if="item.stk !== -1">
                            {{item.stk}}
                        </span>
                    </td>
                    <td class="text-right">
                        <span 
                            v-if="item.preis !== -1"
                        >   
                            <strong
                                v-if="item.posten === 'Gesamt'"
                            >
                                {{formatMoney(item.preis)}}
                            </strong>
                            <span
                                v-else
                            >
                                {{formatMoney(item.preis)}}
                            </span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-alert>
    
</template>

<script>
export default {
    props:{
        tassePreis:{type:Number, default:7},
        tshirtPreis:{type:Number, default:10}
    },
    data(){
        return{
            year:undefined,
            freizeitKosten:{
                freizeit:{
                    posten:"Freizeit",
                    stk:0,
                    preis:0,
                    index:0
                },
                tshirt:{
                    posten:"T-Shirt",
                    stk:0,
                    preis:0,
                    index:1
                },
                tasse:{
                    posten:"Tasse",
                    stk:0,
                    preis:0,
                    index:2
                },
                gesamt:{
                    posten:"Gesamt",
                    stk:-1,
                    preis:-1,
                    index:3
                }
            }
        }
    },
    methods:{
        getCheckoutData(){
            return this.freizeitKosten;
        },
        formatMoney(value){
            if(value === undefined){
                return " - "
            }else if(value === "Preis"){

                return value.toString();
            }
            return value.toString() + " €";
        },
        async setupCheckout(formData, collection){
            this.year = parseInt(this.$router.currentRoute.path.replace(/[^0-9]/g,''));

            let result = await this.$network.requestGet("/items/camp?filter[jahr][_eq]="+this.year.toString()+"&fields=freizeit.*")
            if(result.status === 200){
                let camp = result.data.data[0];
                if(collection.includes("teilnehmer")){
                    for(let freizeit of camp.freizeit){
                        if (freizeit.name === formData.freizeit){
                            this.freizeitKosten.freizeit.preis = freizeit.preis;
                            this.freizeitKosten.freizeit.stk = 1;
                        }
                    }
                    
                    if(formData.tshirt){
                        this.freizeitKosten.tshirt.stk = 1;
                        this.freizeitKosten.tshirt.preis = this.tshirtPreis;
                    }
                    
                    this.freizeitKosten.tasse.stk = formData.tassen;
                    this.freizeitKosten.tasse.preis = formData.tassen * this.tassePreis;

                    this.freizeitKosten.gesamt.preis = 0
                    if (this.freizeitKosten.freizeit.preis){
                        this.freizeitKosten.gesamt.preis += this.freizeitKosten.freizeit.preis
                    }
                    if (this.freizeitKosten.tshirt.preis){
                        this.freizeitKosten.gesamt.preis += this.freizeitKosten.tshirt.preis
                    }
                    if (this.freizeitKosten.tasse.preis){
                        this.freizeitKosten.gesamt.preis += this.freizeitKosten.tasse.preis
                    }
                }
                else if(collection.includes("mitarbeiter")){
                    this.freizeitKosten.freizeit.stk = 1
                    if(["Beruf","Rentner"].includes(formData.taetigkeit)){
                        this.freizeitKosten.freizeit.preis = 40;
                    }else if(["Azubi","Schüler","nicht erwerbstätig"].includes(formData.taetigkeit)){
                        this.freizeitKosten.freizeit.preis = 30;
                    }else if(["Bibelschüler"].includes(formData.taetigkeit)){
                        this.freizeitKosten.freizeit.preis = 0;
                    }else{
                        this.freizeitKosten.freizeit.preis = 40;
                    }
                    this.freizeitKosten.tshirt.stk = 1;
                    this.freizeitKosten.tshirt.preis = undefined;

                    this.freizeitKosten.tasse.stk = formData.tassen;
                    this.freizeitKosten.tasse.preis = formData.tassen * this.tassePreis;

                    this.freizeitKosten.gesamt.preis = 0
                    if (this.freizeitKosten.freizeit.preis){
                        this.freizeitKosten.gesamt.preis += this.freizeitKosten.freizeit.preis
                    }
                    if (this.freizeitKosten.tshirt.preis){
                        this.freizeitKosten.gesamt.preis += this.freizeitKosten.tshirt.preis
                    }
                    if (this.freizeitKosten.tasse.preis){
                        this.freizeitKosten.gesamt.preis += this.freizeitKosten.tasse.preis
                    }

                }
            }
        }
    }
}
</script>