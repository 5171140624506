<template>
    <div>

        
        <div  v-for="table, tableIndex in localValue" :key="tableIndex">
            <v-divider class="mt-2"></v-divider>
            <div class="mb-2 mt-2 d-flex flex-row justify-end">
                <div
                    v-if="!showJson"
                >
                    <v-btn @click="newColumn(table)" class="mr-2" fab x-small color="primary">
                        <v-icon>mdi-table-column-plus-after</v-icon>
                    </v-btn>
                    <v-btn class="mr-2" @click="newRow(table)" fab x-small color="primary">
                        <v-icon>mdi-table-row-plus-after</v-icon>
                    </v-btn>
                    <v-btn @click="deleteTable(tableIndex)" class="mr-2" fab x-small color="error">
                        <v-icon>mdi-table-large-remove</v-icon>
                    </v-btn>
                </div>
                <v-btn
                    icon
                    @click="toggleJsonEditor()"
                >
                    <v-icon>mdi-code-json</v-icon>
                </v-btn>
            </div>

            <interface-code-editor
                v-if="showJson"
                class="mr-1 ml-1"
                v-model="localValueJson"
            />

            <div class="d-flex flex-row"
                v-if="!showJson"
            >
                <v-text-field
                    @change="changeLocalValue"
                    class="rounded-0" label="Tabellen-Icon" outlined v-model="table.icon" dense hide-details 
                />
                <v-text-field 
                    @change="changeLocalValue"
                    class="rounded-0" label="Tabellen-Name" outlined v-model="table.title" dense hide-details 
                />
            </div>
            <v-simple-table
                v-if="!showJson"
                dense
            >
                <tbody>
                    <tr class="ma-0 pa-0" style="background-color: #f2f2f2;">
                        <td class="ma-0 pa-0"  v-for="header, headerIndex in table.headers" :key="headerIndex">
                            <v-text-field 
                                @change="changeLocalValue"
                                class="rounded-0" outlined dense hide-details v-model="table.headers[headerIndex]"
                            />
                        </td >
                        <td style="border:1px solid silver;"> </td>
                    </tr>
                    <tr class="ma-0 pa-0" v-for="row, rowIndex in table.rows" :key="rowIndex">
                        <td class="ma-0 pa-0" v-for="col, colIndex in row" :key="colIndex">
                            <v-text-field 
                                @change="changeLocalValue"
                                class="ma-0 pa-0 rounded-0" outlined dense hide-details v-model="table.rows[rowIndex][colIndex]"
                            />
                        </td>
                        <td style="border:1px solid silver;">
                            <div class="d-flex flex-row">

                                <v-btn class="rounded-0 flex-fill" color="secondary" small @click="moveRow(table, rowIndex,-1)" icon><v-icon size="16">mdi-chevron-up</v-icon></v-btn>
                                <v-btn class="rounded-0" color="secondary" small @click="moveRow(table, rowIndex,1)" icon><v-icon size="16">mdi-chevron-down</v-icon></v-btn>
                                <v-btn class="rounded-0" color="secondary" small @click="deleteRow(table, rowIndex)" icon><v-icon size="16">mdi-close-thick</v-icon></v-btn>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="ma-0 pa-0 bordered-cell" v-for="header, headerIndex in table.headers" :key="headerIndex">
                            <div class="d-flex flex-row">
                                <v-btn class="rounded-0" color="secondary"  small @click="moveCol(table, headerIndex,-1)" icon><v-icon size="16">mdi-chevron-left</v-icon></v-btn>
                                <v-btn class="rounded-0 flex-grow-1" color="secondary" small @click="deleteCol(table, headerIndex)" icon><v-icon size="16">mdi-close-thick</v-icon></v-btn>
                                <v-btn class="rounded-0" color="secondary"  small @click="moveCol(table, headerIndex,1)" icon><v-icon size="16">mdi-chevron-right</v-icon></v-btn>
                            </div>
                        </td>
                        <td style="border:1px solid silver;"></td>
                    </tr>
                </tbody>
        
            </v-simple-table>
        </div>

        
        <v-btn
            v-if="!showJson"
            class="mt-2" color="primary" block small @click="newTable()"
        >
            <v-icon>mdi-table-large-plus</v-icon> Neue Tabelle
        </v-btn>
    </div>
</template>

<script>
import InterfaceCodeEditor from './InterfaceCodeEditor.vue';
export default {
    components:{
        InterfaceCodeEditor
    },
    created(){
        this.localValue = this.value
    },
    props:{
        value:Array
    },
    data(){
        return{
            localValue:undefined,
            showJson:false,
            localValueJson:undefined,
        }
    },
    methods:{
        toggleJsonEditor(){
            this.showJson = !this.showJson
            if(this.showJson){
                this.localValueJson = JSON.stringify(this.localValue, null, 4);
            }else{
                try{
                    const ob = JSON.parse(this.localValueJson);
                    this.localValue = ob;
                    this.$emit("input", this.localValue);
                }catch(e){
                    console.error(e);
                }
            }
        },
        changeLocalValue(){
            this.$emit("input", this.localValue);
        },
        moveCol(table, index, dir){
            let newIndex = Math.min(Math.max(0, index+dir), table.headers.length)
            let header = table.headers.splice(index, 1);
            table.headers.splice(newIndex, 0, header[0]);
            for(let row of table.rows){
                let header = row.splice(index, 1);
                row.splice(newIndex, 0, header[0]);
            }
        },
        moveRow(table, index, dir){
            let newIndex = Math.min(Math.max(0, index+dir), table.rows.length)
            let row = table.rows.splice(index, 1);
            table.rows.splice(newIndex, 0, row[0]);
        },
        deleteTable(tableIndex){
            this.localValue.splice(tableIndex, 1);
            this.$emit("input", this.localValue);
        },
        newTable(){
            if(!this.localValue){
                this.localValue = [];
            }
            this.localValue.push(
                {
                    title:"Neue Tabelle",
                    headers:["","",""],
                    rows:[
                        ["","",""]
                    ]
                }
            )
            this.$emit("input", this.localValue);
        },
        newColumn(table){
            table.headers.push("");
            for(let row of table.rows){
                row.push("");
            }
            this.$emit("input", this.localValue);
        },
        newRow(table){
            let row = []
            for(let i=0; i<table.headers.length; i++){
                row.push("")
            }
            table.rows.push(row);
            this.$emit("input", this.localValue);
        },
        deleteRow(table, index){
            table.rows.splice(index, 1);
            this.$emit("input", this.localValue);
        },
        deleteCol(table, index){
            table.headers.splice(index, 1);
            for(let row of table.rows){
                row.splice(index, 1);
            }
            this.$emit("input", this.localValue);
        }
    }
}
</script>

<style scoped>
    .bordered-cell{
        border:1px solid silver;
    }
</style>