<template>
    <v-container>
        <v-card>
            <v-card-title>
                <v-icon>
                    mdi-alert
                </v-icon>
                Fehler 404 - Die Seite wurde nicht gefunden!
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-btn
                            block
                            class="primary mb-2"
                            @click="backToCampDataLogin()"
                        >
                            Zum Mitarbeiter-Login {{ year() }}
                        </v-btn>
                        

                        <v-btn
                            block
                            class="primary"
                            @click="backToMainLogin()"
                        >
                            Zum Verwaltungs-Login
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>


<script>
import dayjs from 'dayjs';
export default {
    inject:[
        "app"
    ],
    components:[
        dayjs
    ],
    methods:{
        backToMainLogin(){
            this.app.$router.push({name:"Dashboard"});
            this.app.$forceUpdate();
        },
        backToCampDataLogin(){
            this.app.$router.push({path:"freizeit-daten/"+this.year()});
            this.app.$forceUpdate();
        },
        year(){
            return dayjs().year();
        }
    }
}
</script>