export class Utils{
    app = undefined;

    getDifference(oldOb, newOb, type=Object) {
        let delta = undefined;
        // if(type === Object){
        //     delta = {}
        // }else if(type === Array){
        //     delta = []
        // }
        
        for(let key in oldOb){
            let oldValue = oldOb[key];
            let newValue = newOb[key];

            if(oldValue instanceof Array){
                const val = this.getDifference(oldValue, newValue, Array);
                if(val !== undefined){
                    if (delta === undefined){
                        delta = [];
                    }
                    delta[key] = val
                }
            }else if(oldValue instanceof Object){
                const val = this.getDifference(oldValue, newValue);
                if(val !== undefined){
                    if (delta === undefined){
                        delta = {};
                    }
                    delta[key] = val
                }
            }else{
                if(oldValue !== newValue){
                    if (delta === undefined){
                        if(type === Object){
                            delta = {};
                        }else if(type === Array){
                            delta = [];
                        }
                    }
                    delta[key] = newValue;
                }
            }
        }
        // if(type === Array && delta.length === 0){
        //     return undefined;
        // }else if(type === Object && Object.keys(delta).length === 0){
        //     return undefined;
        // }
        return delta;
      }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getFreizeitKostenGesamt(personData, campData, freizeitData){
        let kostenGesamt = freizeitData.preis;
        if(personData.tassen > 0){
            kostenGesamt += campData.tasse_preis*personData.tassen
        }
        if(personData.tshirt){
            kostenGesamt += campData.tshirt_preis;
        }
        return kostenGesamt;
    }

    buildCheckoutTable(personData, campData, freizeitData){
        
        let freizeit_kosten = "(Der Betrag setzt sich zusammen aus:"
        freizeit_kosten += " Freizeitbetrag "+freizeitData.preis+"€"
        if(personData.tassen > 0){
            freizeit_kosten += ", "+personData.tassen+"xTassen "+campData.tasse_preis*personData.tassen+"€"
        }
        if(personData.tshirt){
            freizeit_kosten += ", 1xT-Shirt "+campData.tshirt_preis+"€"
        }
        freizeit_kosten += ")";
        return freizeit_kosten
	}

    beautifyKey(key){
        let stringArr = key.replaceAll("_"," ").replaceAll("oe","ö").replaceAll("ue","ü").replaceAll("ae","ä").split(" ");
        let finalStr = ""

        // for(let subStr of stringArr){
        for(let i=0; i<stringArr.length; i++){
            let subStr = stringArr[i];
            finalStr += subStr.charAt(0).toUpperCase() + subStr.slice(1);
            if(i<stringArr.length-1){
                finalStr += " "
            }
        }
        return finalStr;
    }
}