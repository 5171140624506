    <template>
        <v-app-bar
            app
            clipped-left
            dark
            class="success"
            height="60"
        >
        <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.xsOnly"
        >
            <portal-target name="topbar-menu-button" />
        </v-app-bar-nav-icon>
        <v-toolbar-title style="width:140px;">
            <!-- <span class="mr-2">
            <v-icon>mdi-tablet-dashboard</v-icon>
            </span>Admin -->
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <div class="d-flex align-center">
            <v-img
            alt="Espelcamp Logo"
            class="ma-2"
            contain
            src="@/assets/espelcamp_logo.png"
            transition="scale-transition"
            max-height="50"
            width="70"
            />
        </div>
        <v-spacer></v-spacer>
        <div v-if="$vuetify.breakpoint.xsOnly" class="text-right" style="width:40px"></div>
        <div class="text-right" style="width:140px;">
            <user-menu
                v-if="app.userData && app.loggedIn"
                :app="this.app"
            />
        </div>
        </v-app-bar>
    </template>

    <script>

    import UserMenu from './UserMenu.vue';

    export default {
        name:"TopBar",
        components:{
            UserMenu,
        },
        inject:[
            "app"
        ],
        mounted(){
        },
        data(){
            return{
            }
        },
        methods:{
            toggleMenu(){
                // console.log(this.app.refs["NavBar"]);
            }
        }
    }
    </script>

    <style scoped>
    .gradient{
        background: linear-gradient(90deg, rgba(69,90,100,1) 0%, rgba(38,50,56,1) 100%);
    }

    </style>