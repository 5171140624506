<template>
    <v-app v-if="loginChecked" translate="no" lang="de">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="HandheldFriendly" content="true" />

        <div style="height:100%; width:100%;" v-if="!uiHidden()">
            <top-bar/>
    
            <v-main id="body">
                <router-view />
            </v-main>
        </div>
        <v-main v-else>
            <router-view />
        </v-main>
        <notification ref="Notification"></notification>
    </v-app>
</template>

<script>
import TopBar from './components/TopBar.vue';
import Notification from './components/Notifiaction.vue';
import PeopleRegistration from './components/PeopleRegistration.vue';
import CamphelferRegistration from './components/CamphelferRegistration.vue';
import UserData from './pages/UserData.vue';
import Dashboard from '@/pages/Dashboard.vue';
import CountDown from './components/CountDown.vue';

export default {
    name: 'App',
    metaInfo: {
        htmlAttrs: {
            lang: 'de',
        },
        bodyAttrs: {
            class: 'custom-body-class'
        }
    },
    components: {
        TopBar,
        Notification
    },
    async created(){
        this.$vuetify.lang.current = 'de';
        this.$network.setup(this);
        await this.createRoutesAndMenu();
        await this.$network.refreshUserLogin();
        await this.$network.checkIfLoggedIn();
        this.loginChecked = true;

        // log versions
        console.log('%c Dashboard v'+process.env.VUE_APP_VERSION+' ', 'background: #222; color: #bada55');
        let result = await this.$network.requestGet("/backend-version");
        if(result.status === 200){
            console.log('%c Dashboard Backend v'+result.data.version+' ', 'background: #222; color: #bada55');
        }
    },

    provide(){
        return{
            app:this,
        }
    },
    data(){
        return{
            loginChecked:false,
            dataRefreshTimer:undefined,
            loggedIn:false,
            drawer:true,
            app:this,
            dashboard:undefined,
            appReady:false,
            axios:this.$axios,
            restUrl:process.env.VUE_APP_REST_URL,
            userData:undefined,
            menu:[],
            activeMenu:undefined,
            activeSubMenu:undefined,
            emailAdresses:[
                '"Espelcamp" info@espelcamp.de',
                '"Espelcamp" mitarbeiter-anmeldung@espelcamp.de',
                '"Espelcamp" teilnehmer-anmeldung@espelcamp.de'
            ],
        }
    },
    computed:{
        notifications(){
            return this.$refs.Notification;
        }
    },
    methods:{
        uiHidden(){
            return this.$route.query.hide_ui === "true";
        },
        showNotification(message, timeout, type, icon, position){
            if(timeout === undefined){timeout = 2000;}
            if(type === undefined){type = "success";}
            this.notifications.showNotification(message, timeout, type, icon, position);
        },
        loadLastRoute(){
            let routerPath = localStorage.getItem("routerPath");
            if(routerPath){
                let route = JSON.parse(routerPath)
                if(this.$router.currentRoute.path != route.path){
                    this.$router.push({path:route.path, query:route.query});
                }
            }
        },
        async createRoutesAndMenu(){
            let main_menu_result = await this.$network.requestGet("/items/main_menu")

            
            this.$router.addRoute(
                {name:"Admin", path:"/admin", component:Dashboard}
            )
            if(main_menu_result.status === 200){
                for(let mainMenuItem of main_menu_result.data.data){
                    this.$router.addRoute(
                        {
                            name:mainMenuItem.title, 
                            path:mainMenuItem.url, 
                            component:Dashboard,
                            meta:{requires_auth:mainMenuItem.requires_auth}
                        }
                    )
                    let sub_menu_result = await this.$network.requestGet("/items/"+mainMenuItem.sub_menu);
                    if(sub_menu_result.status === 200){
                        for(let subMenuItem of sub_menu_result.data.data){
                            let url = mainMenuItem.url + subMenuItem.url;
                            subMenuItem.url = url;
                            this.$router.addRoute(
                                {
                                    name:subMenuItem.title,
                                    path:url,
                                    component:Dashboard,
                                    meta:{requires_auth:subMenuItem.requires_auth}
                                }
                            )
                            subMenuItem.main_menu = JSON.parse(JSON.stringify(mainMenuItem));
                        }
                        mainMenuItem.sub_menu_items = JSON.parse(JSON.stringify(sub_menu_result.data.data));
                    }
                    this.menu.push(mainMenuItem)
                }
            }
            await this.createRegistrationRoutes()
            if(this.$router.currentRoute.name === null){
                let path = this.$router.currentRoute.fullPath;
                this.$router.push({path:"/"})
                this.$nextTick(()=>{
                    this.$router.push({path:path})
                })
            }

            // create route guard
            this.$router.beforeEach((to, from, next)=>{
                from;
                if(!to.name){
                    this.$router.push({path:"/404"})
                }else{
                    next()
                }
            })
        },
        async createRegistrationRoutes(){
            let result = await this.$network.requestGet("/items/camp?fields=jahr");
            if(result.status === 200){
                for(let camp of result.data.data){
                    this.$router.addRoute(
                        {name:"Mitarbeiter Anmeldung "+camp.jahr.toString(),path:"/mitarbeiter-anmeldung/"+camp.jahr.toString(), component:PeopleRegistration}
                    )
                    this.$router.addRoute(
                        {name:"Teilnehmer Anmeldung "+camp.jahr.toString()+" Countdown",path:"/teilnehmer-anmeldung/"+camp.jahr.toString(), component:PeopleRegistration}
                    )
                    this.$router.addRoute(
                        {name:"Teilnehmer Anmeldung "+camp.jahr.toString(),path:"/teilnehmer-anmeldung/"+camp.jahr.toString()+"/countdown", component:CountDown}
                    )
                    this.$router.addRoute(
                        {name:"Teilnehmer Anmeldung Extra "+camp.jahr.toString(),path:"/teilnehmer-anmeldung-extra/"+camp.jahr.toString(), component:PeopleRegistration}
                    )
                    this.$router.addRoute(
                        {name:"Camphelfer Anmeldung "+camp.jahr.toString(),path:"/camphelfer-anmeldung/"+camp.jahr.toString(), component:CamphelferRegistration}
                    )
                    this.$router.addRoute(
                        {name:"Freizeit Daten "+camp.jahr.toString(),path:"/freizeit-daten/"+camp.jahr.toString(), component:UserData}
                    )
                }
            }
        }
    }
};
</script>

<style scoped>
    #body{
        width:100%;
        height:100%;
        background-color:rgb(245, 245, 245);
    }
</style>
