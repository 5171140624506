<template>
    <v-container>
        <v-alert
            text
            v-if="confirmed && confirmationChecked"
            border="left"
            type="success"
        >
            <v-row>
                <v-col>
                    <h3>Der Vorgang ist abgeschlossen. </h3>
                    <p>
                        Die Reservierung ist nun abgeschlossen. Wir melden uns bei Ihnen sobald es weitere Neuigkeiten gibt.
                    </p>
                    <p>
                        <b>Das Espelcamp Planungsteam</b>
                    </p>
                </v-col>
            </v-row>
        </v-alert>
        <v-alert
            text
            v-else-if="confirmationChecked"
            border="left"
            type="error"
        >
            <v-row>
                <v-col>
                    <h3>Die Email konnte nicht bestätigt werden. </h3>
                    <p>
                        Die Email konnte aus unbekannten Gründen nicht bestätigt werden.
                    </p>
                </v-col>
            </v-row>
        </v-alert>
    </v-container>
</template>

<script>
export default {
    async created(){
        let url = "/email-confirmation"
        url +="?token="+this.$route.query.token;
        url +="&collection="+this.$route.query.collection;
        let result = await this.$network.requestGet(url);
        this.confirmationChecked = true
        if(result.status === 200){
            this.confirmed = true;
        }
    },
    data(){
        return{
            confirmed:false,
            confirmationChecked:false
        }
    }
}
</script>