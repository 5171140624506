<template>
    <confirm-dialog
        ref="dialog"
        :icon="'mdi-content-copy'"
        :show-button="true"
        :button-icon="'mdi-content-copy'"
        :title="'Feld aus vorherigen Jahren übertragen'"
        :max-width="600"
        :onConfirm="transferFieldValues"
        :hide-on-confirm="false"
        :buttonDisabled="disabled"
    >
        <template v-slot:content>
            <v-row>
                <v-col>
                    <span>Feldname</span>
                    <v-combobox
                        v-model="data.field"
                        :items="fields"
                        solo dense hide-details
                        class="mb-2"    
                    />
                    <span class="mt-2">Rückblickend (Jahre)</span>
                    <v-text-field 
                        v-model="data.depth"
                        type="number"
                        min="1"
                        solo dense hide-details
                    />
                    <v-checkbox 
                        v-model="data.restrictedOverride"
                        hide-details
                        label="Nur überschreiben falls in aktuellem Jahr nicht gesetzt"
                    >
                    </v-checkbox>
                    <v-checkbox
                        v-model="data.onlySelectedUser"
                        label="Nur ausgewählte Personen prüfen"
                    ></v-checkbox>
                    <v-progress-linear v-if="progress > 0" v-model="progress"></v-progress-linear>
                </v-col>
            </v-row>
        </template>
    </confirm-dialog>
</template>

<script>
import ConfirmDialog from './ConfirmDialog.vue';

export default {
    components:{
        ConfirmDialog
    },
    inject:[
        "app"
    ],
    created(){
    },
    props:{
        year:{type:String, default:"2020"},
        collection:{type:String, default:"mitarbeiter_"},
        disabled:{type:Boolean, default:false},
        peopleManager:{type:Object}
    },
    data(){
        return{
            progress:0,
            fields:[
                "status_fuehrungszeugnis_datum"
            ],
            data:{
                field:"status_fuehrungszeugnis_datum",
                depth:5,
                restrictedOverride:true,
                onlySelectedUser:false
            }
        }
    },
    methods:{
        getSelectedUser(){
            return this.peopleManager.peopleSelectedRows
        },
        async transferFieldValues(){
            //disable buttons while processing
            this.$refs.dialog.disableButtons();
            this.$refs.dialog.makePersistent(true);

            let campResult = await this.$network.requestGet("/items/camp?filter[jahr]="+this.year+"&fields=freizeit.datum_bis")
            let camp = campResult.data.data[0];

            let people = this.getSelectedUser();
            if(!this.data.onlySelectedUser){
                // get all userdata
                let url = "/items/"+this.collection+this.year+"?limit=-1&fields=id,vorname,nachname,geburtstag,status_fuehrungszeugnis,"+this.data.field;
                let result = await this.$network.requestGet(url);
                if(result.status === 200){
                    people = result.data.data
                }
            }else{
                // get only selected userdata
                let ids = people.map((person)=>{return person.id})
                let url = "/items/"+this.collection+this.year+"?limit=-1&fields=id,vorname,nachname,geburtstag,status_fuehrungszeugnis,"+this.data.field;
                url+='&filter={"id":{"_in":'+ids.toString()+'}}';
                let result = await this.$network.requestGet(url);
                if(result.status === 200){
                    people = result.data.data
                }
            }
            if(people && people.length > 0){
                
                this.progress = 0;
                let idx = 0
                for(let person of people){
                    idx += 1;
                    if(!person[this.data.field] || !this.data.restrictedOverride){
                        
                        let payload = {
                            id:person.id,
                            field:this.data.field,
                            year:parseInt(this.year),
                            collection:this.collection,
                            conditions:{"_and":[{"vorname":{"_eq":person.vorname}},{"nachname":{"_eq":person.nachname}},{"geburtstag":{"_eq":person.geburtstag}}]},
                            depth:this.data.depth
                        }
                        let result = await this.$network.requestPatch("/transfer-field-value", payload);
                        if(result.data && result.data.fields){
                            for(let key of Object.keys(result.data.fields)){
                                person[key] = result.data.fields[key];
                            }
                        }
                    }
                    await this.$network.peopleManagerPostSaveOperations(this.collection+this.year,camp, person);
                    this.progress = 100*(idx / people.length);
                }
            }

            this.app.showNotification("Felder wurden aus vorherigen Jahren übertragen.")

            this.$refs.dialog.enableButtons();
            this.$refs.dialog.makePersistent(false);
            setTimeout(()=>{
                this.$refs.dialog.hide()
                this.progress = 0;
            },200)
        }
        
    }
}
</script>