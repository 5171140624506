<template>
    <div>
        <!-- {{ localValue }} -->
        <v-card>
            <!-- Heading -->
            <v-card flat class="d-flex grey lighten-3 pa-1">
                <b class="flex-fill">Beschreibung</b>
                <b class="width60 mr-2 text-right">Datum</b>
                <b class="width60 mr-2 text-right">Betrag</b>
            </v-card>

            <!-- Payments -->
            <div v-for="(value, index) in localValue.payments" :key="index" class="pa-2">
                <v-hover v-if="!(newEntry && newEntry.index === index)" v-slot="{hover}">
                    <div class="d-flex">
                        <span class="flex-fill">{{ value.description }}</span>
                        <v-chip label small class="mr-2 pa-1 justify-center" style="min-width:100px;" :class="value.type.operation==='ADD'?'success':'warning'">
                            <v-icon color="white" size="16" class="mr-1" v-if="value.type.operation==='ADD'">mdi-arrow-right</v-icon>
                            <v-icon color="white" size="16" class="mr-1" v-else>mdi-arrow-left</v-icon>{{ value.type.name }}
                        </v-chip>
                        <span class="width60 grey--text mr-2">{{ formatDate(value.date) }}</span>
                        <h3 class="width60 text-right" :class="value.type.operation ==='ADD'?'success--text':'warning--text'">
                            <span v-if="value.type.operation ==='ADD'">+</span>
                            <span v-else>-</span>
                            {{ value.amount }} €
                        </h3>
                        <div v-if="hover" style="cursor:pointer; position:absolute; width:100%; left:0px;" class="text-center">
                            <div @click="editPayment(index)" style=" width:100%; height:100%;"><v-icon size="20">mdi-pencil</v-icon></div>
                        </div>
                    </div>
                </v-hover>

                <!-- Edit Payment -->
                <div v-if="newEntry && newEntry.index === index">
                    <div :class="isMobile ? '' : 'd-flex'" class="pl-2 pr-2">
                        <v-text-field class="flex-fill" solo label="Beschreibung" hide-details dense v-model="newEntry.data.description"></v-text-field>
                        <v-select :style="{'max-width':maxWidth()}" solo hide-details dense item-text="name" :items="typeOptions" return-object v-model="newEntry.data.type"></v-select>
                        <v-text-field :style="{'max-width':maxWidth()}" solo class="flex-shrink" hide-details dense v-model="newEntry.data.date" type="date"></v-text-field>
                        <v-text-field :style="{'max-width':maxWidth()}" hide-details  solo dense v-model="newEntry.data.amount" type="number" append-icon="mdi-currency-eur"></v-text-field>
                    </div>
                    <div class="d-flex justify-center ma-2">
                        <v-btn @click="cancelAddPayment()" small color="error" icon><v-icon>mdi-close</v-icon></v-btn>
                        <v-btn @click="addPayment()" small color="primary" icon><v-icon>mdi-check</v-icon></v-btn>
                        
                        <v-btn v-if="newEntry.index !== -1" @click="deletePayment(index)" small color="primary" icon><v-icon>mdi-delete</v-icon></v-btn>
                    </div>
                </div>
                <v-divider/>
            </div>

            <!-- New Payment -->
            <div v-if="newEntry && newEntry.index === -1">
                <v-divider class="mb-2"/>
                <div :class="isMobile ? '' : 'd-flex'" class="pl-2 pr-2">
                    <v-text-field class="flex-fill" solo label="Beschreibung" hide-details dense v-model="newEntry.data.description"></v-text-field>
                    <v-select :style="{'max-width':maxWidth()}" solo  hide-details dense item-text="name" :items="typeOptions" return-object v-model="newEntry.data.type"></v-select>
                    <v-text-field :style="{'max-width':maxWidth()}" solo class="flex-shrink" hide-details dense v-model="newEntry.data.date" type="date"></v-text-field>
                    <v-text-field :style="{'max-width':maxWidth()}" hide-details  solo dense v-model="newEntry.data.amount" type="number" append-icon="mdi-currency-eur"></v-text-field>
                </div>
                <div class="d-flex justify-center ma-2">
                    <v-btn @click="cancelAddPayment()" small color="error" icon><v-icon>mdi-close</v-icon></v-btn>
                    <v-btn @click="addPayment()" small color="primary" icon><v-icon>mdi-check</v-icon></v-btn>
                    
                    <v-btn v-if="newEntry.index !== -1" @click="deletePayment(index)" small color="primary" icon><v-icon>mdi-delete</v-icon></v-btn>
                </div>
            </div>

            <!-- New payment button -->
            <div v-if="!newEntry" class="d-flex mr-2 pb-1">
                <div class="flex-fill"></div>
                <v-btn class="" @click="addNewPayment()" small icon><v-icon>mdi-plus</v-icon></v-btn>
            </div>

            <!-- Summary -->
            <v-card class="pa-2" flat
                color="grey lighten-3"
            >
                <div class="d-flex">
                    <h3 class="flex-fill">
                        Zahlung
                    </h3>
                    <h3>
                        <span
                            :class="saldo()>=localValue.total ? 'success--text' : ''"
                        >
                            {{ saldo() }} €
                        </span> / <span class="grey--text">{{ localValue.total }} €</span>
                    </h3>
                </div>
            </v-card>
        </v-card>
    </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
    props:{
        value:Object,
        meta:Object
    },
    created(){
        if(this.localValue === undefined || this.localValue === null){
            this.localValue = {total:0, payments:[]}
            this.changeValue(this.localValue);
        }
    },
    data(){
        return{
            editable:false,
            // data:{
            //     payments:[
            //         {date:"2024-05-01", amount:30, type:"Einzahlung", description:"Zuschuss Stadt"},
            //         {date:"2024-05-03", amount:50, type:"Einzahlung", description:"Elternbeitrag"},
            //         {date:"2024-05-18", amount:60, type:"Erstattung", description:"Erstattung an Eltern"},
            //     ],
            //     total:186
            // },
            newEntry:undefined,
            localValue: this.value,
        }
    },
    watch:{
        value(value){
            this.localValue = value;
        }
    },
    computed:{
        isMobile(){
            return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly;
        },
        typeOptions(){
            return this.meta.options.type;
            // return ['Einzahlung', 'Erstattung']
        }
    },
    methods:{
        maxWidth(){
            return this.isMobile ? undefined : 120
        },
        changeValue(value){
            this.$emit("input", value);
        },
        formatDate(date){
            return dayjs(date).format("DD.MM.YY")
        },
        addPayment(){
            if(this.newEntry.index === -1){
                this.localValue.payments.push(this.newEntry.data);
            }
            this.newEntry = undefined;
        },
        cancelAddPayment(){
            this.newEntry = undefined;
        },
        addNewPayment(){
            this.newEntry = {
                index:-1,
                data:{
                    date:dayjs().format("YYYY-MM-DD"), 
                    amount:0, 
                    type:{name:"Einzahlung", operation:"ADD"}, 
                    description:""
                }
            }
            this.changeValue(this.localValue);
        },
        deletePayment(index){
            this.localValue.payments.splice(index, 1);
            this.newEntry = undefined;
            
            this.changeValue(this.localValue);
        },
        editPayment(index){
            this.newEntry = {
                index:index,
                data:this.localValue.payments[index]
            }
        },
        saldo(){
            let saldo = 0
            for(let item of this.localValue.payments){
                if(item.type.operation === "ADD"){
                    saldo += Number(item.amount)
                }else if(item.type.operation === "SUBTRACT"){
                    saldo -= Number(item.amount)
                }
            }
            return saldo;
        }
    }
    
}
</script>

<style scoped>
    .width60{
        min-width: 60px;
    }
</style>