<template>
        <v-row>

            <v-col 
                cols="12"
                sm="4"
            >
                    <h3 v-if="heading">E1</h3>
                    <v-select
                        v-if="e1_wish_1"
                        :label="heading ? 'Wahl 1' : 'Wahl'"
                        solo
                        hide-details="auto"
                        dense
                        class="mb-2"
                        :items="e1_wish_1.meta.options.choices"
                        v-model="peopleManager.editData[e1_wish_1.field]"
                        clearable
                    />

                    <v-select
                        v-if="e1_wish_2"
                        label="Wahl 2"
                        solo
                        hide-details="auto"
                        dense
                        :items="e1_wish_2.meta.options.choices"
                        v-model="peopleManager.editData[e1_wish_2.field]"
                        clearable
                    />
            </v-col>
            
            <v-col 
                cols="12"
                sm="4"
            >
                    <h3 v-if="heading">E2</h3>
                    <v-select
                        v-if="e2_wish_1"
                        :label="heading ? 'Wahl 1' : 'Wahl'"
                        solo
                        hide-details="auto"
                        dense
                        class="mb-2"
                        :items="e2_wish_1.meta.options.choices"
                        v-model="peopleManager.editData[e2_wish_1.field]"
                        clearable
                    />

                    <v-select
                        v-if="e2_wish_2"
                        label="Wahl 2"
                        solo
                        hide-details="auto"
                        dense
                        :items="e2_wish_2.meta.options.choices"
                        v-model="peopleManager.editData[e2_wish_2.field]"
                        clearable
                    />
            </v-col>
            <v-col 
                cols="12"
                sm="4"
            >
                    <h3 v-if="heading">E3</h3>
                    <v-select
                        v-if="e3_wish_1"
                        :label="heading ? 'Wahl 1' : 'Wahl'"
                        solo
                        hide-details="auto"
                        dense
                        class="mb-2"
                        :items="e3_wish_1.meta.options.choices"
                        v-model="peopleManager.editData[e3_wish_1.field]"
                        clearable
                    />

                    <v-select
                        v-if="e3_wish_2"
                        label="Wahl 2"
                        solo
                        hide-details="auto"
                        dense
                        :items="e3_wish_2.meta.options.choices"
                        v-model="peopleManager.editData[e3_wish_2.field]"
                        clearable
                    />
            </v-col>
        </v-row>
</template>

<script>
export default {
    props:{
        peopleManager:Object,
        heading:{type:Boolean, default:true},
        e1_wish_1:{type:Object, default:undefined},
        e1_wish_2:{type:Object, default:undefined},
        e2_wish_1:{type:Object, default:undefined},
        e2_wish_2:{type:Object, default:undefined},
        e3_wish_1:{type:Object, default:undefined},
        e3_wish_2:{type:Object, default:undefined}

    }
    
}
</script>