<template>

    <div style="min-width:70px;" :class="isHovered?'hovered':'not-hovered'" class="rounded-lg" ref="groupParent">
        <v-icon size="16" :color="color">
            mdi-square
        </v-icon>
        <strong class="capitalize">{{title}}</strong>
        <!-- {{data}} -->
        <v-card :color="getColor('secondary',0.4)" class="person-field" outlined flat >
            <v-card-text class="d-flex flex-row pa-1">
                <v-icon size="20" style="width:10px;" class="ma-0 pa-0 ml-1 drag-handle mr-1 secondary--text">
                    mdi-account
                </v-icon>

                <v-hover v-if="dataEmployee" v-slot="{hover}">
                    <div
                        style="width:100%;"
                        ref="nameContainer"
                    >
                        <div class="username" :style="getWidth()">
                        <!-- <div class="username"> -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span style="font-size:14px;" v-bind="attrs" v-on="on">
                                        {{dataEmployee.vorname}} {{dataEmployee.nachname}}
                                    </span>
                                </template>
                                <p class="pa-0 ma-0"><v-icon size="20" color="#fff" class="mr-2">mdi-account</v-icon>{{dataEmployee.vorname}} {{dataEmployee.nachname}}</p>
                            </v-tooltip>

                        </div>
                        
                        <v-btn
                            v-show="hover && edit" 
                            style="position:absolute; right:0px; top:6px;" 
                            icon 
                            x-small
                            @click="removeEmployee(dataEmployee)"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-hover>
                
                <div v-else>
                    <v-autocomplete
                        ref="employeeCell"
                        dense
                        hide-details
                        v-if="employeeCellSelected"
                        :items="availableEmployees"
                        :item-text="getPersonSearchText"
                        @change="selectEmployee"
                        @blur="employeeCellSelected=false;"
                        return-object
                    >
                        
                        <template v-slot:item="{item}">
                            <v-list class="pa-0 ma-0" dense>
                                <v-list-item dense class="ma-0 pa-0">
                                    <v-icon>mdi-account</v-icon>{{item.vorname}} {{item.nachname}}
                                </v-list-item>
                                <span class="ma-0 pa-0" style="font-size:10px; max-height:10px;" v-for="(friend, index) in item.freunde" :key="index">
                                    <v-list-item 
                                        dense style="max-height:10px;"
                                        class="ma-0 pa-0"
                                    >
                                        {{friend}}
                                    </v-list-item>
                                </span>
                            </v-list>
                        </template>

                    </v-autocomplete>

                    <v-hover v-else v-slot="{hover}">
                        <v-btn 
                            v-show="edit"
                            small block text style="position:absolute; top:0px;left:0px; height:100%;"
                            @click="selectEmployeeCell()"    
                        >
                            <v-icon v-show="hover">
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </v-hover>
                </div>
            </v-card-text>
        </v-card>

        <draggable :emptyInsertThreshold="0" :ref="title" :list="data" :group="group" handle=".drag-handle" :move="onMove" @end="onEnd">
            <!-- Empty Slots -->
            <div slot="footer">

                <v-card slot="footer" class="person-field" outlined flat v-for="(item, index) in getRemainingSlots()" :key="index">
                    <v-card-text class="pa-2 mb-0 grey lighten-4" style="height:30px;">
                        <v-autocomplete
                            ref="cell"
                            dense
                            hide-details
                            v-if="selectedCell === index"
                            :items="availableParticipants"
                            :item-text="getPersonSearchText"
                            @change="selectPerson"
                            @blur="selectedCell = -1"
                            return-object
                        >
                            
                            <template v-slot:item="{item}">
                                <v-list class="pa-0 ma-0" dense style="width:100%;">
                                    <v-list-item dense class="ma-0 pa-0">
                                        <v-icon>mdi-account</v-icon>{{item.vorname}} {{item.nachname}}
                                    </v-list-item>
                                    <v-list-item 
                                        dense 
                                        style="max-height: 10px; font-size: 12px;"
                                        class="ma-0 pa-0"
                                        
                                        v-if="item.freunde && item.freunde.length > 0"
                                    >
                                        {{flattenFriendsArray(item.freunde)}}
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </v-list>
                            </template>

                        </v-autocomplete>
                        <v-hover v-else v-slot="{hover}">
                            <v-btn 
                                v-show="edit"
                                small block text style="position:absolute; top:0px;left:0px; height:100%;"
                                @click="selectCell(index)"    
                            >
                                <v-icon v-show="hover">
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </v-hover>
                    </v-card-text>
                </v-card>
            </div>

            <!-- Filled Slots -->
            <v-card class="person-field" outlined flat v-for="(item, index) in data" :key="index">
                <v-card-text class="d-flex flex-row pa-1">
                    <v-icon size="20" v-show="edit" style="width:10px;" class="ma-0 pa-0 drag-handle mr-1 secondary--text">
                        mdi-drag
                    </v-icon>
                    <v-menu ref="picker" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-show="edit"
                                elevation="0"
                                icon x-small
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon :color="item.freunde_farbe">mdi-square</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-text>
                                <v-color-picker
                                    :value = "item.frunde_farbe ? item.freunde_farbe : '#cacaca'" 
                                    v-model="item.freunde_farbe"
                                    @update:color="onColorChange(color, item)"
                                    show-swatches
                                    hide-mode-switch
                                    :swatches="swatches"
                                    mode="hexa"
                                />
                            </v-card-text>
                        </v-card>
                    </v-menu>

                    <v-hover v-slot="{hover}">
                        <div
                            style="width:100%;"
                            ref="nameContainer"
                        >
                            <div class="username" :style="getWidth()">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span style="font-size:14px;" v-bind="attrs" v-on="on">
                                            {{item.vorname}} {{item.nachname}}
                                        </span>
                                    </template>
                                    <p class="pa-0 ma-0"><v-icon size="20" color="#fff" class="mr-2">mdi-account</v-icon>{{item.vorname}} {{item.nachname}}</p>
                                    {{item.geburtstag}}
                                    <p class="pa-0 ma-0"><v-icon size="20" color="#fff" class="mr-2">mdi-calendar</v-icon>{{getAge(item.geburtstag)}}</p>
                                    <p class="pa-0 ma-0"><v-icon size="20" color="#fff" class="mr-2">mdi-account-group</v-icon>{{item.freunde?String(item.freunde):''}}</p>
                                    <p v-if="item.freunde_kommentar" class="pa-0 ma-0"><v-icon size="20" color="#fff" class="mr-2">mdi-comment-account</v-icon>{{item.freunde?String(item.freunde_kommentar):''}}</p>
                                    <p class="pa-0 ma-0"><v-icon size="20" color="#fff" class="mr-2">mdi-church</v-icon>{{item.gemeinde?item.gemeinde:'-'}}</p>

                                </v-tooltip>

                            </div>
                            
                            <v-btn
                                v-show="hover && edit" 
                                style="position:absolute; right:0px; top:6px;" 
                                icon 
                                x-small
                                @click="removePerson(item)"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </v-hover>

                </v-card-text>
            </v-card>
        </draggable>

    </div>
</template>

<script>
import draggable from 'vuedraggable'
import dayjs from 'dayjs';
export default {
    components:{
        draggable
    },
    mounted(){
        this.createSwatches();
        this.resizeListener = window.addEventListener('resize', this.setGroupWidth);
        this.$nextTick(()=>{
            this.setGroupWidth();
        })
        this.getColor("primary")
    },
    destroyed(){
        window.removeEventListener(this.resizeListener, this);
    },
    props:{
        title:String,
        collection:String,
        collectionEmployees:String,
        freizeitName:String,
        freizeiten:Array,
        color:{type:String, default:"#000000"},
        group:{type:String, default:"group"},
        data:{type:Array, default:()=>{return[]}},
        dataEmployee:Object,
        totalSlots:{type:Number, default:8},
        availableParticipants:Array,
        availableEmployees:Array,
        updateMethod:Function,
        edit:{type:Boolean, default:false},
    },
    data(){
        return{
            resizeListener:undefined,
            width:100,
            selectedCell:-1,
            employeeCellSelected:false,
            isHovered:false,
            hoveredComponents:[],
            isMoving:false,
            colorChangeTimeout:undefined,
            swatches:[],
            defaultColors:[
                "#F44336","#EF5350","#EF9A9A",
                "#E91E63","#EC407A","#F48FB1",
                "#9C27B0","#AB47BC","#CE93D8",
                "#673AB7","#7E57C2","#B39DDB",
                "#3F51B5","#5C6BC0","#9FA8DA",
                "#03A9F4","#29B6F6","#81D4FA",
                "#009688","#26A69A","#80CBC4",
                "#4CAF50","#66BB6A","#A5D6A7",
                "#CDDC39","#D4E157","#E6EE9C",
                "#FFEB3B","#FFEE58","#FFF59D",
                "#FFC107","#FFCA28","#FFE082",
                "#FF9800","#FFA726","#FFCC80",
                "#FF5722","#FF7043","#FFAB91"
            ]
        }
    },
    methods:{
        createSwatches(){
            this.swatches = [];
            
            let currentSwatch = []
            for(let i=0; i<this.defaultColors.length; i++){
                currentSwatch.push(this.defaultColors[i]);
                if((i+1) % 3 == 0){
                    this.swatches.push(currentSwatch);
                    currentSwatch = []
                }
            }
            this.swatches.push(currentSwatch)
        },
        onColorChange(color, item){
            if (item.freunde_farbe.toString().match(/#[a-zA-Z0-9]{8}/)) {
                item.freunde_farbe = color.substr(0, 7);
            }
            if(this.colorChangeTimeout){
                clearTimeout(this.colorChangeTimeout)
            }
            this.colorChangeTimeout = setTimeout(async ()=>{
                let url = `/items/${this.collection}/${item.id}`;
                const payload = {
                    "freunde_farbe":item.freunde_farbe
                }
                await this.$network.requestPatch(url, payload);
                this.updateMethod();
                this.createSwatches();
            }, 500);
        },
        flattenFriendsArray(friends){
            let friendsStr = "";
            if(friends && friends.length > 0){
                for(let friend of friends){
                    if(friend){
                        let splitted = friend.split(",")
                        for(let friendSplitted of splitted){
                            friendsStr += friendSplitted + ", ";
                        }
                    }
                }
            }
            return friendsStr;
        },
        getColor(key, alpha){
            let hex = this.$vuetify.theme.themes.light[key];
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? `rgb(
                ${parseInt(result[1], 16)},
                ${parseInt(result[2], 16)},
                ${parseInt(result[3], 16)},
                ${alpha}
            )`:'';
        },
        getEmployeeProvinzColors(person){
            if(person.provinz){
                return person.provinz;
            }
            person.provinz = {};
            for(let freizeit of this.freizeiten){
                person.provinz[freizeit.name] = {color:null}
            }
            return person.provinz;
        },
        async removePerson(person){
            let payload = {
                provinz_farbe:null
            }
            await this.$network.requestPatch("/items/"+this.collection+"/"+person.id, payload);
            this.updateMethod()
            this.selectedCell = -1;
            this.setGroupWidth();
        },
        async removeEmployee(person){
            let payload = {provinz:this.getEmployeeProvinzColors(person)};
            payload.provinz[this.freizeitName].color = null;
            await this.$network.requestPatch("/items/"+this.collectionEmployees+"/"+person.id, payload);
            this.updateMethod()
            this.employeeCellSelected = false;
            this.setGroupWidth();
        },
        async selectEmployee(person){
            let payload = {provinz:this.getEmployeeProvinzColors(person)};
            payload.provinz[this.freizeitName].color = this.title;
            let url = "/items/"+this.collectionEmployees+"/"+person.id;
            await this.$network.requestPatch(url, payload);
            this.updateMethod()
            this.employeeCellSelected = false;
            this.setGroupWidth();
        },
        async selectPerson(person){
            let payload = {
                provinz_farbe:this.title
            }
            await this.$network.requestPatch("/items/"+this.collection+"/"+person.id, payload);
            this.updateMethod()
            this.selectedCell = -1;
            this.setGroupWidth();
        },
        getPersonSearchText(item){
            return item.vorname + " " + item.nachname;
        },
        selectEmployeeCell(){
            this.employeeCellSelected = true;
            this.$nextTick(()=>{
                this.$nextTick(()=>{
                    this.$refs.employeeCell.focus();
                    this.$refs.employeeCell.activateMenu();
                })
            })
        },
        selectCell(index){
            this.selectedCell = index;
            this.$nextTick(()=>{
                this.$nextTick(()=>{
                    this.$refs.cell[0].focus();
                    this.$refs.cell[0].activateMenu();
                })
            })
        },
        onMove(element){
            this.isMoving = true;
            for(let comp of this.hoveredComponents){
                comp.isHovered = false;
            }
            let fromComponent = element.from.__vue__.$parent; 
            let toComponent = element.to.__vue__.$parent; 
            if(this.hoveredComponents.indexOf(toComponent) === -1){
                this.hoveredComponents.push(toComponent);
            }
            fromComponent.isHovered = false;
            toComponent.isHovered = true;
            toComponent.$forceUpdate()
        },
        async onEnd(element){
            let vueComponent = element.to.__vue__.$parent
            let newProvinzColor = vueComponent.title;
            vueComponent.isHovered = false;
            this.isMoving = false;
            this.isHovered = false;

            let item = vueComponent.data[element.newIndex];
            if(!item){
                item = vueComponent.data[0];
            }
            this.setGroupWidth();
            vueComponent.setGroupWidth();
            let payload={
                provinz_farbe:newProvinzColor
            }
            await this.$network.requestPatch("/items/"+this.collection+"/"+item.id,payload);
            this.updateMethod();
            this.selectedCell = -1;
        },
        getAge(birthday){
            let today = dayjs();
            let bday = dayjs(birthday);
            return Math.floor(today.diff(bday, 'year', true));
        },
        setGroupWidth(){
            if(this.$refs.nameContainer && this.$refs.nameContainer[0]){
                this.width = 0;
                this.$forceUpdate();
                this.$nextTick(()=>{
                    if(this.$refs.nameContainer && this.$refs.nameContainer[0]){
                        // this.width = this.$refs.nameContainer[0].clientWidth*0.95;
                        this.width = this.$refs.nameContainer[0].clientWidth*0.8;
                    }
                    this.$forceUpdate()
                })
            }
        },
        getWidth(){
            return "width:"+this.width.toString()+"px;"
        },
        getRemainingSlots(){
            var length = 0
            if(this.data.length){
                length = this.data.length;
            }
            let totalSlots = this.totalSlots;
            if(this.isHovered){
                totalSlots -= 1;
            }
            if(this.isMoving){
                totalSlots += 1;
            }
            return Math.max(0,totalSlots - length);
        }
    }
}
</script>

<style> 
    .capitalize {
        text-transform: capitalize;
    }
    .username {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        /* border:1px solid red; */
    }
    .hovered{
        border:2px solid red;
    }
    .not-hovered{
        border:2px solid transparent;
    }
</style>