<template>
    <popup-dialog-base
        ref="dialog"
        title="Neuen Camp-Datensatz erstellen"
        :toolButtons="buttons"
    >
        <template v-slot:button="{dialog}">
            <v-btn @click="showDialog(dialog)" class="ml-1" icon><v-icon>mdi-plus</v-icon></v-btn>
        </template>

        <template v-slot:content>
            <v-card-text
                v-if="loading"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            indeterminate
                        >

                        </v-progress-circular>
                        <h3><v-icon>mdi-progress-wrench</v-icon>Campdaten werden erstellt.</h3>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text
                v-else
            >   
                <v-expansion-panels
                    v-model="campPanel"
                    accordion
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <h3>Jahr</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-text-field
                                hide-details
                                label="Jahr"
                                solo
                                dense
                                type="number"
                                v-model="campData.year"
                                @input="onYearChange()"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                        v-for="(camp, index) of campData.camps" :key="index"
                    >
                        <v-expansion-panel-header><h3>{{camp.name.value}}</h3></v-expansion-panel-header>
                        <v-expansion-panel-content>
                        <camp-data-interface 
                            :value="camp"
                        />
                                </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </template>
    </popup-dialog-base>
</template>

<script>
import PopupDialogBase from '../PopupDialogBase.vue'
import dayjs from 'dayjs'
import CampDataInterface from './CampDataInterface.vue'
export default {
    components: { 
        PopupDialogBase,
        CampDataInterface },
    mounted(){
    },
    props:{
        dashboard:Object
    },
    data(){
        return{
            loading:false,
            created:false,
            camp:{
                name:{
                    key:"Freizeit-Name",
                    value:"E1",
                    type:"text"
                },
                datum_von:{
                    key:"Freizeit-Datum Von",
                    value:"",
                    type:"date"
                },
                datum_bis:{
                    key:"Freizeit-Datum Bis",
                    value:"",
                    type:"date"
                },
                alter_von:{
                    key:"Teilnehmer-Alter Von",
                    value:"",
                    type:"date"
                },
                alter_bis:{
                    key:"Teilnehmer-Alter Bis",
                    value:"",
                    type:"date"
                },
                anreise_mitarbeiter:{
                    key:"Mitarbeiter Anreisedatum",
                    value:"",
                    type:"date"
                },
                preis:{
                    key:"Preis (€)",
                    value:135,
                    type:"number"
                },
                preis_tasse:{
                    key:"Preis Tasse (€)",
                    value:5,
                    type:"number"
                },
                preis_tshirt:{
                    key:"Preis T-Shirt (€)",
                    value:10,
                    type:"number"
                },
                mitarbeiter_alter_von:{
                    key:"Mitarbeiter-Mindestalter",
                    value:18,
                    type:"number"
                }
            },
            campData:{
                year:undefined,
                camps:[],
            },
            campPanel:0,
            buttons:[
                {
                    label:"Abbrechen", color:"error", icon:"", 
                    method:()=>{this.$refs.dialog.hide()}, 
                    viewCondition:()=>{return true},
                    disabled:()=>{return this.loading}
                },
                {
                    label:"Neue Freizeit", color:"primary", icon:"mdi-plus", 
                    method:()=>{this.newCamp()}, 
                    viewCondition:()=>{return true},
                    disabled:()=>{return this.loading}
                },
                {
                    label:"Zurück", color:"primary", icon:"mdi-arrow-left", 
                    method:()=>{this.campPanel -= 1}, 
                    viewCondition:()=>{return this.campPanel > 0},
                    disabled:()=>{return this.loading}
                },
                {
                    label:"Weiter", color:"primary", icon:"mdi-arrow-right", 
                    method:()=>{this.campPanel += 1}, 
                    viewCondition:()=>{return this.campPanel < this.campData.camps.length},
                    disabled:()=>{return this.loading}
                },
                {
                    label:"Erstellen", color:"primary", icon:"mdi-plus", 
                    method:()=>{this.createCamp()}, 
                    viewCondition:()=>{return this.campPanel === this.campData.camps.length || this.campPanel === undefined},
                    disabled:()=>{return this.loading}
                }
            ]
        }
    },
    methods:{
        onYearChange(){
            this.campData.camps[0].datum_von.value = dayjs().year(this.campData.year).format("YYYY-MM-DD")
            this.campData.camps[0].datum_bis.value = dayjs().year(this.campData.year).format("YYYY-MM-DD")
            this.campData.camps[1].datum_von.value = dayjs().year(this.campData.year).format("YYYY-MM-DD")
            this.campData.camps[1].datum_bis.value = dayjs().year(this.campData.year).format("YYYY-MM-DD")
            this.campData.camps[2].datum_von.value = dayjs().year(this.campData.year).format("YYYY-MM-DD")
            this.campData.camps[2].datum_bis.value = dayjs().year(this.campData.year).format("YYYY-MM-DD")
            this.campData.camps[3].datum_von.value = dayjs().year(this.campData.year).format("YYYY-MM-DD")
            this.campData.camps[3].datum_bis.value = dayjs().year(this.campData.year).format("YYYY-MM-DD")

            this.campData.camps[0].alter_von.value = dayjs().year(this.campData.year-13).month(3).date(1).format("YYYY-MM-DD")
            this.campData.camps[0].alter_bis.value = dayjs().year(this.campData.year-10).month(2).date(31).format("YYYY-MM-DD")

            this.campData.camps[1].alter_von.value = dayjs().year(this.campData.year-16).month(3).date(1).format("YYYY-MM-DD")
            this.campData.camps[1].alter_bis.value = dayjs().year(this.campData.year-12).month(2).date(31).format("YYYY-MM-DD")

            this.campData.camps[2].alter_von.value = dayjs().year(this.campData.year-19).month(3).date(1).format("YYYY-MM-DD")
            this.campData.camps[2].alter_bis.value = dayjs().year(this.campData.year-15).month(2).date(31).format("YYYY-MM-DD")

            this.campData.camps[3].alter_von.value = dayjs().year(this.campData.year-19).month(3).date(1).format("YYYY-MM-DD")
            this.campData.camps[3].alter_bis.value = dayjs().year(this.campData.year-15).month(2).date(31).format("YYYY-MM-DD")
            
            this.campData.camps[0].anreise_mitarbeiter.value = dayjs().year(this.campData.year).format("YYYY-MM-DD")
            this.campData.camps[1].anreise_mitarbeiter.value = dayjs().year(this.campData.year).format("YYYY-MM-DD")
            this.campData.camps[2].anreise_mitarbeiter.value = dayjs().year(this.campData.year).format("YYYY-MM-DD")
            this.campData.camps[3].anreise_mitarbeiter.value = dayjs().year(this.campData.year).format("YYYY-MM-DD")
        },
        showDialog(dialog){
        dialog.visible = true;

            
        this.campData.camps = [];
        this.campData.year = parseInt(dayjs().format("YYYY"))

        let e1 = JSON.parse(JSON.stringify(this.camp));
        e1.name.value = "E1";
        e1.preis.value = 135;
        e1.mitarbeiter_alter_von.value = 18;
        this.campData.camps.push(e1)

        let e2 = JSON.parse(JSON.stringify(this.camp));
        e2.name.value = "E2";
        e2.preis.value = 140;
        e2.mitarbeiter_alter_von.value = 20;
        this.campData.camps.push(e2)

        let e3 = JSON.parse(JSON.stringify(this.camp));
        e3.name.value = "E3";
        e3.preis.value = 145;
        e3.mitarbeiter_alter_von.value = 22;
        this.campData.camps.push(e3)

        let e4 = JSON.parse(JSON.stringify(this.camp));
        e4.name.value = "E4";
        e4.preis.value = 145;
        e4.mitarbeiter_alter_von.value = 22;
        this.campData.camps.push(e4)

        this.$refs.dialog.setLoading(this.loading);
        this.onYearChange();
        },

        newCamp(){
            let camp = JSON.parse(JSON.stringify(this.camp));
            let count = this.campData.camps.length + 1;
            camp.name.value = "E"+count.toString();
            camp.preis.value = 145;
            camp.mitarbeiter_alter_von.value = 22;
            this.campData.camps.push(camp)
        },

        async createCamp(){
            let campData = {};
            campData.year = this.campData.year;
            campData.camps = [];
            for(let camp of this.campData.camps){
                let campObject = {};
                for(let key in camp){
                    campObject[key] = camp[key].value;
                }
                campData.camps.push(campObject);
            }

            this.loading=true;
            let result = await this.$network.requestPost("/create-camp-data", campData);
            if(result.status === 200){
                this.created = true;
                this.$refs.dialog.showNotification("Neuer Datensatz erfolgreich erstellt.","success","mdi-check-bold")
            }
            this.loading=false;
            this.dashboard.loadCampList();
            this.dashboard.selectCamp(campData.year);
            this.$refs.dialog.hide();
        }
    }
}
</script>