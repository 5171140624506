<template>
    <v-container class="pa-0"
    
        v-if="camp"
    >
        
        <v-alert
            text
            v-if="!registrationIsActive() && hasAdminAccess()"
            border="left"
            type="warning"
            class="ma-2"
        >
            <v-row>
                <v-col>
                    <h3>Anmeldung ist noch nicht aktiviert</h3>
                    <p>
                        Du siehst die Anmeldung weil du als Admin angemeldet bist.
                    </p>
                </v-col>
            </v-row>
        </v-alert>

        <people-form-editor
            ref="formEditor"
            v-if="(registrationIsActive() || hasAdminAccess()) && editData && !registrationSend && !registrationError"
            v-model="editData"
            :peopleManager="peopleManager"
            :backendForm="false"
            :dataValidation="dataValidation"
            :showCheckout="false"
        />

        <v-alert
            text
            v-else
            border="left"
            type="info"
            class="ma-2"
        >
            <v-row>
                <v-col>
                    <h3>Anmeldung nicht aktiv</h3>
                    <p>
                        Du musst dich noch etwas gedulden. Die Anmeldung ist noch nicht aktiviert.
                    </p>
                </v-col>
            </v-row>
        </v-alert>

        <v-alert
            text
            v-if="registrationSend"
            border="left"
            type="success"
            class="ma-2"
        >
            <v-row>
                <v-col>
                    <h3>Danke für deine Anmeldung.</h3>
                    <p>
                        Danke, dass du dich angemeldet hast.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn class="grey lighten-3 grey--text text--darken-4" @click="newRegistration()">
                        <v-icon>mdi-form-select</v-icon> Neue Anmeldung
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </v-container>

</template>

<script>

import PeopleFormEditor from "./PeopleFormEditor.vue"

export default {
    components:{
        PeopleFormEditor
    },
    inject:[
        "app"
    ],
    async mounted(){
        this.year = parseInt(this.app.$router.currentRoute.path.replace(/[^0-9]/g,''));
        let result = await this.$network.requestGet("/items/camp?filter[jahr][_eq]="+this.year.toString());

        if(result.status === 200){
            let campCurrent = result.data.data[0];
            this.camp = campCurrent;
            this.collection = campCurrent.camphelfer_collection_key;
            this.type = "camphelfer"
            this.dataValidation = campCurrent.eingabe_evaluierung
            this.editData = await this.createEmptyDataSet();
        }
        this.siteLoaded = true;
    },
    data(){
        return{
            camp:undefined,
            fieldsLoaded:false,
            peopleManager:this,
            fieldData:[],
            editData:undefined,
            editedId:-1,
            collection:"",
            waitingForServerResponse:false,
            registrationSend:false,
            registrationError:false,
            type:"",
            siteLoaded:false,
            dataValidation:undefined,
            writePermissionFields:["*"],
        }
    },
    methods:{
        hasAdminAccess(){
            return this.app.userData.role && this.app.userData.role.admin_access;
        },
        registrationIsActive(){
            return this.camp.camphelfer_anmeldung_aktiv;
        },
        async createEmptyDataSet(){
            this.fieldData = await this.app.$network.getFieldsData(this.collection, true);
            let editData = {};
            for(let field of this.fieldData){
                if(!field.meta.hidden){
                    if (field.schema){
                        editData[field.field] = field.schema.default_value;
                    }else{
                        editData[field.field] = undefined;
                    }
                }
            }
            return editData;
        },
        cancelEdit(){},
        async newRegistration(){
            this.registrationSend = false;
            this.registrationError = false;
            this.$refs.formEditor.$refs.form.resetValidation();

        },
        async saveEdit(){
            this.editData["registration_summary"] = {
                registration_summary: this.$refs.formEditor.$refs.registrationSummary.getRegistrationSummary(),
            }
            let payload = {
                type: this.type,
                collection: this.collection,
                data: this.editData,
            }
            this.waitingForServerResponse = true;
            let result = await this.$network.requestPost("/register", payload);
            this.waitingForServerResponse = false;
            if (result.status === 200){
                this.registrationSend = true;
                this.editData = await this.createEmptyDataSet();
            }else{
                this.registrationError = true;
            }
        },
    }
}
</script>