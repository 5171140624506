<template>

    <div>
        <v-select 
            v-model="localValue" 
            solo
            hide-details
            dense
            :items="options" 
            @input="onInput"
            item-value="value"
            item-text="key"
        />
    </div>
</template>

<script>
export default {
    props:{
        value:[String, Number, Boolean],
        options:Array
    },
    data(){
        return {
            localValue:this.value,
        }
    },
    watch:{
        value(val){
            this.localValue = val;
        }
    },
    methods:{
        onInput(value){
            this.$emit("input", value);
        }
    }
}
</script>