<template>
    <div class="d-flex flex-row">
        <v-checkbox
            :true-value="true"
            :false-value="false"
            hide-details="auto"
            v-model="localValue"
            @change="changeValue"
            class="mt-0 mb-2"
            :disabled="disabled"
            :rules="rules"
        />
        <div class="mt-1" style="width:100%">
            <slot name="label">
                <div v-if="options && options.files">
                    <display-attachment :fileName="file.label" :id="file.id" v-for="(file, index) in options.files" :key="index"/>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
import DisplayAttachment from '../displays/DisplayAttachment.vue';
export default {
    components:{
        DisplayAttachment
    },
    props:{
        value:Boolean,
        disabled:Boolean,
        rules:Array,
        options:Object
    },
    data(){
        return{
            localValue: this.value,
        }
    },
    watch:{
        value(value){
            this.localValue = value;
        }
    },
    methods:{
        changeValue(value){
            this.$emit("input", value);
        }
    }
    
}
</script>