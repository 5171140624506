<template>
    <v-card>
        <vue-editor 
            v-model="localValue"
            class="editor"
            ref="Vue2Editor"
            :customModules="customModulesForEditor"
            :editorOptions="editorSettings"
            :useCustomImageHandler="true"
            @image-added="uploadImage"
        />
        <file-browser
            ref="GalleryBrowser"
            @onFilesSelected="onFilesSelected"
        />

        <interface-variable-selection
            ref="VariableSelector"
            @onVariableSelected="onVariableSelected"
        />
    </v-card>
</template>

<script>
import {VueEditor, Quill} from 'vue2-editor';
import ImageResize from 'quill-image-resize-vue';
import FileBrowser from '../FileBrowser/FileBrowser.vue';
import InterfaceVariableSelection from './InterfaceVariableSelection.vue';

var icons= Quill.import("ui/icons");
icons['gallery'] = `<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M22,16V4A2,2 0 0,0 20,2H8A2,2 0 0,0 6,4V16A2,2 0 0,0 8,18H20A2,2 0 0,0 22,16M11,12L13.03,14.71L16,11L20,16H8M2,6V20A2,2 0 0,0 4,22H18V20H4V6" /></svg>`;
icons['image'] = `<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M18 15V18H15V20H18V23H20V20H23V18H20V15H18M13.3 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H19C20.1 3 21 3.9 21 5V13.3C20.4 13.1 19.7 13 19 13C17.9 13 16.8 13.3 15.9 13.9L14.5 12L11 16.5L8.5 13.5L5 18H13.1C13 18.3 13 18.7 13 19C13 19.7 13.1 20.4 13.3 21Z" /></svg>`;
icons['variable'] = `<svg style="width:24px;height:24px" viewBox="0 0 24 24"><title>code-braces-box</title><path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M11 8H9V10C9 11.1 8.1 12 7 12C8.1 12 9 12.9 9 14V16H11V18H9C7.9 18 7 17.1 7 16V15C7 13.9 6.1 13 5 13V11C6.1 11 7 10.1 7 9V8C7 6.9 7.9 6 9 6H11V8M19 13C17.9 13 17 13.9 17 15V16C17 17.1 16.1 18 15 18H13V16H15V14C15 12.9 15.9 12 17 12C15.9 12 15 11.1 15 10V8H13V6H15C16.1 6 17 6.9 17 8V9C17 10.1 17.9 11 19 11V13Z" /></svg>`
export default {
    components:{
        VueEditor,
        FileBrowser,
        InterfaceVariableSelection
    },
    props:{
        value:String,
    },
    inject:[
        'app'
    ],
    mounted(){
        this.quillEditor = this.$refs.Vue2Editor.quill;
    },
    data(){
        return{
            localValue:this.value,
            quillEditor:undefined,
            cursorLocation:0,
            customModulesForEditor: [
                { alias: "imageResize", module: ImageResize },
            ],
            editorSettings:{
                modules:{
                    imageResize: {},
                    toolbar:{
                        container:[
                            [{header: [false, 1, 2, 3, 4, 5, 6]}], 
                            ["bold", "italic", "underline", "strike"], // toggled buttons
                            [
                                {align: ""}, 
                                {align: "center"}, 
                                {align: "right"}, 
                                {align: "justify"}], 
                                [
                                    "blockquote",
                                    // "code-block"
                                ], 
                                [
                                    {list: "ordered"}, 
                                    {list: "bullet"}, 
                                    // {list: "check"}
                                ], 
                                [{indent: "-1"}, 
                                {indent: "+1"}
                            ], // outdent/indent
                            [{color: []}, {background: []}], // dropdown with defaults from theme
                            [
                                "link", 
                                // "image", 
                                "gallery",
                                // "video"
                                "variable",
                            ], 
                            ["clean"],
                        ],
                        handlers:{
                            gallery: this.openGallery,
                            variable: this.showVariables
                        }
                    }
                },
            },
        }
    },
    methods:{
        showVariables(){
            this.cursorLocation = this.quillEditor.getSelection().index;
            this.$refs.VariableSelector.show();
        },
        openGallery(){
            this.cursorLocation = this.quillEditor.getSelection().index;
            this.$refs.GalleryBrowser.show();
        },
        onVariableSelected(variableName){
            // this.quillEditor.insertEmbed(this.cursorLocation, 'text', "{{"+variableName+"}}")
            this.quillEditor.insertText(this.cursorLocation, "{{"+variableName+"}}")
            this.quillEditor.getSelection().index += variableName.length + 4;
        },
        onFilesSelected(files){
            for(let file of files){
                this.quillEditor.insertEmbed(this.cursorLocation, 'image', file.path)
                this.quillEditor.setSelection(this.cursorLocation+1, 0);
            }
        },
        async uploadImage(file, editor, cursorLocation){
            const formData = new FormData();
            formData.append('file', file);
            let result = await this.$network.requestPost("/files", formData);
            if (result.status === 200){
                let url = this.app.restUrl + "/assets/"+result.data.data.id
                editor.insertEmbed(cursorLocation, 'image', url);
            }
        }
    },
    watch:{
        value(value){
            this.value = value;
            this.localValue = value || "";
        },
        localValue(value){
            this.localValue = value;
            this.$emit("input", value);
        }
    },
}
</script>

<style>
    .ql-editor, .ql-toolbar.ql-snow, .ql-container.ql-snow{
        border:0px;
    }
    .ql-toolbar.ql-snow{
        border-bottom:1px solid silver;
    }
</style>