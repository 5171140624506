<template>
    <popup-dialog-base
        title='Fehlermeldung'
        ref="dialog"
        :toolButtons="buttons"
    >
    <template v-slot:content>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-textarea
                        readonly
                        solo
                        v-model="errorMessage"
                    >

                    </v-textarea>
                </v-col>
            </v-row>
        </v-card-text>
    </template>

    </popup-dialog-base>
</template>

<script>
import PopupDialogBase from './PopupDialogBase.vue'
export default {
  components: {
      PopupDialogBase 
    },
    data(){
        return{
            errorMessage:"",
            buttons:[
                // {label:"Kopieren", color:"primary", icon:"mdi-content-copy", method:this.copyErrorToClipboard, viewCondition:()=>{return true}},
                {label:"Schließen", color:"primary", icon:"", method:this.hide, viewCondition:()=>{return true}},
            ],
        }
    },
    methods:{
        copyErrorToClipboard(){
            this.errorMessage
        },
        show(message){
            this.errorMessage = message;
            this.$refs.dialog.show()
        },
        hide(){
            this.$refs.dialog.hide();
        }
    }
    
}
</script>