<template>
    <report-card-base
        ref="report"
        :title="'Mitarbeiter - Übersicht'"
        :icon="'mdi-form-select'"
    >
        <template v-slot:header-tools>
            <v-btn
                v-if="editable && dashboard.campDataEditable"
                icon
                x-small
                @click="edit=!edit"
                class="mr-2"
            >
                <v-icon
                    :class="[edit?'success--text':'']"
                >
                    mdi-pencil
                </v-icon>
            </v-btn>

            <export-dialog
                ref="exportDialog"
                :table="getTableString()"
                :fileName="'MitarbeiterÜbersicht'"
            >
                <v-btn
                    icon
                    x-small
                    @click="$refs.exportDialog.show()"
                    class="mr-2"
                >
                    <v-icon
                        :class="[edit?'success--text':'']"
                    >
                        mdi-database-export
                    </v-icon>
                </v-btn>
            </export-dialog>
            
            <v-btn
                icon
                x-small
                @click="printTable()"
            >
                <v-icon>
                    mdi-printer
                </v-icon>
            </v-btn>
        </template>
        <template v-slot:content>
            <v-container fluid class="pt-1">
                <v-row
                    align="center"
                    justify="center"
                    class="pa-2 pt-0"
                >
                    <v-btn-toggle
                        multiple
                        v-model="visibleFreizeiten"
                        v-if="freizeiten"
                    >
                        <v-btn 
                            small
                            v-for="(freizeit, index) in freizeiten" :key="index"
                        >
                            <strong>{{freizeit.name}}</strong>
                        </v-btn>
                    </v-btn-toggle>
                </v-row>
            </v-container>
            <v-simple-table
                ref="dataTable"
                dense
                class="my-table"
                style="border:1px solid silver;"
            >
                <thead>
                    <tr v-if="tableData">
                        <th>
                            
                        </th>
                        <th v-for="(camp, index) in getFilteredTableData()[0].value" :key="index">
                            {{camp.key}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(position, rowIndex) in getFilteredTableData()" :key="rowIndex"
                        :class="rowIndex%2===0 ? 'striped' : ''"
                    >
                        <td width="190px">
                            <strong>{{position.label}}</strong>
                            
                        </td>
                        
                        <td nowrap v-for="(person, colIndex) in position.value" :key="colIndex"
                            :class="person.disabled?'grey lighten-2':''"  
                            style="border-left:1px solid silver; padding-top:6px;"
                        >
                           <!-- {{position.disabled}} -->
                            <v-hover v-slot="{ hover }">
                                <div style="position:relative; width:100%; height:100%;">
                                    <v-tooltip bottom>
                                        <span v-if="person.value">

                                            <p class="ma-0">
                                                <v-icon class="mr-2" size="16" color="white">mdi-email</v-icon>{{person.value.email}}
                                            </p>
                                            <p class="ma-0">
                                                <v-icon class="mr-2" size="16" color="white">mdi-cellphone</v-icon>{{person.value.mobil_telefon}}
                                            </p>
                                            <p class="ma-0">
                                                <span v-if="person.value.gemeinde !== 'andere Gemeinde'">
                                                    <v-icon class="mr-2" size="16" color="white">mdi-church</v-icon>{{person.value.gemeinde}}
                                                </span>
                                                <span v-else>
                                                    <v-icon class="mr-2" size="16" color="white">mdi-church</v-icon>{{person.value.meine_gemeinde}}
                                                </span>
                                            </p>
                                        </span>
                                        
                                        <template v-slot:activator="{ on, attrs }">
                                            <span dark v-bind="attrs" v-on="on">
                                                <span v-if="person.value && !edit">
                                                    <v-icon v-if="person.value.status_person_icon" :class="person.value.status_person_icon.color+'--text'">
                                                        {{person.value.status_person_icon.icon}}
                                                    </v-icon>
                                                    {{person.value.vorname}} {{person.value.nachname}}
                                                </span>

                                                <span v-else-if="edit && !person.disabled">
                                                    <v-btn
                                                        v-show="hover"
                                                        style="position:absolute; right:-10px;"
                                                        v-if="(colIndex !== editedCell.colIndex || rowIndex !== editedCell.rowIndex) && person.value"
                                                        icon
                                                        x-small
                                                        @click="clearPosition(position.value[colIndex], rowIndex, colIndex)"
                                                    >
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                    <v-btn
                                                        v-show="hover"
                                                        style="position:absolute; right:-10px;"
                                                        v-if="(colIndex !== editedCell.colIndex || rowIndex !== editedCell.rowIndex) && person.value === undefined"
                                                        icon
                                                        x-small
                                                        @click="editPosition(rowIndex, colIndex); openAutoComplete()"
                                                    >
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>

                                                    <v-autocomplete 
                                                        ref="autocomplete"
                                                        dense
                                                        hide-details
                                                        v-if="colIndex === editedCell.colIndex && rowIndex === editedCell.rowIndex"
                                                        :items="peopleData"
                                                        v-model="position.value[colIndex].value"
                                                        :item-text="getPersonSearchText"
                                                        :item-value="(item)=>{return item}"
                                                        @change="savePosition(position.value[colIndex], rowIndex, colIndex)"
                                                        @blur="editedCell.rowIndex=undefined;editedCell.colIndex=undefined;"
                                                    >  
                                                        <template v-slot:append>
                                                            <v-btn
                                                                icon
                                                                fab
                                                                x-small
                                                                @click="clearPosition(position.value[colIndex], rowIndex, colIndex)"
                                                            >
                                                                <v-icon>mdi-close</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <template v-slot:item="{item}">
                                                            <v-list class="pa-0 ma-0" dense>
                                                                <v-list-item dense class="ma-0 pa-0">
                                                                    <v-icon>mdi-account</v-icon>{{item.vorname}} {{item.nachname}}
                                                                </v-list-item>
                                                                <span class="ma-0 pa-0" style="font-size:10px;" v-for="(wish, index) in wishPositions" :key="index">
                                                                    <v-list-item 
                                                                        v-if="item[wish.value]"
                                                                        dense style="max-height:10px;"
                                                                        class="ma-0 pa-0"
                                                                    >
                                                                        Wunsch {{(index)%2+1}} {{wish.key}} - {{item[wish.value]}}
                                                                    </v-list-item>
                                                                </span>
                                                            </v-list>
                                                            

                                                        </template>
                                                    </v-autocomplete>
                                                    <span v-else-if="person.value">
                                                        
                                                        <v-icon v-if="person.value.status_person_icon" :class="person.value.status_person_icon.color+'--text'">
                                                            {{person.value.status_person_icon.icon}}
                                                        </v-icon>
                                                        {{person.value.vorname}} {{person.value.nachname}}
                                                    </span>
                                                </span>
                                                
                                            </span>
                                        </template>
                                    </v-tooltip>
                                </div>
                            </v-hover>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </template>
    </report-card-base>
</template>

<script>
import ExportDialog from '../../../components/ExportDialog.vue';
import ReportCardBase from './ReportCardBase.vue'
export default {
    components:{
        ReportCardBase,
        ExportDialog
    },
    inject:[
        "dashboard"
    ],
    async mounted(){
        if(this.data === undefined){
            this.statusPersonDisplayOptions = await this.getStatusOptions();
        }
        this.requestData(true);
        
        if(this.data === undefined){
            if(this.refreshTimer){
                clearInterval(this.refreshTimer);
            } 
            this.refreshTimer = setInterval(()=>{
                this.requestData();
            },10000);
        }
    },
    beforeDestroy(){
        if(this.refreshTimer){
            clearInterval(this.refreshTimer);
        } 
    },
    props:{
        collection:String,
        year:String,
        data:{type:Object, default:undefined},
        editable:{type:Boolean, default:true}
    },
    data(){
        return {
            visibleFreizeiten:undefined,
            freizeiten:undefined,
            tableData:undefined,
            peopleData:undefined,
            searchInput:["Andreas Esau"],
            edit:false,
            editedCell:{rowIndex:undefined, colIndex:undefined},
            wishPositions:[
                {key:"E1", value:"e1_wunschposition_1"},
                {key:"E1", value:"e1_wunschposition_2"},
                {key:"E2", value:"e2_wunschposition_1"},
                {key:"E2", value:"e2_wunschposition_2"},
                {key:"E3", value:"e3_wunschposition_1"},
                {key:"E3", value:"e4_wunschposition_2"},
            ],
            statusPersonDisplayOptions:[]
        }
    },
    computed:{
        collectionWithYear(){
            return this.collection + this.year;
        },
        visibleFreizeitKeys(){
            let visibleKeys = []
            for(let index of this.visibleFreizeiten){
                visibleKeys.push(this.freizeiten[index].name);
            }
            return visibleKeys;
        }
    },
    methods:{
        getFilteredTableData(){
            let filteredTable = []
            if(this.tableData){
                for(let position of this.tableData){
                    let positionFiltered = JSON.parse(JSON.stringify(position));
                    positionFiltered.value = [];
                    for(let freizeit of position.value){
                        if(this.visibleFreizeitKeys.includes(freizeit.key)){
                            positionFiltered.value.push(freizeit);
                        }
                    }
                    filteredTable.push(positionFiltered);
                }
            }
            return filteredTable;
        },
        getTableString(){
            if(this.$refs.dataTable){
                return this.$refs.dataTable.$el.getElementsByTagName("TABLE")[0];
            }
            return undefined;
        },
        getPersonStatus(status){
            for(let option of this.statusPersonDisplayOptions){
                if (option.name === status){
                    return option;
                }
            }
            return undefined;
        },
        async getStatusOptions(){
            let field = await this.$network.getFieldData(this.collectionWithYear, "status_person");
            let status = field.meta.display_options.status;
            return status
        },
        openAutoComplete(){
            this.$nextTick(()=>{
                this.$nextTick(()=>{
                    this.$refs.autocomplete[0].focus();
                    this.$refs.autocomplete[0].activateMenu();
                })
            })
        },
        getPersonSearchText(item){
            let str = item.vorname + " " + item.nachname;
            if(item.e1_wunschposition_1){
                str += " E1 " + item.e1_wunschposition_1
            }
            if(item.e1_wunschposition_2){
                str += " E1 " + item.e1_wunschposition_2
            }
            if(item.e2_wunschposition_1){
                str += " E2 " + item.e2_wunschposition_1
            }
            if(item.e2_wunschposition_2){
                str += " E2 " + item.e2_wunschposition_2
            }
            if(item.e3_wunschposition_1){
                str += " E3 " + item.e3_wunschposition_1
            }
            if(item.e3_wunschposition_2){
                str += " E3 " + item.e3_wunschposition_2
            }
            return str;
        },
        async clearPosition(item, rowIndex, colIndex){
            this.savePosition(item, rowIndex, colIndex, true)
        },
        async savePosition(item, rowIndex, colIndex, clear=false){
            colIndex
            let person = item.value;
            let camp = item.key;
            let position = this.tableData[rowIndex].key
            let payload = undefined;
            if(camp === "E1"){
                payload={
                    e1_festposition:clear?null:position
                }
            }else if(camp === "E2"){
                payload={
                    e2_festposition:clear?null:position
                }
            }else if(camp === "E3"){
                payload={
                    e3_festposition:clear?null:position
                }
            }
            let result = await this.$network.requestPatch("/items/"+this.collectionWithYear+"/"+person.id, payload);
            if(result.status === 200){
                this.editedCell.rowIndex = undefined;
                this.editedCell.colIndex = undefined;
            }
            this.requestData()
        },
        editPosition(rowIndex, colIndex){
            this.editedCell.rowIndex = rowIndex;
            this.editedCell.colIndex = colIndex;
        },
        async requestData(initialLoad=false){
            if(initialLoad){
                if(this.$refs.report){
                    this.$refs.report.setLoading(true);
                }
            }
            let peopleData = undefined;
            if(this.data === undefined){
                let url = "/items/"+this.collectionWithYear+"?limit=-1"
                url += "&fields=id,vorname,nachname,status_person,e1_festposition,e2_festposition,e3_festposition,geschlecht,email,gemeinde,meine_gemeinde"
                url += ",e1_wunschposition_1,e1_wunschposition_2,e2_wunschposition_1,e2_wunschposition_2,e3_wunschposition_1,e3_wunschposition_2"
                url += ",mobil_telefon,status_person"
                let result = await this.$network.requestGet(url);
                if(result.status === 200){
                    peopleData = result.data.data;
                    this.peopleData = result.data.data;
                }
                url = "/items/camp?fields=freizeit.*&filter[jahr][_eq]="+this.year.toString();
                result = await this.$network.requestGet(url);
                if(result.status === 200){
                    this.freizeiten = result.data.data[0].freizeit;
                }
            }else{
                this.peopleData = this.data.data_people;
                peopleData = this.data.data_people;
                this.freizeiten = this.data.data_camp.freizeit;
            }
            if(peopleData){
                this.tableData = this.extractDisplayData(peopleData, this.freizeiten);
            }
            if(!this.visibleFreizeiten){
                this.visibleFreizeiten = [];
                for(let i=0; i<this.freizeiten.length; i++){
                    this.visibleFreizeiten.push(i)
                }
            }
            if(this.$refs.report){
                this.$refs.report.setLoading(false);
            }
        },
        getGenderName(string, gender){
            if(gender === "male"){
                return string.split("/")[0];
            }else if(gender === "female"){
                return string.split("/")[0] + string.split("/")[1];
            }
        },
        extractDisplayData(peopleData, freizeiten){
            let tableData = []
            peopleData = peopleData.slice(0);
            // Build tableData skeleton with empty values
            for(let camp of freizeiten){
                let campPositions = []
                if(freizeiten[0].mitarbeiter_positionen){
                    
                    for(let i=0; i<freizeiten[0].mitarbeiter_positionen.length; i++){
                        
                        let position = freizeiten[0].mitarbeiter_positionen[i];
                        if(position.count){
                            for(let j=0; j<position.count; j++){
                                let tablePosition = {
                                    key:position.position,
                                    label:position.position,
                                    value:undefined
                                }
                                if(j > 0){tablePosition.label = "";}
                                if(j > camp.mitarbeiter_positionen[i].count-1){tablePosition.disabled=true;}
                                else{tablePosition.disabled=false;}
                                campPositions.push(tablePosition)
                            }
                        }
                        if(position.count_female){
                            for(let j=0; j<position.count_female; j++){
                                let tablePosition = {
                                    key:position.position,
                                    label:this.getGenderName(position.position, "female"),
                                    gender:"weiblich",
                                    value:undefined
                                }
                                if(j > 0){tablePosition.label = "";}
                                if(j > camp.mitarbeiter_positionen[i].count_female-1){tablePosition.disabled=true;}
                                else{tablePosition.disabled=false;}
                                campPositions.push(tablePosition)
                            }
                        }
                        if(position.count_male){
                            for(let j=0; j<position.count_male; j++){
                                let tablePosition = {
                                    key:position.position,
                                    label:this.getGenderName(position.position, "male"),
                                    gender:"männlich",
                                    value:undefined
                                }
                                if(j > 0){tablePosition.label = "";}
                                if(j > camp.mitarbeiter_positionen[i].count_male-1){tablePosition.disabled=true;}
                                else{tablePosition.disabled=false;}
                                campPositions.push(tablePosition)
                            }
                        }
                    }
                }
                tableData.push({key:camp.name, value:campPositions});
            }

            // fill tableData with persons
            let i = -1;
            for(let camp of tableData){
                i++;
                let j = -1
                for(let position of camp.value){
                    j++;
                    for(let person of peopleData){
                        if(["Abgemeldet", "Absage"].includes(person.status_person) === false){
                            if(camp.key === "E1" && person.e1_festposition){
                                if(position.key === person.e1_festposition && (position.gender === person.geschlecht || !position.gender)){
                                    if(tableData[i].value[j].value === undefined){
                                        person.status_person_icon = this.getPersonStatus(person.status_person);
                                        tableData[i].value[j].value = person;
                                        person.e1_festposition = undefined;
                                    }
                                }
                            }
                            if(camp.key === "E2" && person.e2_festposition){
                                if(position.key === person.e2_festposition && (position.gender === person.geschlecht || !position.gender)){
                                    if(tableData[i].value[j].value === undefined){
                                        person.status_person_icon = this.getPersonStatus(person.status_person);
                                        tableData[i].value[j].value = person;
                                        person.e2_festposition = undefined;
                                    }
                                }
                            }
                            if(camp.key === "E3" && person.e3_festposition){
                                if(position.key === person.e3_festposition && (position.gender === person.geschlecht || !position.gender)){
                                    if(tableData[i].value[j].value === undefined){
                                        person.status_person_icon = this.getPersonStatus(person.status_person);
                                        tableData[i].value[j].value = person;
                                        person.e3_festposition = undefined;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // transpose data array for flipped visualization
            let tableDataTransposed = []
            for(let i=0; i<tableData.length; i++){
                let camp = tableData[i]
                for(let j=0; j<camp.value.length; j++){
                    let position = camp.value[j];
                    if(tableDataTransposed[j] === undefined){
                        tableDataTransposed[j] = {
                            key:position.key,
                            label:position.label,
                            value:[]
                        }
                    }
                    tableDataTransposed[j].value.push({
                        key:camp.key,
                        value:position.value,
                        disabled:position.disabled
                    })
                }
            }
            return tableDataTransposed;
        },

        printTable(){
            let table = this.$refs.dataTable.$el.getElementsByTagName("TABLE")[0];
            let str = ""
            str="<body style='-webkit-print-color-adjust: exact;'>"
            str += `<h4 style='margin-bottom:5px; font-family:Arial;'>Espelcamp - Mitarbeiter Übersicht ${this.year}</h4>`
            str += "<table style='page-break-inside: auto; width:100%; font-family:Arial; border:1px solid silver; border-collapse: collapse;'>"
            str += "<thead>"
            str += "<tr>"
            str += "<th>&nbsp</th> <th style='font-size:10px; padding:5px; text-align:left;'>E1</th> <th style='font-size:10px; padding:5px; text-align:left;'>E3</th> <th style=font-size:10px; 'padding:5px; text-align:left;'>E3</th>"
            str += "</tr>"
            str += "</thead>"
            str += "<tbody>"
            for(let i in table.rows){
                let row = table.rows[i];
                if(i%2 === 0){
                    str += "<tr>"
                }else{
                    str += "<tr style='background-color:rgba(0, 0, 0, .03);'>"
                }
                for(let j in row.cells){
                    let cell = row.cells[j];
                    if(j > 0 && cell.innerText !== undefined && i>0){
                        str += "<td height='20px' style='font-size:10px; padding:4px; border:1px solid silver; border-collapse: collapse;'>"
                        str += cell.innerText;
                        str += "</td>"
                    }else if(cell.innerText !== undefined && i>0){
                        str += "<td width='100px' height='20px' style='font-size:10px; padding:4px; border:1px solid silver; border-collapse: collapse; background-color:rgba(0, 0, 0, .05);'>"
                        str += cell.innerText;
                        str += "</td>"
                    }
                }
                str+="</tr>"
            }
            str += "</tbody>"
            str += "</table>"
            str += "</body>"
            str
            let newWin= window.open("");
            newWin.document.write(str);
            newWin.print();
            newWin.close();
        }
    }
}
</script>

<style>
    .striped {
        background-color: rgba(0, 0, 0, .03);
    }
    .capitalize {
        text-transform: capitalize;
    }
    .custom-table{
        border-right:1px solid silver;
    }
    .custom-row{
        border-radius: 0;
        border-bottom:1px solid silver;
    }
    .custom-col{
        border-bottom:1px solid silver;
    }
</style>