<template>
    <div>
        <v-text-field 
            dense 
            solo 
            hide-details
            :disabled="disabled"
            :value="localValue"
            @change="changeValue"
            @input="changeValue"
        />
    </div>
</template>

<script>
export default {
    props:{
        value:{
            type:String,
            default:""
        },
        disabled:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            localValue: this.value || "",
        }
    },
    watch:{
        value(value){
            this.localValue = value || "";
        },
    },
    methods:{
        changeValue(event){
            this.$emit("input", event);
        }
    }
}
</script>