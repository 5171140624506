<template>
    <div>
        
        <v-app-bar
            flat
            style="position:fixed; top:60px; z-index:2;"
            :style="'left:'+getNavBarWidth().toString()+'px;'"
            elevation="3"
            height="60"
            elevate-on-scroll
            class="no-wrap"
        >
                <h1 class="grey--text text--darken-2">
                    <v-icon size="30" class="pb-1">{{ icon }}</v-icon>
                    {{title}}
                </h1>
                <v-spacer></v-spacer>
                <slot name="header-tools">
                    <portal-target name="header-tools" />
                </slot>
        </v-app-bar>

        <v-container
            class="ma-0 pa-0"
            fluid
        >

            <v-row style="height:60px;" />

            <v-row>
                <v-col
                    :class="{'pa-0':$vuetify.breakpoint.xsOnly}"
                >
                    <slot name="content">
                    </slot>
                </v-col>
            </v-row>
        </v-container>
    </div>

</template>

<script>
export default {
    name:"Registrations",
    props:{
        icon:{type:String, default:""},
        title:{type:String, default:"Dashboard Titel"},
        configTitle:{type:String, default:"Einstellungen Titel"},
        showConfig:Boolean,
        app:Object,
        navBar:Object
    },
    data(){
        return {
            showConfigDialog:false,
        }
    },
    methods:{
        getNavBarWidth(){
            if(this.navBar){
                if(this.$vuetify.breakpoint.xsOnly){
                    return 0;
                }
                return this.navBar.getWidth();
            }
            return 0;
        }
    }
}
</script>

<style scoped>
    .gradient{
        background: linear-gradient(90deg, rgb(122, 151, 165) 5%, rgba(38,50,56,1) 70%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .toolbar{
        position:fixed;
        height:60px;
        right:0px;
        top:100px;
        z-index: 1;
    }

    .no-wrap{
        white-space:nowrap;
        overflow-y:hidden;
        overflow-x:auto;
        text-overflow: ellipsis;
    }
</style>