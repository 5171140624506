<template>
    <popup-dialog-base
        ref="Popup"
        v-if="rootFolderId"
        :windowed="windowed"
        :isDialog="isDialog"
        :title="title"
        :iconColor="'gray'"
        :icon="icon"
        :useIconToolButtons="useIconToolButtons"
        :toolButtons="toolButtons"
    >
        <template v-slot:content>
            <v-card-text class="ma-0 pa-0" v-if="currentFolder">
                
                <v-row no-gutters class="mb-2">
                    <v-col>
                        <v-alert light color="grey lighten-3" class="ma-0 pa-1">
                            <div class="d-flex">
                                <div v-for="(breadcrumb, index) in breadcrumbs" :key="index">
                                    <v-btn text class="text-none" style="min-width:0;"
                                        @click="openFolder(breadcrumb)"
                                    >
                                        <v-icon color="grey darken-1" v-if="index === 0">
                                            mdi-home
                                        </v-icon>
                                        <span v-else class="grey--text text--darken-2">
                                            <v-icon>mdi-folder</v-icon>
                                            {{ breadcrumb.name }}
                                        </span>
                                    </v-btn>
                                    <v-icon style="width:10px;" v-if="index < breadcrumbs.length-1" color="grey darken-1">mdi-chevron-right</v-icon>
                                </div>
                            </div>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-container
                    v-if="folders && folders.length > 0"
                >
                    <v-hover
                        v-slot:default="{ hover }"
                        v-for="(folder, index) in folders" :key="index"
                    >
                        <v-row
                            class="row-item"
                            :class="[rowColor(folder, hover), isDropZone(folder)]"
                            @dblclick="openFolder(folder)"
                            @click="selectFile($event, folder)"
                            draggable
                            @dragstart="onDragStart($event, folder)"
                            @drop="onDrop($event, folder)"
                            @dragover="onDragOver($event, folder)"
                            @dragenter.prevent
                        >
                            <!-- <v-icon :style="{'visibility':hover?'visible':'hidden'}">mdi-drag-vertical-variant</v-icon> -->
                            <v-col>
                                <span v-if="(folder.id !== currentFolder.parent)">
                                    <v-icon>mdi-folder</v-icon>
                                    {{folder.name}}
                                    <v-icon
                                        size="20"
                                        v-if="folderIsShared('share', folder)"
                                    >
                                        mdi-share-variant
                                    </v-icon>
                                </span>
                                <span v-else>
                                    <v-icon>mdi-subdirectory-arrow-left</v-icon>
                                </span>
                            </v-col>
                            <div class="d-flex" v-if="folder.id !== currentFolder.parent">
                                <div class="mt-2" v-for="(tool, index) in folderTools" :key="index">
                                    <v-btn v-if="hover || isMobile"
                                        icon @click="folderTool[tool].method(folder)">    
                                        <v-icon >
                                            {{ folderTool[tool].icon }}
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                    </v-hover>
                </v-container>
                <v-container
                    v-if="(files)"
                >
                    <v-hover
                        v-slot:default="{ hover }"
                        v-for="(file, index) in files" :key="index"
                    >
                        <v-row
                            class="row-item"
                            :class="rowColor(file, hover)"
                            draggable
                            @dragstart="onDragStart($event, file)"
                            @dragover="onDragOver($event, file)"
                            
                        >
                            <!-- <v-icon :style="{'visibility':hover?'visible':'hidden'}">mdi-drag-vertical-variant</v-icon> -->
                            <v-col
                                style="overflow-x: hidden;"
                                @click="selectFile($event, file)"
                            >
                                <v-img
                                    v-if="file.id && isImage(file) && thumbnails[file.id] !== undefined"
                                    style="float:left" 
                                    class="mr-2 rounded" 
                                    width="26" height="26" 
                                    :src="thumbnails[file.id]"
                                />
                                <v-icon
                                    v-else-if="isPdf(file)"
                                    class="mr-1"
                                    size="26"
                                    color="pink darken-1"
                                >
                                    mdi-file-pdf-box
                                </v-icon>
                                <v-icon
                                    v-else-if="isAudio(file)"
                                    class="mr-1"
                                    size="26"
                                    color="indigo lighten-1"
                                >
                                    mdi-book-music
                                </v-icon>
                                <v-icon
                                    v-else
                                >
                                    mdi-file
                                </v-icon>
                                {{file.filename_download}}
                                <span class="caption">({{(file.filesize*0.00000095367432).toFixed(2)}} MB)</span>
                            </v-col>
                            
                            <div class="mt-2" v-for="(tool, index) in fileTools" :key="index">
                                <v-btn v-if="hover || isMobile"
                                    icon @click="fileTool[tool].method(file)">    
                                    <v-icon>{{ fileTool[tool].icon }}</v-icon>
                                </v-btn>
                            </div>
                        </v-row>
                    </v-hover>
                </v-container>

                <display-images
                    v-if="images.length > 0"
                    ref="ImageViewer"
                    :show-thumb-nails="false"
                    :images="images"
                    :email="email"
                    :password="password"
                    :year="year"
                />

                <popup-dialog-base
                    ref="PdfViewer"
                    :title="pdfViewerTitle"
                    :tool-buttons="[]"
                    :fullscreen="false"
                >

                    <template v-slot:content>
                        <iframe style="margin:0; padding:0; border:0px; width:100%; height:99%;" v-if="pdfUrl" :src="pdfUrl"></iframe>
                    </template>
                </popup-dialog-base>
            </v-card-text>
            
            <confirm-dialog
                ref="deleteFile"
                title="Löschen"
                iconColor="error--text"
                icon="mdi-alert"
                text="Datei wird unwiderruflich gelöscht."
                @onConfirm="deleteFile"
            />
            <confirm-dialog
                ref="deleteFolder"
                title="Löschen"
                iconColor="error--text"
                icon="mdi-alert"
                text="Ordner und Inhalt werden unwiderruflich gelöscht."
                @onConfirm="deleteFolder"
            />
            <confirm-dialog
                ref="createFolder"
                :textInput="true"
                icon="mdi-folder-plus"
                title="Ordner erstellen"
                text="Ordner Name eingeben"
                @onConfirm="createFolder"
            >
            </confirm-dialog>
        </template>

    </popup-dialog-base>
</template>

<script>
import PopupDialogBase from '../PopupDialogBase.vue'
import DisplayImages from '../displays/DisplayImages.vue'
import ConfirmDialog from '../ConfirmDialog.vue'

export default {
    components:{
        PopupDialogBase,
        DisplayImages,
        ConfirmDialog
    },
    inject:[
        "app"
    ],
    props:{
        windowed:{type:Boolean, default:true},
        selectable:{type:Boolean, default:true},
        isDialog:{type:Boolean, default:true},
        title:{type:String, default:'Datei-Browser'},
        icon:{type:String, default:"mdi-file-multiple"},
        rootFolderId:{type:String, default:undefined},
        showShareButton:{type:Boolean, default:false},
        fileExtensions:{type:Array, default:()=>{return[".png",".jpg",".jpeg",".webp",".gif"]}},
        useIconToolButtons:{type:Boolean, default:false},
        showToolButtons:{type:Boolean, default:true},
        customToolButtons:{type:Array, default:()=>[]},
        fileTools:{type:Array, default:()=>{return ["view","download","share","delete"]}},
        folderTools:{type:Array, default:()=>{return ["share","delete"]}},
        email:{type:String},
        password:{type:String},
        year:{type:Number},
    },
    data(){
        return{
            defaultToolButtons:[
                {
                    label:"", 
                    color:"error", 
                    icon:"mdi-delete", 
                    method:()=>{this.onFileDelete()}, 
                    viewCondition:()=>{return this.selectedFiles.length > 0 && this.showToolButtons},
                    disabled:()=>{return false}
                },
                {
                    label:"", 
                    color:"", 
                    icon:"mdi-folder-plus", 
                    method:()=>{this.$refs.createFolder.show()}, 
                    viewCondition:()=>{return this.showToolButtons},
                    disabled:()=>{return false}
                },
                {
                    label:"", 
                    color:"", 
                    icon:"mdi-upload", 
                    method:()=>{this.onFileUpload()}, 
                    viewCondition:()=>{return this.showToolButtons},
                    disabled:()=>{return false}
                },
            ],
            rootFolder:undefined,
            currentFolder:undefined,
            folders:[],
            files:[],
            selectedFiles:[],
            selectedFolders:[],
            thumbnails:{},
            images:[],
            folderPath:"",
            breadcrumbs:[],
            sharedFolders:undefined,
            fileTool:{
                "download":{icon:"mdi-file-download", method:(file)=>{this.downloadFile(file)}},
                "share":{icon:"mdi-share-variant", method:()=>{}},
                "delete":{icon:"mdi-delete", method:(file)=>{this.$refs.deleteFile.show(file)}},
                "view":{icon:"mdi-eye", method:(file)=>{this.showFile(file)}},
            },
            folderTool:{
                "delete":{icon:"mdi-delete", method:(folder)=>{this.$refs.deleteFolder.show(folder);}},
                "share":{icon:"mdi-share-variant", method:(folder)=>{this.toggleShareFolder(folder)}},
            },
            pdfUrl:undefined,
            pdfViewerTitle:"",
            dropElement:undefined,
            dragElement:undefined,
        }
    },
    async created(){
        if(!this.isDialog && this.rootFolderId){
            await this.requestRootFolder();
            this.openFolder(this.rootFolder);
        }
        let options = await this.getDokumenteMetaOptions();
        this.sharedFolders = options.shared_folders;
    },
    computed:{
        isMobile(){
            return this.$vuetify.isMobile;
        },
        toolButtons(){
            let btns = []
            return btns.concat(...this.defaultToolButtons).concat(...this.customToolButtons)
        }
    },
    methods:{
        onDragOver(event, item){
            event.preventDefault();
            this.dropElement = item;
        },
        onDragStart(event, item){
            event;
            this.dragElement = item;
            if(this.selectedFiles.indexOf(item) === -1){   
                this.selectedFiles.push(item);
            }
        },
        onDrop(event, item){
            this.dropElement = item;
            event;
            if(this.dragElement !== this.dropElement){
                for(let file of this.selectedFiles){
                    if(file.folder){
                        const payload = {
                            folder:this.dropElement.id
                        }
                        this.$network.requestPatch("/files/"+file.id, payload);
                        let idx = this.files.indexOf(file);
                        if(idx !== -1){
                            this.files.splice(idx, 1);
                        }
                    }else if(file.id !== this.dropElement.id){
                        const payload = {
                            parent:this.dropElement.id
                        }
                        this.$network.requestPatch("/folders/"+file.id, payload);
                        let idx = this.folders.indexOf(file);
                        if(idx !== -1){
                            this.folders.splice(idx, 1);
                        }
                    }
                }
                this.selectedFiles = [];
            }
            this.dragElement = undefined;
            this.dropElement = undefined;
        },
        folderIsShared(toolName, folder){
            if(toolName === "share"){
                if(this.sharedFolders === undefined){
                    return false;
                }else if(this.sharedFolders[this.year] === undefined){
                    return false;
                }else if(this.sharedFolders[this.year].indexOf(folder.id) !== -1){
                    return true;
                }
            }
            return false;
        },
        async getDokumenteMetaOptions(){
            let result = await this.$network.requestGet("/fields/camp/dokumente");
            if(result.status === 200){
                return result.data.data.meta.options;
            }
            return
        },
        async toggleShareFolder(folder){
            let options = await this.getDokumenteMetaOptions();
            let sharedFolders = options.shared_folders;
            let rootFolder = options.root_folder;
            let maxSize = options.max_size;

            if(sharedFolders){

                if(sharedFolders === null){
                    sharedFolders = {}
                }
                const year = this.year.toString();
                if(sharedFolders[year] === undefined){
                    sharedFolders[year] = []
                }
                const idx = sharedFolders[year].indexOf(folder.id);
                if( idx !== -1){
                    sharedFolders[year].splice(idx, 1);
                }else{
                    sharedFolders[year] = [folder.id];
                }
                let payload = {
                    "meta":{
                        "options":{
                            "root_folder":rootFolder,
                            "shared_folders":sharedFolders,
                            "max_size":maxSize
                        }
                    }
                }
                let result = await this.$network.requestPatch("/fields/camp/dokumente", payload);
                if(result.status === 200){
                    this.sharedFolders = sharedFolders;
                }
            }
        },
        rowFolderColor(folder, isHovering){
            if(this.isFolderSelected(folder)){
                return 'primary lighten-5';
            }else if(isHovering){
                return "grey lighten-3";
            }
            return "";
        },
        rowColor(file, isHovering){
            if(this.isSelected(file)){
                return 'primary lighten-5';
            }else if(isHovering){
                return "grey lighten-3";
            }
            return "";
        },
        isDropZone(item){
            if(item === this.dropElement){
                return "indigo lighten-5";
            }else{
                return "";
            }
        },
        async getBreadcrumbs(folder){
            let breadcrumbs = [folder];
            let currentFolder = folder;
            while(currentFolder.parent && currentFolder.id !== this.rootFolder.id){
                currentFolder = await this.requestFolder(currentFolder.parent);
                breadcrumbs.unshift(currentFolder);
            }
            return breadcrumbs;
        },
        async openFolder(folder){
            this.selectedFiles = [];
            this.retrieveFilesInFolder(folder);
            this.breadcrumbs = await this.getBreadcrumbs(folder);
            
        },
        selectFile(event, file){
            if(this.selectable){
                if(event.shiftKey){
                    if(this.selectedFiles.includes(file)){
                        let index = this.selectedFiles.indexOf(file)
                        if(index > -1){
                            this.selectedFiles.splice(index, 1);
                        }
                    }else{
                        this.selectedFiles.push(file);
                    }
                }else{
                    if(this.selectedFiles.includes(file)){
                        this.selectedFiles = [];
                    }else{
                        this.selectedFiles = [file];
                    }
                }
            }
        },
        async retrieveFilesInFolder(folder){
            // get folders
            let folders = await this.requestFolders(folder);
            let parentFolder;
            if(folder !== this.rootFolder && folder.parent){
                parentFolder = await this.requestFolder(folder.parent);
            }


            // get files
            let allFiles = await this.requestFiles(folder);
            let files = []
            this.images = [];
            this.$nextTick(async ()=>{
                for(let file of allFiles){
                    if (this.fileExtensions.includes("."+file.filename_download.split(".")[1])){
                        files.push(file);
                    }
                    if(this.isImage(file) && this.thumbnails[file.id] === undefined){
                        let thumb = await this.requestAsset(file, 40, 40, 10)
                        this.thumbnails[file.id] = `data:${file.type};base64,${thumb}`
                    }if(this.isImage(file)){
                        this.images.push(file);
                    }
                }
                this.files = files;
            })

            // assign folders
            this.folders = folders;
            if(parentFolder){
                this.folders.unshift(parentFolder);
            }
            this.currentFolder = folder;

        },
        async requestRootFolder(){
            let result;
            if(this.email && this.password){
                let payload = {
                    email:this.email,
                    password:this.password,
                    year:this.year,
                }
                if(this.rootFolderId !== undefined){
                    payload["arguments"] = "?filter[id][_eq]="+this.rootFolderId;
                }
                result = await this.$network.requestPost("/userdata/folders", payload)
            }else{
                let url = "/folders"
                if(this.rootFolderId !== undefined){
                    url += "?filter[id][_eq]=" + this.rootFolderId;
                }
                result = await this.$network.requestGet(url)
            }
            
            if (result.status === 200){
                this.rootFolder = result.data.data[0];
                this.currentFolder = this.rootFolder;
            }
            if(this.rootFolder){
                await this.retrieveFilesInFolder(this.currentFolder);
            }
        },
        async requestFolder(folderId){
            let result;
            if(this.email && this.password){
                const payload = {
                    email:this.email,
                    password:this.password,
                    year:this.year,
                    arguments:"/"+folderId
                }
                result = await this.$network.requestPost("/userdata/folders", payload)
            }else{
                result = await this.$network.requestGet("/folders/"+folderId);
            }
            return result.data.data;
        },
        async requestFolders(folder){
            let result;
            if(this.email && this.password){
                const payload = {
                    email:this.email,
                    password:this.password,
                    year:this.year,
                    arguments:"?filter[parent][_eq]="+folder.id+"&limit=-1"
                }
                result = await this.$network.requestPost("/userdata/folders", payload)
            }else{
                let url = "/folders?filter[parent][_eq]="+folder.id;
                url += "&limit=-1";
                result = await this.$network.requestGet(url);
            }
            return result.data.data;
        },
        async requestFiles(folder){
            let result;
            if(this.email && this.password){
                const payload = {
                    email:this.email,
                    password:this.password,
                    year:this.year,
                    arguments:"?filter[folder][_eq]="+folder.id+"&limit=-1&sort=filename_download"
                }
                result = await this.$network.requestPost("/userdata/files", payload);
            }else{
                let url = "/files?filter[folder][_eq]="+folder.id;
                url += "&limit=-1&sort=filename_download";
                result = await this.$network.requestGet(url);
            }
            return result.data.data
        },
        async requestAsset(file,width, height, quality){
            let result;
            if(this.email && this.password){
                let args = ""
                if(width) args += `?width=${width}`;
                if(height) args += `&height=${height}`;
                if(quality) args += `&quality=${quality}`;
                const payload = {
                    email:this.email,
                    password:this.password,
                    year:this.year,
                    id:file.id,
                    responseType:"arraybuffer",
                    arguments:args
                }
                result = await this.$network.requestPost("/userdata/assets", payload)
            }else{
                let url = '/assets/'+file.id;
                if(width) url += `?width=${width}`;
                if(height) url += `&height=${height}`;
                if(quality) url += `&quality=${quality}`;
                result = await this.$network.requestAssetAsBase64(url);
            }

            return result.data;
        },
        getExtension(file){
            return file.filename_download.split(".")[1];
        },
        isSelected(file){
            return this.selectedFiles.includes(file);
        },
        isFolderSelected(folder){
            return this.selectedFolders.includes(folder);
        },
        isImage(file){
            let ext = this.getExtension(file);
            if (ext){
                if (["png", "jpg", "jpeg", "gif", "bmp", "tif", "tiff", "webp"].includes(ext.toLowerCase())){
                    return true
                }
            }
            return false;
        },
        isAudio(file){
            
            let ext = this.getExtension(file);
            if (ext){
                if(["mp3"].includes(ext.toLowerCase())){
                    return true
                }
            }
            return false;
        },
        isPdf(file){
            let ext = this.getExtension(file);
            if (ext){
                if(["pdf"].includes(ext.toLowerCase())){
                    return true
                }
            }
            return false;
        },
        show(){
            this.selectedFiles = [];
            this.selectedFolders = [];
            this.$refs.Popup.show();
            if(this.rootFolderId){
                this.requestRootFolder();
            }
        },
        hide(){
            this.$refs.Popup.hide();
        },
        async createFolder(name){
            const payload = {
                name:name,
                parent:this.currentFolder.id
            }
            let result = await this.$network.requestPost("/folders",payload);
            if(result.status === 200){
                this.retrieveFilesInFolder(this.currentFolder)
            }
        },
        async onFileUpload(){
            let input = document.createElement('input');
            input.type = 'file';
            input.accept = this.fileExtensions;
            input.multiple = true;
            input.onchange = async()=>{
                this.$refs.Popup.setLoading(true);
                for(let file of input.files){
                    const formData = new FormData();
                    formData.append('file', file)
                    let result = await this.$network.requestPost("/files", formData)
                    if(result.status === 200){
                        let imageId = result.data.data.id;
                        let payload = {
                            keys:[imageId],
                            data:{
                                folder:this.currentFolder.id
                            }
                        }
                        await this.$network.requestPatch("/files",payload);
                    }
                }

                await this.$nextTick(async()=>{
                    await this.retrieveFilesInFolder(this.currentFolder);
                    this.$refs.Popup.setLoading(false);
                })
            };
            input.click();

        },
        onFileSelect(){
            var filePathes = [];
            for(let file of this.selectedFiles){
                var filePath = this.app.restUrl + "/assets/"+file.id
                filePathes.push({
                    name:file.filename_download,
                    path:filePath
                })
            }
            this.$emit("onFilesSelected", filePathes);
            this.hide();
        },
        async onFileDelete(){
            for(let file of this.selectedFiles){
                await this.$network.requestDelete("/files",{data:[file.id]})
            }
            this.retrieveFilesInFolder(this.currentFolder);
        },
        async deleteFile(file, updateFolder=true){
            await this.$network.requestDelete("/files",{data:[file.id]})
            if(updateFolder){
                this.retrieveFilesInFolder(this.currentFolder);
            }
        },
        async deleteFolder(folder, updateFolder=true){
            let result = await this.$network.requestGet("/files?filter[folder][_eq]="+folder.id);
            if(result.status === 200){
                for(let file of result.data.data){
                    await this.deleteFile(file, false);
                }
            }
            await this.$network.requestDelete("/folders",{data:[folder.id]});
            
            if(updateFolder){
                this.retrieveFilesInFolder(this.currentFolder);
            }
        },
        async showFile(file){
            if(file.type === "application/pdf"){
                this.showPdf(file);
            }else{
                this.showImage(file);
            }
        },
        async showImage(file){
            this.$refs.ImageViewer.showImage(file.id, file.filename_download)
        },
        async showPdf(file){
            this.pdfViewerTitle = file.filename_download;

            this.$refs.PdfViewer.show();
            this.$nextTick(async ()=>{
                
                let result;
                this.currentPage = 1;
                if(this.email && this.password){
                    const payload = {
                        email:this.email,
                        password:this.password,
                        year:this.year,
                        id:file.id,
                        responseType:"arraybuffer"
                    }
                    result = await this.$network.requestPost("/userdata/assets", payload, true);
                }else{
                    let url = '/assets/'+file.id;
                    result = await this.$network.requestAssetAsBase64(url);
                }
                if(result.status === 200){
                    this.pdfUrl = "data:"+file.type+";base64,"+result.data;
                }
            })
        },
        async downloadFile(file){
            let fileBase64 = await this.requestAsset(file);
            const base64Data = "data:"+file.type+";base64,"+fileBase64;
            const downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);

            downloadLink.href = base64Data;
            downloadLink.target = '_self';
            downloadLink.download = file.filename_download;
            downloadLink.click(); 
        }

    }
}
</script>


<style scoped>
    .row-item{
        white-space: nowrap;
        border-bottom:1px solid silver;
        cursor: default;
        
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    .no-padding{
        min-width:0;
    }
</style>