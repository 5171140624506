<template>
    <v-card
        :loading="loading"
        class="flex grow-1"
    >
        <v-card-subtitle>
            <v-row no-gutters>
                <v-icon class="mr-1">
                    {{icon}}
                </v-icon>
                <span class="mt-1">
                    {{title}}
                </span>
                <v-spacer></v-spacer>
                <slot name="header-tools">
                </slot>
                <v-btn @click="toggleExpand()" icon x-small
                    v-if="showShrinkIcon"
                >
                    <v-icon v-if="expanded">mdi-window-minimize</v-icon>
                    <v-icon v-else>mdi-window-maximize</v-icon>
                </v-btn>
            </v-row>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-expand-transition>
            <v-card-text
                v-show="expanded"
            >
                <v-lazy
                    transition="scroll-y-transition"
                >
                    <div>
                        <slot name="content">
                            Report Content
                        </slot>
                    </div>
                </v-lazy>
            </v-card-text>
        </v-expand-transition>
    </v-card>
</template>

<script>
export default {
    props:{
        title:{type:String, default:"Report Title"},
        icon:{type:String, default:"mdi-view-dashboard"},
        showShrinkIcon:{type:Boolean, default:true},
    },
    data(){
        return{
            loading:false,
            expanded:true,
            reportData:{}
        }
    },
    mounted(){
        if(localStorage.getItem("reports")){
            try{
                this.reportData = JSON.parse(localStorage.getItem("reports"));
                if (this.reportData && this.reportData[this.title]){
                    this.expanded = this.reportData[this.title].expanded;
                }
            }catch(e){
                localStorage.removeItem("reports")
            }
        }
    },
    methods:{
        toggleExpand(){
            this.expanded = !this.expanded;

            if(!this.reportData[this.title]){
                this.reportData[this.title] = {expanded:this.expanded}
            }else{

                this.reportData[this.title].expanded = this.expanded;
            }
            localStorage.setItem("reports", JSON.stringify(this.reportData))
        },
        setLoading(value){
            this.loading = value;
        }
    }
}
</script>