<template>
    <v-container
        v-if="app.userData && app.loggedIn"
    >
        <v-menu transition="scroll-y-transition" offset-y :close-on-content-click="closeOnContentClick">
                <template v-slot:activator="{ on, attrs }"> 
                    <v-btn
                        fab
                        small
                        color="primary"
                        v-bind="attrs"
                        v-on = "on"
                        @click="loadUserData()"
                    >
                        
                        <v-avatar size="40" v-if="app.userData.avatar">
                            <img
                                lazy
                                alt="Avatar"
                                
                                :src="avatarUrl"
                            >
                        </v-avatar>
                        <v-icon v-else>mdi-account</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-if="loggedIn()"
                        @click="closeOnContentClick=false">

                        <v-btn fab x-small class="mr-2" elevation="1">
                            <v-avatar size="30" v-if="app.userData.avatar !== null">
                                <img
                                    alt="Avatar"
                                    :src="avatarUrl"
                                >
                            </v-avatar>
                            <v-icon v-else>mdi-account</v-icon>
                        </v-btn>
                        {{this.userData.first_name}} {{this.userData.last_name}}
                    </v-list-item>
                    <v-divider
                        v-if="loggedIn()"
                    />
                    <div
                        v-for="(item, index) in items" :key="index"
                    >
                        <v-list-item
                            v-if="(loggedIn() && item.loggedIn) || (!loggedIn() && !item.loggedIn)"
                            dense
                            @click="item.function()"
                        >
                            <v-icon>{{ item.icon }}</v-icon><v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-menu>
            
            <popup-dialog-base
                v-if="userData.role"
                :max-width="500"
                ref="SettingsDialog"
                title="Konto Einstellungen"
                :subtitle="userData.role.name"
                :toolButtons="buttons"
            >
                <template v-slot:content>
                    <v-container>
                        <!-- {{userData}} -->
                        
                        <v-row class="pt-4" v-if="userData.avatar">
                            <v-spacer></v-spacer>
                            <v-img     
                                width="10"
                                class="rounded-xl elevation-6"
                                :src="avatarUrl"
                            />
                            <v-btn 
                                style="z-index:0; position:absolute; right:10px; top:80px;"
                                fab
                                color="error"
                                x-small
                                @click="deleteImage"
                            >
                                <v-icon>
                                    mdi-close-thick
                                </v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>

                        </v-row>
                        <v-row
                            v-if="!userData.avatar"
                        >
                            <v-col>
                                <b>Avatar</b>
                                <interface-image-upload
                                    :isSystemCollection="true"
                                    :uploadFolder="'Avatars'"
                                    :collectionName="'users'"
                                    :fieldName="'avatar'"
                                    :itemId="userData.id"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-for="(value, key) in userData" :key="key">
                            <v-col v-if="labels[key] !== undefined" class="pb-1">
                                <b>{{getLabel(key)}}</b>
                                <v-text-field 
                                    solo
                                    hide-details
                                    dense
                                    :label="getLabel(key)" 
                                    v-model="userData[key]" 
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn v-if="!changePassword" @click="changePassword=!changePassword">Passwort ändern</v-btn>
                            </v-col>
                        </v-row>                        
                        <v-row>
                            <v-col v-if="changePassword" class="pb-1">
                                <b>Neues Passwort</b>
                                <v-text-field 
                                    solo
                                    hide-details
                                    dense
                                    :label="'Neues Passwort'"
                                    :type="showPassword ? 'text' : 'password'" 
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showPassword = !showPassword"
                                    v-model="newPassword"
                                />
                            </v-col>
                        </v-row>
                            
                        <v-row>
                            <v-col v-if="changePassword" class="pb-1">
                                <b>Passwort bestätigen</b>
                                <v-text-field 
                                    solo
                                    dense
                                    hide-details
                                    :label="'Passwort bestätigen'"
                                    :type="showPassword ? 'text' : 'password'"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showPassword = !showPassword"
                                    v-model="passwordConfirmed"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-btn class="primary" v-if="changePassword" @click="sendNewPassword()">Ändern</v-btn>
                        </v-row>
                    </v-container>
                </template>

            </popup-dialog-base>
        </v-container>
</template>

<script>
import PopupDialogBase from './PopupDialogBase.vue';
import InterfaceImageUpload from './interfaces/InterfaceImageUpload.vue'

export default {
    name:"UserMenu",
    components:{
        PopupDialogBase,
        InterfaceImageUpload,
    },
    props:{
        app:Object
    },
    async created(){
        
        this.items=[
            {
                title:"Konto Einstellungen",
                icon:"mdi-account-edit",
                loggedIn:true,
                function:()=>{this.showDialog();this.closeOnContentClick = true;}
            },
            {
                title:"Logout",
                icon:"mdi-logout",
                loggedIn:true,
                function:this.logout
            },
            {
                title:"Login",
                icon:"mdi-login",
                loggedIn:false,
                function:this.login
            }
        ]
        this.avatarUrl = await this.getAvatarUrl();
    },
    data(){
        return{
            buttons:[
                {label:"Speichern", color:"primary", icon:"", method:this.saveUserData, viewCondition:()=>{return true}},
            ],
            items:[],
            dialog:false,
            userData:{},
            userAvater:undefined,
            closeOnContentClick:false,
            showPassword:false,
            changePassword:false,
            newPassword:"",
            passwordConfirmed:"",
            avatarUrl:"",
            labels:{
                "first_name":"Vorname",
                "last_name":"Nachname",
                "mobil_telefon":"Mobil Telefon",
                "email":"Email",
            }
        }
    },
    methods:{
        async deleteImage(){
            let payload = {
                avatar:null
            }
            let result = await this.$network.requestDelete("/files/"+this.userData.avatar);

            result = await this.$network.requestPatch("/users/"+this.userData.id, payload)
            if(result.status === 200){
                this.userData.avatar = null;
            }
        },
        loggedIn(){
            return this.app.loggedIn;
        },
        async getAvatarUrl(){
            if(this.app.userData.avatar === null){
                return "";
            }
            let url = "/assets/"+this.app.userData.avatar+"?width=96&height=96"
            let result = await this.$network.requestAssetAsBase64(url);
            let uri = "data:image/png;base64,"+result.data
            return uri;
        },
        login(){
            this.$router.replace({'name':'Login'})
        },
        async logout(){
            this.$network.logoutUser();
        },
        closeDialog(){
            this.dialog=false;
            this.$refs.SettingsDialog.show();
            this.changePassword=false;
        },
        loadUserData(){
            this.userData = JSON.parse(JSON.stringify(this.app.userData));
        },
        showDialog(){
            this.loadUserData();
            this.dialog=true;
            this.$refs.SettingsDialog.show();
            this.changePassword = false;
        },
        async saveUserData(){
            let data = {};
            for (let key in this.labels){
                data[key] = this.userData[key];
            }

            let result = await this.$network.requestPatch("/users/"+this.userData.id, data);
            if(result.status === 200){
                this.showSaveSnackbar = true;
                this.app.userData = JSON.parse(JSON.stringify(this.userData));
                this.app.showNotification("Konfiguration wurde gespeichert.", 2000, "success")

            }
        },
        async sendNewPassword(){
            if(this.newPassword === this.passwordConfirmed && this.newPassword.length >= 4){
                let payload = {"password": this.newPassword}
                await this.$network.requestPatch("/users/"+this.userData.id, payload);
                
                this.app.showNotification("Ihr Passwort wurde erfolgreich geändert.");
                this.changePassword = false;
                this.passwordConfirmed = "";
                this.newPassword = "";
            }else if(this.newPassword !== this.passwordConfirmed){
                this.app.showNotification("Ihr Passwort stimmt nicht überein.");
            }
        },
        getLabel(key){
            if(this.labels[key]){
                return this.labels[key];
            }
            return key;
        }

    }

}
</script>

<style scoped>
.top-row{
    position:absolute; right:0px;
}
</style>