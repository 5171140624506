    <template>
        <report-card-base
            ref="report"
            :title="'Listen'"
            :icon="'mdi-form-select'"
        >
            <template v-slot:header-tools>
            </template>

            <template v-slot:content>
                <!-- {{forms}} -->
                <v-row >
                    <v-col>
                        <v-simple-table
                        >
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>
                                            ID
                                        </th>
                                        <th>
                                            Titel
                                        </th>
                                        <th class="text-right">Aktion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(list, index) in lists" :key="index"
                                        :class="index%2==0?'striped':''"
                                    >
                                        <td>
                                            {{index+1}}
                                        </td>
                                        <td>
                                            {{list.titel}}
                                        </td>
                                        <td class="fixed-col text-right" style="position:sticky; right:0;">
                                            <div class="mt-2" style="position:absolute; top:0; right:0; width:120px;">
                                                
                                                <v-btn
                                                    v-if="hasUpdatePermission"
                                                    fab
                                                    class="mr-2"
                                                    x-small
                                                    color="secondary"
                                                    @click="openTemplateEditor(list)"
                                                >
                                                    <v-icon>mdi-pencil</v-icon>
                                                </v-btn>

                                                <v-btn
                                                    fab
                                                    x-small
                                                    color="primary"
                                                    class="mr-2"
                                                    @click="exportXlsxForm(list.konfiguration, list.titel)"
                                                >
                                                    <v-icon>mdi-microsoft-excel</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    fab
                                                    class="mr-2"
                                                    x-small
                                                    color="primary"
                                                    @click="printForm(list.konfiguration)"
                                                >
                                                    <v-icon>mdi-printer</v-icon>
                                                </v-btn>
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>

                    <popup-dialog-base
                        ref="templateEditor"
                        :title="'Liste - '+editTitle"
                        :tool-buttons="editButtons"
                    >
                        <template v-slot:content>
                            <v-container fluid>
                                <interface-code-editor
                                    v-if="editListData"
                                    v-model="editConfig"
                                />
                            </v-container>
                        </template>
                    </popup-dialog-base>
                </v-row>
            </template>
        </report-card-base>
    </template>

<script>
    import Excel from "exceljs";
    import dayjs from 'dayjs';
    import objectPath from 'object-path'; 
    import ReportCardBase from '../pages/DashboardPages/Reports/ReportCardBase.vue'
    import PopupDialogBase from "./PopupDialogBase.vue";
    import InterfaceCodeEditor from "./interfaces/InterfaceCodeEditor.vue";
    export default {
        components:{
            ReportCardBase,
            PopupDialogBase,
            InterfaceCodeEditor,
        },
        inject:[
            "app"
        ],
        async created(){
            this.requestData();
            this.hasUpdatePermission = await this.$network.hasPermission(this.app.userData.role.id, "/items", "create");
        },
        props:{
            year:String,
            title:String,
        },
        data(){
            return {
                lists:undefined,
                collections:{},
                mitarbeiterCollection:undefined,
                freizeiten:undefined,
                editListData:undefined,
                editConfig:undefined,
                editTitle:"",
                tabListener:false,
                editButtons:[
                    {
                        label:"Speichern", 
                        color:"primary", 
                        icon:"mdi-check", 
                        method:()=>{this.saveEditorTemplate()}, 
                        viewCondition:()=>{return true},
                        disabled:()=>{return false}
                    }
                ],
                hasUpdatePermission:false,
            }
        },
        methods:{
            openTemplateEditor(list){
                this.editTitle = list.titel;
                this.editListData = list;
                this.editConfig = JSON.stringify(list.konfiguration, null, 4);
                this.$refs.templateEditor.show();
            },
            async saveEditorTemplate(){
                try{
                    JSON.parse(this.editConfig);
                    let url = "/items/listen/"+this.editListData.id;
                    let result = await this.$network.requestPatch(url, {konfiguration:this.editConfig});
                    if(result.status === 200){
                        this.$refs.templateEditor.showNotification("Konfiguration wurde erfolgreich gespeichert.","success","mdi-check")
                        await this.requestData()
                    }
                }catch(e){
                    console.log(e)
                    this.$refs.templateEditor.showNotification("JSON-Datei ist nicht valide.","error","mdi-alert-decagram")
                }
            },
            createHeaderHtml(listSetup, position){
                let html = "";
                for(let header of listSetup.header){
                    if(header.position === position){
                        html += `<div style="visibility:${header.visible?'visible':'hidden'};">`
                        html += "<p>"
                        for(let row of header.rows){
                            html += `<table style="border:${row.row_border?1:0}px solid black;">`
                            html += `<tr style='height:30px; border:${row.row_border?1:0}px solid black; '>`
                            for(let el of row.elements){
                                html += `<td style='font-size:${header.font_size?header.font_size:12}pt; border:${row.col_border?1:0}px solid black; text-align:${el.text_align?el.text_align:'left'}; vertical-align:${el.vertical_align?el.vertical_align:'top'};'>`
                                if (el.type === "checkbox"){
                                    html += "<span style='border:2px solid black; width:10px; height:10px; float:left; margin-right:5px;'></span>"
                                }
                                
                                html += el.label;
                                html += "</td>"
                            }
                            html += "</tr>"
                            html += "</table>"
                        }
                        html +="</p>"
                        html += "</div>"
                    }
                }
                return html;
            },

            createTableHeader(table, csv=false){
                let html = "";
                let csvString = "";
                html += `<thead style="height:${table.header_height}px;">`
                for(let col of table.columns){
                    if(col.visible === undefined || col.visible === true){
                        html += `<th style="height:${table.header_height}px;">`
                        html += col.label;
                        html += "</th>"
                        csvString += `"${col.label}",`;
                    }
                }
                html += "</thead>";
                csvString +="\n"
                return csv ? csvString : html;
            },

            insertPageBreak(listSetup, table){
                
                let html = "</table>";
                html += this.createHeaderHtml(listSetup, "bottom");
                html += "<div class='pagebreak'></div>"
                html += this.createHeaderHtml(listSetup, "top");
                
                if(table.width > 0){
                    html += `<table style="width:${table.width}; margin-right:0px; margin-left:auto;">`
                }else{
                    html += `<table style="width:100%;">`
                }
                html += this.createTableHeader(table);
                return html
            },

            async exportXlsxForm(listSetup, filename){
                // request data
                this.freizeiten = await this.getCampData();
                await this.getPersonData(listSetup);

                // create excel workbook
                const workbook = new Excel.Workbook();
                const options = {
                    dateFormats: ['DD/MM/YYYY']
                };
                const worksheet = await workbook.addWorksheet("Seite 1", options);
                
                // Data Table
                let table = listSetup.table;
                let idx = 0;
                for(let i=0; i<table.sets.length; i++){
                    let set = table.sets[i];
                    let collectionName = set.collection + this.year;

                    if (set.reset_index){
                        idx = 0;
                    }

                    // create header
                    let header = [];
                    for(let col of table.columns){
                        if(col.visible === undefined || col.visible === true){
                            header.push(col.label);
                        }
                    }
                    worksheet.addRow(header);

                    // add rows
                    for(let person of this.collections[collectionName]){
                        if(this.passesFilters(person, set.filter)){
                            let row = []
                            idx++;
                            for(let col of table.columns){
                                let val = this.getColumnValue(col, person, idx, set).replace(/[\r\n]/gm, ' ')
                                row.push(val);
                            }
                            worksheet.addRow(row);
                        }
                    }
                    if(i < table.sets.length-1){
                        worksheet.addRow();
                    }
                }

                // auto width columns
                worksheet.columns.forEach(column => {
                    const lengths = column.values.map(v => v.toString().length);
                    const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                    column.width = maxLength;
                });

                // write workbook to buffer and download
                const buffer = await workbook.xlsx.writeBuffer();
                const bufferData = new Uint8Array(buffer);
                const blob = new Blob([bufferData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                let downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = `${filename}.xlsx`;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },

            getPageBreakerValues(person, pageBreaker){
                if(!pageBreaker) return [];

                let pageBreakerValues = [];
                for(let key of pageBreaker){
                    pageBreakerValues.push(person[key])
                }
                return pageBreakerValues
            },

            async printForm(listSetup){
                this.freizeiten = await this.getCampData();
                await this.getPersonData(listSetup);

                let html = `
                
                    <html>
                        <head>
                        <style>
                            @page {
                                size:auto;
                            }
                            @media print {
                                .pagebreak { page-break-before: always; } /* page-break-after works, as well */
                            body{
                                width:100%;
                                height:100%;
                                margin:0px;
                                padding:0px;
                                font-family:Arial;
                            }
                            table{
                                width:100%;
                            }
                            table, tr, td {
                                border-collapse:collapse;
                                border:1px solid black;
                                font-family:Arial;
                                font-size:10px;
                            }
                            td{
                                padding:4px;
                            }
                        </style>
                        </head>
                        <body>
                        <div style="border:1px solid red; visibility:hidden; width:2480px;"></div>
                `
                // Title
                if(listSetup.titel && listSetup.titel !== ""){
                    html += `<h4>${listSetup.titel}</h4>`
                }
                // Header Table
                html += this.createHeaderHtml(listSetup, "top");

                // Data Table
                let table = listSetup.table;
                let idx = 0;
                for(let i=0; i<table.sets.length; i++){
                    let set = table.sets[i];
                    let collectionName = set.collection + this.year;
                    if (set.reset_index){
                        idx = 0;
                    }
                    if(i > 0){
                        html += this.insertPageBreak(listSetup, table);
                        if(set.title && set.pageBreaker === undefined){
                            html += `<h3>${set.title}</h3>`
                        }
                    }else{
                        if(set.title && set.pageBreaker === undefined){
                            html += `<h3>${set.title}</h3>`
                        }
                        if(table.width > 0){
                            html += `<table style="width:${table.width}; margin-right:0px; margin-left:auto;">`
                        }else{
                            html += `<table style="width:100%;">`
                        }
                        html += this.createTableHeader(table);
                    }
                    let x=-1;
                    let pageBreakValuesPrev = [];
                    let pageBreakValuesCurrent = []
                    for(let person of this.collections[collectionName]){
                        if(this.passesFilters(person, set.filter)){
                            x += 1
                            idx += 1;

                            // checks wether adding a pagebreak by pageBreaker elements
                            pageBreakValuesPrev = pageBreakValuesCurrent;
                            pageBreakValuesCurrent = this.getPageBreakerValues(person, set.pageBreaker);
                            let pageBreakerTriggered = JSON.stringify(pageBreakValuesPrev) !== JSON.stringify(pageBreakValuesCurrent); 
                            if(pageBreakerTriggered){
                                x = -1;
                                if (set.reset_index){
                                    idx = 1;
                                }
                                if(pageBreakValuesPrev.length > 0){
                                    html += this.insertPageBreak(listSetup, table);
                                }
                                html += `<h3>${set.title} ${pageBreakValuesCurrent.toString().replace(/,/g," ")}</h3>`
                            }

                            // Page break when max rows per table are reached or pagebreak element occurs
                            if(x >= table.rows_per_page){
                                x = -1;
                                html += this.insertPageBreak(listSetup, table);
                            }
    
                            // add rows
                            html += `<tr style="height:${table.row_height}px; font-size:${table.font_size}pt; background-color:${x%2==0?'#ededed':'#ffffff'};">`
                            for(let col of table.columns){
                                if(col.visible === undefined || col.visible === true){
                                    html += `<td style='min-width:${col.width?col.width:0}px; font-size:${table.font_size}pt;'>`
                                    html += this.getColumnValue(col, person, idx, set);
                                    html += "</td>"
                                }
                            }
                            html += "</tr>"
                        }

                    }

                    // Add extra rows
                    for(let i=0; i<set.extra_rows; i++){
                        x += 1;
                        html += `<tr style="height:${table.row_height}px; font-size:${table.font_size}px; background-color:${x%2==0?'#ededed':'#ffffff'};">`
                        for(let col in table.columns){
                            if(col.visible === undefined || col.visible === true){
                                html += `<td style='min-width:${col.width?col.width:0}px;'></td>`
                            }

                        }
                        html += "</tr>"
                    }
                    html += "</table>"
                }
                
                html += this.createHeaderHtml(listSetup, "bottom");

                html += "</body></html>"
                let newWin= window.open("");
                newWin.document.write(html);
                newWin.print();
                newWin.close();
            },

            getColumnValue(col, person, idx, set){
                let value = "";
                if(col.type === "request"){
                    let addCommaNextTime = false;
                    for(let i=0; i<col.fields.length; i++){
                        let field = col.fields[i];
                        let str = person[field]?person[field]:"";
                        if(str === true){
                            str = "Ja"
                        }else if(str === false){
                            str = "Nein"
                        }
                        if(addCommaNextTime && str !== ""){
                            value += ", "
                        }
                        if(str !== ""){
                            addCommaNextTime = true;
                        }
                        value += str;

                    }
                }else if(col.type === "empty" && col.value){
                    value += col.value.toString();
                }else if(col.type === "increment"){
                    value += idx.toString();
                }else if(col.type === "alter"){
                    value += dayjs().diff(person["geburtstag"], 'years')
                }else if(col.type === "position"){
                    if(set.collection.includes("mitarbeiter")){
                        let leiter = false;
                        for(let freizeitKey in person.mitarbeiter_position){
                            let pos = person.mitarbeiter_position[freizeitKey].position;
                            if(pos && pos.indexOf("leiter") !== -1){
                                leiter = true;
                                break
                            }
                        }
                        if(leiter){
                            value += "L"
                        }else{
                            value += "M"
                        }
                    }
                }else if(col.type === "kosten_tshirt"){
                    value += this.getShirtAmount(person).toString() + " €" ;
                }else if(col.type === "kosten_tassen"){
                    value += this.getCupAmount(person) + " €" ;
                }else if(col.type === "kosten_freizeit"){
                    value += this.getFreizeitAmount(person) + " €" ;
                }

                return value;
            },
            passesFilters(person, filters) {
                // If filters is an object with a single key that isn't _and or _or,
                // wrap it in an object to make it compatible with the old format
                if (typeof filters === 'object' && !Array.isArray(filters) && Object.keys(filters).length === 1 && !filters._and && !filters._or) {
                    filters = { [Object.keys(filters)[0]]: filters[Object.keys(filters)[0]] };
                }

                // Handle _and operator
                if (filters._and) {
                    return filters._and.every(filter => this.passesFilters(person, filter));
                }

                // Handle _or operator
                if (filters._or) {
                    return filters._or.some(filter => this.passesFilters(person, filter));
                }

                // Handle individual field filters (old format and single field new format)
                for (let filterPath in filters) {
                    let personValue = objectPath.get(person, filterPath);
                    let filter = filters[filterPath];

                    // If filter is not an object, assume equality check (backwards compatibility)
                    if (typeof filter !== 'object') {
                        if (personValue !== filter) return false;
                        continue;
                    }

                    for (let filterOperator in filter) {
                        let filterValue = filter[filterOperator];

                        switch (filterOperator) {
                            case "_eq":
                                if (personValue !== filterValue) return false;
                                break;
                            case "_neq":
                                if (personValue === filterValue) return false;
                                break;
                            case "_gt":
                                if (personValue <= filterValue) return false;
                                break;
                            case "_gte":
                                if (personValue < filterValue) return false;
                                break;
                            case "_lt":
                                if (personValue >= filterValue) return false;
                                break;
                            case "_lte":
                                if (personValue > filterValue) return false;
                                break;
                            case "_in":
                                if (!Array.isArray(filterValue) || !filterValue.includes(personValue)) return false;
                                break;
                            case "_nin":
                                if (!Array.isArray(filterValue) || filterValue.includes(personValue)) return false;
                                break;
                            // Add more operators here as needed
                            default:
                                // For backwards compatibility, treat unknown operators as equality
                                if (personValue !== filterValue) return false;
                        }
                    }
                }

                return true;
            },
            async getCampData(){
                let url = "/items/camp";
                url += `?filter={"_and":[{"jahr":{"_eq":"${this.year.toString()}"}}]}`;
                url += "&fields=freizeit.*,tshirt_preis,tasse_preis"
                
                let campResult = await this.$network.requestGet(url)
                return campResult.data.data[0];
            },
            getFreizeitByName(name){
                for(let freizeit of this.freizeiten.freizeit){
                    if(freizeit.name === name){
                        return freizeit;
                    }
                }
                return undefined;
            },
            getShirtAmount(person){
                if(person.tshirt === true){
                    return this.freizeiten.tshirt_preis;
                }
                return 0
            },
            getFreizeitAmount(person){
                return this.getFreizeitByName(person.freizeit).preis;
            },
            getCupAmount(person){
                return this.freizeiten.tasse_preis * person.tassen;
            },
            async getPersonData(listSetup){
                this.collections = {};
                let table = listSetup.table
                // for(let table of listSetup.table){
                    for(let collection of table.collections){
                        let collectionName = collection.name + this.year;
                        // Get main collection
                        let url = "/items/"+collectionName+"?limit=-1";
    
                        // setup sorting
                        url += "&sort="+table.sort.toString();
    
                        // setup which fields need to be requested
                        url +="&fields="
                        let fields = []
                        for(let col of table.columns){
                            for(let field of col.fields){
                                fields.push(field)
                            }
                        }
                        
                        for(let field of collection.fields){
                            if(!fields.includes(field)){
                                fields.push(field);
                            }
                        }
    
                        for(let set of table.sets){
                            for(let field in set.filter){
                                if(!fields.includes(field)){
                                    fields.push(field);
                                }
                            }
                        }
    
                        url += fields.toString();
    
                        // create filters when retrieving data
                        url += '&filter='+JSON.stringify(table.filter).split(" ").join("");

                        let result = await this.$network.requestGet(url);
                        if(result.status === 200){
                            this.collections[collectionName] = result.data.data
                        }
                    }
            },
            async requestData(){
                if(this.$refs.report){
                    this.$refs.report.setLoading(true);
                }
                
                let url = "/items/listen";
                let result = await this.$network.requestGet(url);
                if(result.status === 200){
                    this.lists = result.data.data
                }
                if(this.$refs.report){
                    this.$refs.report.setLoading(false);
                }
            }
        }
    }
    </script>

    <style scoped>
        .striped {
            background-color: rgba(0, 0, 0, .03);
        }
        .capitalize {
            text-transform: capitalize;
        }
    </style>