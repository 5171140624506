<template>

    <div>
        <v-row 
            v-for="(item, index) in localValue" :key="index"
            no-gutters
            class="mb-1"
        >
            <v-col>
                <v-text-field
                    ref="arrayInput"
                    solo
                    dense
                    hide-details
                    v-model="localValue[index]"
                    @input="evalValue()"
                >
                    <template v-slot:append>
                        <v-btn
                            icon
                            @click="clear(index)"
                            :disabled="disabled"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
            
                </v-text-field>
            </v-col>
        </v-row>
        <v-row
            no-gutters
        >
            <v-col>
                <v-btn
                    class="primary"
                    block
                    small
                    :disabled="disabled"
                    @click="addItem()"
                ><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
        </v-row>
    </div>

</template>

<script>
export default {
    props:{
        value:{type:Array, default:()=>{return []}},
        disabled:{type:Boolean, default:false}
    },
    data(){
        return{
            localValue:this.value
        }
    },
    methods:{
        addItem(){
            if(!this.localValue){
                this.localValue = []
            }
            this.localValue.push(null);
            this.$nextTick(()=>{
                let input = this.$refs.arrayInput[this.$refs.arrayInput.length-1];
                input.focus()
                console.log(input)
            })
            
        },
        evalValue(){
            this.$emit("input", this.localValue);

        },
        clear(index){
            this.localValue.splice(index, 1);
            this.$emit("input", this.localValue);
        }
    }
}
</script>