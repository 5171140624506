<template>

    <popup-dialog-base
        ref="QRScanner"
        title="QR-Code Scanner"
        :toolButtons="buttons"
        :max-width="600"
    >
        <template v-slot:button>
            <v-btn
                fab
                x-small
                class="primary mr-2"
                @click="openScanner()"
            >
                <v-icon>
                    mdi-barcode-scan
                </v-icon>
            </v-btn>
        </template>
        <template v-slot:content="{dialog}">
            
            <qrcode-stream
                v-if="showScanner && dialog.visible"
                @decode="onQRDecode"
            />
            <div v-else>
                <div v-if="scannedCode">
                    {{scannedCode}}
                </div>
            </div>
        </template>
    </popup-dialog-base>

</template>

<script>
import PopupDialogBase from './PopupDialogBase.vue';

export default {
    components:{
        PopupDialogBase
    },
    props:{
        inventory:Object
    },
    data(){
        return {
            showScanner:false,
            scannedCode:undefined,
            buttons:[
                {label:"Scannen", color:"primary", icon:"mdi-barcode-scan", method:this.openScanner, viewCondition:()=>{return true}, disabled:()=>{return this.scannedCode===undefined}},
                {label:"Artikel bearbeiten", color:"primary", icon:"mdi-tag", method:this.openInventoryItem, viewCondition:()=>{return true}, disabled:()=>{return this.scannedCode===undefined}},
            ]
        }
    },
    methods:{
        openInventoryItem(){
            for(let item of this.inventory.inventoryItems){
                if(this.scannedCode.includes(item.id)){
                    this.$refs.QRScanner.hide();
                    this.inventory.editInventoryItem(item);
                }
            }
        },
        onQRDecode(decodeString){
            this.scannedCode = decodeString;
            this.showScanner = false;
            this.openInventoryItem();
        },
        openScanner(){
            this.$refs.QRScanner.show()
            this.scannedCode = undefined;
            this.showScanner = true;
        }
    }
}
</script>