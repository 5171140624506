<template>
    <popup-dialog-base
        ref="popup"
        :title="'Collection - '+collection+year"
        :fullscreen="false"
        :tool-buttons="buttons"
    >

        <template v-slot:content>
            <v-container fluid>
                <v-card>
                    <v-card-text>
                        <v-text-field 
                            label="Suche"
                            prepend-inner-icon="mdi-magnify"
                            solo
                            hide-details
                            dense
                            clearable
                            v-model="filter"
                        />
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-title class="mb-0 pb-0">
                        <v-icon>mdi-list-box-outline</v-icon>Felder
                    </v-card-title>
                    <v-card-text>
                        <CFieldContainer 
                            v-if="fieldData"
                            :editor="editor"
                            v-model="fieldData"
                            v-on:orderChanged="saveOrdering"
                        />
                    </v-card-text>
                </v-card>

            </v-container>
        </template>
    </popup-dialog-base>
</template>

<script>
import PopupDialogBase from '../PopupDialogBase.vue';
import CFieldContainer from './interfaces/CFieldContainer.vue';
export default {
    name:"CollectionEditor",
    components:{
        PopupDialogBase,
        CFieldContainer: CFieldContainer
    },
    props:{
        year:{default:"2022", type:String},
        collection:{default:"mitarbeiter_", type:String}
    },
    data(){
        return {
            loading:false,
            fieldDataInitial:undefined,
            fieldData:undefined,
            editor:this,
            filter:"",
            formPermissionInitial:undefined,
            formPermission:undefined,
            buttons:[
                {
                    label:"Schließen", 
                    color:"", 
                    icon:"", 
                    method:()=>{this.hide()}, 
                    viewCondition:()=>{return true},
                    disabled:()=>{return false}
                }
            ]
        }
    },
    created(){
    },
    computed:{
        dataChanged(){
            return false; //JSON.stringify(this.fieldData) !== this.fieldDataInitial;
        }
    },
    methods:{
        test(stuff){
            console.log(stuff)
        },

        async saveOrdering(fieldData){
            let arrayNew = this.buildFlatArray(fieldData.children);
            
            this.fieldData = undefined;
            this.fieldDataInitial = undefined;
            this.$nextTick(()=>{
                this.fieldData = {children:this.buildNestedArray(arrayNew)};
                // this.fieldDataInitial = JSON.stringify(this.fieldData);
                // this.$refs.popup.showNotification("Reihenfolge der Felder wurde geändert.", "success");
            })

            // let arrayInit = this.buildFlatArray(JSON.parse(this.fieldDataInitial).children);
            let payload = [];
            for(let i=0; i<arrayNew.length; i++){
                // let fieldInit = arrayInit[i];
                let fieldNew = arrayNew[i];
                // if(fieldNew.meta.sort !== fieldInit.meta.sort || fieldNew.meta.group !== fieldInit.meta.group){
                    payload.push({
                        field:fieldNew.field,
                        meta:{
                            sort:fieldNew.meta.sort,
                            group:fieldNew.meta.group
                        }
                    })
                // }
            }
            let url = `/fields/${this.collection+this.year}`;
            this.loading = true;
            // this.$refs.popup.setLoading(true);
            let result = await this.$network.requestPatch(url, payload);
            // this.$refs.popup.setLoading(false);
            this.loading = false;
            if(result.status === 200){
                
                this.fieldData = undefined;
                this.fieldDataInitial = undefined;
                this.$nextTick(()=>{
                    this.fieldData = {children:this.buildNestedArray(result.data.data)};
                    this.fieldDataInitial = JSON.stringify(this.fieldData);
                    // this.$refs.popup.showNotification("Reihenfolge der Felder wurde geändert.", "success");
                })
            }
        },
        async show(){
            this.formPermission = undefined;
            this.fieldData = undefined;
            this.filter = "";

            await this.requestPublicPersmission();
            await this.requestCollectionFieldData();
            if(this.formPermission){
                this.$refs.popup.show();
            }
        },
        hide(){
            this.$refs.popup.hide();
        },
        async requestPublicPersmission(){
            const url = `/permissions?filter[collection][_eq]=${this.collection+this.year}&filter[role][_null]=true&filter[action][_eq]=read`;
            let result = await this.$network.requestGet(url);
            if(result.status === 200){
                this.formPermission = result.data.data[result.data.data.length-1];
                this.formPermissionInitial = JSON.stringify(this.formPermission);
            }
        },
        buildFlatArray(nestedArray){
            let flatArray = [];

            function flatten(item, index, parent){
                if(item.field !== undefined && item.field !== null){
                    let field = JSON.parse(JSON.stringify(item.field));
                    field.meta.group = parent;
                    field.meta.sort = index+1;
                    flatArray.push(field);
                }
                if(item.children && item.children.length > 0){
                    let field = JSON.parse(JSON.stringify(item.field));
                    item.children.forEach((item, index) => flatten(item, index, field.field));

                    // for(let j=0; j<item.children.length; j++){
                    //     flatten(item.children[j], j, field.field)
                    // }

                }
            }


            nestedArray.forEach((item, index) => flatten(item, index, null));
            // for(let i=0; i<nestedArray.length; i++){
            //     flatten(nestedArray[i], i, null);
            // }

            return flatArray
        },
        buildNestedArray(flatArray, group = null) {
            const nestedArray = [];

            flatArray.forEach(field => {
                if (field.meta.group === group) {
                    const children = this.buildNestedArray(flatArray, field.field);
                    const newItem = { field: field, children: [] };

                    if (children.length) {
                        newItem.children = children;
                    }

                    nestedArray.push(newItem);
                }
            });

            return nestedArray;
        },
        async requestCollectionFieldData(){
            let url = `/fields/${this.collection+this.year}`
            let result = await this.$network.requestGet(url);
            if(result.status === 200){
                let data = result.data.data;
                data.sort((a,b)=>{
                    if(a.meta.sort && b.meta.sort){
                        if(a.meta.sort > b.meta.sort){
                            return 1;
                        }else{
                            return -1;
                        }
                    }
                    return 0
                })
                this.fieldData = {children:this.buildNestedArray(data)};
                this.fieldDataInitial = JSON.stringify(this.fieldData);
            }
        }
    }
}
</script>