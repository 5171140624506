<template>
    <v-row class="pt-2 pb-2" v-if="loaded">
        <v-col
            class="pb-0 pt-0"
            :lg="parseInt(12/Object.keys(valueLocal).length)"
            :sm="6"
            cols="12"
            v-for="(freizeit, key) in valueLocal" :key="key"
        >
            <h3 v-if="heading">{{key}}</h3>
            <div
                v-if="employeeWish"
            >
                <v-select
                    v-for="(wish, keyWish) in freizeit" :key="keyWish"
    
                    :label="keyWish==='wish1'?'Wunsch 1' : 'Wunsch 2'"
                    solo
                    hide-details="auto"
                    dense
                    class="mb-2"
                    :items="availableOptions[key]"
                    v-model="valueLocal[key][keyWish]"
                    clearable
                />
            </div>
            <div
                v-else-if="groupColor"
            >
                <v-select
                    v-for="(color, keyColor) in freizeit" :key="keyColor"
                    :label="key"
                    solo
                    hide-details="auto"
                    dense
                    class="mb-2"
                    :items="availableOptions[key]"
                    v-model="valueLocal[key][keyColor]"
                    clearable
                />
            </div>
            <div
                v-else-if="finalPosition"
            >
                <v-select
                    v-for="(finalPosition, positionKey) in freizeit" :key="positionKey"
                    :label="key"
                    solo
                    hide-details="auto"
                    dense
                    class="mb-2"
                    :items="availableOptions[key]"
                    v-model="valueLocal[key][positionKey]"
                    clearable
                />
            </div>
        </v-col>
    </v-row>
</template>

<script>

export default {
props:{
    employeeWish:{Boolean, default:false},
    groupColor:{Boolean, default:false},
    finalPosition:{Boolean, default:false},
    backendForm:{Boolean, default:false},
    peopleManager:Object,
    heading:{type:Boolean, default:true},
    value:Object,
    year:Number
},
data(){
    return{
        loaded:false,
        availableOptions:{},
        valueLocal:this.value,
        //employeWish - {E1:{wish1:String, wish2:String}, E2:{wish1:String, wish2:String},...}
        //finalPosition - {E1:{position:String}, E2:{position:String},...}
        //groupColor - {E1:{color:String}, E2:{color:String},...}
        data:{}
    }
},
watch:{
    value(value){
        this.valueLocal = value;
    }
},
async created(){
    if(this.employeeWish){
        let wishes = await this.setupEmployeeWishes();
        this.loaded = true;
        if(!this.value){
            this.$emit("input", wishes);
        }
    }else if(this.groupColor){
        let groupColors = await this.setupGroupColor();
        this.loaded = true;
        if(!this.value){
            this.$emit("input", groupColors);
        }
    }else if(this.finalPosition){
        let finalPosition = await this.setupFinalPosition();
        this.loaded = true;
        if(!this.value){
            this.$emit("input", finalPosition);
        }
    }
},
methods:{
    updateValue(){
        this.$emit("input", this.localValue);
    }, 
    async setupEmployeeWishes(){
        let wishes;
        let result = await this.$network.requestGet("/items/camp?filter[jahr][_eq]="+this.year.toString()+"&fields=freizeit.*")
        if(result.status === 200){
            let freizeiten = result.data.data[0].freizeit;
            for(let freizeit of freizeiten){
                var availablePositions = []
                for(let position of freizeit.mitarbeiter_positionen){
                    if(position.form){
                        availablePositions.push(position.position)
                    }
                }
                if(!wishes){
                    wishes = {};
                }
                this.availableOptions[freizeit.name] = availablePositions;
                wishes[freizeit.name] = {wish1:null, wish2:null}
            }
        }
        return wishes;
    }, 
    async setupFinalPosition(){
        let finalPosition;
        let result = await this.$network.requestGet("/items/camp?filter[jahr][_eq]="+this.year.toString()+"&fields=freizeit.*")
        if(result.status === 200){
            let freizeiten = result.data.data[0].freizeit;
            for(let freizeit of freizeiten){
                var availableOptions = []
                for(let position of freizeit.mitarbeiter_positionen){
                    availableOptions.push(position.position)
                }
                if(!finalPosition){
                    finalPosition = {};
                }
                this.availableOptions[freizeit.name] = availableOptions;
                finalPosition[freizeit.name] = {position:null}
            }
        }
        return finalPosition;
    }, 
    async setupGroupColor(){
        let groupColor;
        let result = await this.$network.requestGet("/items/camp?filter[jahr][_eq]="+this.year.toString()+"&fields=freizeit.*")
        if(result.status === 200){
            let freizeiten = result.data.data[0].freizeit;
            for(let freizeit of freizeiten){
                var availableOptions = []
                for(let provinz of freizeit.provinzen){
                    availableOptions.push(provinz.key)
                }
                if(!groupColor){
                    groupColor = {};
                }
                this.availableOptions[freizeit.name] = availableOptions;
                groupColor[freizeit.name] = {color:null}
            }
        }
        return groupColor;
    }
    
}

}
</script>