<template>
    <div
        v-if="loaded"
    >
        <!-- Header Buttons -->
        <portal to="header-tools">
            <v-row 
                class="pr-3"
                v-if="freizeiten && dataChanged()"
            >
                <v-btn 
                    class="error mr-2" fab x-small elevation="2" @click="restoreFreizeiten()"
                >
                    <v-icon>mdi-close-thick</v-icon>
                </v-btn>
                <v-btn 
                    class="primary mr-2" fab x-small elevation="2" @click="saveCampData()"
                >
                    <v-icon>mdi-check-bold</v-icon>
                </v-btn>
            </v-row>
        </portal>

        <report-card-base
            :title="'Freizeit Einstellungen ' + year"
            icon="mdi-tent"
        >

        <template v-slot:content>
            <v-tabs
                centered
                show-arrows
                style="position:sticky; top:120px; z-index:1;"
                v-model="settingsTab"
            >
                <v-tab :href="'#tab-einstellungen'">
                    Einstellungen
                </v-tab>
                <v-tab :href="'#tab-freizeit-daten'">
                    Freizeit-Daten
                </v-tab>
                <v-tab
                    :href="'#tab-dokumente'"
                >
                    Freizeit-Dokumente
                </v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <v-tabs-items 
                v-model="settingsTab"
                touchless
            >
                <v-tab-item :value="'tab-einstellungen'">
                    <div v-for="(prop, key) in camp" :key="key">
                        <v-row no-gutters v-if="prop.type">
                            <v-col>
                                <h5>{{prop.key}}</h5>
                                <v-switch
                                    v-if="prop.type === 'boolean'"
                                    hide-details
                                    v-model="prop.value"
                                    class="ma-0 pa-0"
                                >
                                </v-switch>
                                
                                <v-text-field
                                    v-else
                                    class="mb-0"
                                    solo
                                    dense
                                    hide-details
                                    :type="prop.type"
                                    :label="prop.key"
                                    v-model="prop.value"
                                    clearable
                                />
                            </v-col>
                        </v-row>
                    </div>
                </v-tab-item>
                <v-tab-item :value="'tab-freizeit-daten'">
                    <div v-if="freizeitDaten">
                        <div v-for="prop, key in freizeitDaten" :key="key">
                            <interface-table-builder v-if="prop.type === 'tabellen-builder'" v-model="freizeitDaten[key].value"/>
                        </div>
                    </div>
                </v-tab-item>
                
                <v-tab-item
                    v-if="documentsMeta && documents"
                    :transition="false"
                    :value="'tab-dokumente'"
                >
                    <file-browser
                        :rootFolderId="documentsMeta.options.root_folder"
                        :selectable="true"
                        :windowed="false"
                        :isDialog="false"
                        :file-tools='["view","download","delete"]'
                        :file-extensions='[".png",".jpg",".jpeg",".webp",".gif",".pdf",".mp3"]'
                        :year="parseInt(year)"
                    />
                </v-tab-item>
            </v-tabs-items>

            <div v-if="settingsTab === 'tab-einstellungen'">

                <v-tabs centered v-model="tab"
                    style="position:sticky; top:120px; z-index:1;"
                    show-arrows
                >
                    <v-tab
                        v-for="(freizeit, index) of freizeiten" :key="index"
                        :href="'#tab-'+freizeit.name.value"
                    >
                        {{freizeit.name.value}}
                    </v-tab>
                </v-tabs>
                <v-divider></v-divider>
                <v-tabs-items
                    v-model="tab"
                    touchless
                >
                    <v-tab-item
                        :transition="false"
                        v-for="(freizeit, index) of freizeiten" :key="index"
                        :value="'tab-' + freizeit.name.value"
                    >
                    <camp-data-interface
                        :campSettings="campSettings"
                        :value="freizeit"
                    />
                    </v-tab-item>
    <!-- 
                    <v-tab-item
                        v-if="documentsMeta && documents"
                        :transition="false"
                        :value="'tab-dokumente'"
                    >
                        <file-browser
                            :rootFolderId="documentsMeta.options.root_folder"
                            :selectable="true"
                            :windowed="false"
                            :isDialog="false"
                            :file-tools='["view","download","delete"]'
                            :file-extensions='[".png",".jpg",".jpeg",".webp",".gif",".pdf",".mp3"]'
                            :year="parseInt(year)"
                        />
                    </v-tab-item> -->
                </v-tabs-items>
                <v-divider class="mt-2 mb-2"></v-divider>
    
    
                
                <popup-dialog-base
                    :title="'Freizeit-Daten Löschen'"
                    :maxWidth="400"
                    :toolButtons="popupButtons"
                    :icon="'mdi-alert'"
                    :icon-color="'error'"
                    ref="dialog"
                >
                    <template v-slot:button>
                        
                        <v-row
                            no-gutters
                        >
                                <v-col cols="12">
                                    
                                    <confirm-dialog
                                        ref="fixFreizeitKosten"
                                        :icon="'mdi-alert'"
                                        :icon-color="'error--text'"
                                        :max-width="400"
                                        title="Freizeit-Kosten-Korrektur"
                                        text="Du weißt was du tust? Das korrigiert die Freizeitkostenbeiträge für alle Anmeldungen!"
                                        :onConfirm="freizeitKostenFix"
                                    />
                                    <v-btn 
                                        block
                                        small 
                                        class="warning mb-2" 
                                        @click="$refs.fixFreizeitKosten.show()"
                                    >
                                        <v-icon>mdi-alert</v-icon>Freizeit-Kosten Fix
                                    </v-btn>
                                    
                                </v-col>
                                <v-col cols="12">
                                    <v-btn
                                        class="error"
                                        block
                                        small
                                        @click="showDeleteCampDialog"
                                    >
                                        <v-icon>mdi-alert</v-icon>
                                        Freizeit-Daten Löschen
                                    </v-btn>
    
                                </v-col>
                            </v-row>
    
                    </template>
    
                    <template v-slot:content>
                        <v-container
                            fluid
                        >
                            <v-row>
                                <v-col
                                    v-if="!loading"
                                >
                                    <!-- <h3> -->
                                        Daten werden unwiderruflich gelöscht. Zum löschen Passwort eingeben und bestätigen.
                                    <!-- </h3> -->
                                    <v-text-field
                                        v-model="password"
                                        dense
                                        solo
                                        hide-details
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="showPassword = !showPassword"
                                        :type="showPassword ? 'text' : 'password'"
                                        label="Passwort"
                                    >
                    
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    v-else
                                >
                                    <v-row >
                                        <v-col class="text-center">
                                            <v-progress-circular indeterminate></v-progress-circular>
                                        </v-col>
                                    </v-row>
    
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
    
                </popup-dialog-base>
            </div>
        </template>
        </report-card-base>
    </div>

</template>

<script>
import ReportCardBase from '../../pages/DashboardPages/Reports/ReportCardBase.vue'
import CampDataInterface from './CampDataInterface.vue'
import PopupDialogBase from '../PopupDialogBase.vue';
import ConfirmDialog from '../ConfirmDialog.vue';
import InterfaceTableBuilder from '../interfaces/InterfaceTableBuilder.vue';
import FileBrowser from '../FileBrowser/FileBrowser.vue';
export default {
    components: {
    ReportCardBase,
    CampDataInterface,
    PopupDialogBase,
    ConfirmDialog,
    InterfaceTableBuilder,
    FileBrowser
},
    async mounted(){
        this.reloadData();

        let url = "/items/camp?fields=dokumente,id&filter[jahr][_eq]="+this.year.toString();
        let result = await this.$network.requestGet(url);
        if(result.status === 200){
            if(result.data.data.length > 0){
                this.documents = result.data.data[0];
            }
        }
        url = "/fields/camp/dokumente"
        result = await this.$network.requestGet(url);
        if(result.status === 200){
            this.documentsMeta = result.data.data.meta;
        }
    },
    props:{
        year:{type:String, default:"2022"},
        dashboard:Object
    },
    data(){
        return {
            documents:undefined,
            documentsMeta:undefined,
            loaded:false,
            campSettings:this,
            settingsTab:null,
            tab:null,
            popupButtons:[
                {
                    label:"Ja", 
                    color:"primary", 
                    icon:"", 
                    method:()=>{this.deleteCamp()}, 
                    viewCondition:()=>{return true},
                    disabled:()=>{return this.loading}
                },
                {
                    label:"Nein", 
                    color:"normal", 
                    icon:"", 
                    method:()=>{this.cancel()}, 
                    viewCondition:()=>{return true},
                    disabled:()=>{return this.loadingfalse}
                },

            ],
            showPassword:false,
            password:undefined,
            loading:false,
            camp:undefined,
            freizeitDaten:undefined,
            freizeitDatenCopy:this.freizeitDaten,
            campCopy:this.camp,
            freizeitDatenSchema:{
                freizeit_daten_tabellen:{
                    key:"Freizeit-Daten-Tabellen",
                    value:undefined,
                    type:'tabellen-builder'
                }
            },
            campSchema:{
                id:{
                    key:"Id",
                    value:undefined
                },
                freizeit_editierbar:{
                    key:"Daten Editierbar",
                    value:undefined,
                    type:'boolean'
                },
                freizeit_daten_aktiviert:{
                    key:"Freizeit-Daten Aktiviert",
                    value:undefined,
                    type:'boolean'
                },
                teilnehmer_anmeldung_aktiv:{
                    key:"Teilnehmer Anmeldung Aktiviert",
                    value:null,
                    type:'boolean'
                },
                teilnehmer_anmeldung_countdown:{
                    key:"Teilnehmer Anmeldung Countdown",
                    value:null,
                    type:'datetime-local'
                },
                mitarbeiter_anmeldung_aktiv:{
                    key:"Mitarbeiter Anmeldung Aktiviert",
                    value:null,
                    type:'boolean'
                },
                camphelfer_anmeldung_aktiv:{
                    key:"Camphelfer Anmeldung Aktiviert",
                    value:null,
                    type:'boolean'
                },
                eingabe_evaluierung:{
                    key:"Formulareingabe Evaluieren",
                    value:null,
                    type:'boolean'
                },
                schulung_von:{
                    key:"Mitarbeiter Schulung von",
                    value:null,
                    type:'date'
                },
                schulung_bis:{
                    key:"Mitarbeiter Schulung bis",
                    value:null,
                    type:'date'
                },
                sonderurlaub_formular_abgabe:{
                    key:"Sonderurlaub Formular Abgabe",
                    value:null,
                    type:'date'
                },
                tasse_preis:{
                    key:"Tasse Preis (€)",
                    value:null,
                    type:"number"
                },
                tshirt_preis:{
                    key:"T-Shirt Preis (€)",
                    value:null,
                    type:"number"
                }
            },
            freizeiten:[],
            freizeitenCopy:this.freizeiten,
            freizeitSchema:{
                id:{
                    key:"Id",
                    value:undefined
                },
                name:{
                    key:"Freizeit-Name",
                    value:"E1",
                    type:"text"
                },
                datum_von:{
                    key:"Freizeit-Datum Von",
                    value:"",
                    type:"date"
                },
                datum_bis:{
                    key:"Freizeit-Datum Bis",
                    value:"",
                    type:"date"
                },
                alter_von:{
                    key:"Teilnehmer-Alter Von",
                    value:"",
                    type:"date"
                },
                alter_bis:{
                    key:"Teilnehmer-Alter Bis",
                    value:"",
                    type:"date"
                },
                anreise_mitarbeiter:{
                    key:"Mitarbeiter Anreisedatum",
                    value:"",
                    type:"datetime-local"
                },
                preis:{
                    key:"Preis (€)",
                    value:135,
                    type:"number"
                },
                // preis_tasse:{
                //     key:"Preis Tasse (€)",
                //     value:5,
                //     type:"number"
                // },
                // preis_tshirt:{
                //     key:"Preis T-Shirt (€)",
                //     value:10,
                //     type:"number"
                // },
                mitarbeiter_alter_von:{
                    key:"Mitarbeiter-Mindestalter",
                    value:18,
                    type:"number"
                },
                mitarbeiter_positionen:{
                    key:"Mitarbeiter Positionen",
                    value:"",
                    type:"positions"
                },
                provinzen:{
                    key:"Provinzen",
                    value:"",
                    type:"provinzen"
                }
            },
            
        }
    },
    methods:{
        async freizeitKostenFix(){
            console.log("Freizeit Kosten Fix");
            console.log("======= Mitarbeiter =======");
            let url = "/items/mitarbeiter_"+this.year;
            url +="?limit=-1&fields=id,taetigkeit,tassen,vorname,nachname"
            let result = await this.$network.requestGet(url);
            if (result.status === 200){
                console.log(this.camp)
                for(let person of result.data.data){
                    let preisFreizeit = 0;
                    let preisTassen = 0
                    if(["Beruf","Rentner"].includes(person.taetigkeit)){
                        preisFreizeit = 40;
                    }else{
                        preisFreizeit = 30;
                    }
                    preisTassen = this.camp.tasse_preis.value * person.tassen;
                    let preisTotal = preisFreizeit + preisTassen;
                    console.log(person.vorname," ", person.nachname, " " ,preisTotal);
                    let url = "/items/mitarbeiter_"+this.year+"/"+person.id;
                    let payload = {
                        kosten_gesamt:preisTotal
                    }
                    await this.$network.requestPatch(url, payload);
                }
            }

            console.log("======= Teilnehmer =======")
            url = "/items/teilnehmer_"+this.year;
            url +="?limit=-1&fields=id,freizeit,tshirt,tassen,vorname,nachname"
            result = await this.$network.requestGet(url);
            if(result.status === 200){
                for(let person of result.data.data){
                    let preisFreizeit = 0;
                    for(let freizeit of this.freizeiten){
                        if (freizeit.name.value === person.freizeit){
                            preisFreizeit = freizeit.preis.value;
                        }
                    }
                    let preisTasse = this.camp.tasse_preis.value * person.tassen;
                    let preisTshirt = person.tshirt ? this.camp.tshirt_preis.value : 0;
                    let preisTotal = preisFreizeit + preisTasse + preisTshirt;
                    console.log(person.vorname," ", person.nachname, " " ,preisTotal);
                    let url = "/items/teilnehmer_"+this.year+"/"+person.id;
                    let payload = {
                        kosten_gesamt:preisTotal
                    }
                    await this.$network.requestPatch(url, payload);
                }
            }
        },
        dataChanged(){
            let frezeitChanged = JSON.stringify(this.freizeiten) !== JSON.stringify(this.freizeitenCopy);
            let campChanged = JSON.stringify(this.camp) !== JSON.stringify(this.campCopy);
            let freizeitDatenChanged = JSON.stringify(this.freizeitDaten) !== JSON.stringify(this.freizeitDatenCopy);
            return frezeitChanged || campChanged || freizeitDatenChanged;
        },
        restoreFreizeiten(){
            this.freizeiten = undefined;
            this.camp = undefined;
            this.freizeitDaten = undefined;


            this.$nextTick(()=>{
                this.freizeiten = JSON.parse(JSON.stringify(this.freizeitenCopy));
                this.camp = JSON.parse(JSON.stringify(this.campCopy));
                this.freizeitDaten = JSON.parse(JSON.stringify(this.freizeitDatenCopy));
            })
        },
        async saveCampData(){
            for(let freizeit of this.freizeiten){

                let data = {}
                for(let key in freizeit)[
                    data[key] = freizeit[key].value
                ]
                await this.$network.requestPatch("/items/freizeit/"+freizeit.id.value, data)
            }

            let data = {}
            for(let key in this.camp){
                data[key] = this.camp[key].value;
            }
            for(let key in this.freizeitDaten){
                data[key] = this.freizeitDaten[key].value
            }
            await this.$network.requestPatch("/items/camp/"+this.camp.id.value, data)

            this.dashboard.app.showNotification("Freizeit-Daten wurden geändert.",2000, "success")
            this.reloadData();
            this.$emit("onCampDataSaved", data);
        },
        async reloadData(){
            let url = "/items/camp?filter[jahr][_eq]="+this.year+"&fields=*,freizeit.*"
            let result = await this.$network.requestGet(url);
            if(result.status === 200 && result.data.data.length > 0){
                this.camp = this.convertData(result.data.data, this.campSchema)[0];
                this.campCopy = JSON.parse(JSON.stringify(this.camp));
                this.freizeitDaten = this.convertData(result.data.data, this.freizeitDatenSchema)[0];
                this.freizeitDatenCopy = JSON.parse(JSON.stringify(this.freizeitDaten));
                this.freizeiten = this.convertData(result.data.data[0].freizeit, this.freizeitSchema);
                this.freizeitenCopy = JSON.parse(JSON.stringify(this.freizeiten));
            }
            this.loaded=true;
        },
        convertData(data, schema){
            let dataConverted = []
            for(let dataset of data){
                let newObject = {}
                for(let key in schema){
                    newObject[key] = {
                        value:dataset[key],
                        type:schema[key].type,
                        key:schema[key].key,
                    }
                }
                dataConverted.push(newObject)
            }
            return dataConverted;
        },
        showDeleteCampDialog(){
            this.$refs.dialog.show();
        },
        cancel(){
            this.password = undefined;
            this.$refs.dialog.hide();
        },
        async deleteCamp(){
            this.loading = true;
            let payload = {data:{
                password:this.password,
                year:this.year
            }}
            let result = await this.$network.requestDelete("/create-camp-data", payload);
            if(result.status === 200){
                this.$refs.dialog.hide();
                console.log("CORRECT PASSWORD")
                
                this.dashboard.loadCampList();
                this.dashboard.selectCamp(this.dashboard.campYears[this.dashboard.campYears.length-1]);
                this.$refs.dialog.showNotification("Freizeit-Daten wurden gelöscht","success","mdi-check-bold");
            }else{
                console.log("WRONG PASSWORD")
                this.$refs.dialog.showNotification("Passwort wurde falsch eingegeben","error","mdi-alert");
            }   
            this.loading = false;
            this.password = undefined;
        }
    }
}
</script>