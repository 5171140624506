<template>
    <div>

        <v-alert
            border="left" 
            class="pa-1 ma-0 mb-1"
            text 
            hide-details
            dense 
            color="primary"
        >
            <v-row>
                <v-col class="d-flex flex-row">

                    <v-btn icon class="primary--text"
                        v-if="!isMovileDevice()"
                        @click="showPdf(id)"
                    >
                        <v-icon>
                            mdi-eye
                        </v-icon>
                    </v-btn>
                    <div class="ma-2">
                        {{ fileName }}  
                        <!-- <span style="font-size:small; font-weight: bold;">
                            ({{(fileMeta[item.id].filesize*0.000001).toFixed(2)}} MB)
                        </span> -->
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn icon class="primary--text"
                        @click="downloadFile(id)"
                    >
                        <v-icon>
                            mdi-download
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <popup-dialog-base
            ref="PdfViewer"
            :title="fileName"
            :fullscreen="false"
            :tool-buttons="[]"
        >

            <template v-slot:content>
                <iframe 
                    v-if="pdfUrl" 
                    style="margin:0; padding:0; border:0px; width:100%; height:99%;" 
                    :src="pdfUrl"
                >
                </iframe>
                <v-progress-linear v-else indeterminate></v-progress-linear>
            </template>
        </popup-dialog-base>
    </div>
</template>

<script>
import PopupDialogBase from '../PopupDialogBase.vue';

export default {
    components:{
        PopupDialogBase
    },
    props:{
        id:String,
        fileName:String
    },
    data(){
        return{
            activePdfId:undefined,
            activeFilename:undefined,
            activeFileTpe:undefined,
            pdfUrl:undefined,
        }
    },
    methods:{
        
        async showPdf(id){            
            this.activePdfId = id;

            this.$refs.PdfViewer.show();
            this.$nextTick(async ()=>{
                let url = '/assets/'+this.activePdfId;
                
                this.currentPage = 1;
                let result = await this.$network.requestAssetAsBase64(url);
                if(result.status === 200){
                    this.pdfUrl = "data:application/pdf;base64,"+result.data;
                }
            })
        },
        downloadFile(id){
            let url = this.$network.app.restUrl+"/assets/"+id
            this.$axios.get(url,{responseType:"blob"})
            .then(result => {
                if(result.status === 200){
                    const blob = new Blob([result.data], {type: 'application/pdf'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.target = "_blank"
                    link.download = this.fileName;
                    link.rel = "noopener noreferrer";
                    setTimeout(()=>{
                        link.click();
                        document.body.removeChild(link);
                    },70);
                }
            }).catch(error =>{
                console.log(error)
            })
        },
        isMovileDevice() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return true;
            }

            if (/android/i.test(userAgent)) {
                return true;
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return true;
            }

            return false;
        },
    }
}
</script>