<template>
    <v-row>

        <portal to="sub-menu-title">
            <v-btn
                icon
                x-small
                :class="app.dashboard.$refs.NavBar.editMenu? 'success--text' : ''"
                @click="(app.dashboard.$refs.NavBar.editMenu = !app.dashboard.$refs.NavBar.editMenu)"
            >
                <v-icon>
                    mdi-pencil
                </v-icon>
            </v-btn>
        </portal>

        <portal to="header-tools">
            <v-row
                class=""
            >
                <v-btn
                    fab
                    :disabled="(selectedRows.length === 0)"
                    x-small class="error mr-2"
                    @click="showDeleteDialog()"
                >
                    <v-icon>
                        mdi-delete
                    </v-icon>
                </v-btn>

                <v-btn
                    fab
                    :disabled="(selectedRows.length === 0)"
                    x-small class="primary mr-2"
                    @click="createPrintSheet()"
                >
                    <v-icon>
                        mdi-printer
                    </v-icon>
                </v-btn>

                <qr-scanner
                    ref="QRScanner"
                    :inventory="inventory"
                />
                <v-btn
                    fab
                    x-small
                    class="primary mr-3"
                    @click="createNewItem()"
                >
                    <v-icon>
                        mdi-tag-plus
                    </v-icon>
                </v-btn>
            </v-row>
        </portal>

        
        <confirm-dialog
            ref="deleteDialog"
            icon="mdi-alert"
            icon-color="error--text"
            title="Artikel Löschen?"
            text="Ausgewählte Artikel werden unwiderruflich gelöscht!"
            :onConfirm="deleteSelectedItems"
        />
        
        <v-col>
                <v-card>

                    <v-data-table
                        :items="filteredInventoryItems"
                        v-model="selectedRows"
                        show-select
                        :headers="headers"
                    >
                        <template v-slot:top>
                            <v-row class="mt-2 mb-2 mr-1 ml-1">
                                <v-col>
                                    <v-text-field 
                                        v-model="search"
                                        dense 
                                        hide-details 
                                        solo 
                                        prepend-inner-icon="mdi-magnify"
                                        placeholder="Suche"
                                        clearable
                                    />
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item="props">
                            <tr
                                :class="{'striped':props.index%2===0 || props.index===0}"
                            >
                                <td class="pa-1">
                                    <v-simple-checkbox 
                                        @click="toggleSelection(props.item)" 
                                        v-model="props.isSelected" 
                                        class="ml-3"
                                    />
                                </td>
                                <td
                                    style="text-align:center;"
                                    class="ma-0 pa-0 text-center justify-center"
                                >
                                    <div
                                        v-if="preloaded && getImage(props.item.bild, true) != ''"
                                        :class="$vuetify.breakpoint.xsOnly ? 'ml-1 mr-1':''"
                                        style="width:32px; margin:0 auto;"
                                    >
                                        <v-img 
                                            width="32"
                                            class="rounded"
                                            contain
                                            :src="getImage(props.item.bild, true)"
                                        />
                                            <!-- :src="restUrl+'/assets/'+props.item.bild+'?width=40&height=40&fit=cover'" -->
                                    </div>
                                    <v-icon v-else class="secondary--text text--lighten-1" size="40">
                                        mdi-image
                                    </v-icon>
                                    <!-- <div style="width:40px; height:40px;" v-else /> -->
                                </td>
                                <td>
                                    <v-row class="d-flex justify-start">
                                        <strong>
                                            <h2 
                                                :class="[props.item.anzahl >= props.item.anzahl_minimum ? 'success--text' : 'warning--text']"
                                            >
                                                {{props.item.anzahl}}
                                            </h2>
                                        </strong>
                                    </v-row>
                                </td>
                                <td>
                                    <v-row class="d-flex justify-start">
                                        <strong>
                                            <h2 
                                                :class="[props.item.anzahl_verliehen >0 ? 'warning--text' : 'secondary--text']"
                                            >
                                                {{props.item.anzahl_verliehen}}
                                            </h2>
                                        </strong>
                                    </v-row>
                                </td>
                                <td style="width:100%;">
                                    <v-row class="d-flex justify-start">
                                        {{props.item.name}}
                                    </v-row>
                                </td>
                                <td style="width:100%;">
                                    <v-row class="d-flex justify-start">
                                        <v-chip label small v-if="(props.item.lagerplatz && props.item.lagerplatz != '')"><strong>{{props.item.lagerplatz}}</strong></v-chip>
                                    </v-row>
                                </td>
                                <td>
                                    <v-row class="pr-2 d-flex justify-end">
                                        <popup-dialog-base
                                            :title="'Artikel - '+getActiveItemName()"
                                            :toolButtons="editItemButtons"
                                            ref="EditInventoryItemDialog"
                                        >
                                            <template v-slot:button>
                                                <v-btn
                                                    class="primary"
                                                    fab
                                                    x-small
                                                    @click="editInventoryItem(props.item);"
                                                >
                                                    <v-icon>
                                                        mdi-pencil
                                                    </v-icon>
                                                </v-btn>
                                            </template>
        
                                            <template 
                                                v-if="activeItem"
                                                v-slot:content>
        
                                                <div v-if="getImage(activeItem.bild, false) != ''">
                                                    <v-img     
                                                        height="250"
                                                        :src="getImage(activeItem.bild, false)"
                                                    />
                                                    <v-btn 
                                                        style="z-index:10000; position:absolute; right:10px; top:74px;"
                                                        fab
                                                        x-small
                                                        class="error"
                                                        @click="deleteImage"
                                                    >
                                                        <v-icon>
                                                            mdi-close-thick
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                                
                                                <v-card-text>
                                                    <v-row v-for="(field, key) in filteredFields" :key="key">
                                                        <v-col 
                                                            cols="12"
                                                            sm="3"
                                                            class="pa-1"
                                                            :class="[!$vuetify.breakpoint.xsOnly? 'text-end' : '']"
                                                        >   
                                                            <div 
                                                                :class="[!$vuetify.breakpoint.xsOnly? 'mt-2' : '']"
                                                            >
                                                                <strong>{{field.label}}</strong>
                                                            </div>
                                                        </v-col>
                                                        <v-col
                                                            :class="[!$vuetify.breakpoint.xsOnly? 'pa-1' : 'pa-0 pr-1 pl-1']"
                                                        >   
                                                            <div class="d-flex"
                                                                v-if="['number','text','array', 'textarea'].includes(field.type)"
                                                            >
                                                                <interface-combobox
                                                                    class="pr-2"
                                                                    v-if="field.type === 'array'"
                                                                    v-model="activeItem[key]"
                                                                    :items="availableTags"
                                                                />
                                                                <v-text-field
                                                                    v-else-if="field.type !== 'textarea'"
                                                                    solo
                                                                    hide-details
                                                                    dense
                                                                    :type="field.type"
                                                                    v-model="activeItem[key]"
                                                                    class="mr-2"
                                                                />
                                                                <v-textarea 
                                                                    v-else-if="field.type === 'textarea'"
                                                                    solo hide-details
                                                                    v-model="activeItem[key]"
                                                                    class="mr-2"
                                                                />
                                                                <div style="min-width:144px;" v-if="field.type === 'number'">
                                                                    <v-btn class="primary mr-2"
                                                                        @click="increaseCount(key)"
                                                                    >
                                                                        <v-icon>mdi-chevron-up</v-icon>
                                                                    </v-btn>
                                                                    <v-btn class="primary mr-2"
                                                                        @click="decreaseCount(key)"
                                                                    >
                                                                        <v-icon>mdi-chevron-down</v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                            <interface-image-upload
                                                                v-else-if="field.type === 'image'"
                                                                :uploadFolder="'Inventar'"
                                                                :collectionName="'inventar'"
                                                                :fieldName="'bild'"
                                                                :itemId="activeItem.id"
                                                                :updateMethod="refreshEditInventory"
                                                            />
                                                            
                                                        </v-col>
                                                    </v-row>
                                                    
                                                    <!-- {{activeItem}} -->
                                                </v-card-text>
                                            </template>
                                        </popup-dialog-base>
                                    </v-row>
                                </td>
                            </tr>
                        </template>
                    
                    </v-data-table>
                </v-card>
        </v-col>
        

        <popup-dialog-base
            :title="'QRCodes Drucken'"
            ref="QRCodePrinter"
            :tool-buttons="qrCodeButtons"
        >
            <template v-slot:content>
                <v-card-text>
                    <qrcode :ref="item.id" v-show="false" :options="{'margin':10,'size':240,  'scale':4}" :value="getInventoryLink(item)"
                        v-for="(item, index) in selectedRows" :key="index"
                    />
                    <canvas ref="printCanvas" width="629" height="877"></canvas>
                    
                </v-card-text>
                
            </template>

        </popup-dialog-base>
    </v-row>
</template>

<script>
import QrScanner from '../../components/QRScanner.vue';
import PopupDialogBase from '../../components/PopupDialogBase.vue';
import InterfaceImageUpload from '../../components/interfaces/InterfaceImageUpload.vue';
import InterfaceCombobox from '../../components/interfaces/InterfaceCombobox.vue';
import ConfirmDialog from '../../components/ConfirmDialog.vue';

export default {
    components:{
        PopupDialogBase,
        QrScanner,
        InterfaceImageUpload,
        InterfaceCombobox,
        ConfirmDialog
    },
    props:{
        navBar:Object,
        filteredTags:{type:Array, default:()=>[]}
    },
    inject:[
        "app"
    ],  
    async created(){
        await this.requestInventory();
    },
    data(){
        return{
            selectedRows:[],
            search:"",
            headers:[
                {text:'Bild',width:60,sortable:false},
                {text:'Anzahl', width:60},
                {text:'Verliehen', width:70},
                {text:'Name'},
                {text:'Platz'},
                {text:'Aktionen', align:'end'}
            ],
            restUrl:process.env.VUE_APP_REST_URL,
            inventory:this,
            inventoryItems:undefined,
            activeItem:undefined,
            qrText:undefined,
            qrLabel:undefined,
            qrCodeButtons:[
                {label:"Drucken", color:"primary", icon:"mdi-printer", method:this.printQRCode, viewCondition:()=>{return true}},
                {label:"Download", color:"primary", icon:"mdi-download", method:this.downloadQRCode, viewCondition:()=>{return true}}
            ],
            editItemButtons:[
                // {label:"Scanner Öffnen", color:"primary", icon:"mdi-barcode-scan", method:this.openScanner, viewCondition:()=>{return true}},
                {label:"Zurück", color:"error", icon:"", method:this.hideEditInventoryDialog, viewCondition:()=>{return true}},
                {label:"Speichern", color:"primary", icon:"", method:this.saveInventoryItemEdit, viewCondition:()=>{return true}},
            ],
            fields:{
                bild:{label:"Bild", type:"image", editable:false},
                lagerplatz:{label:"Lagerplatz", type:"text", editable:false},
                name:{label:"Name", type:"text", editable:false},
                anzahl:{label:"Anzahl", type:"number", editable:true},
                anzahl_verliehen:{label:"Verliehen", type:"number", editable:true},
                anzahl_minimum:{label:"Minimum Anzahl", type:"number", editable:true},
                tags:{label:"Tags", type:"array", editable:false},
                kommentar:{label:"Kommentar", type:"textarea", editable:false}
            },
            preloaded:false,
            images:{
                thumbs:{},
                original:{}
            }
        }
    },
    computed:{
        filteredInventoryItems(){
            if(!this.search || this.search === ""){
                return this.inventoryItems;
            }
            let items = []
            for(let item of this.inventoryItems){
                if(item.name.toLowerCase().replaceAll(" ","").includes(this.search.toLowerCase().replace(" ",""))){
                    items.push(item)
                }
                if(item.tags){
                    for(let tag of item.tags){
                        if(tag.toLowerCase().replaceAll(" ","").includes(this.search.toLowerCase().replace(" ",""))){
                            if(items.indexOf(item) === -1){
                                items.push(item)
                            }
                        }
                    }
                }
                if(item.lagerplatz && item.lagerplatz != ""){
                    if(item.lagerplatz.toLowerCase().replaceAll(" ","").includes(this.search.toLowerCase().replace(" ",""))){
                        items.push(item)
                    }
                }
            }
            return items;
        },
        filteredFields(){
            let fields = Object.assign({}, this.fields);
            if(this.activeItem.bild || this.activeItem.id === undefined){
                delete fields.bild
            }
            return fields;
        },
        availableTags(){
            let tags = []
            for(let subMenuItem of this.app.activeMenu.sub_menu_items){
                tags.push(subMenuItem.title)
            }
            return tags
        }
    },
    methods:{
        async refreshEditInventory(){
            await this.requestInventory();
            this.preloadImages([this.activeItem], false);
        },
        async preloadImages(items, thumb=false, refresh=false){
            if(refresh){
                this.preloaded = false;
            }
            if(items){
                for(let item of items){
                    let url = '/assets/'+item.bild
                    if(thumb){
                        url += '?width=40&height=40&fit=cover'
                    }
                    let images = thumb ? this.images.thumbs : this.images.original;
                    if(item.bild && !images[item.bild]){
                        let result = await this.$network.requestAssetAsBase64(url);
                        if(result.status === 200){
                            if(thumb){
                                this.images.thumbs[item.bild] = "data:image/png;base64,"+result.data;
                            }else{
                                this.images.original[item.bild] = "data:image/png;base64,"+result.data;
                            }
                        }
                    }
                }
            }
            this.preloaded = true;
        },
        getImage(imageId, thumb=false){
            let images = thumb ? this.images.thumbs : this.images.original;
            if(images[imageId]){
                return images[imageId];
            }
            return '';

        },
        showDeleteDialog(){
            this.$refs.deleteDialog.show();
        },
        async deleteSelectedItems(){
            let ids = []
            for(let item of this.selectedRows){
                ids.push(item.id);
            }
            let url="/items/inventar"
            let result = await this.$network.requestDelete(url, {data:ids})
            if(result.status === 204){
                for(let i=0; i<this.inventoryItems.length; i++){
                    let item = this.inventoryItems[i];
                    if(ids.includes(item.id)){
                        this.inventoryItems.splice(i, 1);
                        i -= 1;
                    }
                }
                this.selectedRows = [];
            }
        },
        toggleSelection(item){
            let index = this.selectedRows.indexOf(item)
            if(index !== -1){
                this.selectedRows.splice(index, 1);
            }else{
                this.selectedRows.push(item);
            }
        },
        async deleteImage(){
            let payload = {
                bild:null
            }
            let result = await this.$network.requestDelete("/files/"+this.activeItem.bild);

            result = await this.$network.requestPatch("/items/inventar/"+this.activeItem.id, payload)
            if(result.status === 200){
                this.activeItem.bild = null;
            }
        },
        getActiveItemName(){
            if(this.activeItem){
                return this.activeItem.name;
            }
            return "";
        },
        async editInventoryItem(item){
            await this.preloadImages([item], false)

            this.activeItem = item;
            this.$refs.EditInventoryItemDialog.show();
        },
        getInventoryLink(item){
            let url = "dashboard.espelcamp.de/?inventar="
            if(item && item.id){
                url += item.id;
            }
            return url;
        },
        getCanvasSize(){
            if(this.$refs.qrcode){
                return this.$refs.qrcode.$el.height;
            }
            return 0
        },
        createPrintSheet(){
            this.$refs.QRCodePrinter.show();
            this.$nextTick(()=>{
                let rowLength = 4;
                let y = 0
                let margin = 4

                let printCanvas = this.$refs.printCanvas;
                let ctx2 = printCanvas.getContext("2d");
                ctx2.clearRect(0,0, printCanvas.width, printCanvas.height);
                for(let i=0; i<this.selectedRows.length; i++){
                    let item = this.selectedRows[i];

                    let qrCanvas = this.$refs[item.id][0].$el;
                    let ctx = qrCanvas.getContext('2d');
                    ctx.font = "18px Arial";
                    let w = ctx.measureText(item.name).width;
                    ctx.fillText(item.name, (qrCanvas.width - w)*0.5, 30);
                    ctx.strokeStyle = "#cccccc"
                    ctx.beginPath();
                    ctx.rect(0,0,qrCanvas.width, qrCanvas.height);
                    ctx.stroke();

    
                    
                    ctx2.fillStyle="#cccccc";
                    let width = (printCanvas.width / rowLength) - margin;
                    let height = width;
                    if (i%rowLength === 0 && i>0){
                        y += 1
                    }

                    ctx2.drawImage(qrCanvas, (i%rowLength)*(width+margin),y*(height+margin), width, height);
                }
            })

        },
        async saveInventoryItemEdit(){
            if(this.activeItem.id){
                let result = await this.$network.requestPatch("/items/inventar/"+this.activeItem.id, this.activeItem);
                if(result.status === 200){
                    this.$refs.EditInventoryItemDialog.showNotification("Erfolgreich gespeichert.","success","mdi-information");
                    this.requestInventory();
                    this.$refs.EditInventoryItemDialog.hide();
                }
            }else{
                let result = await this.$network.requestPost("/items/inventar", this.activeItem);
                if(result.status === 200){
                    this.$refs.EditInventoryItemDialog.showNotification("Neuer Artikel wurde erstellt.","success","mdi-information");
                    this.requestInventory();this.$refs.EditInventoryItemDialog.hide();
                }
            }
        },
        downloadQRCode(){
            var link = document.createElement('a');
            link.download = this.activeItem.name+'.png';
            link.href = this.$refs.printCanvas.toDataURL()
            link.click();
        },
        printQRCode(){
            let html = `
                <html>
                    <head>
                    <style>
                        @page {
                            size:auto;
                        }
                        @media print {
                            .pagebreak { page-break-before: always; } /* page-break-after works, as well */
                        body{
                            width:100%;
                            height:100%;
                            margin:0px;
                            padding:0px;
                            font-family:Arial;
                        }
                        table{
                            width:100%;
                        }
                        table, tr, td {
                            border-collapse:collapse;
                            border:1px solid black;
                            font-family:Arial;
                            font-size:10px;
                        }
                        td{
                            padding:4px;
                        }
                    </style>
                    </head>
                    <body>
                        <img style="width:100%;" src='${this.$refs.printCanvas.toDataURL()}'>
                    </body>
                </html>
            `

            var win=window.open();
            win.document.write(html);
            // win.document.write("<img src='"+this.$refs.printCanvas.toDataURL()+"'/>");

            this.$nextTick(()=>{
                win.print();
                win.close();
            })
        },
        async requestInventory(){

            let url = "/items/inventar?limit=-1";
            let result = await this.$network.requestGet(url);
            this.inventoryItems = [];
            if(result.status === 200){
                let items = result.data.data;
                for(let item of items){
                    if(!this.filteredTags || (this.filteredTags && this.filteredTags.length === 0)){
                        this.inventoryItems.push(item)
                    }else{
                        if(item.tags){
                            for(let tag of item.tags){
                                if(this.filteredTags.includes(tag)){
                                    this.inventoryItems.push(item);
                                }
                            }
                        }
                    }
                }
                if(this.activeItem){
                    for(let item of items){
                        if(item.id === this.activeItem.id){
                            this.activeItem = item;
                            break;
                        }
                    }
                }
            }
            await this.preloadImages(this.filteredInventoryItems, true);
        },
        increaseCount(key){
            this.activeItem[key] ++;
        },
        decreaseCount(key){
            this.activeItem[key] --;
        },
        openScanner(){
            this.$refs.QRScanner.openScanner();
            this.$refs.EditInventoryItemDialog.hide();
        },
        createNewItem(){
            this.activeItem = {
                name:"",
                anzahl:0,
                anzahl_minimum:0,
                kommentar:""
            }
            this.$refs.EditInventoryItemDialog.show();
        },
        hideEditInventoryDialog(){
            this.$refs.EditInventoryItemDialog.hide();
        }
    }
}
</script>


<style>
    .striped {
        background-color: rgba(0, 0, 0, .03);
    }
    /* td img{
        display: block;
        margin-left: auto;
        margin-right: auto;

    } */
</style>