<template>
    <div>
        <slot name="default">
            <v-btn 
                class="primary ml-2" fab x-small elevation="2" 
                @click="show()"
            >
                <v-icon>mdi-database-export</v-icon>
            </v-btn>
        </slot>
        <confirm-dialog
            ref="exportDialog"
            :icon="'mdi-database-export'"
            title="Daten Export"
            text="Daten im XLSX-Format exportieren?"
            :onConfirm="exportCSV"
        >
            <template>
                <div v-if="collection && collection.indexOf('mitarbeiter_') !== -1">
                    Export Preset
                    <v-select
                        label="Export Preset"
                        solo hide-details dense
                        v-model="activePreset" :items="exportPresets" item-text="label" item-value="label"
                    >
                        <v-template></v-template>
                    </v-select>
                </div>
                <!-- <v-checkbox v-model="removeLineBreaks" label="Zeilenumbrüche entfernen"></v-checkbox> -->
            </template>

        </confirm-dialog>
    </div>
</template>

<script>
import Excel from "exceljs";
import ConfirmDialog from './ConfirmDialog.vue';
export default {
    components:{
        ConfirmDialog
    },
    props:{
        collection:String,
        fileName:String,
        table:HTMLTableElement,
        peopleManager:undefined
    },
    data(){
        return{
            removeLineBreaks:true,
            activePreset:"Alles",
            exportPresets:[
                {
                    label:"Alles",
                    fields:"*"
                },
                {
                    label:"MA-Beiträge",
                    fields:"nachname,vorname,kosten_gesamt,kosten_bezahlt,status_bezahlt,status_bezahlung_kommentar"
                },
                {
                    label:"Alina's Auswahl",
                    fields:"nachname,vorname,strasse,plz,ort,email,mobil_telefon,geburtstag,geburtsort,geschlecht,familienstand,status_fuehrungszeugnis,status_fuehrungszeugnis_datum,sonderurlaub,status_sonderurlaub,status_bezahlt,kosten_gesamt,kosten_bezahlt,mitarbeiter_wunsch,mitarbeiter_position,schulungen,instrumente,tshirt_typ,tshirt_groesse_herren,tshirt_groessen_damen,gemeinde,meine_gemeinde"
                },
            ]
        }
    },
    computed:{
        fieldExports(){
            return this.exportPresets.filter(item => item.label === this.activePreset)[0].fields;
        }
    },
    methods:{
        show(){
            this.$refs.exportDialog.show()
        },

        cleanupData(data){
            let cleanedUpData = []
            for(let item of data){
                if(this.fieldExports === "*"){
                    cleanedUpData.push(item)
                }else{
                    const fields = this.fieldExports.split(",")
                    let cleanedItem = {}
                    for(let field of fields){
                        cleanedItem[field] = item[field];
                    }
                    cleanedUpData.push(cleanedItem);
                }
            }

            return cleanedUpData
        },

        async exportCSV(){
            const workbook = new Excel.Workbook();
            const options = {
                dateFormats: ['DD/MM/YYYY']
            };
            const worksheet = await workbook.addWorksheet("Seite 1", options);

            if(this.table){
                for(let i in this.table.rows){
                    let tableRow = this.table.rows[i];
                    let row = [];
                    for(let j in tableRow.cells){
                        let cell = tableRow.cells[j];
                        row.push(cell.innerText);
                    }
                    worksheet.addRow(row);
                }
            }else{

                // check if single items are selected in table. If so, request only selected items for export.
                const selectedItems = this.peopleManager.peopleSelectedRows;
                let result;
                if(selectedItems.length === 0){
                    result = await this.$network.requestPeopleData(this.collection, this.peopleManager.getSelectedFilter());
                }else{
                    let ids = []
                    for(let item of selectedItems){
                        ids.push(item.id)
                    }
                    let url = `/items/${this.collection}?limit=-1&filter[id][_in]=${ids}`;
                    result = await this.$network.requestGet(url)
                }

                if(result.status === 200){
                    let data = this.cleanupData(result.data.data);

                    if(data.length > 0){
                        let header = []
                        for(let key in data[0]){
                            header.push(key);
                        }
                        worksheet.addRow(header);
                        for(let item of data){
                            let row = []
                            for(let key in item){
                                if(item[key]){
                                    row.push(item[key]);
                                }else{
                                    row.push("")
                                }
                            }
                            worksheet.addRow(row);
                        }
                    }
    
                }
            }

            // auto width columns
            worksheet.columns.forEach(column => {
                const lengths = column.values.map(v => v.toString().length);
                const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
                column.width = maxLength;
            });

            // export xlsx file
            const buffer = await workbook.xlsx.writeBuffer();
            const bufferData = new Uint8Array(buffer);
            const blob = new Blob([bufferData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            let downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = `${this.fileName}.xlsx`;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }
}
</script>